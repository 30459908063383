import styled from "styled-components";
import { media } from "../../Assets/Styles/utils";
import "../../Assets/fonts/Almarai-Regular.ttf";
import AlmaraiFont from "../../Assets/fonts/Almarai-Regular.ttf";

export const ScholarI = styled.img`
  position: relative;
  left: ${(props) => (props.lang === "ar" ? "auto" : "27%")};
  right: ${(props) => (props.lang === "ar" ? "27%" : "auto")};
  top: -7%;
  z-index: 2;
  transition: transform 0.5s ease;
  /* Replace with your image source */
`;

export const Annot = styled.img`
  position: relative;
  left: -44%;
  top: -21%;
  z-index: 2;
  /* Replace with your image source */
`;

export const AboutCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 48px;
  gap: 48px;
  position: relative;
`;

export const AboutAnimation = styled.div`
  padding: 30px;
  z-index: 1;
`;

export const AboutBottomLeft = styled.div`
  position: relative;
  top: -15%;
  left: ${(props) => (props.lang === "ar" ? "auto" : "-95%")};
  // right: ${(props) => (props.lang === "ar" ? "-95%" : "auto")};
  width: 350px;
  height: 80px;
  padding: 16px;
  border-radius: 8px;
  align-items: ${(props) => (props.lang === "ar" ? "right" : "left")};
  justify-content: flex-end;
  justify-items: center;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 30px 0px #00000014;
  background: #ffffff;
  font-family: ${AlmaraiFont};
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: ${(props) => (props.lang === "ar" ? "right" : "left")};
  z-index: 1;
  transition: margin-top 0.5s ease;
  ${media("medium")} {
    width: 250px;
    left:  "-60%";
    align-items: left;
    justify-content: flex-end;
    justify-items: center;
  }
`;

export const AboutBottomLeft2 = styled.div`
  position: relative;
  top: -15%;
  left: ${(props) => (props.lang === "ar" ? "auto" : "-95%")};
  right: ${(props) => (props.lang === "ar" ? "-95%" : "auto")};
  width: 350px;
  height: 80px;
  padding: 16px;
  border-radius: 8px;
  align-items: ${(props) => (props.lang === "ar" ? "right" : "left")};
  justify-content: flex-end;
  justify-items: center;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 30px 0px #00000014;
  background: #ffffff;
  font-family: ${AlmaraiFont};
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: ${(props) => (props.lang === "ar" ? "right" : "left")};
  z-index: 1;
  transition: margin-top 0.5s ease;
  ${media("medium")} {
    width: 250px;
    left: ${(props) => props.customLeft || "-60%"};
    align-items: left;
    justify-content: flex-end;
    justify-items: center;
  }
`;
export const AboutBottomRight = styled.div`
  width: 350px;
  height: 80px;
  padding: 16px;
  border-radius: 8px;
  align-items: left;
  justify-content: flex-end;
  justify-items: center;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 30px 0px #00000014;
  background: #ffffff;
  font-family: ${AlmaraiFont};
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: ${(props) => (props.lang === "ar" ? "right" : "left")};
  position: relative;
  left: ${(props) => (props.lang === "ar" ? "auto" : "50%")};
  right: ${(props) => (props.lang === "ar" ? "50%" : "auto")};
  top: -53%;
  z-index: 1;
  transition: margin-top 0.5s ease;
  ${media("medium")} {
    width: 250px;
    left: 50%;
    align-items: left;
    justify-content: flex-end;
    justify-items: center;
  }
`;
export const AboutIn = styled.div`
  background: #ca56a11a;
  width: 290px !important;
  height: 290px !important;
  border-radius: 16px;
  position:relative;
  ${media("smedium")} {
    width: 200px;
    height: 200px;
    
  }
  &:hover {
    ${ScholarI} {
      console.log("in");
      transform: rotateY(180deg); // Adjust the scale factor as needed
    }
    ${AboutBottomLeft2}{
      margin-top: ${(props) => props.customtop || "-10%"}
    }
    ${AboutBottomLeft}{
      margin-top: ${(props) => props.customtop || "-10%"}
    }
    ${AboutBottomRight}{
      margin-top: ${(props) => props.customtop || "0%"}
    }
  }
`;
export const AboutTop = styled.div`
  position: relative;
  width: 76.6%;
  height: 39.7%;
  top: -30%;
  left: ${(props) => (props.lang === "ar" ? "auto" : "11.5%")};
  right: ${(props) => (props.lang === "ar" ? "11.5%" : "auto")};
  border-radius: 8px;
  box-shadow: 0px 4px 30px 0px #00000014;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;
export const AboutTopTextHeading = styled.span`
  font-family: ${AlmaraiFont};
  font-size: 14px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: #4c4c4c;
`;
export const AboutTopTextSub = styled.span`
  font-family: ${AlmaraiFont};
  font-size: 24px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0px;
  text-align: center;
  color: #252525;
`;

export const AboutBottomImage = styled.img`
  align-self: center;
  position: relative;
  width: 80%;
  transform: rotateY(180deg);
  margin-top: -10px; /* Align to the bottom */
`;
