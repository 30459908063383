import React, { useEffect, useState } from 'react'
import { Librarydatacontainer, Librarydatacontent, Librarydataheader, Librarydatawrapper, OptionsModal } from './public.style'
import LibraryBlogCard from './LibraryBlogCard';
import { LibBlog } from "../Blogs/blogs.styles";
import { useSelector } from "react-redux";
import { home } from "../../Services/api";
import { publicLang } from '../data/public.lang';
import FacebookShare from '../SocialShare/FaceBookShare';
import TwitterShare from '../SocialShare/TwitterShare';

const Librarydata = ({activeFile,setActiveFile,activeShare,setActiveShare,moreOptions,setMoreOptions}) => {
  const lang = useSelector((store) => store?.lang?.language);
 

  return (
    <Librarydatacontainer>
        <Librarydatawrapper>
            <Librarydataheader>
                 {publicLang[lang].head3}
            </Librarydataheader>
            <Librarydatacontent>
                {publicLang[lang].desc3}
            </Librarydatacontent>
            <LibraryBlog activeShare={activeShare} setActiveShare={setActiveShare} activeFile={activeFile} setActiveFile={setActiveFile} moreOptions={moreOptions} setMoreOptions={setMoreOptions}/>
        </Librarydatawrapper>
    </Librarydatacontainer>
  )
}

const LibraryBlog = ({activeFile,setActiveFile,activeShare,setActiveShare,moreOptions,setMoreOptions}) => {
  const [LibraryContent, setLibraryContent] = useState([]);
  const lang = useSelector((store) => store?.lang?.language);

  const fetchdata = async () => {
    try {
      const data = await home.getrecent();
      setLibraryContent(data?.data?.data?.library);
     
    } catch (error) {
      setLibraryContent([]);
    }
  };
  useEffect(() => {
    fetchdata();
  }, []);

  return (
    <LibBlog>
      {LibraryContent.length !== 0 &&
        LibraryContent?.map((data, index) => {
          return (
            <div key={index}>
              <LibraryBlogCard data={data} activeShare={activeShare} setActiveShare={setActiveShare} activeFile={activeFile} setActiveFile={setActiveFile} moreOptions={moreOptions} setMoreOptions={setMoreOptions} />
            </div>
          );
        })}
    </LibBlog>
  );
};





export default Librarydata;