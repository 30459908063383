import React, { useEffect, useState } from "react";
import school from "../../Assets/Images/AddLinesIcons.svg";
import college from "../../Assets/Images/Vector (1).png";
import {
  AskMeLater,
 ModelButton,
  ModelContent,
  ModelWrapper,
  ModelbtnContainer,
  ModelbtnWrapper,
  Modelh1,
  Modelh3,
  Option,
  ProgressBar,
  ProgressValue,
  ProgressWidth,
  Query,
  Query2,
  Returnbtn,
  SelectButton,
  Tabcontainer,
  Tabquery,
  Tabquery2,
} from "./Auth.Style";
// import arrow from "../"
import { useNavigate } from "react-router-dom";
import { Auth } from "../../Services/api/auth";
import { filterData } from "./Validate.js";
import { getErrorMessage } from "../Error/Errorcode.js";
import Taost from "../Toast/Toast.js";
import { toast } from "react-toastify";
import { IoIosArrowForward } from "react-icons/io";
import arrowl from "../../Assets/Images/modellefti.svg";
import arrowr from "../../Assets/Images/modelrighti.svg";
import { useSelector } from "react-redux";
import { model, model1, model2, model3 } from "../data/model.lang.js";
// import arrowl

const Model = () => {
  const [currentValue, setCurrentValue] = useState(0);
  const [educationallevel, setEducationallevel] = useState([]);
  const [collegename, setCollegename] = useState("");
  const [specialization, setSpecialization] = useState("");
  const [academiclist, setAcademiclist] = useState([]);
  const [allacademiclist, setAllAcademiclist] = useState([]);
  const [datalist, setDatalist] = useState([]);
  const [alldatalist, setAlldatalist] = useState([]);
  const [search, setSearch] = useState("");
  const [search2, setSearch2] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItem2, setSelectedItem2] = useState(null);
  const [selectedItem3, setSelectedItem3] = useState(null);
  const [edu_id,setedu_id]=useState("");
  const navigate = useNavigate();
  const maxValue = 3;
  const lang = useSelector((store) => store?.lang?.language);
  const [disabled, setDisabled] = useState(true);

  //to handle the academic level input
  const handleacadbutton = async (inputData) => {
    try {
      const education_id = {
        education_level_id: inputData.tmyyoz_id,
      };
      setedu_id(inputData.tmyyoz_id);
      const update = await Auth.updateProfile(education_id);
      const data2=await Auth.getUniversity(inputData.tmyyoz_id);
      setAcademiclist(data2?.data?.data);
      setAllAcademiclist(data2?.data?.data);
      setSelectedItem((prevItem) =>
        prevItem === inputData ? null : inputData
      );
      setDisabled(false);
    } catch (error) {
      const errorcode = error?.response?.data?.error_code;
      const message = getErrorMessage(errorcode);
      toast(<Taost message={message} error />);
    }
  };

  // to handle the college name input
  const handlecollegename = async (inputData) => {
    try {
      const datatoupdate = {
        university_id: inputData.tmyyoz_id,
      };
      const update = await Auth.updateProfile(datatoupdate);
      const data2=await Auth.getCollege(inputData.tmyyoz_id,edu_id);
      setDatalist(data2?.data?.data);
      setAlldatalist(data2?.data?.data);
      setSelectedItem2((prevItem) =>
        prevItem === inputData ? null : inputData
      );
      setDisabled(false);
    } catch (error) {
      const errorcode = error?.response?.data?.error_code;
      const message = getErrorMessage(errorcode);
      toast(<Taost message={message} error />);
    }
  };

  //to handle the specialization input
  const handlespecialization = async (inputData) => {
    try {
      const college_id = {
        college_id: inputData.tmyyoz_id,
      };
      const data = await Auth.updateProfile(college_id);
      setSelectedItem3((prevItem) =>
        prevItem === inputData ? null : inputData
      );
      setDisabled(false);
    } catch (error) {
      const errorcode = error?.response?.data?.error_code;
      const message = getErrorMessage(errorcode);
      toast(<Taost message={message} error />);
    }
  };

  // filterting the data on search
  const handlesearch = () => {
    const filter = filterData(search, allacademiclist);
    setAcademiclist(filter);
  };
  const handlesearch2 = () => {
    const filter = filterData(search2, alldatalist);
    setDatalist(filter);
  };
  useEffect(() => {
    handlesearch();
  }, [search]);
  useEffect(() => {
    handlesearch2();
  }, [search2]);

  //to handle the next button
  const handlemodelbutton = async () => {
    if (currentValue < maxValue) {
      setCurrentValue(currentValue + 1);
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    const update = async () => {
      try {
        const data = await Auth.getProfile();
        const academic_level = data?.data?.data?.user?.education_level_id;
        const college = data?.data?.data?.user?.college_id;
        const uni =data?.data?.data?.user?.university_id;
        if(college!==null && uni !==null && academic_level!==null){
          navigate("/");
        }
        setedu_id(academic_level);
        if (academic_level!==null) {
          if (uni !== null) {
            const datalistlspec = await Auth.getCollege(uni,academic_level );
            setDatalist(datalistlspec ?.data?.data);
            setAlldatalist(datalistlspec ?.data?.data);
            setCurrentValue(3);
          } else {
            const data2=await Auth.getUniversity(academic_level);
            setAcademiclist(data2?.data?.data);
            setAllAcademiclist(data2?.data?.data);
            setCurrentValue(2);
          }
        } else {
          const data2 = await Auth.getEducationData();
          const educationdata=data2?.data?.data;
          setEducationallevel(educationdata);
          setCurrentValue(1);
        }
      } catch (error) {
        const errorcode = error?.response?.data?.error_code;
        const message = getErrorMessage(errorcode);
        toast(<Taost message={message} error />);
      }
    };
    update();
  }, []);

  return (
    <>
      <ModelWrapper>
        {/* <Returnbtn
          lang={lang}
          disabled={currentValue <= 1}
          onClick={() => setCurrentValue(currentValue - 1)}
        >
          <img
            src={lang === "ar" ? arrowr : arrowl}
            alt="logo-school"
            style={{ width: "12px", height: "15px", alignItems: "center" }}
          />{" "}
          {model[lang]?.return}
        </Returnbtn> */}
        <ModelContent>
          <>
            <Modelh1>{"أكمل معلوماتك الدراسية"}</Modelh1>
            {/* <Modelh3>{model[lang]?.description}</Modelh3> */}
          </>
          <ProgressWidth>
            <ProgressBar value={currentValue} max={maxValue} />
            <ProgressValue>
              {currentValue} /{maxValue}
            </ProgressValue>
          </ProgressWidth>
          {currentValue === 1 && (
            <>
              <Query>{"المستوى الدراسي "}</Query>
              <Tabquery>
                {educationallevel?.map((item) => ( 
                  <SelectButton key={item.id}
                    onClick={() => handleacadbutton(item)}
                    style={{
                      backgroundColor:selectedItem === item ? "#DCDCDC" : "#fcfcfc",}}>
                    <img
                      src={college}
                      alt="logo-school"
                      style={{ width: "20px", alignItems: "center" }}
                    />
                    {item.name}
                  </SelectButton>
                ))}
               
              </Tabquery>
            </>
          )}
          {currentValue === 2 && (
            <Tabcontainer>
              <Query2>{model2[lang]?.select}</Query2>
              <input
                type="text"
                placeholder={model2[lang]?.placeholder}
                onChange={(e) => setSearch(e.target.value)}
              />
              <Tabquery2>
                {academiclist &&
                  academiclist?.map((item) => (
                    <Option  key={item.id}
                      value={item}
                      onClick={() => handlecollegename(item)}
                      style={{ backgroundColor: selectedItem2?.name === `${item.name}` ? "#DCDCDC" : "#fcfcfc",
                      }}
>
                      {item.name}
                    </Option>
                  ))}
              </Tabquery2>
            </Tabcontainer>
          )}
          {currentValue === 3 && (
            <>
              <Tabcontainer>
                <Query2>{model3[lang]?.select}</Query2>
                <input
                  type="text"
                  placeholder={model3[lang]?.placeholder}
                  onChange={(e) => setSearch2(e.target.value)}
                />
                <Tabquery2>
                  {datalist &&
                    datalist?.map((item) => (
                      <Option key={item.id}
                        value={item}
                        onClick={() => handlespecialization(item)}
                        style={{
                          backgroundColor:
                            selectedItem3?.name === `${item?.name}` ? "#DCDCDC" : "#fcfcfc",
                        }}
                      >
                        {item.name}
                      </Option>
                    ))}
                </Tabquery2>
              </Tabcontainer>
            </>
          )}
        </ModelContent>
        <ModelbtnWrapper>
          <ModelbtnContainer>
            <AskMeLater onClick={() => navigate("/")}>
              {model[lang]?.ask}
            </AskMeLater>
            <ModelButton
              onClick={() => {
                handlemodelbutton();
                setDisabled(true);
              }}
              disabled={disabled}
            >
              {model[lang]?.next} <IoIosArrowForward size={"15px"} />
            </ModelButton>
          </ModelbtnContainer>
        </ModelbtnWrapper>
      </ModelWrapper>
    </>
  );
};

export default Model;
