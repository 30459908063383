import styled from "styled-components";
import AlmaraiFont from "../../Assets/fonts/Almarai-Regular.ttf";
import { media } from "../../Assets/Styles/utils";

export const HeroContainer = styled.div`
  height: 600px;
  padding: 30px;
  display: flex;
  background: #4D2C77;
  justify-content: space-evenly;
  align-items: center;
  ${media("medium")} {
    flex-direction: column;
    height: 100%;
  }
`;

export const HeroContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const HeroInput = styled.div`
  background: #ffffff;
  width: 80%;
  padding: 30px;
  height: 350px;
  border-radius: 15px;
  z-index: 1;
  ${media("medium")} {
    width: 80%;
  }
`;

export const HeroPDF = styled.div`
  padding: 16px;
  border-radius: 15px;
  background: #fafafa;
  color: #989898;
  align-items: center;
  justify-content: center;
  height: 90%;
`;

export const PdfInput = styled.div`
  padding: 16px;
  border-radius: 8px;
  background: #fafafa;
  color: #989898;
  border: 1.5px dashed #989898;
  align-items: center;
  justify-content: center;
  height: 90%;
`;

export const PdfForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: 100%;
`;

export const PdfLabel = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: 100%;
`;

export const FormInput = styled.input`
  display: none;
`;

export const HeroText = styled.div`
  align-items: center;
  justify-content: start;
  width: 80%;
  padding: 30px;
  margin-right: 5%;
  ${media("medium")} {
    width: 100%;
  }
`;

export const HeroHeading = styled.h2`
  font-family: ${AlmaraiFont};
  font-size: 40px;
  font-weight: 700;
  color:white;
  line-height: 59px;
  letter-spacing: 0px;
  text-align: ${(props) => (props.lang === "ar" ? "right" : "left")};
  ${media("medium")} {
    font-size: 1.2rem;
    text-align: center;
    line-height: 1.5;
  }
`;

export const HeroSubText = styled.p`
  font-family: ${AlmaraiFont};
  font-size: 18px;
  color:white;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: ${(props) => (props.lang === "ar" ? "right" : "left")};
  margin-top: -30px;
  ${media("medium")} {
    text-align: center;
    margin-top: -10px;
    font-size: .8rem;
    line-height: 1.5;
  }
`;

export const HeroBackLeft = styled.img`
  width: 10%;
  position: absolute;
  top: 15%;
  left: ${(props) => (props.lang === "ar" ? "1.8%" : "0")};
  right: ${(props) => (props.lang === "ar" ? "1.5%" : "0")};
  z-index: -1;
  transition: 0.5s ease;
  animation: floatAnimation 3s infinite; /* Add this line for animation */
  @keyframes floatAnimation {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }

  ${media("medium")} {
    width: 20%;
  }
`;
export const HeroBackRight = styled.img`
  width: 5%;
  position: absolute;
  top: 50%;
  left: ${(props) => (props.lang !== "ar" ? "95%" : "0")};
  right: ${(props) => (props.lang !== "ar" ? "0" : "95%")};
  z-index: -1;
  transition: 0.5s ease;

  animation: floatAnimation 3s infinite; /* Add this line for animation */

  @keyframes floatAnimation {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  ${media("medium")} {
    left: 80%;
  }
`;
