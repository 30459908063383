import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data:[],
    id: "",
}

const FolcerCreationSlice = createSlice({
    name:'folderCreationData',
    initialState,

    reducers:{
        setFolderData:(state, action) => {
            state.data = null;
            state.id = null;
            state.data = action.payload.data;
            state.id = action.payload.id;
        },
    }
})

export const {setFolderData} = FolcerCreationSlice.actions;
export default FolcerCreationSlice.reducer;