import React, { useEffect, useState } from "react";
import {
  Head3,
  ProfileButton,
  ProfileEdit,
  ProfileForm,
  PasswordInput,
} from "./Profilestyle";
import { Error, InputStyle, Passwordvisible } from "../Auth/Auth.Style";
import { BiSolidHide, BiSolidShow } from "react-icons/bi";
import { validatePassword } from "../Auth/Validate";
import { Auth } from "../../Services/api";
import { getErrorMessage } from "../Error/Errorcode";
import { toast } from "react-toastify";
import Taost from "../Toast/Toast";
import { useSelector } from "react-redux";
import { passwordData } from "../data/profile.lang";

const PasswordForm = () => {
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [oldpass, setOldPassWord] = useState("");
  const [visible, setVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessage2, setErrorMessage2] = useState("");
  const lang = useSelector((store) => store?.lang?.language);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleconfirmPasswordChange = (e) => {
    setConfPassword(e.target.value);
  };
  const handleoldPasswordChange = (e) => {
    setOldPassWord(e.target.value);
  };

  useEffect(() => {
    if (!password || password === "") {
      setErrorMessage2("");
      return;
    }
    const message = validatePassword(password, lang);
    if (message) {
      setErrorMessage2(message);
    } else {
      setErrorMessage2("");
    }
  }, [password]);

  useEffect(() => {
    if (password !== confPassword) {
      setErrorMessage("Password does not match");
    } else {
      setErrorMessage("");
    }
  }, [confPassword]);

  const handlesubmit = async (e) => {
    const passwordupdate = {};
    passwordupdate.old_password = oldpass;
    passwordupdate.new_password = password;

    try {
      e.preventDefault();
      const update = await Auth.updatePassword(passwordupdate);
      setPassword("");
      setOldPassWord("");
      setConfPassword("");
      toast(<Taost message="Password updated successfully" />);
    } catch (error) {
      const errorcode = error?.response?.data?.error_code;
      const message = getErrorMessage(errorcode, lang);
      toast(<Taost message={message} error />);
    }
  };

  return (
    <ProfileEdit>
      <ProfileForm>
        <Head3>{passwordData[lang]?.password}</Head3>
        <PasswordInput>
          <InputStyle
            value={oldpass}
            onChange={handleoldPasswordChange}
            type="password"
            placeholder={passwordData[lang]?.oldPassword}
            lang={lang}
          />
        </PasswordInput>
        <PasswordInput>
          <InputStyle
            value={password}
            onChange={handlePasswordChange}
            type="password"
            placeholder={passwordData[lang]?.newPassword}
            lang={lang}
          />
        </PasswordInput>
        <PasswordInput>
          <InputStyle
            value={confPassword}
            onChange={handleconfirmPasswordChange}
            type={visible ? "text" : "password"}
            placeholder={passwordData[lang]?.confirmPassword}
            pass
            lang={lang}
          />
          <Passwordvisible onClick={() => setVisible(!visible)} profile>
            {visible ? <BiSolidShow /> : <BiSolidHide />}
          </Passwordvisible>
        </PasswordInput>
        {errorMessage2.lengthError && (
          <Error>{errorMessage2.lengthError}</Error>
        )}
        {errorMessage2.uppercaseError && (
          <Error>{errorMessage2.uppercaseError}</Error>
        )}
        {errorMessage2.numberError && (
          <Error>{errorMessage2.numberError}</Error>
        )}
        {errorMessage2.specialCharError && (
          <Error>{errorMessage2.specialCharError}</Error>
        )}
        {errorMessage && <Error>{errorMessage}</Error>}
        <PasswordInput>
          <ProfileButton pass onClick={(e) => handlesubmit(e)}>
            {passwordData[lang]?.button}
          </ProfileButton>
        </PasswordInput>
      </ProfileForm>
    </ProfileEdit>
  );
};

export default PasswordForm;
