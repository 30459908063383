import styled from "styled-components"
import {device, size} from "../../../Breakpoints"

export const CreateFolderModal = styled.div`
    position: fixed;
    background-color: rgba(77, 44, 119, 0.55);
    top: 0;
    left: 0;
    height: calc(100vh);
    right: 0;
    overflow: hidden;
    scroll-behavior: none;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const FolderCreationContainer = styled.div`
    width: 40rem;
    height: 24rem;
    background-color: white;
    display: flex;
    justify-content: end;
    align-items: start;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 2.5rem 3rem 2.5rem 3rem;
    @media ${device.md} {
        width: 35rem;
    }
    @media ${device.sm} {
        position: absolute;
        bottom: 0;
        width: 100%;
        border-radius: 24px 24px 0 0;
        padding : 1rem 1rem 1rem 1rem;
        height: 22rem;
    }
`

export const StyledCrossIcon = styled.svg`
    margin-right: ${(props) => props.lang === 'en' ? '-20px' : null};
    margin-left: ${(props) => props.lang === 'ar' ? '-20px' : null};
    cursor: pointer;
    @media ${device.sm} {
        margin-right: 0;
        margin-left: 0;
        position: absolute;
        top: 0;
        margin-top: 1rem;
    }
`

export const FolderCreationSubContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
    gap: 1.5rem;
`

export const FolderNameInput = styled.input`
    font-family: "Almarai", sans-serif;
    width: 100%;
    height: 3.6rem;
    border: 1.5px solid ${(props) => props.bordercolor ? props.bordercolor : null};
    border-radius: 8px;
    display: flex;
    flex-direction: ${(props) => props.flexdirection === 'row' ? 'row' : 'column'};
    align-items: ${(props) => props.flexdirection === 'row' ? 'center' : 'start'};
    justify-content: ${(props) => props.flexdirection === 'row' ? 'start' : 'center'};
    padding: 0 18px 0 18px;
    gap: ${(props) => props.flexdirection === 'row' ? '8px' : '4px'};
    color: ${(props) => props.color ? props.color : null};
    font-weight: ${(props) => props.fontweight ? props.fontweight : null};
    outline: none;
    font-size: 16px;
    box-sizing: border-box;
`

export const FileAccessStatusSection = styled.div`
    display: flex;
    width: 100%;
    height: auto;
    color: #727272;
    gap: 1rem;
    display: flex;
    justify-content: start;
    align-items: center;
    color : #252525;
    @media ${device.sm} {
        font-size: 14px;
    }
`

export const UpperBorderLine = styled.div`
    width: 100%;
    position: absolute;
    border: 1px solid #EEEEEE;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: 6.2rem;
    @media (min-width: ${size.sm}) {
        display: none;
    }
    @media (max-width: 350px){
        margin-bottom: 5rem;
    }
`

export const AccessButton = styled.div`
    padding: 4px 8px 4px 8px;
    border-radius: 50px;
    border: 1px solid ${(props) => props.bordercolor ? props.bordercolor : null};
    background-color: ${(props) => props.background ? props.background : null};
    cursor: pointer;
`
export const TextSection = styled.div`
    width: ${(props) => props.width ? props.width : null};
    color: #727272;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 16px;
    line-height: 25px;
    @media ${device.sm} {
        text-align: ${(props) => props.lang === 'en' ? "left" : "right"};
        align-items: start;
        margin-top: 4rem;
    }
`

export const Heading = styled.div`
    font-weight: bold;
    width: 100%;
    font-size: 2rem;
    color: #252525;
    line-height: 4rem;
    text-align: center;
    margin-top: ${props => props.margintop ? props.margintop : null};
    @media ${device.sm} {
        font-size: 18px;
        line-height: 2rem;
        text-align: ${(props) => props.lang === 'en' ? "left" : "right"};
    }
`

export const StyledButton = styled.div`
    background-color: #4D2C77;
    width: 100%;
    color: white;
    height: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    gap:  12px;
    pointer-events: ${props => props.dissablebutton === "true" ? "none" : null};
    @media ${device.sm} {
        margin-top: 1.5rem;
    }
`