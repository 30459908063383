import styled from "styled-components";
import backImage from '../../Assets/Images/back.png';
import { device, size } from "../../Breakpoints";

export const FlashTest = styled.div`
    height:100vh;
    width:100%;
`

export const FlashTestContainer = styled.div`
    height: calc(100vh - 96px);
    width: 100%;
    background-image: url(${backImage});
    background-repeat: no-repeat;
    background-size: cover;
    display:flex;
    justify-content:center;
    align-items:center;
    @media ${device.sm} {
        height: calc(100vh - 66px);
    }
    @media (max-height: 700px){
        align-items: start;
        height: auto;
        padding: 2rem 0 2rem 0;
    }
`

export const FlashInner = styled.div`
    width:648px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    @media ${device.sm} {
        width: 100%;
        padding: 0 2rem 0 2rem;
    }
    @media (max-width: 300px) {
        padding: 0 1rem 0 1rem;
    }
`

export const StartImage = styled.img`
    height: 253px;
    width: 338px;
    @media ${device.sm} {
        width: 200px;
        height: 224px;
    }
    @media (max-width: 300px) {
        width: 180px;
        height: 210px;
    }
`
// export const FlashImg  = styled.div`
// height:50%;
// width:100%;
// display:flex;
//     justify-content:center;
//     align-items:center;
// `

export const TestHeading1 = styled.div`
    width:100%;
    display:flex;
    font-size: 32px;
    font-weight: bold;
    justify-content:center;
    align-items:center;
    line-height: 51px;
    text-align: center;
    @media ${device.sm} {
        font-size: 20px;
        line-height: 20px;
    }
`
export const TestHeading2 = styled.div`
    width:100%;
    display:flex;
    font-size: 20px;
    color: #727272;
    justify-content:center;
    align-items:center;
    line-height: 2rem;
    text-align: center;
    @media ${device.sm} {
        font-size: 14px;
        line-height: 20px;
    }
`
export const TestHeading3 = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    background-color: white;
    min-width: 210px;
    border-radius: 8px;
    color: #4c4c4c;
    font-size: 16px;
    height: 58px;
    gap: 6px;
    padding: 0 1rem 0 1rem;
    box-sizing: border-box;
    @media ${device.xs} {
        min-width: 0;
        width: 100%;
        flex-direction: column;
    }
`

export const TestTextDiv = styled.div`
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    @media ${device.sm} {
    }
`
    
    export const TestStartButton = styled.div`
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
`
export const TestButton = styled.div`
    height: 64px;
    width: 274px;
    border-radius:4px;
    justify-content:center;
    align-items:center;
    cursor:pointer;
    display:flex;
    background-color:#4D2C77;
    color: white;
    font-size: 18px;
    font-weight: bold;
    gap: 8px;
    @media ${device.sm} {
        font-size: 16px;
        height: 56px;
    }
    @media ${device.xs} {
        width: 100%
    }
`

export const TestContainer = styled.div`
    height:auto;
    width:100%;
`

export const TestMiddleDiv = styled.div`
    height: calc(100vh - (160px));
    width: 100%;
    background-image: url(${backImage});
    background-repeat: no-repeat;
    background-size: cover;
    display:flex;
    justify-content:center;
    align-items: center;
    overflow-y: scroll;
    box-sizing: border-box;
    padding: 0 1rem 0 1rem;
    @media (max-height: 850px){
        padding: 2rem 0 8rem 0;
        overflow-y: scroll;
        align-items: start;
    }
    @media ${device.sm} {
        height: calc(100vh - (66px + 156px));
        padding: 1rem 1rem 1rem 1rem;
        align-items: start;
    }
`

export const TestMiddle = styled.div`
    width: 530px;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
    box-sizing: border-box;
    height: auto;
    @media ${device.sm} {
        margin-top: 0;
        gap: 8px;
    }
    @media ${device.md} {
        margin-top: 0rem;
    }
`
export const TestDiv = styled.div`
    height: auto;
    width: 100%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color:white;
    padding: 1rem 1.1rem 1rem 1.1rem;
    box-sizing: border-box;
    border: 1px solid #EEEEEE;
    gap: 20px;
    @media ${device.sm} {
        gap: 12px;
    }
`

export const UpperContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`

export const TestTop = styled.div`
    height: 96px;
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    background-color: white;
    cursor: pointer;
    box-sizing: border-box;
    padding: 0 2rem 0 2rem;
    box-sizing: border-box;
    @media ${device.sm} {
        height: 66px;
        padding: 0 2rem 0 2rem;
    }
`

export const InsideTopDiv = styled.div`
    width:100%;
    height:60%;
    display:flex;
    align-items:center;
    font-size: 18px;
    color: #727272;
    font-weight: bold;
    @media ${device.sm} {
        font-size: 16px;
    }
`

export const Test = styled.div`
    height:100%;
    width:38%;
    border:2px solid #EEEEEE;
    border-radius:7px;
`

export const TestHead = styled.div`
    /* height: 110px;    */
    width: 100%;
    padding: 0 1.1rem 0 1.1rem;
    border-radius: 8px;
    border:1px solid #EEEEEE;
    display:flex;
    gap: 1rem;
    justify-content: space-between;
    background-color:#FFFFFF;
    box-sizing: border-box;
    height: auto;
    @media ${device.sm} {
        gap: 0px;
    }
`

export const UpperInnerDiv = styled.div`
    font-size: 16px;
    font-weight: bold;
    display:flex;
    flex-direction:column;
    justify-content:center;
    gap: 8px;
    @media ${device.sm} {
        display: none;
    }
`
export const TestData = styled.div`
    flex-direction:column;
    justify-content:center;
    align-items:start;
    @media ${device.xs} {
        gap: 6px;
        height: 5.8rem;
        overflow-y: hidden;
        align-items: start;
        justify-content: start;
    }
`
export const TestNumber = styled.div`
    height:90%;
    width:50%;
    margin-top:17px;
    border-radius:100%;
    border:4px solid #eeeeee;
    display:flex;
    justify-content:center;
    align-items:center;
    font-size:25px;
    font-weight:500;
    margin-right:8px;
    color:#4C4C4C;
`


export const TestNumberDiv = styled.div`
    display:flex;
    justify-content:start;
    align-items: center;
    margin-left: ${props => props.lang === 'ar' ? "-1rem" : null};
    margin-right: ${props => props.lang === 'en' ? "-1rem" : null};
    
    @media ${device.xs} {
        position: relative;
        font-size: 20px;
    }
`

export const TestUpper = styled.div`
    font-size: 20px;
    line-height: 32px;
    font-weight:bold;
    color:#252525;
    @media ${device.sm} {
        font-size: 18px;
        line-height: 18px;
    }
    @media (max-width: 300px) {
        font-size: 16px;
    }
`

export const TestLower = styled.div`
    font-size: 16px;
    line-height: 21px;
    color:#727272;
    @media ${device.sm} {
        font-size: 14px;
        line-height: 16px;
    }
`
// export const TestQues = styled.div`
//     width:100%;
//     display:flex;
//     margin-bottom:10px;
//     justify-content: start;
//     align-items:center;
//     background-color: red;
// `
// export const UpperDiv = styled.div`
//     width:100%;
//     display:flex;
//     align-items:center;
//     @media (max-width:466px){
//         /* width:95%; */
//     }
// `

export const Ques = styled.div`
    font-size: 24px;
    font-weight: bold;
    @media ${device.sm} {
        font-size: 18px;
    }
`

export const TestOpt = styled.div`
    height: auto;
    width:100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
`

export const ExplanationContainer = styled.div`
    width: 100%;
    height: 65px;
    border: 1px solid #FFAF44;
    border-radius: 4px;
    background-color: #FFF7ED;
    padding: 12px;
    text-align: center;
    display: flex;
    align-items: start;
    justify-content: center;
    box-sizing: border-box;
    overflow-y: scroll;
    color: #F99611;
    font-size: 14px;
    font-weight: normal;
    &::-webkit-scrollbar {
        width: 0px;
    }
`

export const TestOne = styled.div`
    height: 72px;
    width: 100%;
    display:flex;
    align-items: center;
    border: 1px solid #EEEEEE;
    border-radius: 8px;
    cursor:pointer;
    color: #4C4C4C;
    padding: 0 12px 0 12px;
    box-sizing: border-box;
    display: flex;
    font-weight: 600;
    gap: 2px;
    font-size: 16px;
    overflow-y: scroll;
    @media ${device.sm} {
        height: 56px;
    }
    
    &.correct {
        background-color: rgba(3, 152, 85, 0.05); /* Change to your desired correct color */
        color:white;
    }

    &.incorrect {
        background-color: rgba(239, 14, 1, 0.05); 
        color:white;
    }
    &::-webkit-scrollbar {
        width: 0px;
    }
`

export const StyledInput = styled.input`
    height: 100%;
    width: 100%;
    outline: none;
    border: none;
    font-size: 16px;
    color: #4c4c4c;
    font-weight: bold;
    background-color: transparent;
`

export const SubmitFillUp = styled.div`
    width: 6rem;
    height: 2rem;
    background-color: #4D2C77;
    color: white;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    &:hover{
        background-color: #CA56A1;
    }
    @media ${device.sm}{
        font-size: 14px;
    }
`

export const NumberingContainer = styled.div`
    width: 2.1rem;
`

export const OptionContainer = styled.div`
    width: 100%;
    height: auto;
    max-height: 100%;
    overflow-y: scroll;
    display: flex;
    align-items: start;
    justify-content: start;
    padding: 2px 0 2px 0;
    &::-webkit-scrollbar {
        width: 0px;
    }
`

export const Chatdiv = styled.div`
    height:8%;
    width:100%;
`

export const BottomDiv = styled.div`
    height: 96px;
    width:100%;
    position: fixed;
    bottom: 0;
    display:flex;
    justify-content:center;
    align-items:center;
    box-shadow:0 20px 24px rgba(0, 0, 0, 0.6);
    z-index: 0;
    background-color: white;
    box-sizing: border-box;
    padding: 0 1rem 0 1rem;
    @media ${device.sm} {
        text-align: center;
        height: 155px;
        padding: 0 1rem 0 1rem;
    }
`
    
export const BottomData = styled.div`
    width: 730px;
    display:flex;
    justify-content: space-between;
    gap: 2rem;
    align-items: center;
    @media ${device.sm} {
        flex-direction: column;
        gap: 16px;
    }
`
export const LeftBottomDiv = styled.div`
    height:100%;
    width: auto;
    display:flex;
    align-items:center;
    justify-content: start;
    gap: 8px;
    font-size: 18px;
    font-weight: bold;
    @media ${device.sm} {
        height: 2.5rem;
        text-align: center;
        gap: 4px;
        font-size: 16px;
    }
    @media (max-width: 350px) {
        font-size: 14px;
    }
`
export const HorizontalLine = styled.div`
    width: 100%;
    border: 0.5px solid #EEEEEE;
    @media (min-width: ${size.sm}) {
        display: none;
    }
`

export const RightBottomDiv = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    gap: 2rem;
    @media ${device.sm} {
        width: 100%;
    }
`

export const SkipDiv = styled.div`
    width:100%;
    display:flex;
    background-color: red;
    justify-content:start;
    align-items:center;
    cursor:pointer;
`


export const NextQues = styled.div`
    width: 170px;
    height: 58px;
    display:flex;
    font-weight: bold;
    justify-content:center;
    align-items:center;
    color: white;
    gap: 8px;
    background-color:  ${props => props.isAnswered ? '#4D2C77' : 'rgba(77, 44, 119, 0.7)'};
    border-radius: 4px;
    cursor:pointer;
    @media ${device.sm}{
        width:100%;
    }
`
export const Flag = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    font-weight: bold;
    cursor:pointer;
    color: #989898;
    @media ${device.sm} {
        display: none;
    }
`

export const FlagTopBar = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    font-weight: bold;
    cursor:pointer;
    color: #989898;
    font-size: 14px;
    @media (min-width: ${size.sm}) {
        display: none;
    }
`