import { CellContent, ChatBotLimitContainer, ChatBotLimitSize, ChatBotLimitText, ChatBotMessageContainer, ChatBotSubscribeButton, ChatBotTextContainer, Container, ContentContainer, ExhaustWarningDescription, ExhaustWarningText, ExhaustedCardNumber, Grid, MainContainer, ModalContainer, OuterContainer, StyledCross, SubscribeButton, SubscriptionDetails, SubscriptionDetailsCells, SubscriptionDetailsHeader, SubscriptionDetailsRow, TableBody, TrailLimitNumber, TrailLimitText, Wrapper } from "./ExhaustModals.styles";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import SubscriptionModal from "../SubscriptionModal/SubscriptionModal";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { setVisibility } from "../../../Store/Slice/SubscriptionModal/SubscriptionModal.slice";
import flashCardIcon from "../../../Assets/Images/FlashCardIcon.svg";
import FlashCardGrid from "../../FlashCards/FlashCardGrid";
import { TableWrapper } from "../SubscriptionModal/SubscriptionModal.style";

export const ChatBotExhaustMessage = () => {
    const lang = useSelector((store) => store.lang.language)
    const premiumFeaturesPrice = useSelector((store) => store.premiumDetails.Data)
    const dispatch = useDispatch()
    const ChatBotLanguage = 
    {
        ar: {limitHeading: "الرسائل المجانية اليومية", text: `لقد استنفذت الرسائل المجانية، اشتراك وأحصل على عدد رسائل لا محدودة `, subscribeText: "اشترك في باقة مميز"},
        en: {limitHeading: "Free daily messages", text: `Your conversation feature attempts have been exhausted. To enjoy an ${premiumFeaturesPrice.premium_user_number_of_chat_messages} messages,subscribe to Mumayaz package`, subscribeText: "Subscribe"}
    }

    const handleClick = () => {
        dispatch(setVisibility(true));
    }
    return(
        <ChatBotMessageContainer>
            <ChatBotLimitContainer>
                <ChatBotLimitText>{ChatBotLanguage[lang].limitHeading}</ChatBotLimitText>
                <ChatBotLimitSize>{premiumFeaturesPrice.free_user_number_of_chat_messages}<span style={{color: "#727272", fontWeight: "normal"}}>/{premiumFeaturesPrice.free_user_number_of_chat_messages}</span></ChatBotLimitSize>
            </ChatBotLimitContainer>
            <ChatBotTextContainer>{ChatBotLanguage[lang].text}</ChatBotTextContainer>
            <ChatBotSubscribeButton onClick={() => handleClick()}>
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.3492 7.31293H0.973633V11.6984H7.3492V7.31293Z" fill="white"/>
                    <path d="M18.0255 7.31293H11.6499V11.6984H18.0255V7.31293Z" fill="white"/>
                    <path d="M11.6483 0.980469H7.34918L7.3492 7.31293L11.6483 7.3129V0.980469Z" fill="white"/>
                    <path d="M11.6483 11.6999H7.34918V18.0323H11.6483V11.6999Z" fill="white"/>
                </svg>
                {ChatBotLanguage[lang].subscribeText}
            </ChatBotSubscribeButton>
        </ChatBotMessageContainer>
    )
}

const ExhausteModel = ({setLimitExhaustModal, data}) => {
    const [subscriptionVisibility, setSubscriptionVisibility] = useState(false)
    const lang = useSelector((store) => store.lang.language)
    const handleSubsciption = () => {
        setSubscriptionVisibility(true);
    }

    const ExhaustedFlashLanguage = {en:{mumayazName: "Mumayaz Package", advantages: "Advantages", free: "Free Package", mumayaz: "Mumayaz Package", subscribe: "Subscribe Mumayaz"}, ar:{mumayazName:"باقة المميز", advantages: "المزايا", free: "الباقة المجانية ( الحالية )"    , mumayaz: "باقة المميز", subscribe: "اشترك في متميز"}}

    return(
        <ModalContainer>
            <MainContainer>
            <StyledCross onClick={() => setLimitExhaustModal(false)} width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13 1.50098L1 13.501M1 1.50098L13 13.501" stroke="#667085" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </StyledCross>
            <ExhaustedCardNumber>
                <TrailLimitText>{data.LimitHeading[lang]}</TrailLimitText>
                <TrailLimitNumber>
                    <span style={{color: "#252525", fontWeight:"bold"}}>{data.Limit[lang]}</span>
                    /{data.Limit[lang]}
                </TrailLimitNumber>
            </ExhaustedCardNumber>
            <ExhaustWarningText>{data.Heading[lang]}</ExhaustWarningText>
            <ExhaustWarningDescription>{data.SubHeading[lang]}
            {/* <span style={{fontWeight:"bold"}}>{ExhaustedFlashLanguage[lang].mumayazName}</span> */}
            </ExhaustWarningDescription>
            <SubscriptionDetails>
                <TableBody>
                    <SubscriptionDetailsRow>
                        <SubscriptionDetailsHeader>{ExhaustedFlashLanguage[lang].advantages}</SubscriptionDetailsHeader>
                        <SubscriptionDetailsHeader>{ExhaustedFlashLanguage[lang].free}</SubscriptionDetailsHeader>
                        <SubscriptionDetailsHeader>{ExhaustedFlashLanguage[lang].mumayaz}</SubscriptionDetailsHeader>
                    </SubscriptionDetailsRow>
                    <SubscriptionDetailsRow>
                        <SubscriptionDetailsCells>{data.Advantage[lang]}</SubscriptionDetailsCells>
                        <SubscriptionDetailsCells>
                            <CellContent>
                            <IoIosCheckmarkCircleOutline style={{color: "#727272", fontSize:"1rem"}}/> {data.Free[lang]}
                            </CellContent>
                        </SubscriptionDetailsCells>
                        <SubscriptionDetailsCells>
                            <CellContent style={{color: "#252525"}}>
                            <IoIosCheckmarkCircle style={{color: "#CA56A1", fontSize:"1rem"}}/> {data.Mumayaz[lang]}
                            </CellContent>
                        </SubscriptionDetailsCells>
                    </SubscriptionDetailsRow>
                </TableBody>
            </SubscriptionDetails>
            <SubscribeButton onClick={() => handleSubsciption()}>
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.3492 7.31293H0.973633V11.6984H7.3492V7.31293Z" fill="white"/>
                    <path d="M18.0255 7.31293H11.6499V11.6984H18.0255V7.31293Z" fill="white"/>
                    <path d="M11.6483 0.980469H7.34918L7.3492 7.31293L11.6483 7.3129V0.980469Z" fill="white"/>
                    <path d="M11.6483 11.6999H7.34918V18.0323H11.6483V11.6999Z" fill="white"/>
                </svg>
                {ExhaustedFlashLanguage[lang].subscribe}
            </SubscribeButton>
            </MainContainer>
            {subscriptionVisibility ? <SubscriptionModal subscriptionVisibility={subscriptionVisibility} setSubscriptionVisibility={setSubscriptionVisibility} setLimitExhaustModal={setLimitExhaustModal}/> : null}
        </ModalContainer>
    )
};

export const ExhaustedFlashCard = ({data}) => {
    const [subscriptionVisibility, setSubscriptionVisibility] = useState(false)
    const lang = useSelector((store) => store.lang.language)
    const premiumFeaturesPrice = useSelector((store) => store.premiumDetails.Data)
    const handleSubsciption = () => {
        setSubscriptionVisibility(true);
    }
    const FreeFlashCards = premiumFeaturesPrice?.free_user_number_of_flash_cards
    const premiumFlashCards = premiumFeaturesPrice?.premium_user_number_of_flash_cards
    const exhaustFlashCardData = 
    [   
        {LimitHeading: {ar: "الوصول الى جميع البطاقات", en: "Access to all cards"},
        Heading: {ar: "الوصول الى جميع البطاقات", en: "Access to all cards"},
        SubHeading: {ar: "اشترك في باقة متميز , وأحصل على عدد لا نهائي من بطاقات الفلاش كارد", en: "To take full advantage of all cards indefinitely and enjoy all the benefits, please subscribe to the"},
        Mumayaz: {ar: premiumFlashCards ||  "غير محدود", en: premiumFlashCards ||  "unlimited"},
        Free: {ar: `${FreeFlashCards} محاولة/يوم`, en: `${FreeFlashCards} attempts/day`},
        Advantage: {ar: "بطاقات فلاش", en: "Flash cards"}},
    ]
    const ExhaustedFlashLanguage = {en:{mumayazName: "Mumayaz Package", advantages: "Advantages", free: "Free Package(current)", mumayaz: "Mumayaz Package", subscribe: "Subscribe Mumayaz"}, ar:{mumayazName:"باقة المميز", advantages: "المزايا", free: "الباقة المجانية ( الحالية )"    , mumayaz: "باقة المميز", subscribe: "اشترك مميز"}}
    return(
        <OuterContainer>
            <Grid>
                {data.map((item, index) => {
                    if(index < 4){
                        return(
                            <FlashCardGrid
                            key={index}
                            head={item.title}
                            data={item.description}
                            stats={item.status}
                            id={item?.id}
                            />
                        )
                    }
                    return null;
                })}
            </Grid>
            <ContentContainer>
                <ExhaustWarningText>{exhaustFlashCardData[0].Heading[lang]}</ExhaustWarningText>
                <ExhaustWarningDescription>{exhaustFlashCardData[0].SubHeading[lang]} 
                    {/* <span style={{fontWeight:"bold"}}>{ExhaustedFlashLanguage[lang].mumayazName}</span> */}
                </ExhaustWarningDescription>
                    <TableWrapper>
                    <Container>
                        <TableBody>
                            <SubscriptionDetailsRow>
                                <SubscriptionDetailsHeader>{ExhaustedFlashLanguage[lang].advantages}</SubscriptionDetailsHeader>
                                <SubscriptionDetailsHeader>{ExhaustedFlashLanguage[lang].free}</SubscriptionDetailsHeader>
                                <SubscriptionDetailsHeader>{ExhaustedFlashLanguage[lang].mumayaz}</SubscriptionDetailsHeader>
                            </SubscriptionDetailsRow>
                            <SubscriptionDetailsRow>
                                <SubscriptionDetailsCells>
                                    <CellContent>
                                        <img src={flashCardIcon} alt=""/>
                                        {exhaustFlashCardData[0].Advantage[lang]}
                                    </CellContent>
                                </SubscriptionDetailsCells>
                                <SubscriptionDetailsCells>
                                    <CellContent>
                                    <IoIosCheckmarkCircleOutline style={{color: "#727272", fontSize:"1rem"}}/> {exhaustFlashCardData[0].Free[lang]}
                                    </CellContent>
                                </SubscriptionDetailsCells>
                                <SubscriptionDetailsCells>
                                    <CellContent style={{color: "#252525"}}>
                                    <IoIosCheckmarkCircle style={{color: "#CA56A1", fontSize:"1rem"}}/> {exhaustFlashCardData[0].Mumayaz[lang]}
                                    </CellContent>
                                </SubscriptionDetailsCells>
                            </SubscriptionDetailsRow>
                        </TableBody>
                    </Container>
                    </TableWrapper>
                <SubscribeButton insideFlashCards="true" onClick={() => handleSubsciption()}>
                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.3492 7.31293H0.973633V11.6984H7.3492V7.31293Z" fill="white"/>
                        <path d="M18.0255 7.31293H11.6499V11.6984H18.0255V7.31293Z" fill="white"/>
                        <path d="M11.6483 0.980469H7.34918L7.3492 7.31293L11.6483 7.3129V0.980469Z" fill="white"/>
                        <path d="M11.6483 11.6999H7.34918V18.0323H11.6483V11.6999Z" fill="white"/>
                    </svg>
                    {ExhaustedFlashLanguage[lang].subscribe}
                </SubscribeButton>
            </ContentContainer>
            {subscriptionVisibility ? <SubscriptionModal subscriptionVisibility={subscriptionVisibility} setSubscriptionVisibility={setSubscriptionVisibility}/> : null}
        </OuterContainer>
    )
}

export default ExhausteModel;