import styled from "styled-components";
import { device } from "../../../Breakpoints";

export const ModalContainer = styled.div`
    position: fixed;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(77, 44, 119, 0.55);
    z-index: 1000;
`

export const MainContainer = styled.div`
    background-color: white;
    width: 648px; 
    height: 437px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 36px 16px 36px 16px;
    font-size: 16px;
    gap: 20px;
    text-align: center;
    @media ${device.md}{
        width: 550px;
    }
    @media ${device.sm}{
        position: fixed;
        padding: 1rem 8px 1rem 8px;
        bottom: 0;
        height: 500px;
        width: 100%;
        border-radius: 24px 24px 0 0 ;
    }
`

export const OuterContainer = styled.div`
    width: 100%; 
    height: 360px;
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: row;
    flex-wrap: wrap;
    box-sizing: border-box;
    position: relative;
    grid-template-columns: repeat(2, 1fr); 
    gap: 16px;
    overflow-y: hidden;
    @media ${device.lg} {
      width: 100%;
    }
    @media (max-width: 920px){
      gap: 12px;
    }
    @media ${device.xs}{
      gap: 12px;
      height: 350px;
      overflow: hidden;
    }
`

export const Grid = styled.div`
    width: 616px; 
    height: 360px;
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: row;
    flex-wrap: wrap;
    box-sizing: border-box;
    position: relative;
    grid-template-columns: repeat(2, 1fr); 
    gap: 16px;
    height: auto;
    filter: blur(1px);
    @media ${device.lg} {
      width: 100%;
    }
    @media (max-width: 920px){
      gap: 12px;
    }
`

export const ContentContainer = styled.div`
    background-color: white;
    width: 100%; 
    height: 100%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 36px 16px 36px 16px;
    font-size: 16px;
    gap: 20px;
    text-align:center;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.83) 0%, #FFFFFF 100%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    overflow-x: hidden;
    @media ${device.md}{
        width: 100%;
    }
    @media ${device.xs}{
        width: 100%;
        justify-content: start;
        padding: 36px 8px 36px 8px;
    }
`

export const StyledCross = styled.svg`
    position: absolute;
    top: 0;
    left: 0;
    margin: 16px 0 0 16px;
    cursor: pointer;
`

export const ExhaustedCardNumber = styled.div`
    width: 163px;
    height: 84px;
    border-radius: 8px;
    background-color: #FAFAFA;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 6px;
    color: #252525;
`

export const TrailLimitText = styled.div`
    color: #989898;
    font-size: 12px;
`

export const TrailLimitNumber = styled.div`
    font-size: 24px;
    color: #727272;
`

export const ExhaustWarningText = styled.div`
    font-size: 24px;
    font-weight: bold;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media ${device.sm}{
        font-size: 18px;
    }
`

export const ExhaustWarningDescription = styled.div`
    color: #727272;
    text-align: center;
    @media ${device.sm}{
        font-size: 14px;
    }
`

export const SubscriptionDetails = styled.table`
    width: 534px;
    height: 90px;
    background-color: #FFFFFF;
    border-collapse: collapse;
    font-size: 14px;
    text-align: center;
    color: #252525;
    font-weight: bold;
    @media ${device.sm}{
        width: 100%;
    }
`

export const TableContainer = styled.div`
    width: 100%
`

export const Container = styled.table`
    width: 534px;
    height: 90px;
    background-color: #FFFFFF;
    border-collapse: collapse;
    font-size: 14px;
    text-align: center;
    color: #252525;
    font-weight: bold;
    @media ${device.lg}{
        width: 100%;
    }
    @media ${device.xs}{
        width: 350px;
    }
    
`
export const TableBody = styled.tbody`
    & > :first-child{
        background-color: #FAFAFA;
    }
    width: 100%;
    @media ${device.sm}{
        font-size: 12px;
    }
`

export const SubscriptionDetailsRow = styled.tr`
    & > :first-child {
        background-color: #FAFAFA;
    }
`

export const SubscriptionDetailsHeader = styled.th`
`

export const SubscriptionDetailsCells = styled.td`
    color: #727272;
`

export const CellContent = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
`

export const SubscribeButton = styled.div`
    width: 259px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 18px;
    border-radius: 4px;
    gap: 8px;
    cursor: pointer;
    background-color: #CA56A1;
    &:hover {
        background-color: #4D2C77;
    }

    @media ${device.xs}{
        width: 100%;
        height: ${props => props.insideFlashCards === "true" ? "56px": "64px"};
        font-size: ${props => props.insideFlashCards === "true" ? "16px": "18px"}
    }
`

export const ChatBotMessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem 8px 1rem 8px;
    box-sizing: border-box;
    background-color: #CDFEE8;
    gap: 8px;
    height: 276px;
    width: 100%;
`

export const ChatBotLimitContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #BFBFBF;
    border-radius: 8px;
    gap: 8px;
    box-sizing: border-box;
    width: 100%;
    height: 67px;
`

export const ChatBotLimitText = styled.div`
    font-size: 12px;
    color: #727272;
    text-align: center;
`

export const ChatBotLimitSize = styled.div`
    font-size: 24px;
    font-weight: bold;
    color: #252525;
`

export const ChatBotTextContainer = styled.div`
    font-size: 14px;
    color: #4C4C4C;
    line-height: 18px;
`

export const ChatBotSubscribeButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4D2C77;
    border-radius: 4px;
    font-weight: bold;
    font-size: 12px;
    gap: 8px;
    height: 42px;
    width: 100%;
    color: white;
    box-sizing: border-box;
    cursor: pointer;
`
