import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import UploadPDF from "./Uploadfile";
import { LibBlog } from "../Blogs/blogs.styles";
import LibraryBlogCard from "./LibraryBlogCard";
import { home } from "../../Services/api";
import Loader from "../Loader/Loader";


const LibraryBlog = () => {
    const [LibraryContent, setLibraryContent] = useState([]);
    const [loading,setLoading]=useState(true);
    const dispatch=useDispatch();
    const [activeShare,setActiveShare]=useState(false);
    const [activeFile,setActiveFile]=useState(null);
    const [moreOptions,setMoreOptions]=useState(null);
    const navigate=useNavigate();
    const Searchdegree=useSelector((store)=>store?.public?.academic_level);
    const Searchcollege=useSelector((store)=>store?.public?.college);
    const Searchspecialization=useSelector((store)=>store?.public?.specialization);
    const NavigationData = useSelector((store) => store?.navigationData?.routesArray);
    const lang = useSelector((store) => store?.lang?.language);
    const [isLoading, setIsLoading] = useState(true);
    const SearchData={
      academic_level:Searchdegree,
      college:Searchcollege,
      specialization:Searchspecialization
    }
      const fetchdata = async () => {
      try {
        const data = await home.getsearch(SearchData);
        setLibraryContent(data?.data?.data?.Folder_and_pdf);
      } catch (error) {
        setLibraryContent([]);
       
      }
    };
    useEffect(() => {
        setIsLoading(true);
        console.log("SearchData");
        fetchdata().finally(() => setIsLoading(false));
    }, [Searchdegree,Searchcollege,Searchspecialization]);
    
   
    if (isLoading) {
        return <Loader />; 
      }
      
  
  
    return (
      <LibBlog>
        {LibraryContent.length === 0 &&<div style={{paddingTop:"2%"}}><UploadPDF /></div> }
        {LibraryContent &&
          LibraryContent.map((data, index) => {
            return (
              <div key={index}>
                <LibraryBlogCard data={data} activeShare={activeShare} setActiveShare={setActiveShare} activeFile={activeFile} setActiveFile={setActiveFile} moreOptions={moreOptions} setMoreOptions={setMoreOptions}  />
              </div>
            );
          })}
      </LibBlog>
    );
  };

  export default LibraryBlog;