import React, { useState } from 'react';
import { IoMdClose } from "react-icons/io";
import {FlashCardlang} from '../../data/FlashCard.lang.js';
import { useDispatch, useSelector } from 'react-redux';
import {AddCardData} from '../../../Store/Slice/FlashCards/GetFlashCardsSlice.js'
import { BottomButton, CancelButton, CrossIcon, EditHead, EditInnerPopUp, EditPop, QuesEditInput, QuesLabel, QuesUpdateDiv, SaveButton, TextArea } from '../../FlashCards/FlashCards.styles.js';


const AddModal = ({close,onUpdateMessage }) => {
    const dispatch = useDispatch();
    const document_id  = useSelector(state => state?.FlashCards?.document_id); 
    const lang = useSelector((state) => state?.lang?.language);

    const [AddD,setAddD]= useState({
        document_id:document_id,
        title:'',
        description:''
    })
    const handleTextareaChange = (event) => {
        const newText = event.target.value;
        setAddD({ ...AddD, title: newText });
    }
    const handleDesareaChange = (event) =>{
        const textdes = event.target.value;
        setAddD({...AddD,description:textdes});
    }

    const AddCard = async () =>{
        if(AddD.description !== '' && AddD.title !== ''){
        try {
            const res = await dispatch(AddCardData(AddD));
            console.log(res);
            if (res && res.data.message === 'Flash card created successfully') {
                onUpdateMessage();
            }
        } catch (error) {
            console.log(error);
        }
        }
    }
  return (
    <EditPop>
        <EditInnerPopUp>
            <EditHead> {FlashCardlang[lang].AddCard} </EditHead>
                <CrossIcon lang={lang}> <IoMdClose onClick={close}/> </CrossIcon>
                <QuesUpdateDiv lang={lang}>
                    <QuesLabel lang={lang}>
                        {FlashCardlang[lang].TheQues}
                    </QuesLabel>
                    <QuesEditInput>
                        <TextArea height="80px" type="text" onChange={handleTextareaChange} value={AddD.title}> </TextArea>
                    </QuesEditInput>
                </QuesUpdateDiv>
                <QuesUpdateDiv lang={lang}>
                    <QuesLabel>
                        {FlashCardlang[lang].TheAns}
                    </QuesLabel>
                    <QuesEditInput >
                        <TextArea height="140px" type="text" onChange={handleDesareaChange} value={AddD.description}> </TextArea>
                    </QuesEditInput>
                </QuesUpdateDiv>
                <BottomButton>
                    <SaveButton lang={lang} onClick={AddCard}>
                      {FlashCardlang[lang].save}
                    </SaveButton>
                    <CancelButton onClick={close}>{FlashCardlang[lang].cancel}</CancelButton>
                </BottomButton>
        </EditInnerPopUp>
    </EditPop>
  )
}

export default AddModal
