import React,{useEffect, useState} from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import { ResultPage , 
         ResultMidPage,
         ResultInnerPage,
         ResultMid,
         ResultBottom,
         ResultTopDiv,
         ExamRepeat,
         EndButton,
         ValueDiv,
         StarDiv,
         Value1,
         Startdiv1,
         RatingText,
         DivInner,
         DivInnerValue,
         InnerText,
         ColorDiv,
         ResultHeading,
         ResultSubHeading,
         ResultBottomFixed,
         StarImage
} from './Result.styles.js';
import star_white from "../../Assets/Images/Star.png";
import star_yellow from "../../Assets/Images/Star1.png";
import { useDispatch, useSelector } from 'react-redux';
import {FlashCardsLearning} from '../data/FlashCardLearning.lang'
import { RatingApi } from '../../Store/Slice/FlashCards/GetFlashCardsSlice.js';
import ExhausteModel from '../Modals/ExhaustModals/ExhaustModals.js';

const Result = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const state = location.state.Update;

  const dispatch = useDispatch();
  const lang = useSelector((state) => state?.lang?.language);
  const document_id  = useSelector(state => state?.FlashCards?.document_id); //Get all Flash Cards
  const CardData  = useSelector(state => state?.FlashCards?.CardData);
  const [exhaustModal, setExhaustModal] = useState(false);
  const premiumFeaturesPrice = useSelector((store) => store.premiumDetails.Data)
  const FreeCards = premiumFeaturesPrice?.free_user_number_of_flash_cards
  const PremiumCards = premiumFeaturesPrice?.premium_user_number_of_flash_cards
  const exhaustFlashCardData = 
    [   
        {LimitHeading: {ar: "يتجاوز الحد الأقصى للبطاقات", en: "Cards limit exceeds"},
        Limit: {ar: FreeCards, en: FreeCards},
        Heading: {ar: "الوصول الى جميع البطاقات", en: "Access to all cards"},
        SubHeading: {ar: "حتى تحصل بعدد لا محدود من الفلاش كارد، اشترك في ", en: "To take full advantage of all cards indefinitely and enjoy all the benefits, please subscribe to the"},
        Mumayaz: {ar: PremiumCards || "غير محدود", en: PremiumCards ||  "unlimited"},
        Free: {ar: `${FreeCards} محاولة/يوم`, en: `${FreeCards} attempts/day`},
        Advantage: {ar: "بطاقات فلاش", en: "Flash cards"}},
    ]

  const [Flashrating, setFlashRating] = useState({
    document_id:document_id,
    type:'flash_card',
    rating:0
  });

  const handleStarClick = (starIndex) => {
    setFlashRating({
      ...Flashrating, // Spread the existing state
      rating: starIndex + 1 // Update the rating based on the clicked starIndex
    });
  };
  
  const renderStars = () => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      const starImage = i < Flashrating?.rating ? star_yellow : star_white;
      stars.push(
        <StarImage
          key={i}
          src={starImage}
          alt="star"
          onClick={() => handleStarClick(i)}
        />
      );
    }
    return stars;
  };

  useEffect(()=>{
    if(Flashrating?.rating !== 0){
      dispatch(RatingApi(Flashrating));
    }
  },[Flashrating])

  const handleBack = () =>{
    navigate(`/flash-card/${document_id}`)
  }

  const QuizRestart = () =>{
    navigate("/Flash/StartLearning")
  }

  useEffect(() => {
    if(CardData.length <= 20){
      setExhaustModal(true)
    }
  },[])

  return (
      <ResultPage>
      <ResultMidPage>
      <ResultInnerPage>
        <ResultTopDiv>
          <ResultHeading>{FlashCardsLearning[lang].Congrats}</ResultHeading>
          <ResultSubHeading>{FlashCardsLearning[lang].CongratsBody}</ResultSubHeading>
        </ResultTopDiv>
      <ResultMid>
        <ValueDiv>
          <Value1>
            <DivInner>
              <DivInnerValue>
                {state?.masterCount}
                <ColorDiv  color='#039855'></ColorDiv>
              </DivInnerValue>
              <InnerText>
                {FlashCardsLearning[lang].CompleteCount}
              </InnerText>
            </DivInner>
          </Value1>
          <Value1>
          <DivInner>
          <DivInnerValue>
                {state?.needReviewCount}
                <ColorDiv color='#FFDFB4'></ColorDiv>
              </DivInnerValue>
              <InnerText>
                {FlashCardsLearning[lang].needsReviewCount}
              </InnerText>
          </DivInner>
          </Value1>
          <Value1>
          <DivInner>
          <DivInnerValue>
                {CardData?.length - (state?.needReviewCount+state?.masterCount)}
                <ColorDiv ></ColorDiv>
              </DivInnerValue>
              <InnerText>
                {FlashCardsLearning[lang].RemainingCount}
              </InnerText>
            </DivInner>
          </Value1>
        </ValueDiv>
        <StarDiv>
          <Startdiv1>
          {renderStars()}
          </Startdiv1>
          <RatingText>
           {FlashCardsLearning[lang].Rating}
          </RatingText>
        </StarDiv>
      </ResultMid>
      <ResultBottom>
      <ExamRepeat onClick={QuizRestart}>
          {FlashCardsLearning[lang].Repeat}
      </ExamRepeat>
      <EndButton onClick={handleBack}>
          {FlashCardsLearning[lang].Ending}
      </EndButton>
      </ResultBottom>
      </ResultInnerPage>
      </ResultMidPage>
      <ResultBottomFixed>
          <ExamRepeat onClick={QuizRestart}>
            {FlashCardsLearning[lang].Review}
          </ExamRepeat>
          <EndButton onClick={handleBack}>
              {FlashCardsLearning[lang].Ending}
          </EndButton>
      </ResultBottomFixed>
      {exhaustModal ? 
      <ExhausteModel setLimitExhaustModal={setExhaustModal} data={exhaustFlashCardData[0]}/>
      : null
      }
      </ResultPage>
  )
}

export default Result
