import React, { useEffect, useState } from 'react'
import { AccessButton, CreateFolderModal, FileAccessStatusSection, FolderCreationContainer, FolderCreationSubContainer, FolderNameInput, Heading, StyledButton, StyledCrossIcon, TextSection, UpperBorderLine } from './FolderCreationModal.styles'
import localStorage from "../../../Store/index"
import ApiClient from '../../../Config/index.config'
import { setUpdation } from '../../../Store/Slice/LibraryData/LibraryDataSlice'
import { useDispatch, useSelector } from 'react-redux'
import { CreateFolderLanguage } from '../../data/CreateFolder.lang'
import { SubHeading, SubHeadingSmall } from '../../UploadFile/UploadFile.styles'

const FolderCreationModal = ({setCreateFolderModal, type, Name, EditId, IsPublic, folderId, getFolders, currentFolder}) => {
    const id = localStorage.getState().libraryData.id
    const [folderName, setFolderName] = useState(Name);
    const [isPublic, setIsPublic] = useState(IsPublic);
    const [borderColorGeneral, setBorderColorGeneral] = useState("#f4f4f4")
    const [backgroundColorGeneral, setBackgroundColorGeneral] = useState("white")
    const [borderColorSpecial, setBorderColorSpecial] = useState("#63B4FF")
    const [backgroundColorSpecial, setBackgroundColorSpecial] = useState("rgba(99, 180, 255, 0.2)");
    const lang = useSelector((store) => store.lang.language)
    const dispatch = useDispatch()

    const setDataEdit = async () => {
        try{
            const response = await ApiClient.put(`/library/` + EditId, {
                "name":`${folderName}`,
                "is_public": `${isPublic}`
            })
            setCreateFolderModal(false)
        }catch(error){
            console.log(error)
            setCreateFolderModal(false)
        }
        dispatch(setUpdation())
    };
    
    const setDataFolderCreation = async () => {
        let currentId = id;
        if(folderId || folderId === ""){
            currentId = folderId
        }
        try{
            const response = await ApiClient.post(`/library/` + currentId, {
                "name":`${folderName}`,
                "type":"folder",
                "is_public": `${isPublic}`
            })
            // navigate("/library/" + id)
            setCreateFolderModal(false)
            // window.location.reload(true)
        }catch(error){
            console.log(error)
            setCreateFolderModal(false)
        }
        
        if(type === "createFolderTransfer" || type === "createFolderAttachFile"){
            getFolders(currentFolder, folderId)
        }
        else{
            dispatch(setUpdation()) 
        }
    }

    const handleSpecialClick = () => {
        setIsPublic(false)
        setBackgroundColorSpecial("rgba(99, 180, 255, 0.2)")
        setBorderColorSpecial("#63B4FF")
        setBackgroundColorGeneral("white")
        setBorderColorGeneral("#f4f4f4")
    }

    const handleGeneralClick = () => {
        setIsPublic(true)
        setBackgroundColorGeneral("#E6F5EE")
        setBorderColorGeneral("#75C7A1")
        setBackgroundColorSpecial("white")
        setBorderColorSpecial("#f4f4f4")
    }

    useEffect(() => {
        if(IsPublic === true){
            handleGeneralClick()
        }
    },[])

    return (
      <CreateFolderModal>
          <FolderCreationContainer>
              <FolderCreationSubContainer>

                <StyledCrossIcon lang={lang} onClick={() => {setCreateFolderModal(false)}} width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13 1.83398L1 13.834M1 1.83398L13 13.834" stroke="#667085" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </StyledCrossIcon>

                <TextSection lang={lang} width={"100%"}>
                    <Heading lang={lang} margintop={"-3rem"}>
                        {type === "edit" ? CreateFolderLanguage[lang].editHeading : CreateFolderLanguage[lang].heading}
                    </Heading>
                    <SubHeading>
                        {CreateFolderLanguage[lang].subHeading}
                    </SubHeading>
                    <SubHeadingSmall>
                        {CreateFolderLanguage[lang].subHeadingSmall}
                    </SubHeadingSmall>
                </TextSection>
            
                {type === 'edit' ? 
                <FolderNameInput bordercolor="#f4f4f4" color="#727272" fontWeight={"normal"} defaultValue={Name} onChange={(event) => {setFolderName(event.target.value)}}></FolderNameInput>
                : 
                <FolderNameInput bordercolor="#f4f4f4" color="#727272" fontWeight={"normal"} placeholder={CreateFolderLanguage[lang].folderTitle} onChange={(event) => setFolderName(event.target.value)}></FolderNameInput>
                }
                
                <FileAccessStatusSection>
                    {CreateFolderLanguage[lang].fileAccessStatus}
                    <AccessButton bordercolor={borderColorSpecial} background={backgroundColorSpecial} onClick={() => handleSpecialClick()}>{CreateFolderLanguage[lang].privateButton}</AccessButton>
                    <AccessButton bordercolor={borderColorGeneral} background={backgroundColorGeneral} onClick={() => handleGeneralClick()}>{CreateFolderLanguage[lang].publicButton}</AccessButton>
                </FileAccessStatusSection>
                
                <UpperBorderLine></UpperBorderLine>

                {type === 'edit' ?
                <StyledButton onClick={() => setDataEdit()}>{CreateFolderLanguage[lang].saveButton}</StyledButton>
                :
                <StyledButton onClick={() => setDataFolderCreation()}>{CreateFolderLanguage[lang].createFolderButton}</StyledButton>
                }
                  
              </FolderCreationSubContainer>
          </FolderCreationContainer>
      </CreateFolderModal>
    )  
}

export default FolderCreationModal 