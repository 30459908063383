export const authlang = {
  ar: {
    Loginheading: "تسجيل الدخول",
    loginparagraph: "يرجى إدخال معلومات تسجيل الدخول الخاصة بك للوصول إلى حسابك",
    forgetpassword: "هل نسيت كلمة المرور؟",
    loginbutton: "تسجيل الدخول",
    password: "كلمة المرور",
    logindescription: "ليس لديك حساب على متميز ؟",
    loginlink: "سجل هنا",
    logingoogle: "جوجل",
    loginfacebook: "فيسبوك",
    registerHeading: "سجل الآن",
    registerParagraph1: "انضم لأكثر من",
    registerParagraph2: "متعلم في رحلتهم التعليمية وطوّر مهاراتك",
    registersub: "+20000",
    fullname: "الاسم كامل",
    email: "البريد الإلكتروني",
    registerbutton: "أرسل كود التحقق",
    terms: "بإتمام عملية التسجيل، تؤكد قبولك شروط وأحكام منصة تميز",
    registeraccountdesc: "هل لديك حساب؟",
    registeraccountdesc2: "تسجيل الدخول",
    term1: "بإتمام عملية التسجيل، تؤكد قبولك",
    term2: "شروط وأحكام",
    term3: "منصة تميز",
    validatemsgemail: "معرف البريد الإلكتروني غير صالح",
    return: "",
    otpheading: "أدخل رمز التحقق",
    otpparagraph: "يرجى إدخال رمز التحقق المرسل إلى بريدك الإلكتروني/هاتفك",
    otpbutton: "تحقق",
    phonenumber: "يجب أن يكون طول رقم الهاتف من 9 إلى 10 أرقام",
    Student: "طالب جامعي"

  },
  en: {
    Loginheading: "Login",
    loginparagraph:
      "Please enter your login information to access your account",
    forgetpassword: "did you forget your password?",
    loginbutton: "signin",
    password: "password",
    logindescription: "Don't have an account on Tmyyoz?",
    loginlink: "Register Here",
    logingoogle: "google",
    loginfacebook: "facebook",
    registerHeading: "Create a free account ",
    registerParagraph1: "Join more than",
    registerParagraph2:
      " learners on their educational journey and develop your skills",
    registersub: "20,000+",
    fullname: "Fullname",
    email: "Email (optional)",
    registerbutton: "Send Verification Code",
    terms:
      "By completing the registration process, you confirm that you accept the terms and conditions of the Tamayoz platform",
    registeraccountdesc: "Do you have an account on Tamayoz?",
    registeraccountdesc2: " Log in",
    term1:
      " By completing the registration process, you confirm your acceptance",
    term2: "Terms and Conditions",
    term3: "of the Tamayoz platform",
    validatemsgemail: "Email ID is not valid",
    otpheading: "Enter Verification Code",
    otpparagraph: "Please enter the verification code sent to your email/phone",
    otpbutton: "Verify",
    phonenumber: "Phone Number length should be from 9 to 10 digits",
    Student: "College Student",
  },
};

export const passwordlang = {
  ar: {
    lengthError: "يجب أن تكون كلمة المرور على الأقل 8 أحرف.",
    uppercaseError: "يجب أن تحتوي كلمة المرور على حرف كبير واحد على الأقل.",
    numberError: "يجب أن تحتوي كلمة المرور على رقم واحد على الأقل.",
    specialCharError: "يجب أن تحتوي كلمة المرور على حرف خاص واحد على الأقل.",
  },
  en: {
    lengthError: "Password must be at least 8 characters long.",
    uppercaseError: "Password must contain at least one uppercase letter.",
    numberError: "Password must contain at least one number.",
    specialCharError: "Password must contain at least one special character.",
  },
};
