import { createSlice } from "@reduxjs/toolkit";
import { FlashCard } from "../../../Services/api/FlashCards.js";

const FlashCardsQuiz = createSlice({
    name:"Quiz",
    initialState:{
        QuizData:{}
    },
    reducers:{
        SetQuizData(state,action) {
            state.QuizData = action.payload;
        }
    }
})

export const { SetQuizData } = FlashCardsQuiz.actions;

export const { reducer: QuizReducer } = FlashCardsQuiz;

const GetQuizData = (document_id, callback) => async (dispatch) => {
    try {
      const response = await FlashCard.DataQuiz(document_id);
      if (response) {
        dispatch(SetQuizData(response.data.data));
      }
    } catch (error) {
        if(error?.response?.data?.error_code === 1024){
            dispatch(SetQuizData({questions:null}));
        }
        console.log(error);
    }
};


export { GetQuizData };
export default FlashCardsQuiz.reducer;
