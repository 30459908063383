import {
  Head1,
  Head2,Image, ProfileBtn, ProfileContainer, ProfileTab,
  ProfileTopRow,
  ProfileWrapper,
  Profilepersonal,
} from "./Profilestyle";
import profile from "../../Assets/Images/images.png";
import NameEmailForm from "./NameEmailForm";
import { CiUser , CiCreditCard1 } from "react-icons/ci";
import { useEffect, useState } from "react";
import PasswordForm from "./PasswordForm";
import EducationForm from "./EducationForm";
import { Auth } from "../../Services/api";
import { getErrorMessage } from "../Error/Errorcode";
import Taost from "../Toast/Toast";
import { toast } from "react-toastify";
import { LuBook } from "react-icons/lu";
import Loader from "../Loader/Loader";
import { profileLanguage, subscription } from "../data/profile.lang";
import { useSelector } from "react-redux";
import Subscription from "./Subscription";
import { authlang } from "../data/auth.lang";

const PersonalDataform = () => {
  const [value, setValue] = useState(1);
  const [name, setNam] = useState("");
  const [student, setStudent] = useState("");
  const lang = useSelector((store) => store?.lang?.language);

  useEffect(() => {
    getProfileData();
  }, []);
  const getProfileData = async () => {
    try {
      const data = await Auth.getProfile();
      const { name } = data?.data?.data?.user;
      setNam(name);
    } catch (error) {
      const errorcode = error?.response?.data?.error_code;
      const message = getErrorMessage(errorcode, lang);
      toast(<Taost message={message} error />);
    }
  };

  if (name === "") {
    return (
      <ProfileContainer>
        <Loader></Loader>
      </ProfileContainer>
    );
  }
  return (
    <ProfileContainer>
      <ProfileWrapper>
        <ProfileTopRow>
          <Image src={profile} alt="profile" />
          <Head1>{name}</Head1>
          <Head2>{authlang[lang]?.Student}</Head2>
        </ProfileTopRow>
        <Profilepersonal>
          <ProfileTab>
            <ProfileBtn selected={value === 1} onClick={() => setValue(1)}>
              <CiUser size={20} /> {profileLanguage[lang]?.personalData}
            </ProfileBtn>
            <ProfileBtn onClick={() => setValue(2)} selected={value === 2}>
              <LuBook size={20} /> {profileLanguage[lang]?.educationalData}
            </ProfileBtn>
            <ProfileBtn onClick={() => setValue(3)} selected={value === 3}>
              <CiUser size={20} /> {profileLanguage[lang]?.passwordData}
            </ProfileBtn>
            <ProfileBtn onClick={() => setValue(4)} selected={value === 4}>
              <CiCreditCard1 size={20} /> {subscription[lang]?.subscription}
            </ProfileBtn>
          </ProfileTab>
          {value === 1 && <NameEmailForm />}
          {value === 3 && <PasswordForm />}
          {value === 2 && <EducationForm />}
          {value === 4 && <Subscription/>}
        </Profilepersonal>
      </ProfileWrapper>
    </ProfileContainer>
  );
};

export default PersonalDataform;
