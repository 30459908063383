import React, { useEffect, useState } from 'react';
import {
    FlashInner1,
    FlashStart,
    FlashStartInner,
    FlashStartLearning,
    FlashStartMid,
    FlashStartTop,
    GuidelinesDiv,
    OverButton,
    SpaceBetween,
    StartJourneyButton,
    GuidelinesUpper,
    GuidelinesLower1,
    StartImgdiv,
    StartText,
    ButtonMix,
    FlashStartInner1,
    StartImg
} from '../FlashCardLearning/StartLearn.styles';
import {FlashCardsLearning} from '../data/FlashCardLearning.lang'

import TopBar from './TopBar';
import skill1 from '../../Assets/Images/skill-based (2).png';
import skill2 from '../../Assets/Images/skill-based (1).png';
import skill3 from '../../Assets/Images/skill-based.png';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Slider } from './Slider';
import {slideLanguage} from './Slider.lang';
import { clearData ,CardByLimit} from '../../Store/Slice/FlashCards/GetFlashCardsSlice';

const FlashStartLearn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const document_id  = useSelector(state => state?.FlashCards?.document_id); //Get all Flash Cards
  const lang = useSelector((state) => state?.lang?.language);

  const serializedData = localStorage.getItem('FlashCards');
  const data = JSON.parse(serializedData);
  const Totalcount = data?.count;

  const [Carddata,setdata] = useState({
    limit:Totalcount,
    document_id:document_id
  })

  useEffect(()=>{
    dispatch(clearData([]));
    dispatch(CardByLimit(Carddata))
  },[Totalcount])

  const handleStart = () =>{
    navigate('/Flash/StartLearning/Cards');
  }

  const handleBack = () =>{
    navigate(`/flash-card/${document_id}`)
  }

  return (
    <FlashStartLearning>
      <TopBar/>
      <FlashStart>
        <FlashStartMid>
          <FlashStartTop>
            <GuidelinesDiv lang={lang}>
              <GuidelinesUpper>
                {FlashCardsLearning[lang].UpperText}
              </GuidelinesUpper>
              {/* <GuidelinesLower1>
                {FlashCardsLearning[lang].lowerText}
              </GuidelinesLower1> */}
            </GuidelinesDiv>
          </FlashStartTop>
            <Slider slides={slideLanguage}/>
          <FlashStartInner1>
            <FlashInner1>
              <StartImg src={skill1} alt="" />
              <StartText lang={lang}>
                {FlashCardsLearning[lang].StartText2}
              </StartText>
            </FlashInner1>
            <FlashInner1>
            <StartImg src={skill2} alt="" />
              <StartText lang={lang}> 
                {FlashCardsLearning[lang].StartText1}
              </StartText>
            </FlashInner1>
            <FlashInner1>
            <StartImg src={skill3} alt="" />
              <StartText lang={lang}>
                {FlashCardsLearning[lang].StartText}
              </StartText>
            </FlashInner1>
          </FlashStartInner1>
          <ButtonMix>
          <StartJourneyButton onClick={handleStart}>
            {FlashCardsLearning[lang].StartButton}
          </StartJourneyButton>
          <OverButton onClick={handleBack} >
            {FlashCardsLearning[lang].BackButton}
          </OverButton>
          </ButtonMix>
        </FlashStartMid>
      </FlashStart>
    </FlashStartLearning>
  )
}

export default FlashStartLearn
