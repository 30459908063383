import React, { useEffect, useState } from "react";
import {
  FormInput,
  HeroBackLeft,
  HeroBackRight,
  HeroContainer,
  HeroContent,
  HeroHeading,
  HeroInput,
  HeroPDF,
  HeroSubText,
  HeroText,
  PdfForm,
  PdfInput,
  PdfLabel,
} from "./hero.styles";
import pdf from "../../Assets/Images/uploadpdf.png";
import hero from "../../Assets/Images/Grouphero.png";
import right from "../../Assets/Images/Grouphero right.png";
import { useDispatch, useSelector } from "react-redux";
import { Herolang } from "../data/Home.lang";
import { useNavigate } from "react-router-dom";
import ApiClient from "../../Config/index.config";
import { setClickId } from "../../Store/Slice/UserDetails/UserDetailsSlice";
import { setAmount, setPremiumDetails } from "../../Store/Slice/PremiumDetails/PremiumDetailsSlice";

function Hero() {
  const lang = useSelector((state) => state?.lang?.language);
  const isAuthenticatid = useSelector((store) => store.auth.isAuthenticated)
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const handleUploadClick = () => {
    if(!isAuthenticatid){
      navigate("/user/login")
    }
    else{
      navigate("/upload")
    }
  }

  const getClickId = async (ref) => {
    try{
      const response = await ApiClient.post("/auth/send-click", {
        "reference": ref
      })
      console.log(response)
      dispatch(setClickId(response?.data?.data?.click_id));
    }
    catch(error){
      console.log(error)
    }
  }

  const getPremiumDetails = async () => {
    try{
      const response = await ApiClient.get("library/premium-details")
      dispatch(setPremiumDetails(response.data.data.feature_data))
      dispatch(setAmount(response.data.data.amount))
    }
    catch(error){
      console.log(error);
    }
  }

  useEffect(() => {
    getPremiumDetails();
    const params = new URLSearchParams(window.location.search);


    const ref = params.get('ref');
    if(ref !== null){
      getClickId(ref);
    }
  },[])

  return (
    <>
      <HeroContainer>
        {/* <HeroBackLeft src={hero} lang={lang}></HeroBackLeft> */}
        <HeroText>
          <HeroHeading lang={lang}>
            {Herolang[lang]?.head1}{" "}
            {/* <span style={{ color: "#CA56A1" }}>{Herolang[lang]?.head2}</span>
            {Herolang[lang]?.head3} */}
          </HeroHeading>
          <HeroSubText lang={lang}>{Herolang[lang]?.desc}</HeroSubText>
        </HeroText>
        <HeroContent>
          <HeroInput>
            <HeroPDF>
              <PdfInput>
                <PdfForm action="#">
                  <PdfLabel>
                    <FormInput
                      type=""
                      onClick={() => {handleUploadClick()}}
                    />
                    <img src={pdf} alt="pdf" />
                    <p>{"اسحب ملفك الى هنا أو ارفع الملف من جهازك "}</p>
                  </PdfLabel>
                </PdfForm>
              </PdfInput>
            </HeroPDF>
          </HeroInput>
          {/* <UploadPDF/> */}
        </HeroContent>
      </HeroContainer>
      {/* <HeroBackRight src={right} lang={lang}></HeroBackRight> */}
    </>
  );
}

export default Hero;