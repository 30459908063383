import React, { useEffect, useState,useRef } from 'react';
import { useSelector } from 'react-redux';
import dots from '../../Assets/Images/MoreIcon.svg';
import {
  DeleteDiv,
  EditDiv,
  GridHead,
  GridItem,
  GridPara,
  OpenDiv,
  TextEdit,
  TextGrid
} from "./FlashCards.styles";
// import initialData from '../../Utils/FlashCards.json';
import EditIcon from "../../Assets/Images/EditIcon.svg"
import DeleteIcon from "../../Assets/Images/DeletIconRed.svg"
import {FlashCardlang} from '../data/FlashCard.lang.js'

const FlashCardGrid = ({head,data,DeleteDiv1,EditDelete,id,stats}) => {
  const [open,setopen] = useState(false);
  const handleopen = (id) => {
    open ? setopen(false) : setopen(true);
  }

  const lang = useSelector((state) => state?.lang?.language);
  // const responses = initialData.data.responses; // Get the responses 
  // console.log(responses);
  
  const status = stats;
  const words = data.split(' ');
  const first15Words = words.slice(0, 22).join(' ');

  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setopen(false);
      }
    }

    // Add event listener when component mounts
    document.addEventListener('click', handleClickOutside);
    
    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleclose = () =>{
    setopen(false);
  }

  return (
    <>
    <GridItem ref={dropdownRef} color={
      status === "mastered" ? '#e8ffdd':
      status === "needs_review" ? '#fff7ed':
      '#ffffff'
    } border={
      status === "mastered" ? '#cdfee8' :
      status === "needs_review" ? '#ffeace' :
      '#eeeeee'
    }>
    {
      open ?
      <>
      <OpenDiv lang={lang}>
        <EditDiv>
          <img src={EditIcon} alt="edit icon"/>
          <TextEdit onClick={()=>{EditDelete(id,head,data); handleopen();}}>
            <div>{FlashCardlang[lang].amendment}</div>
          </TextEdit>
        </EditDiv>
        <DeleteDiv onClick={()=>{DeleteDiv1(id); handleopen();}}>
          <img src={DeleteIcon} alt="edit icon"/>
            <TextEdit>
              <div style={{color: "#FF6868"}}>{FlashCardlang[lang].Delete}</div>
            </TextEdit>
        </DeleteDiv>
      </OpenDiv>
      </>
      :
      <>
      </>
    }
          <GridHead lang={lang}>
            <TextGrid >
            {head}
            </TextGrid>
            <div onClick={() => handleopen(id)} style={{cursor: "pointer", height: "1rem", width: "1rem", marginTop: "1rem", display: "flex"}}>
              <img  
              style={{width: "1rem"}}
              src={dots} alt="dot icon" />
            </div>
          </GridHead>
          <GridPara>
            {first15Words}
          </GridPara>
    </GridItem>
    </>
  )
}

export default FlashCardGrid
