import React, { useState } from 'react'
import Filterdata from './filterdata.js';
import Librarydata from './librarydata.js';
import Interactivedata from './Interactivedata.js';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import ApiClient from '../../Config/index.config.js';
import { setAmount, setPremiumDetails } from '../../Store/Slice/PremiumDetails/PremiumDetailsSlice.js';
import { useDispatch } from 'react-redux';



const Publiclibrary = () => {
  const [activeFile, setActiveFile] = useState(null);
  const [activeShare, setActiveShare] = useState(null);
  const [moreOptions, setMoreOptions] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  const handleclickshare = () => {
    setMoreOptions(false);
    setActiveShare(null);
    setActiveFile(null);
  }

  const getPremiumDetails = async () => {
    try{
      const response = await ApiClient.get("library/premium-details")
      dispatch(setPremiumDetails(response.data.data.feature_data))
      dispatch(setAmount(response.data.data.amount))
    }
    catch(error){
      console.log(error);
    }
  }

  useEffect(() => {
    getPremiumDetails();
  },[])

  return (
    <div onClick={()=>handleclickshare()}>
        <Filterdata/>
        <Librarydata activeFile={activeFile} setActiveFile={setActiveFile} activeShare={activeShare} setActiveShare={setActiveShare} moreOptions={moreOptions} setMoreOptions={setMoreOptions} />
        <Interactivedata activeFile={activeFile} setActiveFile={setActiveFile} activeShare={activeShare} setActiveShare={setActiveShare} moreOptions={moreOptions} setMoreOptions={setMoreOptions}/>
    </div>
  )
}

export default Publiclibrary;