import styled, { keyframes } from "styled-components";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Link } from "react-router-dom";
import { media } from "../../Assets/Styles/utils";
import "../../Assets/fonts/Almarai-Regular.ttf";
import AlmaraiFont from "../../Assets/fonts/Almarai-Regular.ttf";

export const AuthWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #fcf7fa;
  display: flex;
  justify-content: center;
  align-items: center;
  
  ${media("small")} {
    align-items: start;
  }
  ${media("medium")} {
    height:auto;
  }
  @media screen and (orientation: landscape) {
    height: auto; 
  }
`;

export const LoginContainer = styled.div`
  align-items: center;
  padding: 20px;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 1440px) {
    width: 80%;
  }
  @media (max-width: 768px) {
    width: 100%;
    align-items: auto;
  }
`;
export const HeadingContainer = styled.h1`
  text-align: center;
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 10px;
`;
export const ParagraphContainer = styled.p`
  text-align: center;
  font-size: 0.8rem;
  font-weight: 400;
  color: #333;
  ${media("small")} {
    font-size: 1rem;
  }
`;
export const Span = styled.span`
  color: #ca56a1;
`;
export const FormContainer = styled.form`
  display: flex;
  width: 70%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // border: 1px solid #e0e0e0;
  padding: 10px 5%;
  &:focus {
    outline: none;
    border: solid 2px #b8dafc;
  }
  @media (max-width: 768px) {
    width: 90%;
  }
`;
// export const InputContainer = styled.div`
//   width: 100%;
//   justify-content: center;
//   align-items: center;
//   display: flex;
//   margin: 10px 0;
//   height: 50px;
// `;
export const InputStyle = styled.input`
  width: 97%;
  height: 50px;
  justify-content: ${(props) => (props.lang === "ar" ? "end" : "start")};
  text-align: ${(props) => (props.lang === "ar" ? "right" : "left")};
  padding-left: 10px;
  border: 2px solid #eeeeee;
  &:focus {
    outline: none;
  }
  &::placeholder {
    text-align: ${(props) => (props.lang === "ar" ? "right" : "left")};
  }
  ${media("small")}{
    height:64px;
  }
`;
export const Error = styled.p`
  color: red;
  font-size: 0.8rem;
  margin: 0;
  padding: 0;
  text-align: left;
  width: 100%;
  height: 20px;
`;
export const Phone2Input = styled(PhoneInput)`
  &.react-tel-input {
    margin: 10px 5%;
    padding: auto 10px;
    width: 100%;
    height: 52px;
    background-color: white;
    border: 2px solid #eeeeee;
    direction: ltr;
    ${media("small")} {
      height: 64px;
    }
  }
  &.react-tel-input .flag-dropdown {
    height: 40px;
    background-color: white;
    border: none;
    margin: auto 7px;
    ${media("small")} {
      height: 50px;
    }
  }
  &.react-tel-input .selected-flag {
    width: 100%;
    height: 115%;
    background-color: white;
    border: none;
    &.hover {
      background-color: white;
    }
  }
  &.react-tel-input .sa {
    scale: 1.5;
    padding: auto 5px;
    margin-top: -8px;
    border: none;
  }
  &.react-tel-input .arrow {
    scale: 1.5;
    padding: auto 5px;
    direction: ltr;
  }

  &.react-tel-input .form-control {
    height: 52px;
    width: 100%;
    font-size: 0.8rem;
    border-radius: 0;
    background-color: white;
    border: none;
    padding-right: 0px;
    padding-left: 56px;
    border-right-width: 0px;
    direction: ltr;
    ${media("small")} {
      height: 64px;
    }
  }
`;

export const PasswordInput = styled.div`
  border: 2px solid #eeeeee;
  padding: 0 0;
  display: ${(props) => (props.lang === "ar" ? "flex" : "flex")};
  margin: 10px 0;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
  &:focus {
    outline: none;
  }

  ${media("small")} {
    height: 64px;
  }
`;
export const PassContainer = styled.div`
  width: 100%;
  margin: 10px 0;
  border: 2px solid #eeeeee;
`;
export const Passwordvisible = styled.div`
  background-color: white;
  height: 52px;
  border: 1px solid #eeeeee;
  opacity: 0.8;
  border-left: none;
  width: 17%;
  display: ${(props) => (props.lang === "sa" ? "flex-reverse" : "flex")};
  border-left: none;
  border-right: ${(props) =>
    props.lang === "sa" ? "2px solid #eeeeee" : "none"};
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  cursor: pointer;
  ${media("small")} {
    height: ${(props) => (!props.profile ? "66px" : "66px")};
  }
`;
export const ParagraphContainer2 = styled.p`
  display: flex;
  margin: auto 2%;
  padding-bottom: 10px;
  justify-content: start;
  text-align: right;
  font-size: 0.8rem;
  ${media("small")} {
    font-size: 1rem;
  }
`;
export const ParaContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 0 10px;
  justify-content:center;
`;
export const ButtonContainer = styled.div`
  width: 100%;
  padding: 0 0;
  border: none;
  margin: 5px 0px;
  justify-content: center;
  display: flex;
`;
export const ButtonStyle = styled.button`
  width: 100%;
  margin: 5% auto;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 700;
  border: 2px solid #eeeeee;
  background-color: #4d2c77;
  &:disabled {
    background-color: #ffffff;
    color: grey;
  }
  height: 56px;
  ${media("small")} {
    height: 64px;
  }
`;
export const ViewContain = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;
export const ViewContain2 = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  ${media("small")} {
    flex-direction: column;
  }
`;

export const ViewLine = styled.div`
  flex: 1;
  background-color: #e0e0e0;
  height: 1.2px;
  color: #eeeeee;
`;

export const ViewText = styled.p`
  margin: 0 10px;
  color: #4c4c4c;
`;
export const SocialButton = styled.button`
  width: 45%;
  margin: 5% 0;
  border: 2px solid #eeeeee;
  background-color: #ffffff;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${media("small")} {
    width: 100%;
    margin: 10px 0;
    height: 64px;
  }
`;
export const InputStyle2 = styled.input`
  width: 97%;
  height: 50px;
  justify-content: ${(props) => (props.lang === "ar" ? "end" : "start")};
  text-align: ${(props) => (props.lang === "ar" ? "right" : "left")};
  padding-left: 10px;
  border: 2px solid #eeeeee;
  border-right: none;
  border-left: none;
  &:focus {
    outline: none;
  }
  &::placeholder {
    text-align: ${(props) => (props.lang === "ar" ? "right" : "left")};
}
  ${media("small")} {
    height: 64px;
  }
`;

//Model for query on registerion
export const ModelWrapper = styled.div`
  width: 100%;
  height: 90vh;
  background-color: #fcf7fa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
`;
export const Returnbtn = styled.div`
  position: absolute;
  top: 0;
  left: ${(props) => (props.lang !== "ar" ? "0" : "auto")};
  right: ${(props) => (props.lang !== "ar" ? "auto" : "0")};
  display: none;
  margin: 10px;
  padding: 10px;
  font-size: ${(props) => (props.lang === "ar" ? "1.5rem" : "1rem")};
  cursor: pointer;
  line-height: 1.5;
  &:hover {
    background-color: #eeeeee;
  }
  img {
    padding: 0 5px;
  }
  ${media("small")} {
    display: initial;
  }
`;

export const ModelContent = styled.div`
  background-color: white;
  width: 30%;
  margin-top: -6%;
  max-height: 70%;
  padding: 0.5% 1%;
  border-radius: 10px;
  overflow: hidden;
  @media (max-width: 1440px) {
    width: 60%;
  }
  @media (max-width: 768px) {
    width: 80%;
    margin-top:-20%;
  }
`;
export const Modelh1 = styled.h1`
  color: #252525;
  font-size: 1.5rem;
  font-weight: 600;
  display: flex;
  margin-left: 10px;
  justify-content: start;
`;
export const Modelh3 = styled.h3`
  color: #727272;
  font-weight: 400;
  font-size: 1rem;
  display: flex;
  margin-left: 10px;
  justify-content: start;
  padding: 0;
`;
export const ProgressWidth = styled.div`
  width: 100%;
  display: flex;
  padding: auto 10px;
  justify-content: space-around;
  align-items: center;
`;
export const ProgressBar = styled.progress`
  appearance: none;
  height: 10px;
  width: 80%;
  border-radius: 100px;
  &::-webkit-progress-bar {
    background: #eeeeee;
    border-radius: 100px;
  }

  &::-webkit-progress-value {
    background-color: #ca56a1;
    border-radius: 100px;
  }
`;
export const ProgressValue = styled.p`
  color: #4c4c4c;
  font-size: 1rem;
  font-weight: 500;
`;
export const Query = styled.div`
  display: flex;
  margin: auto 5%;
  color: #4c4c4c;
  justify-content: start;
  align-items: center;
  padding: 10px 0;
  font-size: 1.2rem;
  font-weight: 400;
`;
export const Query2 = styled.div`
  display: flex;

  color: #4c4c4c;
  justify-content: start;
  align-items: center;
  padding: 10px 0;
  font-size: 1.2rem;
  font-weight: 400;
`;
export const Tabquery = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0 0;
`;
export const Tabcontainer = styled.div`
  width: 100%;
  margin-top: -5px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0 0;
  input {
    width: 95%;
    height: 45px;
    border: 2px solid #eeeeee;
    padding: 0 10px;
    &:focus {
      outline: none;
    }
  }
`;
export const Tabquery2 = styled.div`
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  margin: 15px auto;
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 20px;
  }
  
  // &::-webkit-scrollbar {
  //   width: 4px;
  // }

  // &::-webkit-scrollbar-track {
  //   background: #f4f4f4;
  // }

  // &::-webkit-scrollbar-thumb {
  //   background-color: black;
  //   border-radius: 20px;
  // }
  // &::-webkit-scrollbar-track-piece {
  //   background: #f4f4f4;
  // }

  // &::-webkit-scrollbar-thumb {
  //   background-color: black;
  //   border-radius: 20px;
  //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  // }
`;
export const SelectButton = styled.button`
  height: 50px;
  margin: 4px 5%;
  border: 1px solid #eeeeee;
  background-color: #fcfcfc;
  justify-content: start;
  align-items: center;
  display: flex;
  border-radius: 5px;
  column-gap: 5px;
`;

export const A = styled(Link)`
  color: #4c4c4c;
`;

//dropdown select

export const Option = styled.option`
  width: 95%;
  height: 50px;
  align-items: center;
  display: flex;
  padding: 5px;
  font-size: 1rem;
  font-weight: 400;
  color: #4c4c4c;
  margin: 6px 0;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  background-color: #fcfcfc;
  &:focus {
    outline: none;
  }
`;
export const ImgStyle = styled.img`
  width: 14px;
  height: 14px;
  align-items: center;
  margin: 0 8px;
`;

export const ModelbtnWrapper = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: 0px 0px 10px 0px #eeeeee;
`;
export const ModelbtnContainer = styled.div`
  display: flex;
  width: 40%;
  padding: 1%;
  padding-left: 0px;
  padding-right: 0px;
  justify-content: end;
  align-items: center;
  margin: 10px 0;
  ${media("small")} {
    width: 100%;
    padding:auto;
    flex-direction: column;
  }
`;

export const ModelButton = styled.button`
  width: 100px;
  height: 50px;
  border: none;
  background-color: #4d2c77;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  border-radius: 5px;
  &:disabled {
    background-color: #ffffff;
    color: grey;
    cursor: not-allowed;
  }
  ${media("small")} {
    margin: 10px 0;
    width: 80%;
    height:30px;
  }
`;
export const AskMeLater = styled.button`
  width: 100px;
  height: 50px;
  border: none;
  background-color: #ffffff;
  color: grey;
  font-weight: 600;
  border-radius: 5px;
  ${media("small")} {
    height: 30px;
  }
`;
export const rotate = keyframes`
  to {
    transform: rotate(.5turn);
  }
`;
export const LoaderCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
export const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 5px solid;
  border-color: #fff #0000;
  animation: ${rotate} 1s infinite;
  color: #fff; /* Change the text color to white */
`;
export const Otp=styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vh;
  padding: 40px;
  self-align: center;
   `;
export const OtpContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
  margin: 20px 0;
  direction: ltr;
`;
export const OtpInput = styled.input`
// border: 1px solid;
border:none;
width: 50px;
min-height: 40px;
text-align: center;
padding: 3px;
border-radius: 4px;
background-color: #f6edf4;
color: black;
&:focus {
  // border-width: 2px;
  border:none;
  outline: none;
}
appearance: none;
${media("small")} {
  width: 30px;
  min-height: 20px;
}
`;