import React, { useState } from "react";
import {
  ResultButton,
  ResultButton2,
  ResultContainer,
  ResultSubTitle,
  ResultTitle,
  ResultWrapper,
  Result_,
  Resultparagraph,
  Resultreport,
  Resulttab,
  Span,
  StyledStar,
} from "./ResultStyle";
import { FaStar } from "react-icons/fa";
import { Auth } from "../../Services/api";
import { authlang } from "../data/auth.lang";
import { useSelector } from "react-redux";
import { result } from "../data/result.lang";

const Result = () => {
  const lang = useSelector((state) => state?.lang?.language);
  const [rating, setRating] = useState(null);
  const [hover, setHover] = useState(null);
  return (
    <ResultContainer>
      <ResultWrapper>
        <ResultTitle>{result[lang]?.heading}</ResultTitle>
        <ResultSubTitle>{result[lang]?.subhead}</ResultSubTitle>
        <Result_>
          <Resulttab>
            <h1>
              8 <Span style={{ backgroundColor: "#eeeeee" }} />
            </h1>
            <h2>{result[lang]?.remaining}</h2>
          </Resulttab>
          <Resulttab>
            <h1>
              8 <Span style={{ backgroundColor: "#FFDFB4" }} />
            </h1>
            <h2>{result[lang]?.review}</h2>
          </Resulttab>
          <Resulttab>
            <h1>
              8 <Span style={{ backgroundColor: "#039855" }} />
            </h1>
            <h2>{result[lang]?.success}</h2>
          </Resulttab>
        </Result_>
        <Resultparagraph>
          {[...Array(5)].map((star, index) => {
            const currentRating = index + 1;
            return (
              <label>
                <input
                  style={{ display: "none" }}
                  type="radio"
                  name="rating"
                  value={currentRating}
                  onClick={() => {
                    setRating(currentRating);
                  }}
                />
                <StyledStar
                  class="star"
                  size={50}
                  color={
                    currentRating <= (rating || hover) ? "#FFD700" : "#e4e5e9"
                  }
                  onMouseEnter={() => setHover(currentRating)}
                  onMouseLeave={() => setHover(null)}
                />
              </label>
            );
          })}
        </Resultparagraph>
        <Resultparagraph>{result[lang]?.paragraph}</Resultparagraph>
        <ResultButton>{result[lang]?.Submitbutton}</ResultButton>
        <ResultButton2>{result[lang]?.ending}</ResultButton2>
      </ResultWrapper>
    </ResultContainer>
  );
};

export default Result;
