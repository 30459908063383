import React, { useEffect, useRef, useState } from 'react';
import { IoMdClose } from "react-icons/io";
import { MdNavigateNext } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Search from '../../Assets/Images/Search.png';
import StartTestIcon from '../../Assets/Images/StartTestIcon.svg';
import AddLineIcon from '../../Assets/Images/AddLinesIcons.svg';
import CopyIcon from '../../Assets/Images/CopyIcon.svg';
import { Editcard, GetCardsPara, GetData, GetSearchData, GetSearchDataPara, GetallCards, addDocument, clearData, deleteCard } from "../../Store/Slice/FlashCards/GetFlashCardsSlice.js";
import Navigation from '../NavigationBar/Navigation.js';
import CircularProgressBar from './CircularProgressBar.js';
import DeletePopUp from '../Modals/FlashCardDeleteModal/DeletePopUp.js';
import FlashCardGrid from './FlashCardGrid.js';
import {
  AllCards,
  BottomButton,
  CancelButton,
  ColorDiv,
  Container,
  DivPara,
  EditHead,
  EditInnerPopUp,
  EditPop,
  FlashCardsLearning,
  GridContainer,
  IconDiv,
  ImgText,
  InputDiv,
  LearnHead,
  LearningPara,
  LearningSection,
  LeftCardIcons,
  LeftPanelContainer,
  LowerText,
  MainContent,
  QuesEditInput,
  QuesLabel,
  QuesUpdateDiv,
  RightCardBody,
  RightCardFooter,
  RightCardHeader,
  RightCardTitle,
  RightLearningCard,
  SaveButton,
  StartLearning,
  StartLearningButton,
  TopRightBar,
  UpperText,
  LoadingArea,
  ParameterName,
  LearningTextUpper,
  AddFlashCard,
  Add,
  InputContainer,
  StartLearningContainer,
  SmallColorCircle,
  IconsContainer,
  AnimationOuterContainer,
  AnimatedContainer,
  StartLearningHeader,
  DropDown,
  CrossIcon,
  TextArea,
  GridBox,
} from "./FlashCards.styles.js";
import { GrFormPrevious } from "react-icons/gr";
import {FlashCardlang} from '../data/FlashCard.lang.js';
import Loader from '../Loader/Loader.js';
import { GetQuizData } from '../../Store/Slice/FlashCards/FlashCardsQuiz.js';
import { IoIosAdd } from "react-icons/io";
import AddModal from '../Modals/FlashCardAddModal/AddModal.js';
import TwitterShare from '../SocialShare/TwitterShare.js';
import FacebookShare from '../SocialShare/FaceBookShare.js';
import dots from '../../Assets/Images/MoreIcon.svg';
import DonwloadIcon from "../../Assets/Images/DownloadIcon.svg"
import { ExhaustedFlashCard } from '../Modals/ExhaustModals/ExhaustModals.js';
import WhatsappShare from '../SocialShare/WhatsappShare.js';
import NotificationModal from '../Modals/NotificationModal/NotificationModal.js';
import { updateRoutesArray } from '../../Store/Slice/Navigation/NavigationSlice.js';
import ApiClient from '../../Config/index.config.js';
import { setAmount, setPremiumDetails } from '../../Store/Slice/PremiumDetails/PremiumDetailsSlice.js';

const FlashCards = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dropDownRef = useRef();
  const {id} = useParams();
  const location = useLocation();

  useEffect(()=>{
    dispatch(addDocument(id));
  },[id])

  const [activeFilter, setActiveFilter] = useState('all');
  const [Edit,setEdit] = useState(false);
  const [AddCard,SetAddCard] = useState(false);
  const [message,setmessage] = useState(false);
  const [Delete,setDelete] = useState(false);
  const [text,settext] =useState('')
  const [activeId, setActiveId] = useState("all");
  const [loading,setLoading] = useState(true);
  const [flashlen,setflashlen] = useState(false);
  const [dropDown, setDropdown] = useState(false);
  const [isPremiumUser, setIsPremiumUser] = useState(false);
  const [copyModal, setCopyModal] = useState(false);

  const document_id  = id; 
  const flash_cards  = useSelector(state => state?.FlashCards?.CardData ); //Get all Flash Cards
  const NavigationData = useSelector(state => state.navigationData.routesArray) //Get Navigation Routes
  const serializedData = localStorage.getItem('FlashCards');
  const data = JSON.parse(serializedData);
  const StatsElements = data?.stats; // Replace 'particularElement' with the key of the element you want
  const [lastRouteName, setLastRouteName] = useState('');
  const rootObjectString = localStorage.getItem('persist:root');

  useEffect(() => {
    if (rootObjectString) {
      const rootObject = JSON.parse(rootObjectString);
      const navigationDataString = rootObject.navigationData;
      const navigationData = JSON.parse(navigationDataString);
      const lastRoute = navigationData.routesArray[navigationData.routesArray.length - 1];
      if (lastRoute) {
        setLastRouteName(lastRoute.name);
      }
    }
  }, [rootObjectString]);

  const lang = useSelector((state) => state?.lang?.language);

  //Get all cards ki usestate
  const [FlashCardData,SetFlashCardData] = useState({
    page:1,
    status:null,
    search: null,
    document_id:id
  })

  //Delete the card
  const [deleteC,setdeleteC] = useState({
    document_id:document_id,
    id:''
  })
  
  //Edit data
  const [editD,setEditD]= useState({
    document_id:document_id,
    id:'',
    title:'',
    description:''
  })
  
  const handleInputChange = (e) => {
    SetFlashCardData({
      ...FlashCardData,
      [e.target.name]: e.target.value
    });
  };

  //Infinite Scroll
  useEffect(() => {
    const gridContainer = document.getElementById("Grid");
    if (gridContainer) {
      gridContainer.addEventListener("scroll", handleInfiniteScroll);
    }
  
    return () => {
      if (gridContainer) {
        gridContainer.removeEventListener("scroll", handleInfiniteScroll);
      }
    };
  });
  
  const handleInfiniteScroll = async () => {
    const doc = document.getElementById("Grid");
    if (((doc.clientHeight + doc.scrollTop + 1) > doc.scrollHeight) && flash_cards.length > 0) {
      if (FlashCardData?.status !== null) {
        SetFlashCardData((prevState) => ({
          ...prevState,
          page: prevState.page + 1
        }));
      } else {
        SetFlashCardData((prevState) => ({
          ...prevState,
          page: prevState.page + 1
        }));
      }
    }
  };

  //Search By value
  const timerRef = useRef(null);

  useEffect(() => {
    
    return () => {
      // Cleanup function to clear the timeout
      clearTimeout(timerRef.current);
    };
  }, []);

  useEffect(() => {
    if(NavigationData.length === 0){
      dispatch(updateRoutesArray({data: "library", id: " "}))
    }

    if(serializedData !== null && NavigationData.length === 1){
      dispatch(updateRoutesArray({data: JSON.parse(serializedData)?.document_name, id: id}))
    }
  },[serializedData])

  useEffect(() => {
    // Clear previous timer
    clearTimeout(timerRef.current);

    // Set a new timer
    timerRef.current = setTimeout(() => {
      // Your fetch logic here
      const fetchSearchData = async () => {
        setLoading(true);
        try {
          if(FlashCardData?.search === '' && FlashCardData?.status === null){
            dispatch(clearData([]));
            SetFlashCardData({ ...FlashCardData,status:null,search :null, page :  1});
          }
          else if(FlashCardData?.search === '' && FlashCardData?.status !== null){
            dispatch(clearData([]));
            SetFlashCardData({ ...FlashCardData,search:null, page : 1});
          }
          else if(FlashCardData?.search !== '' && FlashCardData?.search != null && FlashCardData?.status == null){
            console.log("Hereee...");
            const res = await dispatch(GetSearchDataPara(FlashCardData));
            console.log(res);
            if (res && res.status === 200) {
              setLoading(false);
            }
            if(res.response.status === 404){
              setflashlen(true);
            }
            else{
              dispatch(clearData([]));
              setLoading(false);
            }
          }
          if (FlashCardData?.search != null && FlashCardData?.search !== '' && FlashCardData?.status != null) {
            const res = await dispatch(GetSearchData(FlashCardData));
            console.log(res);
            console.log(flash_cards);
            if (res && res.status === 200) {
              setLoading(false);
            }
            if(res.response.status === 404){
              setflashlen(true);
            }
            else{
              dispatch(clearData([]));
              setLoading(false);
            }
          }
          setLoading(false);
        } catch (error) {
          console.log(error);
          if (error?.response?.data?.error_code === 1022) {
            dispatch(clearData([]));
          }
          
          setLoading(false);
        }
      };
      fetchSearchData();
    }, 1000);

    return () => clearTimeout(timerRef.current);
  }, [FlashCardData?.search]);

  // Get by parameter
  useEffect(()=>{
    const fetchParadata = async () => {
      setflashlen(false);
      try {
        if(FlashCardData?.search == null && FlashCardData?.status !== null){
          const res = await dispatch(GetCardsPara(FlashCardData));
          console.log(res); 
          if(res.status === 200){
            setLoading(false);
          }
          console.log(flash_cards);
          if(res.response.status === 404 && flash_cards.length === 0){
            setflashlen(true);
          }
          else{
            setflashlen(false);
          }
        }
      } catch (error) {
        if(error?.response?.data?.error_code === 1022){
          dispatch(GetData(null));
        }
        setLoading(false);
      }
    }
    fetchParadata();
  },[FlashCardData])

  //Get all flash Cards
  useEffect(() => {
    // setLoading(true);
    const fetchData = async () => {
      try {
        let response;
        if(FlashCardData?.status === null && FlashCardData?.search === null){
          response = await dispatch(GetallCards(FlashCardData));
          setIsPremiumUser(response?.data?.data?.is_premium_user);
        }
        // let flen = response?.data?.data?.count;
        setLoading(false);
        if(response.response.status === 404 && flash_cards.length === 0){
          setflashlen(true);
        }
        else{
          setflashlen(false);
        }
      } catch (error) {
        if (error?.response?.data?.error_code === 1022) {
          dispatch(GetData([]));
        }
        setLoading(false);
      }
    };

    if(FlashCardData.page >= 3 && isPremiumUser){
      fetchData();
    }
    else if(FlashCardData.page < 3){
      fetchData();
    }
  }, [FlashCardData, dispatch]);

  const handleFilter = (value) => {
    setActiveFilter(value);
    setLoading(true);
    if(value === ''){
      dispatch(clearData([]));
      SetFlashCardData({ ...FlashCardData, status: null , page : 1});
    }
    else{
      dispatch(clearData([]));
      SetFlashCardData({ ...FlashCardData, status: [value] , page : 1});
    }
  };
  const questions = useSelector(state => state?.Quiz?.QuizData?.questions);
  const mcqObjects = questions?.filter(obj => obj.type === "mcq");

  useEffect(()=>{
    dispatch(GetQuizData(document_id));
  },[document_id])
  
  const DeleteDiv = (id) =>{
    setDelete(true);
    setdeleteC({...deleteC,id:id});
  }

  const EditDelete = (id,head,data) =>{
    setEdit(true);
    setEditD({...editD, id: id,title :head,description : data});
  }

  const handleTextareaChange = (event) => {
    const newText = event.target.value;
    setEditD({ ...editD, title: newText });
  };

  const handleDesareaChange = (event) =>{
    const textdes = event.target.value;
    setEditD({...editD,description:textdes});
  }

  const StartLearn = () =>{
    navigate('/Flash/StartLearning');
  }

  const StartTest = () =>{
    navigate('/Flash/StartQuiz');
  }

  const handleBack = () =>{
    setDelete(false);
  }

  //Delete Api Call
  const handleDelete = async () =>{
    setDelete(false);
    let response = await dispatch(deleteCard(deleteC));
    console.log(response);
    if(response && response.data.message === 'Success'){
      setmessage(true);
      if(lang === 'en'){
        settext("The card has been successfully deleted");
      }
      else{
        settext("تم حذف البطاقة بنجاح");
      }
      dispatch(clearData([]));
      SetFlashCardData({ ...FlashCardData, page : 1});
      setTimeout(() => {
        setmessage(false);
      }, 2000);
    }
  }

  const close = () =>{
    SetAddCard(false);
    setEdit(false);
  }

  // Scrolling to the top onload
  useEffect(() => {
    window.scrollTo(0,0)
  },[])

  //Update Api Call
  const updated = async () =>{
    setEdit(false);
    if(editD.description !== '' && editD.title !== ''){
      try {
        const res = await dispatch(Editcard(editD));
        if (res && res.data.message === 'Success') {
          setmessage(true);
          settext("The card has been modified successfully")
          if(lang === 'en'){
            settext("The card has been modified successfully")
          }
          else{
            settext("تم تعديل البطاقة بنجاح")
          }
          dispatch(clearData([]));
          SetFlashCardData({ ...FlashCardData, page : 1});
          setTimeout(() => {
            setmessage(false);
          }, 2000);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  const handleMessage = () => {
    SetAddCard(false);
    setmessage(true);
    if(lang === 'en'){
      settext("The card has been Added successfully")
    }
    else{
      settext("تمت إضافة البطاقة بنجاح")
    }
    dispatch(clearData([]));
    SetFlashCardData({ ...FlashCardData, page : 1});
    setTimeout(() => {
        setmessage(false);
    }, 2000);
  };

  const HandleAddPopUp = () =>{
    SetAddCard(true);
  }

  let masteredCount = StatsElements?.mastered;
  let total = StatsElements?.total;

  let perc = Math.floor((masteredCount / total) * 100);

  const handleMoreClick = () => {
    setDropdown(!dropDown)
  }

  const handleDownload = (e) => {
    e.stopPropagation();

    // here we are creating a link button using js ans simulating its click effect
    // so that we can donwload the file from here only
    // after creating the button and clicking on it with the help of link.click()
    // we deletes the created link button. (NOTE: we are simulating the actual button click behaviour here)
    const link = document.createElement("a");
    link.href = location.state.s3_link;
    // link.setAttribute("download", `${data.name}.pdf`);
    link.download = location.state.s3_link;
    document.body.appendChild(link);
    link.click();

    // Clean up and remove the link
    // link.parentNode.removeChild(link);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
        if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
          setDropdown(false);
        }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [dropDownRef, dropDown]);

  const handleCopy = () => {
    setCopyModal(true)
    navigator.clipboard.writeText(window.location.href)
  }

  const getPremiumDetails = async () => {
    try{
      const response = await ApiClient.get("library/premium-details")
      dispatch(setPremiumDetails(response.data.data.feature_data))
      dispatch(setAmount(response.data.data.amount))
    }
    catch(error){
      console.log(error);
    }
  } 

  useEffect(() => {
      getPremiumDetails();
  },[])


  return (
    <>
        {
            Edit?
            <EditPop>
                <EditInnerPopUp>
                    <EditHead>
                        {FlashCardlang[lang].EditCard}
                    </EditHead>
                    <CrossIcon lang={lang}>
                        <IoMdClose onClick={close}/>
                    </CrossIcon>
                    <QuesUpdateDiv lang={lang}>
                        <QuesLabel lang={lang}>
                            {FlashCardlang[lang].TheQues}
                        </QuesLabel>
                        <QuesEditInput>
                            <TextArea height="80px" type="text" onChange={handleTextareaChange} // Assign onChange handler
                              value={editD.title}>
                            </TextArea>
                        </QuesEditInput>
                    </QuesUpdateDiv>
                    <QuesUpdateDiv lang={lang}>
                        <QuesLabel>
                            {FlashCardlang[lang].TheAns}
                        </QuesLabel>
                        <QuesEditInput>
                            <TextArea height="140px" type="text" onChange={handleDesareaChange} // Assign onChange handler
                            value={editD.description}>
                            </TextArea>
                        </QuesEditInput>
                    </QuesUpdateDiv>
                    <BottomButton>
                        <SaveButton lang={lang} onClick={updated}>
                            {FlashCardlang[lang].save}
                        </SaveButton>
                        <CancelButton onClick={close}>
                            {FlashCardlang[lang].cancel}
                        </CancelButton>
                    </BottomButton>
                </EditInnerPopUp>
            </EditPop>
            : null
        }
        { Delete ? <DeletePopUp handleBack={handleBack} handleDelete={handleDelete}/> : null }
        { AddCard? <AddModal close={close} onUpdateMessage={handleMessage}/> : null }
        <Container Delete={Delete} Edit={Edit}>
            { message ? <NotificationModal data={text} closeNotification={setmessage}/> : null }
            <Navigation insideDocument={true}/>
            <MainContent>
              <LearningSection>

                  <LeftPanelContainer>

                      <TopRightBar>
                          <AllCards isSelected={activeId === 'all'} active={activeFilter === 'all'} onClick={() => {handleFilter(''); setActiveId("all")}}>
                              <ParameterName>{FlashCardlang[lang].All} </ParameterName>
                          </AllCards>
                          <AllCards isSelected={activeId === 'mastered'} active={activeFilter === '#e8ffdd'} onClick={() => {handleFilter('mastered'); setActiveId("mastered")}}>
                              <ColorDiv lang={lang} color='#039855'></ColorDiv>
                              <ParameterName>{FlashCardlang[lang].Completed}</ParameterName> 
                          </AllCards>
                          <AllCards isSelected={activeId === 'needs_review'} active={activeFilter === '#fff7ed'} onClick={() => {handleFilter('needs_review'); setActiveId("needs_review")}}>
                              <ColorDiv lang={lang} color='#ffe7c7' ></ColorDiv>
                              <ParameterName >{FlashCardlang[lang].NeedReview}</ParameterName> 
                          </AllCards>
                          <AllCards isSelected={activeId === 'unvisited'} active={activeFilter === 'white'} onClick={() => {handleFilter('unvisited'); setActiveId("unvisited")}}>
                              <ColorDiv lang={lang} color='#F4F4F4' ></ColorDiv>
                              <ParameterName>{FlashCardlang[lang].Stay}</ParameterName>   
                          </AllCards>
                      </TopRightBar>
                      <InputDiv>
                          <img src={Search} alt="search icon" />
                          <InputContainer lang={lang} name='search' placeholder={FlashCardlang[lang].search} value={FlashCardData?.search || ""} onChange={handleInputChange} />
                      </InputDiv>
                      <AddFlashCard lang={lang}>
                          {FlashCardlang[lang].SummaryCard}
                          <Add onClick={HandleAddPopUp}> <IoIosAdd style={{fontSize:"18px"}}/> {FlashCardlang[lang].Add} </Add>
                      </AddFlashCard>
                      <GridBox id="Grid">
                       <GridContainer>
                        {!loading && flashlen === false ? (
                          flash_cards.map((item, index) => (
                            <FlashCardGrid
                              key={index}
                              head={item.title}
                              data={item.description}
                              stats={item.status}
                              DeleteDiv1={DeleteDiv}
                              EditDelete={EditDelete}
                              id={item?.id}
                            />
                          ))
                        ) : flashlen ? (
                          <AnimationOuterContainer>
                            <AnimatedContainer>
                            {FlashCardlang[lang].AnimationBoxText}
                            </AnimatedContainer>
                            {/* {FlashCardlang[lang].AnimationDescriptionText} */}
                          </AnimationOuterContainer>
                        ) : (
                          <LoadingArea>
                            <Loader/>
                          </LoadingArea>
                        )}
                        {!loading && flashlen === false && (activeFilter === '' || 'all') && FlashCardData.page >= 3 && !(JSON.parse( localStorage.getItem("FlashCards") )).is_premium_user ? 
                        <>
                        <ExhaustedFlashCard data={flash_cards}/> 
                        </>
                        : null}
                      </GridContainer>
                      </GridBox>
                  </LeftPanelContainer>

                  <RightLearningCard lang={lang}>

                    <RightCardHeader>
                        <RightCardBody>
                            <RightCardTitle lang={lang}> {lastRouteName}</RightCardTitle>
                            <RightCardFooter>
                                <IconsContainer> <SmallColorCircle color='#CDFEE8'></SmallColorCircle> {FlashCardlang[lang].Completed} ({StatsElements?.mastered?StatsElements?.mastered:0}) </IconsContainer>
                                <IconsContainer> <SmallColorCircle color='#FFE7C7'></SmallColorCircle> {FlashCardlang[lang].NeedReview} ({StatsElements?.needs_review?StatsElements?.needs_review:0}) </IconsContainer>
                                <IconsContainer> <SmallColorCircle color='#F4F4F4'></SmallColorCircle> {FlashCardlang[lang].remaining} ({StatsElements?.unvisited?StatsElements?.unvisited:0})  </IconsContainer>
                            </RightCardFooter>
                        </RightCardBody>
                        <CircularProgressBar percentage={perc}/>
                    </RightCardHeader>

                    <div style={{border: "0.5px solid #F4F4F4", width: "100%"}}></div>

                    <StartLearningContainer>

                        <StartLearningHeader>
                            <StartLearning lang={lang}>
                              <LearningTextUpper >{FlashCardlang[lang].Startlearning}</LearningTextUpper>
                              <div style={{fontSize:"12px" ,color:"#727272"}}>{FlashCardlang[lang].BottomLearn}</div>
                            </StartLearning>
                            {/* <img ref={dropDownRef} style={{cursor:'pointer'}} onClick={() => handleMoreClick()} src={dots} alt=""/>
                            { dropDown ? <DropDown lang={lang} onClick={handleDownload} ><img src={DonwloadIcon} alt=""/> {FlashCardlang[lang].dropDownDelete}</DropDown> : null } */}
                        </StartLearningHeader>

                        <FlashCardsLearning>
                            <DivPara>
                                <LearnHead>
                                    <img src={AddLineIcon} alt="" />
                                    <ImgText lang={lang}> {FlashCardlang[lang].FlashCards} </ImgText>
                                </LearnHead>
                                <LearningPara> {FlashCardlang[lang].LearnPara} </LearningPara>
                            </DivPara>
                          <div>
                              <StartLearningButton dissabled={flash_cards.length} lang={lang} onClick={StartLearn}>
                                  {FlashCardlang[lang].Startlearning}
                                  { lang === "en" ? <MdNavigateNext style={{"fontSize":"24px"}}/> : <GrFormPrevious style={{"fontSize":"24px"}}/> }
                              </StartLearningButton>
                          </div>
                        </FlashCardsLearning>

                        <FlashCardsLearning>
                          <DivPara>
                              <LearnHead>
                                  <img src={StartTestIcon} alt="" />
                                  <ImgText lang={lang}> {FlashCardlang[lang].Test} </ImgText>
                              </LearnHead>
                              <LearningPara> {FlashCardlang[lang].LearnTest} </LearningPara>
                          </DivPara>
                          <div>
                              <StartLearningButton dissabled={mcqObjects !== undefined} lang={lang} onClick={StartTest}>
                                  {FlashCardlang[lang].startTest}
                                  { lang === "en" ? <MdNavigateNext style={{"fontSize":"24px"}}/> : <GrFormPrevious style={{"fontSize":"24px"}}/> }
                              </StartLearningButton>
                          </div>
                        </FlashCardsLearning>

                        <div style={{width: "100%", border: "0.5px solid #F4F4F4"}}></div>

                        <LeftCardIcons>
                            <div lang={lang}>
                                <UpperText> {FlashCardlang[lang].TicketUpper} </UpperText>
                                <LowerText> {FlashCardlang[lang].TicketLower} </LowerText>
                            </div>
                            <IconDiv>
                              <WhatsappShare pdfName={lastRouteName} url={window.location.href}/>
                              {/* <FacebookShare pdfName={lastRouteName} url={window.location.href}/> */}
                              <TwitterShare pdfName={lastRouteName} url={window.location.href}/>
                              <img style={{cursor:'pointer'}} onClick={() => handleCopy()} src={CopyIcon} alt="copy icon" />
                            </IconDiv>
                        </LeftCardIcons>

                    </StartLearningContainer>

                  </RightLearningCard>

              </LearningSection>
            </MainContent>
        </Container>
        {copyModal ?
        <NotificationModal data={FlashCardlang[lang].copyNotification} closeNotification={setCopyModal}/>
        : null
        }
    </>
  )
}

export default FlashCards