import React from 'react';
import styled from 'styled-components';
import {
  StartNumber1
} from "../FlashCardLearning/StartLearn.styles";
const CircularProgressBar = ({percentage}) => {
  const radius = 34;
  const strokeWidth = 3;
  const normalizedRadius = radius - strokeWidth * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - ((percentage?percentage:0) / 100) * circumference;
  return (
    <ProgressBarWrapper>
      <Svg height={radius * 2} width={radius * 2}>
        <BackgroundCircle
          fill="transparent"
          strokeWidth={strokeWidth}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <ProgressCircle
          stroke="#CA56A1"
          fill="#fcf7fa"
          strokeWidth={strokeWidth}
          strokeDasharray={circumference + ' ' + circumference}
          style={{ strokeDashoffset }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </Svg>
      <PercentageText >
        {percentage?percentage :0 }%
      </PercentageText>
    </ProgressBarWrapper>
  );
};

const ProgressBarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  margin-top: -1rem;
`;

const Svg = styled.svg`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BackgroundCircle = styled.circle``;

const ProgressCircle = styled.circle``;

const PercentageText = styled.span`
  color: #CA56A1;
  font-size: 14px;
  font-weight: bold;
  position: absolute;
`;

export default CircularProgressBar;
