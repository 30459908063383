import React, { useEffect, useState } from 'react'
import {  Description, Dropdown, DropdownButton, Filterdatacontainer, Filterdatawrapper, Header, Selectionfeature } from './HomeSearch.style'
import { useSelector } from 'react-redux'
import { getErrorMessage } from '../Error/Errorcode';
import { toast } from 'react-toastify';
import Taost from '../Toast/Toast';
import { Auth } from '../../Services/api';
import { model, model1, model2 } from '../data/model.lang';
import { educationData } from '../data/profile.lang';
import { useNavigate } from 'react-router-dom';
import { setPublic } from '../../Store/Slice/PublicLibrary/Public.Slice';
import {useDispatch} from 'react-redux';
import { HomeSearchlang } from '../data/Home.lang';
import { Loader, LoaderCenter } from '../Auth/Auth.Style';


const HomeSearch = () => {
    const lang = useSelector((store) => store?.lang?.language);
    const [degree, setDegree] = useState(null);
    const [college, setcollege] = useState(null);
    const [specialization, setspecialization] = useState(null);
    const [allcollege, setallcollege] = useState([]);
    const [alldata, setalldata] = useState([]);
    const [allsecilization, setallsecilization] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading,setLoading]=useState(true);
    const isAuthanticated = useSelector((store) => store?.auth?.isAuthenticated);

   {/* Get the profile data */}
    const getProfileData = async () => {
        try {
          // const data = await Auth.getProfile();
          // const { college_id ,education_level_id, university_id} = data?.data?.data?.user;
          // setDegree(education_level_id);
          // setcollege(college_id);
          // setspecialization(university_id);
          
          const data2 = await Auth.getEducationData();
          const education_id = data2?.data?.data[0]?.tmyyoz_id;
          const unidata2 = await Auth.getUniversity(data2?.data?.data[0]?.tmyyoz_id);
         const university_id = unidata2?.data?.data[0]?.tmyyoz_id;
          // const unidata2 = await Auth.getUniversity(data2?.data?.data[0]?.tmyyoz_id);
          const input2 = unidata2 ?.data?.data[0]?.tmyyoz_id;
          const clgdata2 = await Auth.getCollege(university_id,education_id);
          setDegree(data2?.data?.data[0]?.tmyyoz_id);
          setcollege(unidata2?.data?.data[0]?.tmyyoz_id);
          setspecialization(clgdata2?.data?.data[0]?.tmyyoz_id);
          setalldata(data2?.data?.data);
          setallcollege(unidata2?.data?.data);
          setallsecilization(clgdata2?.data?.data);
          setLoading(false);;
        } catch (error) {
          setLoading(false);
          const errorcode = error?.response?.data?.error_code;
          const message = getErrorMessage(errorcode, lang);
          toast(<Taost message={message} error />);
        }
      };

    useEffect(() => {
        getProfileData();
      }, []);

  {/* handle academic level */}
  const handleAcademicLevel = async (inputData) => {
    try {
        
         const unidata2=await Auth.getUniversity(inputData);
         setcollege("");
         setDegree(inputData);
         setallcollege(unidata2?.data?.data);
         setallsecilization([]);
         setspecialization("");
    } catch (error) {
      const errorcode = error?.response?.data?.error_code;
      const message = getErrorMessage(errorcode, lang);
      toast(<Taost message={message} error />);
    }
  };

  {/* handle college name */}
  const handlecollegename = async (inputData) => {
    try {
      setcollege(inputData);
      const clgdata2 = await Auth.getCollege(inputData,degree);
      setallsecilization(clgdata2?.data?.data);
      setspecialization("");
    } catch (error) {
      const errorcode = error?.response?.data?.error_code;
      const message = getErrorMessage(errorcode, lang);
      toast(<Taost message={message} error />);
    }
  };

  {/* handle specialization */}
  const handleSpecialization = async (inputData) => {
    try {
      setspecialization(inputData);
    } catch (error) {
      const errorcode = error?.response?.data?.error_code;
      const message = getErrorMessage(errorcode, lang);
      toast(<Taost message={message} error />);
    }
  };

  {/* handle search */}
  const handleSearch=() => {
    if(isAuthanticated){
    const data = {
      academic_level: degree,
      college: college,
      specialization: specialization
    };
    dispatch(setPublic(data));
    navigate('/overview');
  }
  else{
    // const message = getErrorMessage(errorcode, lang);
      toast(<Taost message={"تسجيل الدخول للمتابعة"} error />);
    navigate('/user/login');
  }
  };
    

  return (
    <Filterdatacontainer style={{minHeight:"75vh"}}>
        <Filterdatawrapper>
          
            <Header lang={lang}>{HomeSearchlang[lang].Heading1} </Header>
            <Description>{"اختر مرحلتك وتخصصك الدراسي و استفيد من ملفات زملائك في المكتبة العامة "}</Description>
            <Selectionfeature>

                {/* education level selection */}
                <Dropdown>
                    <p>{model1[lang]?.academic}</p>
                    <select style={{fontFamily: "Almarai, sans-serif"}} value={degree} onChange={(e)=>handleAcademicLevel(e.target.value)}>
                        <option disabled value={""}>{model[lang].select}</option>
                        {alldata?.map((data, index) => {  
                            return (<option key={data} value={data.tmyyoz_id}>{data.name}</option>);
                        })}
                        
                    </select>
                </Dropdown>

                {/* University selection */}
                <Dropdown>
                    <p>{model2[lang]?.select}</p>
                    <select style={{fontFamily: "Almarai, sans-serif"}} value={college} onChange={(e)=>handlecollegename(e.target.value)}>
                        <option disabled value="">{model[lang].select}</option>
                        {allcollege?.map((data, index) => {
                            return (<option key={data} value={data.tmyyoz_id}>{data.name}</option>);    
                        })}
                    </select>
                </Dropdown>

                {/* Specialization selection */}
                <Dropdown >
                    <p>{educationData[lang]?.specialization}</p>
                    <select style={{fontFamily: "Almarai, sans-serif"}} value={specialization} onChange={(e)=>handleSpecialization(e.target.value)}>
                        <option disabled value="">{model[lang].select}</option>
                        {allsecilization?.map((data, index) => {
                            return (<option key={data} value={data.tmyyoz_id}>{data.name}</option>);
                        })}  
                    </select>
                </Dropdown>
                <DropdownButton  style={{fontWeight: "800"}} onClick={()=>handleSearch()}>
                 {loading? <LoaderCenter>
                  <Loader />
                </LoaderCenter> :"ابحث "}
                  </DropdownButton>
            </Selectionfeature>
        </Filterdatawrapper>
    </Filterdatacontainer>
  )
}

export default HomeSearch;