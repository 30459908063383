export const HeaderLanguage = {
    ar : {
        publicLibraryButton :  "المكتبة العامة",
        publicLibraryButtonSmall :  "عام",
        myLibraryButton : "مكتبتي", 
        myLibraryButtonSmall : "لي", 
        loginButton : "تسجيل الدخول",
        registerButton : "سجل الآن ",
        newButton : "جديد",
        folderCreation: "إنشاء مجلد جديد", 
        addFile : "أرفق ملف",
        profile : "حسابي",
        signout :"تسجيل الخروج",
    },

    en : {
        publicLibraryButton :  "Public library",
        publicLibraryButtonSmall :  "Pub.",
        myLibraryButton : "My library", 
        myLibraryButtonSmall : "My", 
        loginButton : "Login",
        registerButton : "Register",
        newButton : "new",
        folderCreation : "Create a new folder",
        addFile : "Attach a file",
        profile : "Profile",
        signout : "Sign out",
    },
}

