import React, { useEffect } from 'react';
import { MdNavigateNext } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TestStartImage from '../../Assets/Images/img1.png';
import { GetQuizData } from '../../Store/Slice/FlashCards/FlashCardsQuiz';
import TopBar from '../FlashCardLearning/TopBar';
import {
  FlashInner,
  FlashTest,
  FlashTestContainer,
  StartImage,
  TestButton,
  TestHeading1,
  TestHeading2,
  TestHeading3,
  TestTextDiv
} from './Quiz.styles';
import { GrFormPrevious } from "react-icons/gr";
import { FlashCardQuiz } from '../data/FlashCardQuiz.lang';
const FlashQuiz = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const document_id  = useSelector(state => state?.FlashCards?.document_id);
    const questions = useSelector(state => state?.Quiz?.QuizData?.questions);
    const mcqObjects = questions;
    useEffect(()=>{
      dispatch(GetQuizData(document_id));
    },[document_id])
    const lang = useSelector((state) => state?.lang?.language);

    const handleclick = () =>{
        navigate('/Flash/StarTest/Quiz');
    }
  return (
    <FlashTest>
        <TopBar/>
        <FlashTestContainer>
            <FlashInner>
                <StartImage src={TestStartImage} alt="" />
                <TestTextDiv>
                    <TestHeading1>
                      {FlashCardQuiz[lang].TestHeading}
                    </TestHeading1>
                    {/* <TestHeading2>
                      {FlashCardQuiz[lang].TestHeading1}
                    </TestHeading2> */}
                    <TestHeading3>
                      <div style={{display: "flex", alignItems: "center", justifyContent: "center", gap: "8px"}}>
                        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1.125 2.125L2.25 3.25L4.125 1.375M1.125 6.625L2.25 7.75L4.125 5.875M1.125 11.125L2.25 12.25L4.125 10.375M6.75 2.5H13.5M6.75 7H13.5M6.75 11.5H13.5" stroke="#727272" strokeWidth="1.71" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <span style={{color: "#989898"}}>{FlashCardQuiz[lang].NoofQuestion}</span>
                      </div>
                        {mcqObjects == null ? 0 : mcqObjects?.length} {FlashCardQuiz[lang].Question}
                    </TestHeading3>
                </TestTextDiv>
                <TestButton onClick={handleclick}>
                  {FlashCardQuiz[lang].StartTest}
                  {
                    lang === "en"?
                    <MdNavigateNext style={{fontSize:"18px",color:"white"}}/>:
                    <GrFormPrevious style={{fontSize:"18px",color:"white"}}/>
                  }
                </TestButton>
            </FlashInner>
        </FlashTestContainer>
    </FlashTest>
  )
}

export default FlashQuiz
