import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store, { persistor } from "./Store";
import { PersistGate } from "redux-persist/integration/react";
import { LayoutDirectionProvider } from "./Components/context/context.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <LayoutDirectionProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </LayoutDirectionProvider>
);

reportWebVitals();
