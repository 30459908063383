import { BillAmount, BillingSection, Details, Discount, Heading, LoadingArea, MainContainer, OrderItems, PaymentSection, Price, SaperatingLine, SubContainer, TextContainer, TotalAmount } from "./PaymentScreen.style"
import Logo from "../../Assets/Images/PaymentPageLogo.svg"
import { PaymentLanguage } from "./Payment.lang"
import { useSelector } from "react-redux"
import PaymentNavigation from "./PaymentNavigation"
import ApiClient from '../../Config/index.config';
import { useEffect } from "react"
import { useState } from "react"
import PaymentForm from "./StaticContent/PaymentForm"
import Loader from "../Loader/Loader"
import { useLocation } from "react-router-dom"


const PaymentScreen = () => {
    const lang = useSelector((store) => store.lang.language);
    const [checkoutId, setCheckoutId] = useState(null);
    const [billingAmount, setBillAmount] = useState(null)
    const [discount, setDiscount] = useState(0)
    const {state} = useLocation();

    const getCheckoutId = async () => {
        try{
            const responce = await ApiClient.post(`/payment/create-checkout`, {
                type: state?.brands === "VISA MASTER" ? "visa" : "mada",
            });
            setCheckoutId(responce?.data?.message?.checkout_id);
            setBillAmount(responce?.data?.message?.amount);
            if(responce?.data?.message?.discount){
                setDiscount(responce.data.message.discount)
            }
        }
        catch(error){
            console.log(error);
        }
    }

    useEffect(() => {
        getCheckoutId();
    },[])

    

    return (
        <MainContainer>
            <PaymentNavigation/>
            {checkoutId !== null ?
            <SubContainer>
                <PaymentSection>
                    <PaymentForm brands={state?.brands} checkoutId={checkoutId}/>
                </PaymentSection>
                <BillingSection>
                    <Heading>{PaymentLanguage[lang].BillingHeading}</Heading>
                    <Details>
                        <img src={Logo} alt="logo"/>
                        <TextContainer>
                            <span style={{fontSize: "14px", color: "#727272"}}>{PaymentLanguage[lang].PackageName}</span>
                            <span style={{color: "#252525"}}>{billingAmount + PaymentLanguage[lang].PackagePrice}</span>
                        </TextContainer>
                    </Details>
                    <SaperatingLine></SaperatingLine>
                    <OrderItems>
                        <BillAmount>
                            <span style={{color: "#727272"}}>{PaymentLanguage[lang].BillText}</span>
                            <Price>{billingAmount + PaymentLanguage[lang].BillAmount}</Price>
                        </BillAmount>
                        <Discount>
                            <span style={{color: "#727272"}}>{PaymentLanguage[lang].DiscountText}</span>
                            <Price>{discount + PaymentLanguage[lang].DiscountAmount}</Price>
                        </Discount>
                        <TotalAmount>
                            <span style={{color: "#252525", fontWeight: "bold"}}>{PaymentLanguage[lang].TotalText}</span>
                            <Price>{(billingAmount + discount) + PaymentLanguage[lang].TotalAmount}</Price>
                        </TotalAmount>
                        <div style={{textAlign: "start", width: "100%"}}>
                            *السعر يشمل ضريبة القيمة المضافة
                        </div>
                    </OrderItems>
                </BillingSection>
            </SubContainer>
            :
            <LoadingArea>
                <Loader/>
            </LoadingArea>
            }
            
        </MainContainer>
    )
}

export default PaymentScreen