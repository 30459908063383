import styled from "styled-components";
import bgimg from "../../Assets/Images/bg_filter.png"
import { media } from "../../Assets/Styles/utils";
import AlmaraiFont from "../../Assets/fonts/Almarai-Regular.ttf";


export const Filterdatacontainer=styled.div`
 background-image: url(${bgimg});
 background-repeat: no-repeat;
 background-color:rgba(202, 86, 161, 0.05);
 width: 100%;
 background-size: cover;
 min-height: 90vh;
 
`

export const Filterdatawrapper=styled.div`
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    gap:0;
    padding-top: 7.5%;
    
`

export const Header=styled.h1`
  margin: 0;
  width:70%;
  text-align: center;
  font-size: ${(props)=>props.lang==='ar'?"2.6rem":"1.6rem"};
  font-weight:700;
  color:#ca56a1;
  font-style:${AlmaraiFont};
  ${media("medium")}{
   font-size:${(props)=>props.lang==='ar'?"1.6rem":"1rem"};
   width:100%;
  }
`

export const Description=styled.p`
font-size: 1.2rem;
font-weight:400;
font-style:${AlmaraiFont};
display: flex;
align-items: center;
text-align: center;
justify-content: center;
padding: 0 20%;
color:#727272;
${media("medium")}{
   font-size:1rem;
   padding :0;
}
`

export const Selectionfeature=styled.div`
display: flex;
flex-direction:row;
gap: 20px;
margin-top:3%;
align-items: center;
justify-content: center;
background-color:white;
padding:20px;
width:60%;
${media("medium")}{
    flex-direction:column;
    width:80%;  
}
`;

export const Dropdown=styled.div`
 height:40px
 gap:0;
 cursor:pointer;
 p{
    padding:0;
    margin:0;
    width:100%;
    font-size:0.8rem;
    color:#727272;
 }
 select{
    border:none;
    width:220px;
    cursor:pointer;
    background-color:white !important;
    option{
      cursor:pointer;
         color:black;
         background-color:white;
      }
 }
   select:focus{
      outline:none;
   }
 ${media("medium")}{
      width:100%;
      margin-bottom:20px;
      display:flex;
      flex-direction:column;
      justify-content:center;
      align-items:center;
      select{
         width:100%;
      }
   }
`;

export const DropdownButton=styled.button`
   font-family: "Almarai", sans-serif;
   background-color: #ca56a1;
   color:white;
   width: 250px;
   height: 50px;
   border: none;
   cursor:pointer;
   border-radius: 5px;
   ${media("medium")}{
      width:100%;
   }
`;