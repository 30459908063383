import styled from "styled-components";
import { device } from "../../Breakpoints";

export const MainContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    box-sizing: border-box;
    flex-direction: column;
    border-top: 1px solid rgba(202, 86, 161, 0.1);
    @media ${device.sm}{
        padding: 0 1rem 0 1rem;
    }
`

export const SubContainer = styled.div`
    width: 70.5rem;
    height: auto;
    display: flex;
    align-items: start;
    justify-content: space-between;
    box-sizing: border-box;
    margin: 24px 0 56px 0;
    @media ${device.lg} {
        width: auto;
        gap: 1rem;
    }
    @media ${device.md} {
        flex-direction: column-reverse;
        align-items: center;
        gap: 1rem;
    }
    @media ${device.lg} {
        width: auto;
        gap: 1rem;
    }
`

export const LoadingArea = styled.div`
    width: 70.5rem;
    height: 476px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media ${device.md}{
        width: 100%;
    }
`


export const BillingSection = styled.div`
    width: 360px;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 16px;
    box-sizing: border-box;
    gap: 20px;
    border: 1px solid #EEEEEE;
    border-radius: 8px;
    @media ${device.lg}{
        width: 300px;
    }
    @media ${device.md}{
        width: 100%;
    }
`

export const PaymentSection = styled.div`
    width: 746px;
    height: 396px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media ${device.lg}{
        width: 580px;
        height: auto;
    }

    @media ${device.sm}{
        width: auto;
        height: auto;
    }
`

export const Heading = styled.div`
    font-weight: bold;
`

export const Details = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 12px;
`

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    width: auto;
    height: 100%;
    font-weight: bold;
    gap: 10px;
`

export const SaperatingLine = styled.div`
    border: 0.5px solid #EEEEEE;
`
export const OrderItems = styled.div`
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    gap: 10px;
`

export const BillAmount = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 26px;
`
export const Discount = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 26px;
`
export const TotalAmount = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #F8F8F8;
    border-radius: 6px;
    height: 50px;
    padding: 12px 6px 12px 6px;
    box-sizing: border-box;
`

export const Price = styled.div`
    color: "#666666";
    width: auto;
    height: 100%;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
`