import apiClient from "../../Config/index.config.js";

export const FlashCard = {
    GetCards: ({page,document_id}) => apiClient.get(`/flash-card/${document_id}`,{params:{page:page}}),
    GetByLimit: ({limit,document_id}) => apiClient.get(`/flash-card/${document_id}`,{params:{limit:limit}}),
    GetCardsParameter: ({ document_id, status ,page}) => {
        const statusString = JSON.stringify(status).replace(/"/g, "'");
        return apiClient.get(`/flash-card/${document_id}`, { params: { filter: statusString,page:page } });
    },
    GetSearchValue: ({ document_id, search,status }) => {
        const statusString = JSON.stringify(status).replace(/"/g, "'");
        return apiClient.get(`/flash-card/${document_id}`, { params: { search: search ,filter : statusString} });
    },
    GetSearchValuePara: ({ document_id, search }) => {
        return apiClient.get(`/flash-card/${document_id}`, { params: { search: search} });
    },
    UpdateCard: ({ document_id, id, title, description }) => apiClient.put(`/flash-card/${document_id}/${id}`,{ title, description }),
    AddCard: ({ document_id, title, description }) => apiClient.post(`/flash-card/${document_id}`,{ title, description }),
    PostRating: ({ document_id,type,rating  }) => apiClient.post(`/user/rate/${document_id}`,{ type, rating }),
    DeleteFlashCard: ({ document_id, id }) => apiClient.delete(`/flash-card/${document_id}/${id}`),
    UserResponse: ({document_id, id,response }) => apiClient.post(`/flash-card/${document_id}/${id}`,{response}),
    DataQuiz: (document_id) => apiClient.get(`/quiz/${document_id}`)
};