import { useNavigate } from "react-router-dom";
import BackArrowIcon from "../Assets/Images/BackArrowIcon.png"

const PDFView = () => {

    const navigate = useNavigate();

    return (
        <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", gap:"2rem", height:"100vh", color:"#CA56A1", fontWeight:"700", fontSize: "3rem"}}>
            UNDER CONSTRUCTION
            <img style={{width: "50px", cursor:"pointer"}} src={BackArrowIcon} alt="back arrow icon" onClick={() => navigate(-1)}/>
        </div>
    )
}

export default PDFView;