import styled from "styled-components";
import "react-toastify/dist/ReactToastify.css";

export const ToaswtWarpper = styled.div`
  // width: 580px;
  height: fit-content;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  $media (max-width: 480px) {
    width: 350px;
  }
`;

export const ToastIcon = styled.div`
  width: 60px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 15px;
  margin: auto 1px;
  padding: 10px 0;
  background-color: #ca56a1;
  :img {
    width: 20px;
    height: 20px;
  }
`;
export const ToastTitle = styled.h1`
  font-family: RB;
  font-size: 1.4rem;
  font-weight: bold;
  //   line-height: 1.5;
  text-align: right;
  color: #7a0651;
`;
export const ToastMessage = styled.p`
  margin-top: 4px;
  font-size: 1rem;
  text-align: right;
  color: #ca56a1;
  word-wrap: break-word;
`;
