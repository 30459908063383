import React, { createContext, useContext, useState, useEffect } from "react";

const LayoutDirectionContext = createContext();

export const useLayoutDirection = () => {
  return useContext(LayoutDirectionContext);
};

export const LayoutDirectionProvider = ({ children }) => {
  const [isRTL, setIsRTL] = useState(() => {
    const storedIsRTL = localStorage.getItem("isRTL");
    return storedIsRTL === "true";
  });

  const toggleLayoutDirection = (lang) => {
    setIsRTL(lang === "ar");
  };

  // useEffect(() => {
  //   localStorage.setItem("isRTL", isRTL.toString());
  // }, [isRTL]);

  useEffect(() => {
    const rootHtml = document.getElementById("root-html");
    if (rootHtml) {
      rootHtml.setAttribute("dir", "rtl");
    }
  }, [isRTL]);
  
  return (
    <LayoutDirectionContext.Provider value={{ isRTL, toggleLayoutDirection }}>
      {children}
    </LayoutDirectionContext.Provider>
  );
};


