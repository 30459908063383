import React from "react";
import store from "../../Store";
import BouncingDotsLoaderComponent from "./BouncingDotsLoader";
import ReactMarkdown from "react-markdown";
import ApiClient from "../../Config/index.config";
import { ChatBotExhaustMessage } from "../Modals/ExhaustModals/ExhaustModals";

class ChatGPTStep extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      response: null,
      errorMessage: null,
      storedValue: null,
      name: null,
      animatedResponse: "", // Track animated response
    };
  }

  componentDidMount() {
    const { steps } = this.props;
    const { name, storedValue } = steps;

    this.setState({ name, storedValue });
    const userInput = storedValue.value; // Extracting userInput from props
    // console.log("User Input:", userInput); // Logging userInput
    const token = store.getState().auth.token;

    ApiClient
      .post(
        // `https://4527-2405-201-402a-6137-97bc-9ff8-ba0a-46a3.ngrok-free.app/chatbot/get-response`,
        "/chatbot/get-response",
        {
          request: userInput, // Using userInput from props
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        const responseData = response.data;
        if (
          responseData &&
          responseData.data &&
          responseData.data.response
        ) {
          this.setState({
            loading: false,
            response: responseData.data.response,
          });
          this.animateResponse(responseData.data.response); // Start animation
        } else {
          throw new Error("Invalid response format");
        }
      })
      .catch((error) => {
        if(error?.response?.data?.error_code === 1044){ // ChatBot Limit Exceeded
          this.setState({
            loading: false,
            errorMessage: error?.response?.data?.error_code,
          })
        }
        else{
          console.error("Error fetching data:", error);
          this.setState({
            loading: false,
            errorMessage: "An error occurred while fetching data.",
          });
        }
      });
  }

  // Function to animate response word by word
  animateResponse = (response) => {
    let index = 0;
    const intervalId = setInterval(() => {
      if (index <= response.length) {
        const animatedResponse = response.slice(0, index);
        this.setState({ animatedResponse });
        index++;
      } else {
        clearInterval(intervalId);
      }
    }, 35); // Adjust speed here (milliseconds per word)
  };

  render() {
    const { loading, errorMessage, animatedResponse } = this.state;
    const bubbleStyle = {
      maxWidth: "500px", // Set maximum width to ensure responses fit within the width
      overflowX: "auto", // Enable horizontal scrolling if content overflows
      whiteSpace: "pre-wrap", // Allow content to wrap instead of overflowing
      wordWrap: "break-word",
    };
    const responseContainerStyle = {
      display: "inline-block", // Ensure inline block for proper width calculation
      maxWidth: "100%", // Ensure the container takes the full width
    };

    if (loading) {
      return <div><BouncingDotsLoaderComponent/></div>;
    }

    if(errorMessage === 1044){
      return (
        <ChatBotExhaustMessage/>
      )
    }
    else if (errorMessage) {
      return <div style={bubbleStyle}>{errorMessage}</div>;
    }

    return (
      <div style={bubbleStyle}>
        <div style={responseContainerStyle}>
          <ReactMarkdown>{animatedResponse}</ReactMarkdown>
        </div>
      </div>
    );
  }
}

export const stepsArabic = [
  {
    id: "1",
    message: "يا أهلاً، أكتب لي كيف أساعدك؟ ",
    trigger: "storedValue",
  },
  {
    id: "storedValue",
    user: true,
    validator: (value) => {
      if (!value || value.trim() === "") {
        return "";
      }
      return true;
    },
    trigger: "5",
  },
  {
    id: "5",
    component: <ChatGPTStep />,
    asMessage: true,
    trigger: "storedValue",
  },
];

export const stepsEnglish = [
  {
    id: "1",
    message: "Hi there! How can I help ?",
    trigger: "storedValue",
  },
  {
    id: "storedValue",
    user: true,
    validator: (value) => {
      if (!value || value.trim() === "") {
        return "";
      }
      return true;
    },
    trigger: "5",
  },
  {
    id: "5",
    component: <ChatGPTStep />,
    asMessage: true,
    trigger: "storedValue",
  },
];

export default ChatGPTStep;


