// MyChatbot.js
import React, { useEffect, useState } from "react";
import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";
import { stepsArabic, stepsEnglish } from "./ChatbotSteps";
import botImage from "../../Assets/Images/IconBot.png";
import ChatBotIcon from "../../Assets/Images/ChatBotIcon.svg"
import { useSelector } from "react-redux";
import SubscriptionModal from "../Modals/SubscriptionModal/SubscriptionModal";


function MyChatbot({insideQuiz, fixed, setSubscriptionVisibility, subscriptionVisibility}) {
  const theme = {
    background: "#ffffff",
    headerBgColor: "#ffffff",
    headerFontSize: "20px",
    botBubbleColor: "#FAFAFA",
    headerFontColor: "#252525",
    botFontColor: "#727272",
    userBubbleColor: "#4D2C77",
    userFontColor: "white",
    userFontSize: "16px",
    botFontSize: "16px",
  };
  
  const lang = useSelector((state) => state?.lang?.language);
  const [bottom, setBottom] = useState("3rem");
  const [chatBottom, setChatBottom] = useState("4rem")
  const [chatBotAlignArabic, setChaBotAlignArabic] = useState(`calc(100% - 6rem)`);

  const configAr = {
    floating: true,
    hideUserAvatar: true,
    height: "570px",
    width: "354px",
    botAvatar: botImage,
    floatingIcon: ChatBotIcon,
    enableSmoothScroll: true,
    floatingStyle: {backgroundColor: "rgba(77, 44, 119, 0.1)", border: "1px solid #E4DAF1", position: "fixed", right: chatBotAlignArabic, bottom: bottom},
    style: {position: "fixed", bottom: chatBottom, right:'calc(100% - 380px)'},
    inputStyle: {border: "2px solid #EEEEEE", color: "#989898", width: "95%"},
    placeholder: "أضِف سؤالًا...",
    headerTitle: "المساعد الذكي",
  };
  
  const configEn = {
    floating: true,
    hideUserAvatar: true,
    height: "570px",
    width: "345px",
    botAvatar: botImage,
    floatingIcon: ChatBotIcon,
    enableSmoothScroll: true,
    floatingStyle: {backgroundColor: "rgba(77, 44, 119, 0.1)", border: "1px solid #E4DAF1", right: "2rem", position: "fixed", bottom: bottom},
    style: {position: "fixed", bottom: chatBottom},
    inputStyle: {border: "2px solid #EEEEEE", color: "#989898"},
    placeholder: "Add a question...",
    headerTitle: "Smart Assistant",
  };
  
  const [config, setConfig] = useState(configEn);
  const [steps, setSteps] = useState(stepsArabic)

  useEffect(() => {
    if(lang === 'en'){
      setSteps(stepsEnglish)
    }
    else{
      setSteps(stepsArabic);
    }


    if(lang === 'en'){
      setConfig(configEn);
    }
    else{
      setConfig(configAr);
    }

    if(insideQuiz && fixed){
      setBottom("10rem");
      setChatBottom("0rem");
      setChaBotAlignArabic(`calc(100% - 5rem)`);
    }
    else{
      if(insideQuiz){
        setBottom("6.5rem")
        setChatBottom("8.5rem")
      }
      else if (fixed && !insideQuiz){
        setBottom("9rem")
        setChatBottom("0rem")
        setChaBotAlignArabic(`calc(100% - 5rem)`);
      }
      else{
        setBottom("3rem");
        setChatBottom("4rem")
      }
    }

  },[lang, bottom])


  return (
    <ThemeProvider theme={theme}>
      <ChatBot
        headerTitle="Smart assistant"
        steps={steps}
        {...config}
        bubbleStyle={{ textAlign: lang==="ar"?'rtl':'ltr' }}
        submitButtonStyle={{
          borderRadius: "50%",
          backgroundColor: "#FAFAFA",
        }}
      />
      {subscriptionVisibility ? <SubscriptionModal subscriptionVisibility={subscriptionVisibility} setSubscriptionVisibility={setSubscriptionVisibility}/> : null}
    </ThemeProvider>
  );
}

export default MyChatbot;
