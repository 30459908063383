import React, { useEffect, useState } from 'react'
import { AnimationContainer, AnimationSubContainer } from './UploadFile.styles'

const CricularProgressBar = ({setDissableButton, file, fileSize, isUploading, s3_link}) => {
    const [degree, setDegree] = useState(0);

    useEffect(() => {
        if(isUploading){
            if(degree === 360){
                setDissableButton(false)
            }
            if(degree === 0 && file !== null){
                setTimeout(() => {setDegree(degree+10)}, 300);
            }
            else if(degree < 270 && file !== null){
                setTimeout(() => {setDegree(degree+10)}, 300);
            }
        }
        else if(!isUploading && s3_link !== null){
            if(degree === 360){
                setDissableButton(false);
            }
            else if(degree < 360 && file !== null){
                setDegree(360);
            }
        }
    }, [file, degree, fileSize, isUploading])

    useEffect(() => {
        setDegree(0);
    },[fileSize])


    return (
        <AnimationContainer degree={degree+"deg"}>
            <AnimationSubContainer></AnimationSubContainer>
        </AnimationContainer>
    )
}

export default CricularProgressBar;