import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    academic_level: "",
    college: "",
    email: "",
    missing_data: [],
    name: "",
    phone_number: "",
    specialization: "",
    clickId: null,
}

const UserDetails = createSlice({
    name:'userData',
    initialState,

    reducers:{
        setUserDetails:(state, action) => {
            state.academic_level = action.payload.education_name;
            state.college = action.payload.university_name;
            state.email = action.payload.email;
            state.missing_data = action.payload.missing_data;
            state.name = action.payload.name;
            state.phone_number = action.payload.phone_number;
            state.specialization = action.payload.college_name;
        },
        setClickId: (state, action) => {
            state.clickId = action.payload
        }
    }
})

export const {setUserDetails, setClickId} = UserDetails.actions;
export default UserDetails.reducer;