import styled from "styled-components";
import { Link } from "react-router-dom";
import { device, size } from "../../Breakpoints";

export const HeaderContainer = styled.div`
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  position: sticky;
  top: 0;
  z-index: 10;
  box-sizing: border-box;
  background-color: white;
  @media ${device.lg} {
    padding: 1.5rem 1rem 1.5rem 1rem;
  }
  @media (max-width: 310px){
    padding: 1.5rem 8px 1.5rem 8px;
  }
`;

export const HeaderSubContanier = styled.div`
  width: 87rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const UserContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: auto;
  gap: 2rem;
  @media ${device.sm} {
    gap: 1rem;
  }
  @media ${device.xs} {
    gap: 8px;
  }
  @media (max-width: 310px){
    gap: 4px;
  }
`;

export const UserImage = styled.img`
  width: 2.5rem;
  @media (max-width: 310px){
    width: 2rem;
  }
`;

export const UserDataContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
  gap: 0.8rem;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  @media (max-width: 310px){
    gap: 4px;
  }
`;

export const UserDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: auto;
  max-width: 10rem;
  box-sizing: border-box;
  @media ${device.md} {
    display: none;
  }
`;

export const UserName = styled.div`
  color: #252525;
`;

export const UserProfession = styled.div`
  font-size: 12px;
  color: #727272;
`;

export const DropdownContainerProfile = styled.div`
  top: 3.6rem;
  right: ${(props) => (props.lang === "en" ? "0px" : null)};
  left: ${(props) => (props.lang === "ar" ? "0px" : null)};
  height: 4.75rem;
  width: 13rem;
  list-style: none;
  position: absolute;
  /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  border: 1.5px solid #f4f4f4;
  border-radius: 5px;
  overflow: hidden;
  background-color: white;
  color: #4c4c4c;
  font-size: small;
  @media ${device.xs}{
    width: 6.8rem;
  }
`;

export const LanguageButton = styled.div`
  height: 2.6rem;
  background-color: transparent;
  color: #727272;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  gap: 8px;
  position: relative;
`;

export const LanguageImage = styled.img`
  @media ${device.sm} {
    display: none;
  }
`;

export const LanguageImageMobile = styled.img`
  @media (min-width: ${size.sm}) {
    display: none;
  }
  @media (max-width: 310px){
    width: 24px;
  }
`;

export const LanguageText = styled.span`
  @media ${device.sm} {
    display: none;
  }
`;

export const LanguageChangeArrow = styled.svg`
  @media ${device.sm}{
    display: none;
  }
`

export const DropdownLanguage = styled.div`
  top: 2.7rem;
  right: ${(props) => (props.lang === "en" ? "6px" : null)};
  left: ${(props) => (props.lang === "ar" ? "6px" : null)};
  height: 3rem;
  width: 9.5rem;
  list-style: none;
  position: absolute;
  /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1.5px solid #f4f4f4;
  border-radius: 5px;
  overflow: hidden;
  background-color: white;
  color: #4c4c4c;
  font-size: small;
  @media ${device.xs}{
    width: 5.8rem;
  }
`;

export const Button = styled.div`
  background-color: #4d2c77;
  min-width: 7.25rem;
  height: 2.6rem;
  border-radius: 5px;
  color: white;
  display: flex;
  padding: 0 8px 0 8px;
  justify-content: center;
  align-items: center;
  gap: 9px;
  cursor: pointer;
  position: relative;
  font-size: 14px;
  font-weight: bold;
  box-sizing: border-box;
  @media ${device.md} {
    min-width: 0;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
  }
  @media (max-width: 310px){
    width: 24px;
    height: 24px;
  }
`;

export const NewButtonText = styled.span`
  @media ${device.md}{
    display: none;
  }
`

export const NewButtonArrow = styled.img`
   @media ${device.md}{
    display: none;
  }
`

export const DropdownContainer = styled.div`
  position: absolute;
  top: 3.2rem;
  right: ${(props) => (props.lang === "en" ? "0px" : null)};
  left: ${(props) => (props.lang === "ar" ? "0px" : null)};
  height: 4.75rem;
  width: 9.5rem;
  list-style: none;
  position: absolute;
  /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  border: 1.5px solid #f4f4f4;
  border-radius: 5px;
  overflow: hidden;
  background-color: white;
  color: #4c4c4c;
  font-size: small;
  @media ${device.xs}{
    width: 6.8rem;
  }
`;

export const PageContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: auto;
  gap: 1rem;
  @media ${device.xs}{
    gap: 8px;
  }
  @media (max-width: 310px){
    gap: 4px;
  }
`;

export const Logo = styled.img`
  cursor: pointer;
  @media ${device.sm}{
    width: 3rem;
  }
  @media (max-width: 310px){
    width: 2.7rem;
  }
`

export const PublicLibrary = styled.span`
  color: ${(props) => (props.isselected ? "#4D2C77" : "#BFBFBF")};
  cursor: pointer;
  font-weight: bold;
  /* @media ${device.sm} {
    display: none;
  } */
`;

export const PublicLibrarySmall = styled.span`
  color: ${(props) => (props.isselected ? "#4D2C77" : "#BFBFBF")};
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  @media (min-width: ${size.sm}) {
    display: none;
  }
`;

export const MyLibrary = styled.span`
  color: ${(props) => (props.isselected ? "#4D2C77" : "#BFBFBF")};
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  font-weight: bold;
  cursor: pointer;
  /* @media ${device.sm} {
    display: none;
  } */
`;

export const MyLibrarySmall = styled.span`
  color: ${(props) => (props.isselected ? "#4D2C77" : "#BFBFBF")};
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;
  @media (min-width: ${size.sm}) {
    display: none;
  }
`;

export const StyledImage = styled.img`
  cursor: pointer;
`;

export const SignoutSVG = styled.img`
  transform: ${(props) => (props.lang === "en" ? "rotateY(180deg)" : null)};
  margin-left: ${(props) => (props.lang === "en" ? "-3px" : null)};
  margin-right: ${(props) => (props.lang === "ar" ? "-3px" : null)};
`;

export const StyledList = styled.span`
  width: 100%;
  height: 2rem;
  z-index: 1;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 4px;
  padding: 0 8px 0 8px;
  cursor: pointer;
  font-size: 12px;
  font-weight: normal;
  color: #4c4c4c;
  border-top: ${(props) =>
    props.bordercolor ? "1px solid" + props.bordercolor : null};
  color: ${(props) => (props.color ? props.color : null)};
  &:hover {
    background-color: #f9f4f8;
  }
  & img {
    margin-top: 2px;
  }
`;

export const CreateFolderSVG = styled.img`
  transform: ${(props) => (props.lang === "en" ? "rotateY(180deg)" : null)};
`;

export const AuthenticationLinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: auto;
  /* width: 15.3rem; */
  box-sizing: border-box;
  font-weight: bold;
  gap: 1rem;
`;

export const RegisterLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  width: 11rem;
  height: 3.3rem;
  border-radius: 4px;
  background-color: #332064;
  color: #fff;
  transition: background-color 0.3s;
  box-sizing: border-box;
  font-weight: bold;
  /* margin-right: -2rem; */
  &:hover {
    background-color: #ca57a0;
  }
  @media (max-width: 700px) {
    display: ${(props) => (props.hideOnMobile ? "none" : "")};
  }
`;

export const LoginLink = styled(Link)`
  display: flex;
  width: 9.5rem;
  height: 3.3rem;
  font-weight: bold;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: 1px solid #4d2c77;
  border-radius: 4px;
  cursor: pointer;
  color: #231f20;
  transition: color 0.3s;

  &:hover {
    color: #231f20;
  }
  /* @media (max-width: 700px) {
        display: none;
    } */
`;
