import { NotificationPopUp } from "./NotificationModal.style";
import NotificationIcon from "../../../Assets/Images/NotificationIcon.svg"
import { useSelector } from "react-redux";

const NotificationModal = ({data, closeNotification, color}) => {
    const lang = useSelector((store) => store.lang.language)
    setTimeout(() => {
        closeNotification(false);
    }, 3000);

    return (
        <NotificationPopUp color={color} lang={lang}>
            <img style={{display: color? "none": null}} src={NotificationIcon} alt="check icon"/>
            {data}
        </NotificationPopUp>
    )
}

export default NotificationModal;