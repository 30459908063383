import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import PaymentScreen from "../Components/Payment/PaymentScreen";

const Payment = () => {
    const isAuthenticated = useSelector((store) => store.auth.isAuthenticated)
    if(!isAuthenticated){
        return <Navigate to="/user/login" replace />;
    }
    return(
        <PaymentScreen></PaymentScreen>
    )
}

export default Payment;