import apiClient from "../../Config/index.config.js";

export const Auth = {
  login: (data) => apiClient.post("/auth/login", data),
  register: (data,ref) => 
  { 
    
    return apiClient.post(`/auth/register?ref=${ref}`, data)
  },
  getProfile: () => apiClient.get("/user/profile"),
  getEducationData: () => apiClient.get("/user/education-api"),
  getUniversity:(data)=>apiClient.get(`/user/university-api?education_id=${data}`),
  getCollege:(data2,data)=>{
   return apiClient.get(`/user/college-api?education_id=${data}&university_id=${data2}`)},
  Profile: (data) => {
    const dataTypesParam = encodeURIComponent(JSON.stringify(data));
    return apiClient.get(`/user/education-data?data_types=${dataTypesParam}`);
  },
  updateProfile: (data) => apiClient.put("/user/profile", data),
  updatePassword:(data)=>apiClient.post("/user/change-password",data),
  getpremium:()=>apiClient.get("/user/premium-details"),
};
export const home = {
     getdata:()=>apiClient.get("/library/most-viewed-docs"),
     getrecent:()=>apiClient.get("/library/most-recent-docs"),
     getpublic:()=>apiClient.get("/library/get-public-library"),
     getsearch: (props) => {
      console.log(props);
      return apiClient.get(`/library/get-public-library?page=1&limit=10&academic_level_id=${props.academic_level}&college_id=${props.college}&university_id=${props.specialization}`);
    }
}
