import sliderImage1 from "../../Assets/Images/skill-based.png"
import sliderImage2 from "../../Assets/Images/skill-based (1).png"
import sliderImage3 from "../../Assets/Images/skill-based (2).png"

export const slideLanguage = {
  en: [
    {
      image: sliderImage1,
      title: "Click We summarize all the information for you in the form of a question and answer",
    },
    {
      image: sliderImage2,
      title:"Swipe and you can rate your understanding of each piece of information",
    },
    {
      image: sliderImage3,
      title:"Drag and if you master it, we will repeat it for you until you can do it",
    },
  ],
  ar: [
    {
      image: sliderImage1,
      title: "بنختبرك في كل معلومة على شكل سؤال وجواب"      ,
    },
    {
      image: sliderImage2,
      title: "اختبر فهمك في كل معلومة",
    },
    {
      image: sliderImage3,
      title: "اسحب البطاقة وإذا ما اتقنتها بنعيدها لك الى أن تتمكن منها",
    },
  ]
}