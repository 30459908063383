import styled from "styled-components";
import imgbg from "../../Assets/Images/UploadPDFBackground.svg";
import { FaStar } from "react-icons/fa";

export const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
  width: 100%;
  height: 80vh;
  background-image: url(${imgbg});
  background-size: cover;
  background-opacity: 0.1;
  background-color: #f9f4f8;
  @media (max-width: 1440px) {
    height: 100%;
  }
`;

export const ResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 35%;
  height: 70%;
  background: white;
  border-radius: 10px;
  padding: 2rem 10px;
  padding-top: 20vh;
  @media (max-width: 1440px) {
    width: 60%;
    padding-top: 15vh;
  }
  @media (max-width: 768px) {
    width: 80%;
    height: 100%;
    padding-top: 20vw;
    margin-bottom: 20px;
  }
`;

export const ResultTitle = styled.h1`
  font-family: "Almarai";
  font-style: normal;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 38px;
  margin: 0;
  text-align: center;
`;

export const ResultSubTitle = styled.h2`
  font-size: 0.8rem;
  font-family: "Almarai";
  font-style: normal;
  font-weight: 200;
  font-style: normal;
  margin: 0;
  text-align: center;
  color: #727272;
`;

export const Result_ = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Resulttab = styled.div`
  margin: 10px;
  width: 100%;
  border: 2px solid #ca5688;
  border-top-color: #ca5688;
  border-image: linear-gradient(to bottom, #ca5688, #ffffff) 1;
  background: linear-gradient(
    180deg,
    rgba(202, 86, 161, 0.06) 0%,
    rgba(202, 86, 161, 0) 46%
  );
  border-image-slice: 1;
  border-bottom-width: 0;
  height: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 1.5rem;
    font-weight: 700;
    font-style: normal;
    margin: 0;
    display: flex;
    align-items: center;
    font-family: "Almarai";
  }
  h2 {
    font-size: 1rem;
    font-weight: 200;
    font-style: normal;
    margin: 0;
  }
  @media (max-width: 1440px) {
    h1 {
      font-size: 1.3rem;
    }
    h2 {
      font-size: 0.9rem;
    }
  }
  @media (max-width: 768px) {
    h1 {
      font-size: 1.2rem;
    }
    h2 {
      font-size: 0.8rem;
    }
  }
`;
export const Span = styled.div`
  height: 10px;
  width: 10px;
  justify-content: center;
  margin: 0 5px;
  border-radius: 50%;
`;
export const Resultparagraph = styled.p`
  font-size: 1rem;
  font-family: "Almarai";
  font-style: normal;
  font-weight: 200;
  margin: 0;
  margin-top: 20px;
  text-align: center;
  padding: 0 2rem;
  color: #727272;
`;
export const ResultButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  height: 3rem;
  margin-top: 20px;
  background: #4d2c77;
  border-radius: 5px;
  border: none;
  outline: none;
  color: white;
  font-size: 1rem;
  font-family: "Almarai";
  font-style: normal;
  font-weight: 700;
  cursor: pointer;
  padding: 19px;
  gap: 8px;
  @media (max-width: 1440px) {
    margin-top: 10px;
  }
  @media (max-width: 768px) {
    margin-top: 5px;
  }
`;

export const ResultButton2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  border-radius: 5px;
  border: none;
  outline: none;
  color: #727272;
  font-size: 1rem;
  font-family: "Almarai";
  font-style: normal;
  font-weight: 700;
  cursor: pointer;
  padding: 19px;
  @media (max-width: 1440px) {
  }
  @media (max-width: 768px) {
  }
`;
export const StyledStar = styled(FaStar)`
  cursor: pointer;
  @media (max-width: 1440px) {
    size: 30px;
  }
  @media (max-width: 768px) {
    size: 20px;
  }
`;
