import { Navigate } from "react-router-dom";
import UploadPDF from "../Components/UploadFile/UploadFile";
import { useSelector } from "react-redux";

const UploadPDFPage = () => {
    const isAuthenticated = useSelector((store) => store.auth.isAuthenticated)
    if(!isAuthenticated){
        return <Navigate to="/user/login" replace />;
    }

    return (
        <UploadPDF/>
    )
}

export default UploadPDFPage;