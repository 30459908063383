import styled from "styled-components";
import UploadPDFBackground from "../../Assets/Images/UploadPDFBackground.svg"
import { device, size } from "../../Breakpoints";


export const UploadPDFContainer = styled.div`
    /* font-family: "Almarai", sans-serif; */
    width:100%;
    height: calc(100vh - 80px);
    background-image: url(${UploadPDFBackground});
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #fcf5f9;
    padding: 0 2rem 0 2rem;
    box-sizing: border-box;
    @media ${device.sm}{
        padding: 0 1rem 0 1rem;
    }
    @media (max-height: 40rem){
        height: auto;
        align-items: start;
        justify-content: center;
        padding: 2rem 0 2rem 0;
    }
`

export const UploadSubContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
`

export const UploadModalContainer = styled.div`
    position: fixed;
    background-color: rgba(77, 44, 119, 0.55);
    top: 0;
    left: 0;
    height: calc(100vh);
    right: 0;
    overflow: hidden;
    scroll-behavior: none;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const UploadModalSubContainer = styled.div`
    width: 60rem;
    height: 30rem;
    padding : 2rem 1rem 2rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    background-color: rgba(252, 245, 249, 1);
    border-radius: 8px;
    box-sizing: border-box;
    background-image: url(${UploadPDFBackground});
`

export const Cross = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    background-color: red;
`

export const UploadSection = styled.div`
    width: 34rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    @media ${device.sm}{
        width: 343px;
    }
     @media (max-width: 360px){
        width: 100%;
    }
`

export const UploadSectionPrimary = styled.div`
    width: 100%;
    height: auto;
    background-color: white;
    border-radius: 8px;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 1rem 1rem 1rem 1rem;
    @media ${device.sm}{
        height: auto;
    }
`

export const UploadSectionSecondary = styled.div`
    width: 100%;
    height: 21rem;
    background-color: #FAFAFA;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media ${device.sm}{
        height: 222px;
    }
`

export const UploadLabel = styled.label`
    width: 30rem;
    height: 19rem;
    background-color: #FAFAFA;
    border: 1px dashed #4D2C77;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    color: #989898;
    margin: 1rem 1rem 1rem 1rem;
    gap: 1rem;
    font-weight: bold;
    font-size: 14px;;
    cursor:pointer;
    overflow: hidden;
    text-align: center;
    display: ${props => props.visibility === 'hidden' ? 'none' : null};
    @media ${device.sm}{
        height: 198px;
    }
`

export const StyledButton = styled.div`
    background-color: #4d2c77;
    opacity:  ${(props) => props.dissablebutton === "true" ? "0.7" : "1"};
    width: 100%;
    color: white;
    height: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    gap:  12px;
    pointer-events: ${props => props.dissablebutton === "true" ? "none" : null};
    @media ${device.sm}{
        height: 56px;
    }
`

export const ArrowImage = styled.img`
    height: 0.8rem;
    transform: ${(props) => props.lang === 'en' ? 'rotate(180deg)' : null}
`

export const Input = styled.input`
    position: absolute;
    top:-1000px;
`

export const TextSection = styled.div`
    width: ${(props) => props.width ? props.width : null};
    color: #727272;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 20px;
    gap: 8px;
    @media ${device.sm}{
        font-size: 14px;
        width: 100%;
    }
`

export const Heading = styled.div`
    width: 100%;
    font-size: 2rem;
    color: #252525;
    font-weight: bold;
    /* line-height: 4rem; */
    text-align: center;
    margin-top: ${props => props.margintop ? props.margintop : null};
    @media ${device.sm}{
        font-size: 20px;
    }
`


export const ColoredText = styled.span`
    color:#CA56A1;
    @media ${device.sm}{
        font-size: 20px;
    }
`

// Uploading Section Styles

export const UploadAnimation = styled.div`
    justify-content: start;
    border-radius: 8px;
    align-items: center;
    position: relative;
    display: flex;
    height: 4.5rem;
    width: 100%;
    display: ${props => props.visibility === 'hidden' ? 'none' : null};
    border : 1px solid #EAECF0;
    box-sizing: border-box;
    overflow: hidden;
    @media ${device.sm}{
        height: 3.5rem;
    }
`

export const UploadAnimationSubSection = styled.div`
    box-shadow: 0 10px 40px -10px #fff;
    background: ${(props) => props.backgroundcolor ? props.backgroundcolor : null};
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: ${(props) => props.justify ? props.justify : null};
    align-items: center;
    padding: 0 1rem 0 1rem;
    gap: 16px;
`

export const FileInformation = styled.div`
    display: flex;
    flex-direction: column;
    color: #252525;
    gap: 4px;
    margin-left: -8px;
    justify-content: center;
    align-items: ${(props) => props.lang === 'ar' ? "start" : null};
`

export const AnimationContainer = styled.div`
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: conic-gradient(#CA56A1 ${props => props.degree ? props.degree : "45deg"}, #F2F4F7 0deg);
    display: flex;
    justify-content: center;
    align-items: center;
`

export const AnimationSubContainer = styled.div`
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color: white;
`

// File Saving Section Styles

export const FolderSelectionSection = styled.div`
    width: 40rem;
    height: ${(props) => props.filetransfer ? "36rem" : "36rem"};
    background-color: white;
    border-radius: 8px;
    border: 1.5px solid #F4F4F4;
    padding: 2rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    position: relative;
    @media ${device.md} {
        width: 35rem;
        padding: ${(props) => props.filetransfer === "true" ? null : "1rem 2rem 1rem 2rem"};
    }
    @media ${device.sm} {
        position: absolute;
        bottom:0;
        width: 100%;
        padding : 1rem 1rem 1rem 1rem;
        border-radius: 24px 24px 0 0;
        height: ${(props) => props.filetransfer === "true" ? "33rem" : null};
    }
`

export const FoldersSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height:13rem;
`

export const HeadingSection = styled.div`
    display: flex;
    width: 100%;
    justify-content: start;
    align-items: center;
    gap: 1.2rem;
    @media ${device.sm} {
        margin-top: 2rem;
    }
    @media (max-width: 230px){
        margin-top: 1rem;
    }
`
export const Text = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    width: 100%;
    gap: 8px;
`

export const MainHeading = styled.div`
    font-size: 24px;
    font-weight: bold;
    color: #252525;
    text-align: start;
    @media ${device.sm} {
        font-size: 18px;
    }
`

export const SubHeading = styled.div`
    color: #727272;
    line-height: 25px;
    @media ${device.sm}{
        display: none;
    }
`

export const DeleteSubHeading = styled.div`
    color: #727272;
    line-height: 25px;
    @media ${device.xs}{
        font-size: 14px;
        line-height: 18px;
    }
`

export const SubHeadingSmall = styled.div`
    font-size: 14px;
    color: #727272;
    line-height: 20px;
    @media (min-width: ${size.sm}){
        display: none;
    }
    @media (max-width: 250px){
        font-size: 12px;
    }
`

export const IconSection = styled.img`
    width: 1rem;
    cursor: pointer;
    transform: ${(props) => props.lang === 'ar' ? 'rotateY(180deg)' : null};
`

export const ScrollableFolderNamesSection = styled.div`
    width: 100%;
    height: ${(props) => props.insideattachfile ? "12.3rem" : "14.3rem"};
    overflow-y: scroll;
    margin-top: ${(props) => props.insideattachfile ? null : "1.3rem"};
    @media ${device.lg} {
        height: ${(props) => props.insideattachfile ? "12.3rem" : "12.3rem"};
    }
    @media ${device.sm} {
        height: ${(props) => props.insideattachfile ? "12.3rem" : "12.3rem"};
        margin-top: ${(props) => props.filetransfer === "true" ? 0 : null};
    }
`

export const EmptyFolder = styled.div`
    height: ${(props) => props.insideattachfile ? "12.3rem" : "15.6rem"};
    display: flex;
    align-items: center;
    justify-content: center;
    color: #CA56A1;
    font-weight: bold;
    font-size: 1.5rem;
    @media ${device.lg} {
        height: ${(props) => props.insideattachfile ? "12.3rem" : "13.6rem"};
    }
    @media ${device.sm} {
        height: 12.3rem;
    }
`

export const LoaderOuterContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${(props) => props.insideattachfile ? "16.5rem" : "19.8rem"};
    @media ${device.sm} {
        height: ${(props) => props.insideattachfile ? "16.3rem" : "19.8rem"};
    }
`   

export const FolderNameSection = styled.div`
    margin-bottom: 8px;
    height: 3.6rem;
    width: 100%;
    border: 1.5px solid ${(props) => props.bordercolor ? props.bordercolor : null};
    border-radius: 8px;
    display: flex;
    flex-direction: ${(props) => props.flexdirection === 'row' ? 'row' : 'column'};
    align-items: ${(props) => props.flexdirection === 'row' ? 'center' : 'start'};
    justify-content: ${(props) => props.flexdirection === 'row' ? 'start' : 'center'};
    padding: 0 18px 0 18px;
    gap: ${(props) => props.flexdirection === 'row' ? '8px' : '4px'};
    color: ${(props) => props.color ? props.color : null};
    font-weight: ${(props) => props.fontweight ? props.fontweight : null};
    cursor: pointer;
    box-sizing: border-box;
    font-weight: bold;
`

export const NewFolderContainer = styled.div`
    width: 50%;
    position: absolute;
    right: ${(props) => props.lang === 'en' ? 0 : null};
    left: ${(props) => props.lang === 'ar' ? 0 : null};
    display: flex;
    justify-content: end;
`

export const LoaderContainer = styled.div`
    width: 100%;
    height: 19.79rem;
    display: flex;
    justify-content: center;
    align-items: center;
    @media ${device.lg} {
        height: 17.78rem;
    }
    @media ${device.sm} {
        height: 16.3rem;
    }
`

export const CreateNewFolderSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 8px;
    color: #CA56A1;
    font-weight: 500;
    margin-bottom: ${(props) => props.insideattachfile ? null : "10px"};
    font-weight: bold;
    & > * {cursor: pointer;}
    @media ${device.sm} {
        /* margin-top: ${(props) => props.filetransfer === 'true' ? "-1rem" : null}; */
    }
`

export const NewFolderButton = styled.span`
    @media ${device.xs} {
       display: none;
    }
`

export const StyledSVG = styled.svg`
    margin-top: 3px;
`

export const UpperBorderLine = styled.div`
    width: 100%;
    position: absolute;
    border: 1px solid #EEEEEE;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: 5.5rem;
    @media (min-width: ${size.sm}) {
        display: none;
    }
`

export const ButtonsSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

export const FileNameInput = styled.input`
    font-family: "Almarai", sans-serif;
    width: 100%;
    display: flex;
    outline: none;
    font-size: medium;
    box-sizing: border-box;
    border: none;
`

export const FileName = styled.div`
    font-size: 12px;
    color: #727272;
    display: flex;
    flex-direction: ${(props) => props.lang === 'ar' ? 'row-reverse' : null};
`

export const FileAccessStatusSection = styled.div`
    display: flex;
    width: 100%;
    height: auto;
    color: #727272;
    gap: 1rem;
    display: flex;
    justify-content: start;
    align-items: center;
    color : #252525;
    font-size: 14px;
    position: relative;
    @media ${device.sm} {
        margin-top: 1rem;
    }
`

export const AccessButton = styled.div`
    padding: 4px 8px 4px 8px;
    border-radius: 50px;
    border: 1px solid ${(props) => props.bordercolor ? props.bordercolor : null};
    background-color: ${(props) => props.background ? props.background : null};
    cursor: pointer;
`

export const CrossContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;

    & > svg{
        cursor:pointer;
    }
`