import styled, { keyframes } from "styled-components";
import { device } from "../../Breakpoints";

export const Container = styled.div`
  height: 1200px;
  width: 100%;
  @media ${device.md}{
    height: auto;
  }
`;

export const TopBar = styled.div`
  height: 8%;
  width: 100%;
  background-color: black;
`;

export const MainContent = styled.div`
  /* height: 1095px; */
  margin-top: 1.2rem ;
  display: flex;
  justify-content: center;
  align-items: start;
  width: 100%;
  box-sizing: border-box;
  @media ${device.lg} {
    padding: 0 1rem 0 1rem;
  }
  @media ${device.md}{
    height: auto;
  }
`;

export const NavigationBar = styled.div`
height: 13%;
display: flex;
align-items:center;
margin-left:8.4%;

`;

export const DeletedPopUp = styled.div`
    width: 37%;
    top: 11%;
    position: absolute;
    height: 7%;
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
    left: 46%;
`
export const DeletedPopUplang = styled.div`
    width: 49%;
    top: 11%;
    position: absolute;
    height: 7%;
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
    left:20%;
    
`
export const Deleted = styled.div`
    position: fixed;
    top: 5rem;
    left: ${props => props.lang === "ar" ? "2rem" : null};
    right: ${props => props.lang === "en" ? "2rem" : null};
    width: 390px;
    height: 60px;
    display: flex;
    align-items: center;
    background-color: #E8FFDD;
    color: #039855;
    font-size: 18px;
    font-weight: bold;
    gap: 12px;
    padding: 8px 24px 8px 24px;
    z-index: 10;

    @media ${device.sm}{
        font-size: 14px;
        width: auto;
        height: 40px;
        left: ${props => props.lang === "ar" ? "1rem" : null};
        right: ${props => props.lang === "en" ? "1rem" : null};
        padding: 8px 12px 8px 12px;
        gap: 8px;
    }
`

export const InsideNavBar = styled.div`
    width:49%;
    height: full;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
`

export const NavigationItem = styled.h2`
  color: ${props => (props.active ? "#c64d9a" : "#727272")};
  font-size:17px;
  font-weight:400;
`;

export const LearningSection = styled.div`
  display: flex;
  height: 87%;
  width: 70.5rem;
  box-sizing: border-box;
  align-items: start;;
  justify-content: space-between;
  @media ${device.lg} {
    width: 100%;
    gap: 1.5rem;
  }
  @media ${device.md}{
    flex-direction: column-reverse;
    height: auto;
    align-items: center;
  }
`;


// ------------------LeftPanel----------------


// export const LeftPanel = styled.div`
//   width: 60%;
//   height: 100%;
//   display:flex;
//   justify-content:end;
//   background-color: green;
//   @media (max-width:466px){
//     width:100%;
//     justify-content:center;
//     margin-top:20px;
//     height:1251px;
//   }
// `;

export const LeftPanelContainer = styled.div`
padding: 1rem;
height: 1030px;
width: 40.5rem;
display: flex;
flex-direction: column;
align-items: center;
flex-shrink: 1;
gap: 24px;
border: 1px solid #F4F4F4;
border-radius: 8px;
box-sizing: border-box;

@media ${device.lg} {
  width: 100%;
}
@media ${device.md}{
  width: 28.5rem;
}
@media (max-width: 500px){
  width: 100%;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #989898;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #777;
}
`

export const TopRightBar = styled.div`
  height: 45px;
  width: 100%;
  display:flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 1px solid #F4F4F4;
`

export const AllCards = styled.div`
  height: 100%;
  width: 8.5rem;
  display:flex;
  justify-content:center;
  align-items:center;
  font-weight: bold;
  color: ${props => (props.active ? 'black' : '#989898')};
  border-bottom: ${props => (props.isSelected  ? '2px solid black' : '')};
  cursor:pointer;
  @media (max-width: 500px){
    flex-direction:column;
  }
  @media ${device.lg} {
    min-width: 6rem;
    width: auto;
  }
  @media (max-width: 930px){
    min-width: 5rem;
  }
  @media (max-width: 500px){
    min-width: 0;
    width: 100%;
    /* background-color: red; */
  }
`
export const ParameterName = styled.div`
  font-size: 16px;
  margin: 0px 0px;
  @media (max-width: 500px){
    text-align:center;
    font-size: 14px;
  }
`


export const ColorDiv = styled.div`
  height:16px;
  width:16px;
  background-color: ${props => props.color || '#fff'};; 
  border-radius:100%;
  box-sizing: border-box;
  ${props => props.lang === "en" ? "margin-right:3px;" : "margin-left:3px;"}
`

export const InputDiv = styled.div`
  height: 3.5rem;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border:1px solid #EEEEEE;
  border-radius: 4px;
`;

export const InputContainer = styled.input`
  font-family: "Almarai", sans-serif;
  width: 100%;
  color: #989898;
  height: 100%;
  outline: none;
  border-style: none;
  font-size: 1rem;
  box-sizing: border-box;
  margin-left: ${(props) => props.lang === 'en' ? "8px" : null};
  margin-right: ${(props) => props.lang === 'ar' ? "8px" : null};
`

export const AddFlashCard = styled.div`
  color: #252525;
  height: 26px;
  width: 100%;
  display:flex;
  font-size:16px;
  font-weight:bold;
  justify-content:space-between;
  align-items:center;
  box-sizing: border-box;
`

export const Add = styled.div`
  border:none;
  color:#4C4C4C;
  background:none;
  font-size: 12px;
  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;
`


export const SearchImgDiv = styled.div`
  height: 70%;
  display: flex;
  align-items: center;
`;

export const LoadingArea = styled.div`
  height:163px;
  width:100%;
  display:flex;
  justify-content:center;
  align-items:center;
`

export const GridContainer = styled.div`
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: row;
    flex-wrap: wrap;
    box-sizing: border-box;
    position: relative;
    grid-template-columns: repeat(2, 1fr); 
    gap: 16px;
    width: 616px;
    height: auto;
    @media ${device.lg} {
      width: 100%;
    }
    @media (max-width: 920px){
      gap: 12px;
    }
    &::-webkit-scrollbar {
        width: 0px;
    }
`

export const FlashCardOuterGrid = styled.div`
  position: relative;
  width: auto;
  height: auto;
`

export const GridItem = styled.div`
  position: relative;
  background-color: ${props => props.color || '#fff'};
  border: 1px solid ${props => props.border};
  height: 156.6px;
  overflow: hidden;
  z-index:1;
  width: 300px;
  padding: 12px 16px 12px 16px;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media ${device.lg} {
    width: 48%;
  }
  @media ${device.md}{
    gap: 14px;
    height: 174.6px;
  }
  @media (max-width: 375px){
      width: 100%;
  }
`

export const GridHead = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
`
  
  export const TextGrid = styled.div`
  width: 85%;
  height: auto;
  max-height: 3.2rem;
  font-size: 16px;
  font-weight: 500;
  line-height: 25.6px;
  color:#252525;
  display: -webkit-box; 
  -webkit-box-orient: vertical; 
  -webkit-line-clamp: 2; 
  overflow: hidden; 
  text-overflow: ellipsis;
`

export const GridPara = styled.div`
  font-size:14px;
  line-height: 20px;
  color:#727272;
  height:4rem;
  width:100%;
  display: -webkit-box; 
  -webkit-box-orient: vertical; 
  -webkit-line-clamp: 3; 
  overflow: hidden; 
  text-overflow: ellipsis;

  @media ${device.md}{
    -webkit-line-clamp: 4; 
    height: 6rem;
  }
`

export const GridDiv = styled.div`
display:flex;
justify-content:center;
width:97%;
margin-left:8px;  
height:85%;
margin-top:20px;
`

// -----------------Right Panel-----------------

export const RightPanel = styled.div`
width: 40%;
height: 100%;
@media (max-width:466px){
  width:100%;
  height:50%;
  display:flex;
  justify-content:center;
  align-items:center;
}
`;

export const RightLearningCard = styled.div`
  padding: 1rem 0 1rem 0;
  width: 28.5rem;
  /* height: 538px; */
  height: auto;
  border-radius: 8px;
  border:1px solid #EEEEEE;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 1rem;
  color: #252525;
  @media ${device.lg} {
    width: 70%;
  }
  @media ${device.md}{
     width: 28.5rem;
  }
  @media (max-width: 500px){
    width: 100%;
  }
`;

export const RightCardHeader = styled.div`
  padding: 0 16px 0 16px;
  display: flex;
  justify-content: space-between;
  height: 92px;
  width: 26.5rem;
  align-items: center;
  border-bottom: 1px solid #F4F4F4;
  box-sizing: border-box;
  @media ${device.lg}{
    width: 100%;
  }
  @media (max-width: 500px) {
    width: 100%;
    padding: 0 8px 0 8px;
  }
`;

export const RightCardBody = styled.div`
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: start;
  gap: 0.8rem;
  height: 60px;
`;

export const RightCardTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #252525;
  display: flex;
  align-items: center;
  gap : 8px;
  @media (max-width: 500px){
    font-size: 18px;
  }
`;

export const RightCardFooter = styled.div`
  font-size: 12px;
  color: #727272;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 1rem;
  width:100%;
  @media ${device.lg}{
    gap: 8px;
  }
  @media ${device.md}{
    gap: 1rem;
  }
  @media (max-width: 500px){
    gap: 8px;
  }
`;

export const SmallColorCircle = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${(props) => props.color ? props.color : null};
  border-radius: 50%;
`

export const IconsContainer = styled.div`
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  @media ${device.lg}{
    gap: 2px;
  }
  @media ${device.md}{
    gap: 8px;
  }
  @media (max-width: 500px){
    gap: 4px;
  }
`

export const Avatar = styled.div`
  width: 70px;
  height: 70px;
  border: 2px solid red;
  border-radius: 50%;
  margin-left: 5px;
  margin-top: 1px;
`;

export const StartLearningContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 0 1rem 0 1rem;
  width: 100%;
  gap: 1rem;
  box-sizing: border-box;
`

export const StartLearningHeader = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
`

export const StartLearning = styled.div`
  height: 54px;
  width:100%;
  display: flex;
  flex-direction: column;
  padding: 6px 0 6px 0;
  box-sizing: border-box;
  justify-content: space-between;
`;

export const DropDown = styled.div`
  position: absolute; 
  background-color: white;
  width: 132px;
  height: 44px;
  border-radius: 4px;
  left: ${props => props.lang=== 'ar'? 0 : null};
  right: ${props => props.lang=== 'en'? 0 : null};
  top: 2.2rem;
  border: 1px solid #f4f4f4;
  box-sizing: border-box;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.07);
  font-size: 12px;
  color: #4C4C4C;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  &:hover{
    background-color: #EEEEEE;
  }
`

export const LearningTextUpper = styled.div`
  font-size:16px;
  color: #252525;
  font-weight:bold;
  margin-bottom:3px;
`

export const FlashCardButton = styled.div`
  display:flex;
  justify-content:center;
  height:130px;
  margin-bottom:1%;
  @media (max-width:466px){
    width:100%;
    height:163px;
  }

`
export const StartLearningButton = styled.div`
  border-radius:4px;
  display:flex;
  justify-content:center;
  align-items:center;
  background-color: white;
  border: 1px solid #4d2c77;
  color: #4D2C77;
  font-size: 14px;
  font-weight: bold;
  cursor:pointer;
  height:42px;
  width:126px;
  box-sizing:border-box;
  pointer-events: ${(props) => props.dissabled > 0 ? null : "none"};
  opacity: ${(props) => props.dissabled > 0 ? null : "0.7"};
  &:hover{
      background-color: #4D2C77;
      color:white;
  }
`

export const LeftArrow = styled.img`
  font-size: 24px;
  width: 2rem;
  height: 2rem;
`

export const FlashCardsLearning = styled.div`
  min-height: 105px;
  width:100%;
  border-radius: 8px;
  border: 1px solid rgba(202, 86, 161, 0.1);
  display:flex;
  align-items:center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 1rem;
  gap: 1rem;
  height: auto;
  cursor: pointer;
  &:hover{
    background-color: #E4DAF1;
  }
  @media (max-width: 930px){
    gap: 4px;
  }
  @media ${device.md}{
    gap: 1rem;
  }
  @media (max-width: 500px){
    height: auto;
    flex-direction: column;
    align-items: start;
  }
`

export const DivPara = styled.div`
  height:100%;
  width: 100%;
  gap: 8px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
`

export const LearningBody = styled.div`
  height:70px;
  width:95%; 
  display:flex;
  justify-content:center;
  align-items:start;
  flex-direction:column;
  padding-left:2%;
`
// export const LearningBody1 = styled.div`  
//   height:70px;
//   width:95%; 
//   display:flex;
//   justify-content:center;
//   align-items:start;
//   flex-direction:column;
//   padding-left:3%;
//   background-color: red;
//   @media (max-width:466px){
//     width:98%;
//     ${props => props.lang === 'en' ? 'padding-left:6%;':'padding-left:0%;'}
//   }
// `

export const LearnHead = styled.div`
  width:auto;
  font-size:16px;
  font-weight: bold;
  gap: 12px;
  display:flex;
  align-items:center;
`

export const Imgdiv = styled.div`
  width:10px;
  height:10px;
  display:flex;
  align-items:center;
`
export const ImgText = styled.div`
  align-items:center;
  height:20px;
  font-weight: bold;
  display:flex;
`

export const LearningPara = styled.div`
  width: 100%;
  margin:0px 0px;
  font-size:12px;
  line-height: 19px;
  color:#727272;
`

// export const ButtonText = styled.span`
//   color:#4d2c77;
//   font-size: 14px;
//   font-weight: bold;
// `

  // export const ButtonText1 = styled.h2`
  // color:white;
  // font-size:14px;
  // font-weight: bold;
  // `

  // export const StartTestButton = styled.div`
  // display:flex;
  // justify-content:center;
  // height:121px;
  // margin-left:0.6%;
  // background-color: red;
  // @media (max-width:466px){
  //   width:100%;
  //   height:163px;
  // }
// `

export const LeftCardIcons = styled.div`
height: 54px;
width:100%;
display:flex;
justify-content:space-between;
align-items:center;
@media ${device.lg}{
  height: auto;
}
@media ${device.xs}{
  height: auto;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}
`

export const IconDiv = styled.div`
  display:flex;
  align-items:flex-end;
  gap: 8px;
  align-items: center;
`
export const UpperText = styled.div`
  font-size: 16px;
  font-weight:bold;
`

export const LowerText = styled.h1`
  font-size:12px;
  color:#727272;
  font-weight: normal;
`

export const Footer = styled.div`
  height:200px;
  width:100%;
  background:black;
`

export const OpenDiv = styled.div`
  font-size: 12px;
  height: 76px;
  width: 100px;
  padding: 12px 0 12px 0;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  box-sizing: border-box;
  background-color:white;
  border-radius: 4px;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 2.5rem;
  right: ${(props) => props.lang === 'en' ? "0.85rem" : null};
  left: ${(props) => props.lang === 'ar' ? "0.85rem" : null};
  box-sizing: border-box;
  @media (max-width:466px){
    top:16px;
    margin-left:1%;
  }
`

export const EditDiv = styled.div`
  height: 2rem;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  padding: 0 8px 0 8px;
  box-sizing: border-box;
`

export const DeleteDiv = styled.div`
    width: 100%;
    display: flex;
    height: 2rem;
    justify-content: start;
    align-items: center;
    cursor: pointer;
    gap: 4px;
    box-sizing: border-box;
    padding: 0 8px 0 8px;
`


export const TextEdit = styled.div`
  display:flex;
  align-items:center;
  color:#4C4C4C;
`

export const ImgDiv1 = styled.div`
display:flex;
justify-content:flex-end;
align-items:center;
height:100%;
width:33%;
`

export const DeletePop = styled.div`
  height:100vh;
  width:100%;
  Position:absolute;
  display:flex;
  justify-content:center;
  z-index:2;
  align-items:center; 
  `
  
  export const DeleteInnerPopUp = styled.div`
  height:300px;
  width:500px;
  background-color:white;
  box-shadow: 0 4px 32px rgba(0, 0, 0, 0.24);
  border-radius:5px;
`

export const DeleteImg = styled.div`
  height:30%;
  width:100%;
  display:flex;
  justify-content:center;
  align-items:center;
`
export const DeleteMid = styled.div`
  height:35%;
  width:100%;

`

export const DeleteButtonDiv = styled.div`
  height:20%;
  display: flex;
  justify-content: center;
  align-items: center;
  width:100%;
`

export const TextDelete = styled.div`
  text-align:center;
`

export const ParaDelete = styled.div`
  text-align:center;
`

export const ButtonBack = styled.button`
  height:60px;
  width:150px;
  margin-right:10px;
  border-radius:5px;
  border:2px solid #4D2C77;
  background:none;
  color:#4D2C77;
  cursor:pointer;
`

export const ButtonDelete = styled.button`
  height:60px;
  width:170px;
  border:none;
  border-radius:5px;
  background-color:#F55157;
  cursor:pointer;
`

export const EditPop = styled.div`
  position: fixed;
  background-color: rgba(77, 44, 119, 0.55);
  top: 0;
  left: 0;
  height: 100vh;
  right: 0;
  bottom: 0;
  overflow: hidden;
  scroll-behavior: none;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-height: 600px){
    align-items: start;
    overflow-y: scroll;
  }
`

export const EditInnerPopUp = styled.div`
  height: 590px;
  width: 640px;
  position: relative;
  display: flex;
  background-color: white;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 32px rgba(0, 0, 0, 0.24);
  border-radius:5px;
  padding: 2rem;
  gap: 24px;
  box-sizing: border-box;
  @media ${device.md}{
    width: 580px;
  }
  @media ${device.sm}{
    width: 100%;
    position: absolute;
    bottom:0;
    border-radius: 24px 24px 0 0;
    height: 472px;
    padding: 18px;
    gap: 16px;
  }
  @media (max-height: 600px){
    margin: 2rem 0 2rem;
  }
`

export const EditHead = styled.div`
  width:100%;
  text-align:center;
  display:flex;
  justify-content: center;
  font-size: 2rem;
  font-weight: bold;
  @media ${device.sm}{
    font-size: 20px;
    justify-content: start;
  }
`

export const CrossIcon = styled.span`
  color: #667085;
  position: absolute;
  top: 0;
  cursor: pointer;
  font-size: 24px;
  margin: 8px 8px 0 8px;
  right: ${props => props.lang === 'en' ? 0 : null};
  left: ${props => props.lang === 'ar' ? 0 : null};
  
`



export const QuesUpdateDiv = styled.div`
  width:100%;
  margin-bottom:10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  `


export const QuesLabel = styled.div`
  width:100%;
  font-weight: 14px;
`

export const QuesEditInput = styled.div`
  width:100%;
  height: auto;
  border:1px solid #EEEEEE;
  box-sizing: border-box;
  border-radius: 4px;
`
export const TextArea = styled.textarea`
  border: none;
  outline: none;
  padding: 16px;
  font-size: 16px;
  font-family: "Almarai", sans-serif;
  resize: none;
  box-sizing: border-box;
  width: 100%;
  height: ${props => props.height};
  &::-webkit-scrollbar {
      width: 0px;
  }
  @media ${device.sm}{
    height: auto;
  }
`


export const BottomButton = styled.div`
  width:100%;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  gap: 24px;
  @media ${device.sm}{
    gap: 16px;
  }
`

export const SaveButton = styled.div`
  height: 64px;
  width: 100%;
  border-radius: 4px;
  font-size: 18px;
  font-weight: bold;
  background-color:#4D2C77;
  color:white;
  cursor:pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  &:hover{
    background-color: #CA56A1;
  }
  @media ${device.sm}{
    height: 56px;
  }
`

export const CancelButton = styled.div`
  border:none;
  background-color:#FFFFFF;
  cursor:pointer;
  text-decoration: underline;
`

// No Flash Cards Animation


export const  wiggle =  keyframes`
  0%, 7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-15deg);
  }
  20% {
    transform: rotateZ(10deg);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  30% {
    transform: rotateZ(6deg);
  }
  35% {
    transform: rotateZ(-4deg);
  }
  40%, 100% {
    transform: rotateZ(0);
  }
`
export const AnimatedContainer = styled.div`
  animation: ${wiggle} 2s linear infinite;

  height: 8rem;
  width: 14rem;

  background: #444;
  background: #CA56A1;
  border: none;
  border-top: 10px solid #4D2C77;
  border-radius: 0 0 0.2em 0.2em;
  color: #fff;
  font-size: 1em;
  transform-origin: 50% 5em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-weight: bold;
`

export const AnimationOuterContainer = styled.div`
  width: 100%;
  height: 363px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  flex-direction: column;
  text-align: center;
  font-weight: bold;
  color: #CA56A1;
`

export const GridBox = styled.div`
  height:81%;
  overflow-y:scroll;
  overflow-x:hidden;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
      background-color: #888; /* Color of the scrollbar handle */
      border-radius: 5px; /* Roundness of the scrollbar handle */
  }

  /* This targets the track of the scrollbar (the area behind the handle) */
  &::-webkit-scrollbar-track {
      background-color: #f1f1f1; /* Color of the scrollbar track */
  }
  &::-webkit-scrollbar {
        width: 0px;
    }
`