import React, { useEffect, useState } from 'react'
import { UploadPDFContainer, UploadSubContainer, UploadSection, UploadSectionPrimary, UploadSectionSecondary, UploadLabel, Input, TextSection, Heading, ColoredText, UploadAnimation, StyledButton, UploadAnimationSubSection, ArrowImage, FileInformation, FileName} from '../UploadFile/UploadFile.styles.js'
import PDFUploadIcon from '../../Assets/Images/PDFUploadIcon.svg'
import WhiteArrowIcon from '../../Assets/Images/WhiteArrowIcon.svg'
import FileSavingSection from '../UploadFile/FileSavingSection'
import UploadIconAnimated from '../../Assets/Images/UploadIconAnimated.svg'
import CricularProgressBar from '../UploadFile/CricularProgressBar.js'
import { useDispatch, useSelector } from 'react-redux'
import { UploadFileLanguage } from '../data/UploadFile.lang'
import ApiClient from '../../Config/index.config'
import { emptyRoutesArray, updateRoutesArray } from '../../Store/Slice/Navigation/NavigationSlice'
import ExhaustedFlashCardsModel from "../Modals/ExhaustModals/ExhaustModals.js";

const UploadPDF = () => {

    const dispatch = useDispatch()
    const [visibilityOfAnimation, setVisibilityOfAnimation] = useState(false)
    const [visibilityOfUploadSection, setVisibilityOfUploadSection] = useState(true);
    const [dissableButton, setDissableButton] = useState(true)
    const lang = useSelector((store) => store.lang.language)
    const [file, setFile] = useState(null);
    const [fileSize, setFileSize] = useState(null)
    const [isUploading, setIsUploading] = useState(false);
    const [s3_link, setS3_link] = useState(null);
    const [LimitExhaustModal, setLimitExhaustModal] = useState(false);
    const [exhaustModalDataIndex, setExhuastModalDataIndex] = useState(null)
    const premiumFeaturesPrice = useSelector((store) => store.premiumDetails.Data)
    const FreeSize = premiumFeaturesPrice.free_user_max_file_size;
    const PremiumSize = premiumFeaturesPrice.premium_user_max_file_size
    const FreeUploadLimit = premiumFeaturesPrice.free_user_number_of_files
    const premiumUploadLimit = premiumFeaturesPrice.premium_user_number_of_files
    const freePages = premiumFeaturesPrice.free_user_number_of_pages    
    const premiumPages = premiumFeaturesPrice.premium_user_number_of_pages    
    const exhaustModalData = 
    [   
        // File Size Limit Data
        {LimitHeading: {ar: "تم تجاوز الحد الأقصى للحجم", en: "Size limit exceeded"},
        Limit: {ar: FreeSize, en: FreeSize},
        Heading: {ar:` حجم الملف أكبر من ${FreeSize} ميجابايت`, en: `File size is larger than ${FreeSize} MB`},
        SubHeading: {ar:` للاستمتاع بحجم ملف يصل إلى ${PremiumSize} ميجابايت/ملف، يجب عليك الاشتراك في`, en: `To enjoy the file size upto ${PremiumSize} MB/file, you must subscribe to`},
        Mumayaz: {ar: `${PremiumSize} م.ب / الملف`, en: `${PremiumSize} mb/file`},
        Free: {ar: `${FreeSize} م.ب / الملف`, en: `${FreeSize} mb/file`},
        Advantage: {ar: "حجم الملف", en: "File size"}},
        // Daily Upload Limit Data
        {LimitHeading: {ar: "التجارب المجانية اليومية", en: "Daily free trials"},
        Limit: {ar: FreeUploadLimit, en: FreeUploadLimit},
        Heading: {ar: "لقد تم استنفاد حد التحميل اليومي", en: "Daily upload limit have been exhausted"},
        SubHeading: {ar:` للاستمتاع بحد التحميل الذي يصل إلى ${premiumUploadLimit} ملفًا في اليوم، يجب عليك الاشتراك في`, en: `To enjoy the upload limit upto ${premiumUploadLimit} Files/day, you must subscribe to`},
        Mumayaz: {ar: `${premiumUploadLimit} ملف / باليوم`, en: `${premiumUploadLimit} files/day`},
        Free: {ar: `${FreeUploadLimit} ملف/يوم`, en: `${FreeUploadLimit} file/day`},
        Advantage: {ar: "عدد الملفات", en: "Number of files"}},
        // Page Limit Data
        {LimitHeading: {ar: "تم تجاوز حد الصفحة", en: "Page limit exceeded"},
        Limit: {ar: freePages, en: freePages},
        Heading: {ar:`عدد الصفحات أكبر من ${freePages}`, en: `Number of pages are larger than ${freePages}`},
        SubHeading: {ar:` للاستمتاع بما يصل إلى ${premiumPages} صفحة/ملف، يجب عليك الاشتراك في`, en: `To enjoy upto ${premiumPages} pages/file, you must subscribe to`},
        Mumayaz: {ar: `${premiumPages} صفحة/ملف`, en: `${premiumPages} pages/file`},
        Free: {ar: `${freePages} صفحة/ملف`, en: `${freePages} pages/file`},
        Advantage: {ar: "عدد الصفحات", en: "Number of pages"}}
    ]

    const handleUploadClick = (event) => {
        event.target.value = null;
        setFile(null);
        setFileSize(null);
        setVisibilityOfAnimation(false);
        setDissableButton(true);
    }

    const handleUpload = async (event) => {
        event.preventDefault();
        setIsUploading(true);
        setFile(event.target.files[0])
        const formData = new FormData();
        formData.append('file', event.target.files[0]);
        if(Math.round((event.target.files[0].size)/1048576) < 1){
            setFileSize("< 1")
        }
        else{
            setFileSize(Math.round((event.target.files[0].size)/1048576))
        }
        
        // if((event.target.files[0].size)/1048576 > 5 && !(JSON.parse(localStorage.getItem("FlashCards") )).is_premium_user){  // Handeling Size Limit On Frontend 
        //     setLimitExhaustModal(true);
        //     setVisibilityOfAnimation(false);
        //     setExhuastModalDataIndex(0);
        // }
        // else{
            try{
                const response = await ApiClient.post(`/library/upload-file`, formData, {headers : { 'content-type': 'multipart/form-data' }})
                setS3_link(response.data.data.file_url);
                setIsUploading(false);
                console.log(response)
              }catch(error){
                console.log(error)
                setIsUploading(false);
                if(error?.response?.data?.error_code === 1039){ // Size Limit Exceeded
                    setLimitExhaustModal(true)
                    setVisibilityOfAnimation(false)
                    setExhuastModalDataIndex(0);
                }
                if(error?.response?.data?.error_code === 1040){  // Daily Upload Limit Exceeded
                    setLimitExhaustModal(true)
                    setVisibilityOfAnimation(false)
                    setExhuastModalDataIndex(1);
                }
                if(error?.response?.data?.error_code === 1041){  // Page Limit Exceeded
                    setLimitExhaustModal(true);
                    setVisibilityOfAnimation(false);
                    setExhuastModalDataIndex(2);
                }
            }
        // }
    }

    const handleAttachFile = () => {
        setVisibilityOfUploadSection(false);
    }

    const handleDelete = () => {
        setFile(null)
        setFileSize(null)
        setVisibilityOfAnimation(false)
    }

    useEffect(() => {
        if(file !== null){
            setVisibilityOfAnimation(true);
        }
        if(LimitExhaustModal){
            setVisibilityOfAnimation(false)
        }
    },[file])

    useEffect(() => {
        if(visibilityOfAnimation === false){
            setFileSize(null)
            setFile(null)
            setDissableButton(true)
            setDissableButton("true")
        }
    }, [visibilityOfAnimation])

    useEffect(() => {
        console.log("UploadPDF")
        dispatch(emptyRoutesArray())
        dispatch(updateRoutesArray({data: "library", id: ""}))
    },[])

    return (

        <>
            {visibilityOfUploadSection ? 
                <UploadSubContainer style={{marginBottom:"20px"}}>
                    <TextSection width={"34rem"} >
                        <Heading>
                            {UploadFileLanguage[lang].heading1pub} 
                        </Heading>
                        {UploadFileLanguage[lang].subHeading1pub}
                    </TextSection>

                    <UploadSection>
                        <UploadSectionPrimary>
                            <UploadSectionSecondary>
                                <UploadLabel onChange={handleUpload} onClick={handleUploadClick}>
                                        <img src={PDFUploadIcon} alt='pdf icon'/>
                                        <Input type="file" accept="application/pdf"/>
                                        {UploadFileLanguage[lang].pdf}
                                </UploadLabel>
                            </UploadSectionSecondary>
                            {visibilityOfAnimation ?
                            <UploadAnimation >
                                <UploadAnimationSubSection backgroundcolor="#FAFAFA" justify="start">
                                    <img src={UploadIconAnimated} alt='upload icon'/>
                                    <FileInformation lang={lang}>
                                        <span style={{width: "7rem", overflow: "hidden", height: "1rem"}}>{file?.name}</span>
                                        <FileName lang={lang}>{fileSize}<span>MB</span></FileName>
                                    </FileInformation>
                                </UploadAnimationSubSection>
                                <UploadAnimationSubSection backgroundcolor="#FFFFFF" justify="end">
                                <CricularProgressBar setDissableButton={setDissableButton} file={file} fileSize={fileSize} isUploading={isUploading} se_link={s3_link}/>
                                    <svg onClick={handleDelete} style={{cursor: "pointer"}} width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.3333 4.49935V3.83268C12.3333 2.89926 12.3333 2.43255 12.1517 2.07603C11.9919 1.76243 11.7369 1.50746 11.4233 1.34767C11.0668 1.16602 10.6001 1.16602 9.66667 1.16602H8.33333C7.39991 1.16602 6.9332 1.16602 6.57668 1.34767C6.26308 1.50746 6.00811 1.76243 5.84832 2.07603C5.66667 2.43255 5.66667 2.89926 5.66667 3.83268V4.49935M7.33333 9.08268V13.2493M10.6667 9.08268V13.2493M1.5 4.49935H16.5M14.8333 4.49935V13.8327C14.8333 15.2328 14.8333 15.9329 14.5608 16.4677C14.3212 16.9381 13.9387 17.3205 13.4683 17.5602C12.9335 17.8327 12.2335 17.8327 10.8333 17.8327H7.16667C5.76654 17.8327 5.06647 17.8327 4.53169 17.5602C4.06129 17.3205 3.67883 16.9381 3.43915 16.4677C3.16667 15.9329 3.16667 15.2328 3.16667 13.8327V4.49935" stroke="#727272" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </UploadAnimationSubSection>
                            </UploadAnimation>
                            : null}
                            
                            <StyledButton dissablebutton={`${dissableButton}`} onClick={() => {handleAttachFile()}}>{UploadFileLanguage[lang].attachButton}<ArrowImage lang={lang} src={WhiteArrowIcon} alt="arrow icon"/> </StyledButton>
                        </UploadSectionPrimary>
                    </UploadSection>
                </UploadSubContainer>
            : <FileSavingSection setVisibilityOfUploadSection={setVisibilityOfUploadSection} setVisibilityOfAnimation={setVisibilityOfAnimation} fileName={file?.name} s3_link={s3_link}/>}
            {LimitExhaustModal ?
                <ExhaustedFlashCardsModel setLimitExhaustModal={setLimitExhaustModal} data={exhaustModalData[exhaustModalDataIndex]}/>
                : null
            }
       
        </>
    )

}

export default UploadPDF;