import styled, { keyframes } from "styled-components";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { media } from "../../Assets/Styles/utils";
import "../../Assets/fonts/Almarai-Regular.ttf";
import AlmaraiFont from "../../Assets/fonts/Almarai-Regular.ttf";

export const ProfileContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #fdf7fb;
  min-height: 90vh;
  font-family:${AlmaraiFont};
`;

export const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 80%;
  ${media("medium")} {
    width: 100%;
    overflow: hidden;
  }
`;

export const ProfileTopRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 25vh;
  margin: 20px 0 0 0;
`;

export const Image = styled.img`
  width: 50px;
  align-items: center;
  object-fit: cover;
  border-radius: 10px;
  background: #f2f2f2;
  border-radius: 50%;
`;

export const Head1 = styled.h1`
  font-size: 1.75rem;
  margin: 10px 0 0 0;
  color: #252525;
  font-weight: 500;
  font-family: "Almarai";
  font-style: normal;
`;

export const Head2 = styled.h2`
  font-size: 1.3rem;
  margin: 0;
  color: #727272;
  font-weight: 400;
  font-family: "Almarai";
  font-style: normal;
  line-height: 38px;
`;

export const Profilepersonal = styled.div`
  display: flex;
  justify-content: start;
  height: 80%;
  text-align: center;
  width: 100%;
  ${media("medium")} {
    flex-direction: column;
  }
`;

export const ProfileTab = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  background: #fffeff;
  width: 21%;
  height: 300px;
  border-radius: 5px;
  border: 1px solid #f2f2f2;
  padding: 10px 5px;
  margin: 5px 20px;
  ${media("medium")} {
    width: auto;
    padding: 0;
    height: auto;
    flex-direction: row;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    margin-top:0;
    
  }
  
`;

export const ProfileBtn = styled.button`
  display: flex;
  width: 100%;
  margin-top: 10px;
  justify-content: start;
  align-items: center;
  background: ${(props) => (props.selected ? "#e6e6e6" : "white")};
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  outline: none;
  color: ${(props) => (props.selected ? "black" : "#727272")};
  font-weight: 500;
  font-family: "Almarai";
  font-style: normal;
  font-size: 1rem;
  line-height: 38px;
  transition: all 0.3s ease;
  white-space: nowrap;
  &:hover {
    background: #e6e6e6;
    color: #252525;
  }
  ${media("medium")} {
    width: 220px;
    margin:10px;
  }
`;

export const ProfileEdit = styled.div`
  padding: 10px 10%;
  margin: 5px 20px;
  cursor: pointer;
  text-align: start;
  width: 50%;
  border: 1.5px solid #f2f2f2;
  ${media("medium")} {
    width: 70%;
    margin: auto;
  }
  ${media("small")} {
    width: 85%;
    margin: auto;
    padding: 0;
    border: none;
  }
`;

export const Head3 = styled.h3`
  font-size: 1.2rem;
  margin: 0;
  color: #252525;
  font-weight: 800;
  font-family: "Almarai";
  font-style: normal;
  line-height: 38px;
  align-self: start;
`;
export const Head4 = styled.h3`
  font-size: 0.8rem;
  margin: 0;
  color: #252525;
  font-weight: 500;
  font-family: "Almarai";
  font-style: normal;
  line-height: 38px;
  align-self: start;
`;
export const Head5 = styled.h3`
  font-size: 1rem;
  margin: 0;
  color: #252525;
  font-weight: 600;
  font-family: "Almarai";
  font-style: normal;
  line-height: 38px;
  align-self: start;
`;

export const Details = styled.div`
  display: flex;
  background: #e6e6e6;
  border-radius: 5px;
  align-items: center;
  padding:auto 2%;
  justify-content: space-around;
  margin-top:4%;
  ${media("small")} {
    flex-direction: column;
    padding: 10px;
    justify-content: start;
    align-items: start;
  }
  `;

export const ProfileForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
  width: 100%;
  text-align: start;
  ${media("small")}{
    gap-y:10px;
  }
`;

export const InputBox = styled.div`
  width: 100%;
  margin-top: 15px;
  position: relative;
  background: #fdf7fb;
  input {
    height: 40px;
    width: 100%;
    font-size: 0.8rem;
    background: #fcf7fb;
    border: 1.65px solid #eeeeee;
    outline: none;
    padding: 8px 0px 3px 2%;
  }
  label {
    position: absolute;
    left: 0px;
    pointer-events: none;
    top: 0px;
    left: 10px;
    right: 10px;
    font-size: 13px;
    color: #727272;
  }
`;

export const ProfileButton = styled.button`
  width: 103%;
  padding: 10px;
  // margin-left: ${(props) => (props.pass ? "0" : "2.5%")};
  margin-top: 15px;
  border-radius: 5px;
  border: ${(props) => (props.modified ? "1px solid #4D2C77" : "none")};
  outline: none;
  background: ${(props) => (props.modified ? "white" : "#4D2C77")};
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  font-family: "Almarai";
  font-style: normal;
  line-height: 38px;
  color: ${(props) => (props.modified ? "#4D2C77" : "white")};
  &:disabled {
    background: #f2f2f2;
    color: #727272;
    cursor: not-allowed;
  }
`;

export const Phone2Input = styled(PhoneInput)`
  &.react-tel-input {
    margin-top: 15px;
    // margin-left: ${(props) => (props.lang !== "en" ? "-2.5" : "2.5")}%;
    width: 102%;
    height: 52px;
    background-color: #fcf7fb;
    border: 2px solid #eeeeee;
    direction: ltr;
  }
  &.react-tel-input .flag-dropdown {
    height: 40px;
    background-color: #fcf7fb;
    border: none;
    margin: auto 7px;
  }
  &.react-tel-input .selected-flag {
    width: 100%;
    height: 115%;
    background-color: #fcf7fb;
    border: none;
    &.hover {
      background-color: #fcf7fb;
    }
  }
  &.react-tel-input .sa {
    scale: 1.5;
    padding: auto 5px;
    margin-top: -8px;
    border: none;
  }
  &.react-tel-input .arrow {
    scale: 1.5;
    padding: auto 5px;
  }

  &.react-tel-input .form-control {
    height: 52px;
    width: 100%;
    font-size: 0.8rem;
    border-radius: 0;
    background-color: #fcf7fb;
    border: none;
    border-right: 2px solid #eeeeee;
    padding-right: 0px;
    padding-left: 56px;
    border-right-width: 0px;
  }
`;

export const PasswordInput = styled.div`
  padding: 0 0;
  display: flex;
  margin: 10px 0;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
  &:focus {
    outline: #4d2c77;
  }
`;

export const Select = styled.select`
  width: 100%;
  height: 60px;
  background: #fcf7fb;
  border: 1.65px solid #eeeeee;
  outline: none;
  padding: 8px 0px 3px 2%;
  font-size: 1rem;
  margin-top: 15px;
`;

export const Option = styled.option`
  font-size: 1rem;
  height: 60px;
  padding: 10px;
  margin: 5px;
  color: #727272;
  background: #fcf7fb;
  border: 1.65px solid #eeeeee;
  outline: none;
`;

export const ProfileButton2 = styled.button`
  width: 100%;
  padding: 10px;
  margin-top: 15px;
  border-radius: 5px;
  border: ${(props) => (props.modified ? "1px solid #4D2C77" : "none")};
  outline: none;
  background: ${(props) => (props.modified ? "white" : "#4D2C77")};
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  font-family: "Almarai";
  font-style: normal;
  line-height: 20px;
  color: ${(props) => (props.modified ? "#4D2C77" : "white")};
  &:disabled {
    background: #f2f2f2;
    color: #727272;
    cursor: not-allowed;
  }
`;

const l5 = keyframes`
  0%  {box-shadow: 20px 0 #000, -20px 0 #0002;background: #000 }
  33% {box-shadow: 20px 0 #000, -20px 0 #0002;background: #0002}
  66% {box-shadow: 20px 0 #0002,-20px 0 #000; background: #0002}
  100%{box-shadow: 20px 0 #0002,-20px 0 #000; background: #000 }
`;

export const Loader = styled.div`
  width: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: ${l5} 1s infinite linear alternate;
`;
