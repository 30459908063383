import React, { useEffect } from 'react'
import { NavigationContainer, NavigationSubContainer, HomeImage, PathContainer, ArrowIconImage, NavigationName } from '../Library/Library.styles'
import HomeIcon from '../../Assets/Images/HomeIcon.svg'
import LeftArrowIcon from '../../Assets/Images/LeftArrowIcon.svg'
import { useDispatch, useSelector } from 'react-redux'
import { initialFileTransferRoute } from '../../Store/Slice/Navigation/NavigationSlice'

const FileTransferNavigation = ({getFolders, setIsLoading}) => {
    const NavigationData = useSelector((store) => store.navigationData.fileTransferRoute)
    const lang = useSelector((store) => store.lang.language)
    const dispatch = useDispatch()

    const handleClick = (data) => {
        setIsLoading(true)
        getFolders(data.name, data.id);
    }
    useEffect(() => {
        dispatch(initialFileTransferRoute());
    },[])

    return (
        <NavigationContainer>
            <NavigationSubContainer nomargin = "true">
                <HomeImage src={HomeIcon} alt='home icon'/>
                {NavigationData?.map((data, index) => {
                    return (
                        <PathContainer lang={lang} key={index} $color={data.color} onClick={() => handleClick(data)}>
                            <ArrowIconImage lang={lang} src={LeftArrowIcon} alt='arrow icon' />
                            {data.name}
                        </PathContainer>
                    )
                })}
            </NavigationSubContainer>
        </NavigationContainer>
    )
}

export default FileTransferNavigation