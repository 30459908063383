import React from 'react'
import { Card, Cardwrapper, ValueContainer, ValueDesc, ValueHead, ValueWrapper } from './Value.style'
import { valuelang } from '../data/Home.lang'
import { useSelector } from 'react-redux'
import container1 from '../../Assets/Images/contentContainer1.png'
import container2 from "../../Assets/Images/Container2.1.png";
import container3 from "../../Assets/Images/container2.4.png";
import container4 from "../../Assets/Images/container2.3.png";

const Value = () => {
    const lang=useSelector(state=>state?.lang?.language);
  return (
    <ValueContainer>
        <ValueWrapper>
            <ValueHead>{valuelang[lang]?.heading1}</ValueHead>
            <ValueDesc>{valuelang[lang]?.description}</ValueDesc>
            <Cardwrapper>
                <Card>
                    <img src={container1} alt="card1"/>
                    <h3>{valuelang[lang]?.card1head}</h3>
                    <p>{valuelang[lang]?.card1desc}</p>
                </Card>
                <Card>
                    <img src={container2} alt="card2"/>
                    <h3>{valuelang[lang]?.card2head}</h3>
                    <p>{valuelang[lang]?.card2desc}</p>
                </Card>
                <Card>
                    <img src={container3} alt="card3"/>
                    <h3>{valuelang[lang]?.card3head}</h3>
                    <p>{valuelang[lang]?.card3desc}</p>
                </Card>
                <Card>
                    <img src={container4} alt="card4"/>
                    <h3>{valuelang[lang]?.card4head}</h3>
                    <p>{valuelang[lang]?.card4desc}</p>
                </Card>
                
            </Cardwrapper>
        </ValueWrapper>
    </ValueContainer>
  )
}

export default Value