import PaypalImage from "./Paypal.svg";
import SabadImage from "./Sabad.svg";
import StcImage from "./Stc.svg";
import VisaImage from "./Visa.svg";
import PaymentCss from "./Payment.css";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { MdStarRate } from "react-icons/md";
import { combineSlices } from "@reduxjs/toolkit";
import axios from "axios";
import ApiClient from "../../../Config/index.config";
import { useNavigate } from "react-router-dom";


const PaymentForm = ({brands, checkoutId}) => {
  const lang = useSelector((store) => store.lang.language)
  const formRef = useRef();
  const navigate = useNavigate();

  var wpwlOptions = {
    locale: lang, // Default to English
  };

  var translations = {
    en: {
      "Payment Method": " Payment Method",
      "Please enter the card number *": "Please enter the card number *",
      "Full Name": "Full Name *",
      "MM/YY": "MM/YY *",
      "CVV code": "CVV code *",
      "Pay now": "Complete payment",
      VISA: "VISA",
      SABAD: "SABAD",
      STC: "STC",
      PAYPAL: "PAYPAL",
    },
    ar: {
      "Payment Method": " طريقة الدفع",
      "Please enter the card number *": "يرجى إدخال رقم البطاقة *",
      "Full Name": "الاسم الكامل",
      "MM/YY": "شهر/سنة",
      "CVV code": "رمز CVV",
      "Pay now": "أكمل عملية الدفع",
      VISA: "فيزا",
      SABAD: "صباد",
      STC: "إس تي سي",
      PAYPAL: "باي بال",
    },
  };

  // Set English placeholders by default
  useEffect(() => {
    updatePlaceholders(wpwlOptions.locale);
    changeLanguage()
  },[lang])

  function updatePlaceholders(language) {
    var placeholders = {
      en: {
        "Please enter the card number *": "Please enter the card number",
        "Full Name": "Full Name",
        "MM/YY": "MM/YY",
        "CVV code": "CVV code",
      },
      ar: {
        "Please enter the card number *":"يرجى إدخال رقم البطاقة ",
        "Full Name":"الاسم الكامل",
        "MM/YY": "شهر/سنة",
        "CVV code": "رمز CVV",
      },
    };

    var inputs = document.querySelectorAll("input[data-translate-placeholder]");
    inputs.forEach(function(input) {
      var key = input.getAttribute("data-translate-placeholder");
      if (placeholders[language][key]) {
        if(lang === "en"){
          input.placeholder = placeholders[language][key] + " *";
        }
        else{
          input.placeholder = "* " + placeholders[language][key];
        }
      }
    });
  }

  function updateDataBrands() {
    var selectedBrand = document.querySelector('input[name="radio"]:checked')
      .id;
    document
      .querySelector(".wpwl-form")
      .setAttribute("data-brands", selectedBrand);
  }

  function changeLanguage() {
    var selectedLanguage = lang;
    wpwlOptions.locale = selectedLanguage;
    // document.querySelector("html").setAttribute("lang", selectedLanguage);
    translateUI(selectedLanguage);
    handleAlignment(selectedLanguage);
    handleTitleClass(selectedLanguage);
    updatePlaceholders(selectedLanguage);
  }

  function translateUI(language) {
    var elements = document.querySelectorAll("[data-translate]");
    elements.forEach(function(element) {
      var key = element.getAttribute("data-translate");
      if (translations[language][key]) {
        element.textContent = translations[language][key];
      }
    });
  }

  function formatCardNumber() {
    var cardNumberInput = document.querySelector('input[name="card.number"]');
    var cardNumber = cardNumberInput.value.replace(/\D/g, "");
    var formattedNumber = "";
    for (var i = 0; i < cardNumber.length; i++) {
      if (i > 0 && i % 4 === 0) {
        formattedNumber += "-";
      }
      formattedNumber += cardNumber[i];
    }
    cardNumberInput.value = formattedNumber;
  }

  function formatExpiry() {
    var expiryInput = document.querySelector('input[name="card.expiry"]');
    var expiry = expiryInput.value.replace(/\D/g, "");
    var formattedExpiry = "";
    for (var i = 0; i < expiry.length; i++) {
      if (i > 0 && i % 2 === 0) {
        formattedExpiry += "/";
      }
      formattedExpiry += expiry[i];
    }

    expiryInput.value = formattedExpiry;
  }

  function formatCVV() {
    var cvvInput = document.querySelector('input[name="card.cvv"]');
    var cvv = cvvInput.value.replace(/\D/g, "");
    if (cvv.length > 3) {
      cvv = cvv.slice(0, 3);
    }
    cvvInput.value = cvv;
    console.log(cvvInput)
  }

  function handleAlignment(language) {
    // var container = document.querySelector(".wpwl-container");
    // if (language === "ar") {
    //   container.setAttribute("dir", "rtl");
    // } else {
    //   container.removeAttribute("dir");
    // }
  }

  function handleTitleClass(language) {
    // var title = document.querySelector(".title");
    // var inputs = document.querySelectorAll(".wpwl-control");
    // if (language === "ar") {
    //   title.classList.add("arabic");
    //   inputs.forEach(function(input) {
    //     input.classList.add("arabic");
    //   });
    // } else {
    //   title.classList.remove("arabic");
    //   inputs.forEach(function(input) {
    //     input.classList.remove("arabic");
    //   });
    // }
  }

  async function  onSubmit(){
    var cardNumberInput = document.querySelector('input[name="card.number"]');
    var cardHolderInput = document.querySelector('input[name="card.holder"]');
    var expiryInput = document.querySelector('input[name="card.expiry"]');
    var cvvInput = document.querySelector('input[name="card.cvv"]');


    var cardNumber = cardNumberInput.value;
    var cardHolder = cardHolderInput.value;
    var expiry = expiryInput.value;
    var cvv = cvvInput.value;
    var selectedBrand = document.querySelector('input[name="radio"]:checked').id;

    try {
      const response = await axios.post(`https://oppwa.com/v1/checkouts/${checkoutId}/payment`);
      // for tapfiliate
//       fetch('https://api.tapfiliate.com/1.6/conversions/', {
//        method: 'POST',
//        headers: {
//         'Content-Type': 'application/json',
//         'Api-Key': 'YOUR API KEY HERE'
//        },
//       body: JSON.stringify({
//        "click_id": document.cookie.replace(/(?:(?:^|.*;\s*)click_id\s*\=\s*([^;]*).*$)|^.*$/, "$1"),
//       "external_id": "ORDER001", //orderid should be uniquee for each order
//       "amount": 10
//      })
// })
//       .then(response => response.json())
//       .then(data => console.log(data));
      console.log('Payment successful:', response.data);
    } catch (error) {
      console.error('Payment failed:', error);
    }

    console.log("Card Number:", cardNumber);
    console.log("Card Holder:", cardHolder);
    console.log("Expiry:", expiry);
    console.log("CVV:", cvv);
    console.log("Brand:", selectedBrand);
    console.log("Checkout_id:", checkoutId)

    // Reset input values and formatting
    cardNumberInput.value = "";
    cardHolderInput.value = "";
    expiryInput.value = "";
    cvvInput.value = "";
    updatePlaceholders(wpwlOptions.locale); // Reset placeholders formatting

    // You can add your further processing or submission logic here

    // return false; // Prevent form submission for demonstration
  }
  useEffect(() => {
    if(checkoutId){
      const script = document.createElement('script');
      script.src = `https://oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}`; // Replace with your script URL
      script.async = true;

      script.onload = () => {
        console.log('External script loaded');
        // You can now use functions or variables defined in the external script
      };
  
      document.body.appendChild(script);
      // const form = document.createElement("form");
      // form.action = `${ApiClient}/invoice`;
      // form.setAttribute("class", "paymentWidgets");
      // form.setAttribute("data-brands", "MADA VISA APPLEPAY");
      // formRef.current.appendChild(form);
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [checkoutId]);

  return (
        <>
                {/* <div className="title" data-translate="Payment Method"> Payment Method </div> */}
                {/* <div className="empty" />
                    <div className="wpwl-wrapper">
                            <div className="input-container">
                                <input id="VISA" type="radio" name="radio" defaultChecked={true} onClick={() => updateDataBrands()} />
                                <div className="radio-tile"> 
                                    <img src={VisaImage} alt="logo" />
                                    <label htmlFor="VISA" />
                                </div>
                            </div>
                            <div className="input-container">
                                <input id="STC" type="radio" name="radio" onClick={() => updateDataBrands()} />
                                <div className="radio-tile">
                                    <img src={StcImage} alt="logo" />
                                    <label htmlFor="STC" />
                                </div>process.env.REACT_APP_NEW_BACKEND_URL
                            </div>
                    </div> */}
                <form
                ref = {formRef}
                className="wpwl-form wpwl-clearfix paymentWidgets"
                action={`/payment-status`}
                // onSubmit={() => onSubmit()}
                method="POST" target="cnpIframe" lang="en"
                data-brands= {brands || "VISA MASTER"}
                >
                </form>
        </>
  );
};

export default PaymentForm;
