import errorIcon from "../../Assets/Images/error.svg";
import {
  ToastIcon,
  ToastMessage,
  ToastTitle,
  ToaswtWarpper,
} from "./Toast.style";

const Taost = ({
  success = false,
  error = false,
  message = "",
  title = "",
}) => {
  return (
    <ToaswtWarpper>
      {/* <ToastContainer> */}
      <ToastIcon error={error} success={success}>
        {error ? (
          <img src={errorIcon} alt="error" />
        ) : (
          <img
            src="https://img.icons8.com/color/48/000000/ok.png"
            alt="success"
          />
        )}
      </ToastIcon>
      <div>
        <ToastTitle>{title}</ToastTitle>
        <ToastMessage>{message}</ToastMessage>
      </div>
      {/* </ToastContainer> */}
    </ToaswtWarpper>
  );
};

export default Taost;
