import React, { useEffect, useRef, useState } from 'react';
import { UserContentContainer, UserImage, UserDataContainer, UserDetailsContainer, UserName, UserProfession, Button, StyledImage, LanguageButton, LanguageText, LanguageImage, LanguageImageMobile, NewButtonText, NewButtonArrow, LanguageChangeArrow } from './Header.styles';
import DropdownArrow from '../../Assets/Images/DropdownArrow.svg';
import DropdownArrowWhite from '../../Assets/Images/DropdownArrowWhite.svg';
import AddSymbole from '../../Assets/Images/AddSymbol.svg';
import DummyUserImage from '../../Assets/Images/DummyUserImage.png';
import ArabicLanguageIcon from '../../Assets/Images/ArabicLanguageIcon.svg';
import ArabicLanguageIconMobile from '../../Assets/Images/ArabicLanguageIconMobile.svg';
import EnglishLanguageIcon from '../../Assets/Images/EnglishLanguageIcon.svg';
import EnglishLanguageIconMobile from '../../Assets/Images/EnglishLanguageIconMobile.svg';  
import { useSelector } from "react-redux";
import ApiClient from '../../Config/index.config';
import {useDispatch} from "react-redux";
import {setUserDetails} from "../../Store/Slice/UserDetails/UserDetailsSlice";
import { useLocation } from 'react-router-dom';
import DropdownLanguageChange from './DropdownLanguageChange';
import DropdownAddPDF from './DropdownAdd';
import DropdownUserProfile from './DropdownUserProfile';
import {HeaderLanguage} from "../data/Header.lang";


const UserDetails = ({setCreateFolderModal, setCreateFileModal, languageChangeVisibility, setLanguageChangeVisibility, currentLanguage, setCurrentLanguage}) => {

    const addPDFRef = useRef();
    const userProfileRef  = useRef();
    const userDetails = useSelector((store) => store.userData)
    const langRef = useRef()
    const lang = useSelector((store) => store.lang.language)
    const [newButtonVisibility, setNewButtonvisibility] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [addPDFVisibility, setAddPDFVisibility] = useState(false);
    const [userProfileVisibility, setUserProfileVisibility] = useState(false);
    const dispatch = useDispatch()
    const location = useLocation()

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (addPDFRef.current && !addPDFRef.current.contains(event.target)) {
          setAddPDFVisibility(false);
        }
        if (userProfileRef.current && !userProfileRef.current.contains(event.target)) {
          setUserProfileVisibility(false);
        }
        if (langRef.current && !langRef.current.contains(event.target)) {
          setLanguageChangeVisibility(false);
        }
      };

      document.addEventListener("click", handleClickOutside);

      return () => {
        document.removeEventListener("click", handleClickOutside)
      }
    }, [addPDFRef, userProfileRef]);

    const getData = async () => {
      try{
        const response = await ApiClient.get("/user/profile")
        dispatch(setUserDetails(response?.data?.data?.user));
        setIsLoading(false)
      }catch(error){
        console.log(error)
        setIsLoading(false)
      }
    };

    useEffect(() => {
      const token = JSON.parse(JSON.parse(localStorage.getItem("persist:root"))?.auth)?.user?.data?.access_token;
      if(token !== null){
        getData()
      }
    },[])

    useEffect(() => {
      if(location.pathname === "/upload" || location.pathname === "/" || location.pathname === "/user/profile"){
        setNewButtonvisibility(false)
      }
      else{
        setNewButtonvisibility(true)
      }
    },[location.pathname])


    return (
      <>
        {!isLoading ?
          (
          <UserContentContainer>

                  {/* <LanguageButton ref={langRef} onClick={() => setLanguageChangeVisibility(!languageChangeVisibility)}>
                  {currentLanguage === "English" ?
                      <>
                        <LanguageImage src={EnglishLanguageIcon} alt="english language icon"/>
                        <LanguageImageMobile src={EnglishLanguageIconMobile} alt="english language icon"/>
                      </>
                      :
                      <>
                        <LanguageImage src={ArabicLanguageIcon} alt="arabic language icon"/>
                        <LanguageImageMobile src={ArabicLanguageIconMobile} alt="arabic language icon"/>
                      </>
                      }
                      <LanguageText>{currentLanguage}</LanguageText>
                      <LanguageChangeArrow width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 1L7 7L13 1" stroke="#727272" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                      </LanguageChangeArrow>
                      {languageChangeVisibility ? <DropdownLanguageChange setLanguageChangeVisibility={setLanguageChangeVisibility} currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage}/> : null}
                  </LanguageButton> */}

                  {newButtonVisibility ?
                  <Button ref={addPDFRef} onClick={() => {setAddPDFVisibility(!addPDFVisibility)}}>
                          <img src={AddSymbole} alt='add symbole'/>
                          <NewButtonText>{HeaderLanguage[lang].newButton}</NewButtonText>
                          <NewButtonArrow src={DropdownArrowWhite} alt='dropdown arrow'/>
                          {addPDFVisibility ? <DropdownAddPDF setCreateFolderModal={setCreateFolderModal} setCreateFileModal={setCreateFileModal}/> : null}
                  </Button>
                  : null}

                  <UserDataContainer ref={userProfileRef} onClick={() => {setUserProfileVisibility(!userProfileVisibility)}}>
                          <UserImage src={DummyUserImage} alt='user image' />
                          <UserDetailsContainer>
                                  <UserName>{userDetails.name}</UserName>
                                  <UserProfession>{userDetails.college} </UserProfession>
                          </UserDetailsContainer>
                          <StyledImage  src={DropdownArrow} alt='dropdown arrow'/>
                          {userProfileVisibility ? <DropdownUserProfile/> : null}
                  </UserDataContainer>

          </UserContentContainer>
          )
          :
          null
        }
      </>
      )
}

export default UserDetails



// localStorage.getItem("persist:root")