import styled from "styled-components";
import { device } from "../../../Breakpoints";
export const ButtonBack = styled.div`
  height: 64px;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid #4D2C77;
  background: none;
  box-sizing: border-box;
  font-weight: bold;
  color:#4D2C77;
  cursor:pointer;
  @media ${device.sm}{
    width: 100%;
  }

`
export const ButtonDelete = styled.div`
  height: 64px;
  width: 180px;
  border:none;
  border-radius: 4px;
  background-color: #F55157;
  cursor:pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 18px;
  color: white;
  @media ${device.sm}{
    width: 100%;
  }
`
export const DeleteButtonDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width:100%;
  gap: 1rem;
  @media ${device.sm}{
    flex-direction: column;
  }
`
export const DeleteImg = styled.div`
  width:100%;
  display:flex;
  justify-content:center;
  align-items:center;
`

export const DeleteInnerPopUp = styled.div`
  width: 642px;
  background-color:white;
  box-shadow: 0 4px 32px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  gap: 32px;
  @media ${device.md}{
      width: 580px;
  }
  @media ${device.sm}{
    position: absolute;
    bottom: 0;
    width: 100%;
    border-radius: 24px 24px 0 0;
    height: 380px;
    gap: 16px;
    padding: 16px;
  }
  @media (max-height: 500px){
    margin: 2rem 0 2rem 0;
  }
`
export const DeleteMid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  width:100%;
`

export const DeletePop = styled.div`
  position: fixed;
  background-color: rgba(77, 44, 119, 0.55);
  top: 0;
  left: 0;
  height: calc(100vh);
  right: 0;
  overflow: hidden;
  scroll-behavior: none;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-height: 500px){
    overflow-y: scroll;
    align-items: start;
  }
`

  export const ParaDelete = styled.div`
  text-align:center;
  font-size: 16px;
  color: #727272;
  @media ${device.sm}{
    font-size: 14px;
  }
`
export const TextDelete = styled.div`
  text-align:center;
  font-weight: bold;
  font-size: 2rem;
  @media ${device.sm}{
    font-size: 20px;
  }
`