import styled from "styled-components";
import backImage from '../../Assets/Images/back.png';
import { device, size } from "../../Breakpoints";

export const ResultContainer = styled.div`
    height: calc(100vh - 80px);
    width:100%;
`

export const ResultPage = styled.div`
    height: calc(100vh - 80px);
    width:100%;
    background-image: url(${backImage});
    background-repeat: no-repeat;
    background-size: cover;
    display:flex;
    justify-content:center;
    align-items:center;
    box-sizing: border-box;
    @media ${device.sm} {
        padding: 0 1rem 0 1rem;
        height: calc(100vh - 8rem);
    }
`

export const ResultMidPage = styled.div`
    height:620px;
    width:602px;
    background-color:#FFFFFF;
    display:flex;
    border-radius: 8px;
    align-items: center;
    padding: 2rem 2rem 2rem 2rem;
    box-sizing: border-box;

    @media ${device.sm}{
        width: 100%;
        height: auto;
        padding: 2rem 1rem 2rem 1rem;
    }
`

export const ResultInnerPage = styled.div`
    width:100%;
    background-color:#FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    align-items: center;
`
    
export const ResultTopBar = styled.div`
    height:20%;
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
`

export const ResultHeading = styled.div`
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 8px;
    @media ${device.sm} {
        font-size: 20px;
    }
`

export const ResultSubHeading = styled.div`
    margin: 0px 0px;
    font-size: 16px;
    text-align: center;
    font-weight: 400;
    color: #727272;
    @media ${device.sm} {
        font-size: 14px;
    }
`

export const ResultMid = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    gap: 2rem;
    align-items:center;
`

export const ResultBottom = styled.div`
    width:100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;

    @media ${device.sm} {
        display: none;
    }
`

export const ResultBottomFixed = styled.div`
    width: 100%;
    position: fixed;
    background-color: white;
    padding: 0 1rem 0 1rem;
    box-sizing: border-box;
    height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.4rem;
    bottom: 0;
    border-top: 1px solid #EEEEEE;
    @media (min-width: ${size.sm}) {
        display: none;
    }
    @media ${device.sm} {
        font-size: 20px;
    }
`

export const ResultTopDiv = styled.div`
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const ExamRepeat = styled.div`
    height: 60px;
    width:100%;
    background-color:#4D2C77;
    border-radius: 4px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    cursor:pointer;
    box-sizing: border-box;
`

export const EndButton = styled.div`
    width:100%;
    background-color:#FFFFFF;
    cursor:pointer;
    display: flex;
    text-decoration: underline;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: normal;
`

export const ValueDiv = styled.div`
    width: 100%; 
    display:flex;
    justify-content:space-between;
`

export const StarDiv = styled.div`
    width:100%;
    display:flex;
    margin-top:20px;
    justify-content: center;
    align-items:center;
    gap: 2rem;
    flex-direction:column;
    @media (max-width:466px){
        margin-top:0px;
    }
`

export const Value1 = styled.div`
    height: 122px;
    width: 170px;
    border-radius: 7px 7px 0 0;
    border: 1px solid #CA56A1;
    overflow: hidden;
    /* background: ; */
    /* background: rgb(202,86,161); */
    background: linear-gradient(180deg, rgba(202,86,161,1) 0%, rgba(245,222,236,1) 0%, rgba(248,232,242,1) 0%, rgba(249,234,243,1) 0%, rgba(255,255,255,1) 51%);
    /* border-image: linear-gradient(to bottom, #CA5688, #FFFFFF) 1; */
    border-image-slice: 1;
    border-bottom-width: 0;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media ${device.sm} {
        width: 32%;
    }
`

export const RatingText = styled.div`
    display:flex;
    width:100%;
    justify-content:center;
    align-items:center;
    color: #727272;
    text-align: center;
    @media ${device.sm} {
        font-size: 14px;
    }
`

export const Startdiv1 = styled.div`
    height:40%;
    width: auto;
    display:flex;
    justify-content: center;
    gap: 8px;
    align-items:center;
`

export const StarImage = styled.img`
    cursor: pointer;
    width: 60px;
    height: 60px;
    @media ${device.sm} {
        width: 40px;
        height: 40px;
    }
`

export const DivInner = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    flex-direction:column;
    gap: 1rem;
`
export const DivInnerValue = styled.div`
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    font-size: 20px;
    font-weight: bold;
    gap: 4px;
    @media (max-width: 260px) {
        flex-direction: column;
    }
`

export const InnerText = styled.div`
    width: 100%;
    color: #727272;
    display:flex;
    justify-content:center;
    align-items:center;
    text-align: center;
    @media ${device.sm} {
        font-size: 14px;
    }

`

export const ColorDiv = styled.div`
    height:12px;
    width:12px;
    border-radius:100%;
    background-color: ${props => props.color || '#EEEEEE'};
`