import React, { useEffect } from "react";
import { CiUser } from "react-icons/ci";
import { useState } from "react";
import {
  Head3,
  InputBox,
  Loader,
  Phone2Input,
  ProfileBtn,
  ProfileButton,
  ProfileEdit,
  ProfileForm,
  ProfileTab,
  Profilepersonal,
} from "./Profilestyle";
import { Auth } from "../../Services/api";
import { toast } from "react-toastify";
import Taost from "../Toast/Toast";
import { getErrorMessage } from "../Error/Errorcode";
import { useNavigate } from "react-router-dom";
import { validateEmail } from "../Auth/Validate";
import { Error } from "../Auth/Auth.Style";
import { useSelector } from "react-redux";
import { personalData } from "../data/profile.lang";

const NameEmailForm = () => {
  const [value, setValue] = useState("sa");
  const [name, setNam] = useState("");
  const [updateName, setUpdateName] = useState("");
  const [updatedemail, setUpdatedEmail] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const lang = useSelector((store) => store?.lang?.language);

  useEffect(() => {
    getProfileData();
  }, []);
  const getProfileData = async () => {
    try {
      const data = await Auth.getProfile();
      const { name, email, phone_number } =data?.data?.data?.user;
      setNam(name);
      setUpdateName(name);
      setEmail(email);
      setUpdatedEmail(email);
      const phone_numberdata = phone_number.replace(/^/, "+966");
      setPhone(phone_numberdata);
    } catch (error) {
      const errorcode = error?.response?.data?.error_code;
      const message = getErrorMessage(errorcode,lang);
      toast(<Taost message={message} error />);
    }
  };

  const handlesubmit = async (e) => {
    try {
      e.preventDefault();
      const data = {};
      if (name !== updateName) {
        data.name = name;
      }
      if (email !== updatedemail) {
        data.email = email;
      }

      const response = await Auth.updateProfile(data);
      getProfileData();
      toast(<Taost message="Profile updated successfully" />);
      // navigate("/");
    } catch (error) {
      const errorcode = error?.response?.data?.error_code;
      const message = getErrorMessage(errorcode,lang);
      toast(<Taost message={message} error />);
    }
  };

  useEffect(() => {
    if (name === updateName && email === updatedemail) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [name, updateName, email, updatedemail]);

  const handleNameChange = (e) => {
    setNam(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  useEffect(() => {
    if (!email || email === "") {
      setErrorMessage("");
      return;
    }
    const message = validateEmail(email);
    if (message) {
      setErrorMessage(message);
    } else {
      setErrorMessage("");
    }
  }, [email]);

  if (updateName === "") {
    return (
      <ProfileEdit>
        <ProfileForm>
          <Loader />
        </ProfileForm>
      </ProfileEdit>
    );
  }
  return (
    <ProfileEdit>
      <ProfileForm>
        <Head3>{personalData[lang]?.personalData}</Head3>
        <InputBox>
          <input
            type="text"
            required
            value={name}
            onChange={(e) => handleNameChange(e)}
          />
          <label>{personalData[lang]?.Name}</label>
        </InputBox>
        <InputBox>
          <input
            type="Email"
            required
            value={email}
            onChange={(e) => handleEmailChange(e)}
          />
          <label>{personalData[lang]?.email}</label>
        </InputBox>
        {errorMessage && <Error>{errorMessage}</Error>}
        <Phone2Input
          country={value}
          value={phone}
          onChange={(data) => setValue(data)}
          placeholder="Enter Number"
          disabled={true}
          countryCodeEditable={false}
          lang={lang}
          disableDropdown={true}
        />
        <ProfileButton
          disabled={isButtonDisabled}
          onClick={(e) => handlesubmit(e)}
          lang={lang}
        >
          {personalData[lang]?.button}
        </ProfileButton>
        <ProfileButton modified onClick={() => navigate("/")} lang={lang}>
          {personalData[lang]?.cancel}
        </ProfileButton>
      </ProfileForm>
    </ProfileEdit>
  );
};

export default NameEmailForm;
