import React, { useState } from "react";
import {
  CardBack,
  CardWrapper,
  StoriesContainer,
  StoryCardHeading,
  StoryCardSub,
  StoryCardText,
  StoryCards1,
  StoryCards2,
  StoryHeading,
  StorySubheading,
  StoryTitles,
} from "./stories.styles";
import {
  AboutAnimation,
  AboutIn,
  ScholarI,
  Annot,
  AboutTop,
  AboutTopTextHeading,
  AboutTopTextSub,
  AboutBottomImage,
  AboutBottomLeft,
} from "../Cards/card1.styles";
import { media } from "../../Assets/Styles/utils";
import backleft from "../../Assets/Images/Vectorstoryback.png";
import backright from "../../Assets/Images/Vectorstoryrightback.png";
import annot from "../../Assets/Images/annot.png";
import scholarpink from "../../Assets/Images/Iconscholar.png";
import time from "../../Assets/Images/idea 2.png";
import light from "../../Assets/Images/light.png";
import mind from "../../Assets/Images/mind.png";
import read from "../../Assets/Images/read.png";
import profiles from "../../Assets/Images/Frame 1000002728test-image.png";
import tl from "../../Assets/Images/Vectortop left.png";
import al from "../../Assets/Images/Vectorarrow-left.svg";
import { useSelector } from "react-redux";
import { Aboutlang, Storieslang } from "../data/Home.lang";
import { ButtonImage, ButtonStyled, ButtonText } from "../Buttons/Buttons.styles";
import { useNavigate } from "react-router-dom";
function Stories() {

  const lang = useSelector((state) => state?.lang?.language);
  const [isMouseEntered5, setIsMouseEntered5] = useState(false);
  const isAuthanticated = useSelector((state) => state?.auth?.isAuthanticated);
  const navigate=useNavigate();

  const handleclick=()=>{
    if(isAuthanticated){
      navigate("/library");
    }
    else{
      navigate("/user/login");
    }
    
  }

  return (
    <>
    <StoriesContainer>
      <StoryTitles>
      <StoryHeading>
      <span style={{color: " #CA56A1", fontWeight: "bold", fontSize: "2.5rem"}}>
  تعلم بتميز
</span><br/>

   </StoryHeading>
        <StorySubheading>
      {"نبدأ معاك من المرحلة الثانوية ونستمر معاك خلال رحلتك الجامعية"}<span className="Break"><br/></span>
      {" وبالنهاية نهيئك لتحصل على الوظيفة بكل تميز"}</StorySubheading>
      </StoryTitles>
      <StoryCards1>
        
        <CardWrapper>
          <img src={time} alt="clock img" lang={lang} />
          <StoryCardText lang={lang}>
            <StoryCardHeading>
              {Storieslang[lang]?.card1subhead}
            </StoryCardHeading>
          
            
          </StoryCardText>
        </CardWrapper>
        <CardWrapper>
          <img src={light} alt="light img" lang={lang} />
          <StoryCardText lang={lang}>
            <StoryCardHeading>
              {Storieslang[lang]?.card2subhead}
            </StoryCardHeading>
           
          </StoryCardText>
        </CardWrapper>
      </StoryCards1>
      <StoryCards1>
        <CardWrapper>
          <img src={read} alt="read image"  lang={lang}/>
          <StoryCardText lang={lang}>
            <StoryCardHeading>
              {Storieslang[lang]?.card3subhead}
            </StoryCardHeading>
            
          </StoryCardText>
        </CardWrapper>
        <CardWrapper>
          <img src={mind} alt="mind image" lang={lang} />
          <StoryCardText lang={lang}>
            <StoryCardHeading>
              {Storieslang[lang]?.card4subhead}
            </StoryCardHeading>
           
          </StoryCardText>
        </CardWrapper>
      </StoryCards1>
      
    </StoriesContainer>
    <div style={{display:"flex",justifyContent:"center"}}>
      <ButtonStyled
          onMouseOver={() => setIsMouseEntered5(true)}
          onMouseOut={() => setIsMouseEntered5(false)}
          onClick={()=>handleclick()}
        >
          <ButtonText>{Aboutlang[lang]?.start}</ButtonText>
          <ButtonImage>
            <img
              className={`image-transition ${isMouseEntered5 ? "on-over" : ""}`}
              src={isMouseEntered5 ? tl : al}
              alt="image"
            />
          </ButtonImage>
        </ButtonStyled>
      </div>
    </>
  );
}

export default Stories;
