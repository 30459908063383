export const publicLang = {
    ar:{
      Header:"",
      span:"ذاكر بتميز لا تذاكر بجهد",
      Header2:"",
      decription:"اختر مرحلتك وتخصصك الدراسي و استفيد من ملفات زملائك في المكتبة العامة",
      head3:"أحدث الملخصات",
      desc3:"أنت عليك ترفع ملفك و الذكاء الاصطناعي مهمته يلخص لك ",
      head4:"الأكثر تفاعلاً",
      desc4:"",
      error:"لا توجد بيانات لهذا البحث",
      nodata:"لا توجد بيانات"
    },
    en:{
      Header:"Achieve Academic Success",
      span:"with Excellence",
      Header2:"Your Journey Towards Outstanding Grades",
      decription:"Explore the world of knowledge with our outstanding educational website and discover a variety of courses and educational resources designed to achieve your educational goals.",
      head3:"Latest Summaries",
      desc3:"Enjoy a customized learning adventure with excellence, benefit from comprehensive resources and distinguished courses, and achieve academic success with us. Explore unique opportunities and prepare for your bright future!",
      head4:"Most Interactive",
      desc4:"Enjoy a customized learning adventure with excellence, benefit from comprehensive resources and distinguished courses, and achieve academic success with us. Explore unique opportunities and prepare for your bright future!",
      error:"No Data Found for this search",
      nodata:"No Data Found "
    }
    
}