import styled, { keyframes } from "styled-components"
import UploadPDFBackground from "../../../Assets/Images/UploadPDFBackground.svg"
import { device } from "../../../Breakpoints"


export const UploadModalContainer = styled.div`
    position: fixed;
    background-color: rgba(77, 44, 119, 0.55);
    top: 0;
    left: 0;
    height: calc(100vh);
    right: 0;
    bottom: 0;
    overflow: hidden;
    scroll-behavior: none;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const UploadModalSubContainer = styled.div`
    width: 45rem;
    height: 35rem;
    padding : 2rem 1rem 2rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: start;
    align-items: center;
    background-color: rgba(252, 245, 249, 1);
    border-radius: 8px;
    box-sizing: border-box;
    background-image: url(${UploadPDFBackground});
    @media ${device.md} {
        width: 35rem;
    }
    @media ${device.sm} {
        position: absolute;
        bottom: 0;
        width: 100%;
        border-radius: 24px 24px 0 0;
        height: 30rem;
    }
`

export const UploadSectionModal = styled.div`
    width: auto;
    min-height: 21rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    margin-top: 4rem;
    animation: ${(props) => props.animationvisible ? moveUp : null} 0.5s linear forwards;
    /* margin-top: ${(props) => props.animationVisible ? '2rem' : "4rem"}; */
    @media ${device.sm} {
        margin-top: 2rem;
    }
`

const moveUp = keyframes`   
  0% {
    margin-top: 4rem;
  }
  100% {
    margin-top: 1.2rem;
  }
`;

export const UploadSectionPrimaryModal = styled.div`
    width: 26rem;
    height: auto;
    background-color: white;
    border-radius: 8px;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 1rem 1rem 1rem 1rem;
    @media ${device.sm} {
        width: 23rem;
    }
    @media ${device.xs} {
        width: 20rem;
    }
`

export const UploadSectionSecondaryModal = styled.div`
    width: 100%;
    height: 15rem;
    background-color: #FAFAFA;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media ${device.sm} {
        height: 13rem;
    }
`

export const UploadLabelModal = styled.label`
    width: 30rem;
    height: 90%;
    background-color: #FAFAFA;
    border: 1px dashed #4D2C77;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    color: #989898;
    margin: 1rem 1rem 1rem 1rem;
    gap: 1rem;
    cursor:pointer;
    overflow: hidden;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    display: ${props => props.visibility === 'hidden' ? 'none' : null};
    @media ${device.sm} {
        font-size: 12px;
    }
`

export const StyledButtonModal = styled.div`
    background-color: #4D2C77;
    width: 100%;
    color: white;
    height: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    gap:  12px;
    pointer-events: ${props => props.dissablebutton === "true" ? "none" : null};
`