import {useDispatch, useSelector} from "react-redux";
import {ButtonsSection,CardContainer,CardOuterContainer,CountNumber,FolderDetails,FolderName,IconContainer,MoreIconImage,ShareIconImage,} from "../Library/Library.styles";
import MoreIcon from "../../Assets/Images/MoreIcon.svg";
import SmallFolderIcon from "../../Assets/Images/SmallFolderIcon.svg";
import ShareIcon from "../../Assets/Images/ShareIcon.svg";
import FolderIcon from "../../Assets/Images/FolderIcon.svg";
import FileIcon from "../../Assets/Images/FileIcon.svg";
import { useNavigate } from "react-router-dom";
import { emptyRoutesArray, removeRoutesArray, updateRoutesArray } from "../../Store/Slice/Navigation/NavigationSlice";
import { useRef, useState } from "react";
import FacebookShare from '../SocialShare/FaceBookShare';
import TwitterShare from '../SocialShare/TwitterShare';
import { OptionsModal } from "./public.style";
import WhatsappShare from "../SocialShare/WhatsappShare";
import CopyIcon from '../../Assets/Images/CopyIcon.svg';
import eyeimg from "../../Assets/Images/eye.png";
import ApiClient from "../../Config/index.config";
import { setLibraryData } from "../../Store/Slice/LibraryData/LibraryDataSlice";
import Taost from "../Toast/Toast";
import { toast } from "react-toastify";
import {LibraryCardLanguage} from "../../Components/data/LibraryCard.lang"


function LibraryBlogCard({ data, activeShare,activeFile, setActiveShare, setActiveFile, moreOptions,shareModal, setShareModal}) {

  const lang = useSelector((store) => store?.lang?.language);
  const [loading,setLoading]=useState(true);
  const navigate =useNavigate();
  const dispatch = useDispatch();
  const activeRef = useRef();
 const shareRef = useRef();
 const [copyModal, setCopyModal] = useState(false);
 const NavigationData = useSelector((store) => store?.navigationData?.routesArray);
const isAuthanticated = useSelector((store) => store?.auth?.isAuthenticated);

  const handleClick = (data) => {
    if(isAuthanticated){
    if( data.type === "document"){
      dispatch(emptyRoutesArray());
      dispatch(updateRoutesArray({data: LibraryCardLanguage[lang]?.publicLibraryNavigation, id:""}))
      dispatch(updateRoutesArray({data: data.name, id:data.id}))
      navigate(`/flash-card/${data.id}`)
      navigate(`/flash-card/${data.id}`, { state: { s3_link: data.s3_link }})
    }
    else{
      if( data.type === "folder"){
        dispatch(emptyRoutesArray());
        dispatch(removeRoutesArray());
        dispatch(updateRoutesArray({data: LibraryCardLanguage[lang]?.publicLibraryNavigation, id:""}))
        dispatch(updateRoutesArray({data: data.name, id:data.id}))
        setLoading(true)
        getData(data.id)
        navigate(`/library/?id=${data.id}`,{ state: {data: data.name,  id: data.id } })
      }
    }
  }else{
    toast(<Taost message={"تسجيل الدخول للمتابعة"} error />);
    navigate('/user/login')
  }
  }

  
  const getData = async (data, id, navigationBack) => {
    window.scrollTo(0, 0, {behaviour : "smooth"})
   
    try{
      const response = await ApiClient.get(`/library/` + id)
      // setLibraryContent(response?.data?.data?.library)
      dispatch(setLibraryData({data: response?.data?.data?.library, id: id}))
      if((data !== null && !navigationBack) || (id !== null && !navigationBack)){
        navigationArray(data, id)
      }
      setLoading(false)
    }catch(error){
      // if(error?.response?.data?.error_code === 1019){
      //   dispatch(setLibraryData({data: [], id: id}))
      //   navigationArray(data, id)
      //   setLibraryContent([]);
      // }
      // if(error?.response?.data?.error_code === 1021){
      //     navigate(`/flash-card/${id}`)
      // }
      setLoading(false)
    }
  }

  const navigationArray = (data, id) => {
    if(NavigationData.find((x) => x.id === id)){
      let x = NavigationData.length-1;
        while(NavigationData[x].id !== id){
          dispatch(removeRoutesArray());
          x--;
        }
    }else{ 
        dispatch(updateRoutesArray({data: data, id:id}))
    }
  }

  const handleShare = (e, data) => {
    e.stopPropagation();
    setActiveFile(null);
    if(shareModal){
        setShareModal(!moreOptions);
    }
    if(activeShare && activeShare.id === data.id){
        setActiveShare(null)
    }
    else{
        setActiveShare(data)
    }
}

function getFirstPart(location){
  let parts = location.split('/');
  parts = parts.at(0) + "://" + parts.at(2);
  return parts;
}

const handleCopy = (e, id) => {
  e.stopPropagation();
  navigator.clipboard.writeText(getFirstPart(window.location.href) + "/flash-card/" + id);
  setActiveShare(null)
  setCopyModal(true)
}



  return (
    <CardOuterContainer>
      <CardContainer  onClick={() => handleClick(data)}>
        <IconContainer>
          {data.type === "folder" ? (<img src={FolderIcon} alt="folder icon" /> ) : (<img src={FileIcon} alt="file icon" />)}
          {/* <MoreIconImage id={data.id} src={MoreIcon}alt="more icon" /> */}
        </IconContainer>
        <FolderDetails>
          <FolderName>{data.name}</FolderName>
        </FolderDetails>
        <ButtonsSection>
          <ShareIconImage lang={lang} ref={shareRef} onClick={(e) => handleShare(e, data)} src={ShareIcon} alt="small folder icon"  />
            <CountNumber lang={lang}>
              <img src={eyeimg} alt="view" />
              <span>{data.view_count}</span>
            </CountNumber>
        </ButtonsSection>
      </CardContainer>
       {activeShare && activeShare.id === data.id && data.type=== 'document' ? 
          <OptionsModal lang={lang} id={data.id} insideShare="true">
              <WhatsappShare pdfName={data.name} url={window.location.href}  />
              {/* <FacebookShare pdfName={data.name} url={window.location.href} onClick={(e) => e.stopPropagation()}/> */}
              <TwitterShare pdfName={data.name} url={window.location.href} onClick={(e) => e.stopPropagation()}/>
              <img style={{cursor:'pointer'}} onClick={(e) => handleCopy(e, data.id)} src={CopyIcon} alt="copy icon" />
              </OptionsModal > 
              :null
        }
    </CardOuterContainer>
  );
}

  export default LibraryBlogCard