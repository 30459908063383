import { authlang, passwordlang } from "../data/auth.lang";

export const validateEmail = (email, lang) => {
  const isEmailValid = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/.test(
    email
  );

  if (!isEmailValid) return authlang[lang].validatemsgemail;
  return null;
};

export const validatePassword = (password, lang) => {
  if (password.length > 4) {
    const errors = {};
    if (password.length < 8) {
      errors.lengthError = passwordlang[lang]?.lengthError;
    }
    if (!password.match(/[A-Z]/)) {
      errors.uppercaseError = passwordlang[lang]?.uppercaseError;
    }
    if (!password.match(/[0-9]/)) {
      errors.numberError = passwordlang[lang]?.numberError;
    }
    if (!password.match(/[@$!%*?&]/)) {
      errors.specialCharError = passwordlang[lang]?.specialCharError;
    }
    return errors;
  }
  return null;
};
//filter search data
export const filterData = (SearchText, alldata) => {
  const filtereddata = alldata
    .map((res) => res)
    .filter((data) => String(data).toLowerCase().includes(SearchText.toLowerCase()));
  return filtereddata;
};
export const filterData2 = (SearchText, alldata) => {
  const filtereddata = alldata
    .map((res) => res)
    .filter((data) => String(data).toLowerCase().includes(SearchText.toLowerCase()));
  return filtereddata;
};
