import React, { useEffect, useState } from 'react'
import { Error, Interactivedatacontainer, Interactivedatawrapper, LibPublic, Librarydatacontent, Librarydataheader, OptionsModal } from './public.style'
import LibraryBlogCard from './LibraryBlogCard';
import { useSelector } from "react-redux";
import { home } from "../../Services/api";
import { publicLang } from '../data/public.lang';
import { getErrorMessage } from '../Error/Errorcode';
import { toast } from 'react-toastify';
import Taost from '../Toast/Toast';
import FacebookShare from '../SocialShare/FaceBookShare';
import TwitterShare from '../SocialShare/TwitterShare';

const Interactivedata = ({activeFile,setActiveFile,activeShare,setActiveShare,moreOptions,setMoreOptions}) => {
  const lang=useSelector((store)=>store?.lang?.language);
  const [LibraryContent, setLibraryContent] = useState([]);

  const fetchdata = async () => {
    try {
      const data = await home.getdata();
      setLibraryContent(data?.data?.data?.library);
    } catch (error) {
      setLibraryContent([]);
      const message = getErrorMessage(error?.response?.data?.error_code, lang);
    toast(<Taost message={message} error />);
    }
  };
  useEffect(() => {
    fetchdata();
  }, []);
  
  
  return (
    <Interactivedatacontainer>
        <Interactivedatawrapper>
            <Librarydataheader>
              {publicLang[lang].head4}
            </Librarydataheader>
            <Librarydatacontent>
              {publicLang[lang].desc4}
            </Librarydatacontent>
         <LibPublic showScrollbar={LibraryContent?.length<4}> 
            {LibraryContent === null && <Error>{publicLang[lang]?.nodata}</Error>} 
            {LibraryContent?.length !== 0 &&
              LibraryContent?.map((data, index) => {
                return (
                <div key={index}>
                  <LibraryBlogCard data={data} activeShare={activeShare} setActiveShare={setActiveShare} activeFile={activeFile} setActiveFile={setActiveFile} moreOptions={moreOptions} setMoreOptions={setMoreOptions} />
                </div>
               );
            })}
          </LibPublic>
        </Interactivedatawrapper>
    </Interactivedatacontainer>
  )
}




export default Interactivedata