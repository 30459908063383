import styled from 'styled-components';
import { media } from '../../Assets/Styles/utils';

export const CardContainer=styled.div`
position: relative;
align-items: center;
img{
    position:'absolute' ,
    top:"20%",
    right:calc(50% - ),
    zIndex:"-100"
}
${media('medium')}{
    img{
        display: none;
    }
}
`;
export const ResponsiveImage = styled.img`
  position: absolute;
  top: 20%;
  right: 26%;
  z-index: -100;
  width: 50%;

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const Cardwrapper=styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-wrap: wrap;
width: 100%;
margin: 0 auto;
${media('medium')}{
    width: 100%;
    flex-direction: column;
}
`;
export const Card=styled.div`
width: 25%;
align-items: center;
display: flex;
flex-direction: column;
margin: 20px;
h3{
    font-size: 1.3rem;
    color: #333;
    text-align: center;
    margin-top: 20px;
    line-height: 38.4px;
    margin-bottom: 0;
   
}
p{
    font-size: 1rem;
    color: #666;
    text-align: center;
    line-height: 25.6px;
}

${media('medium')}{
    width: 70%;
}
${media('small')}{
    width: 90%;
}
    
`;
