  import 'swiper/css';
  import { useState,useEffect,useRef } from 'react';
  import 'swiper/css/navigation';
  import 'swiper/css/pagination';
  import 'swiper/css/scrollbar';
  import { Autoplay, Navigation, Pagination, Scrollbar } from 'swiper/modules';
  import { Swiper, SwiperSlide } from 'swiper/react';
  import 'swiper/swiper-bundle.css'; // Import the main Swiper CSS file
  import {
    FlashInner1,
    FlashStart,
    FlashStartInner,
    FlashStartLearning,
    FlashStartMid,
    FlashStartTop,
    GuidelinesDiv,
    OverButton,
    SpaceBetween,
    StartJourneyButton,
    GuidelinesUpper,
    GuidelinesLower1,
    StartImgdiv,
    StartText,
    ButtonMix,
    ProgressTap,
    StartImg,
    SwiperContainer
  } from '../FlashCardLearning/StartLearn.styles';
  import { useSelector } from 'react-redux';

  export const Slider = ({ slides }) => {
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const [progressWidth, setProgressWidth] = useState(0);
    const lang = useSelector((state) => state?.lang?.language);
    const swiperRef = useRef(null);

    useEffect(() => {
      if (swiperRef.current) {
        swiperRef.current.swiper.on('slideChange', () => {
          setCurrentSlideIndex(swiperRef.current.swiper.activeIndex);
        });
      }
    }, [swiperRef]);
    // const filteredArray = slides?.filter(obj => obj.lang === lang);
    console.log(slides[lang].image)


    useEffect(() => {
      const totalSlides = slides.length;
      const widthPerSlide = 100 / totalSlides;
      const newProgressWidth = widthPerSlide * (currentSlideIndex + 1);
      setProgressWidth(newProgressWidth*2);
    }, [currentSlideIndex, slides]);

    return (
      <FlashStartInner className="swiper-container">
      <Swiper
      ref={swiperRef}
      style={{
        width:"100%",
        height:"100%",
        backgroundColor:"white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
        modules={[Navigation, Pagination, Scrollbar,Autoplay]}
        spaceBetween={70}
        slidesPerView={1}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        // pagination={{
        //   clickable: true,
        //   el: '.swiper-pagination',
        //   // renderBullet: function (index, className) {
        //   //   return `<span class="${className}"></span>`;
        //   // },
        // }}
      >
        {/* <div className="swiper-pagination"></div> */}
        
        {slides[lang].map((slide, index) => (
          
          <SwiperSlide key={slide.image} style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent:"center"}}>
              <StartImg src={slide.image} alt=""/>
              <StartText lang={lang}> {slide.title} </StartText>
          </SwiperSlide>
        ))}
      </Swiper>
      <ProgressTap lang={lang} >
      <div style={{ width: `${progressWidth}%`,background:"#CA56A1",height:"7px"}}></div>
      </ProgressTap>
      </FlashStartInner>
    );
  };
