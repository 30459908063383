import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    isVisible: false,
}

const SubscriptionModalSlice = createSlice({
    name:'subscriptionVisibility',
    initialState,

    reducers:{
        setVisibility:(state, action) => {
            state.isVisible = action.payload;
        },
    }
})

export const {setVisibility} = SubscriptionModalSlice.actions;
export default SubscriptionModalSlice.reducer;