import React, { useState ,useEffect} from "react";
import { MdNavigateNext } from "react-icons/md";
import { GrFormPrevious } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FlashCardReportFlag from "../../Assets/Images/FlashCardReportFlag.svg";
import FlashCardExplainIcon from "../../Assets/Images/FlashCardExplainIcon.svg";
import FlashCardExplainCloseIcon from "../../Assets/Images/FlashCardExplainCloseIcon.svg";
import FlashCardSeeIcon from "../../Assets/Images/FlashCardSeeIcon.svg";
import FlashCardSeeCloseIcon from "../../Assets/Images/FlashCardSeeCloseIcon.svg";
import { UserUpdateResponse } from "../../Store/Slice/FlashCards/GetFlashCardsSlice";
import { TestData, TestLower, TestNumberDiv, TestUpper } from "../FlashCardQuiz/Quiz.styles";
import CircularProgress from "./CircularProgress";
import {
  ButtonDiv,
  HeaderButton,
  RightMaster,
  RightNeed,
  LoadingArea,
  RightReport,
  StartContainer,
  StartHead,
  StartLearnDiv,
  StartLearnParaDiv,
  StartMid,
  FlipDiv,
  ButtonCombine,
  ButtonDivFixed,
  ChatbotContainer,
  ChatbotContainerFixed,
  ExplanationContainer,
  AnswerContainer,
  QuestionContainer,
  HeaderButtonsContainer,
  LoadingAnimation,
} from "./StartLearn.styles";
import Loader from '../Loader/Loader.js';
import TopBar from "./TopBar";
import { FlashCardsLearning } from '../data/FlashCardLearning.lang'
import MyChatbot from "../ChatBot/Bot.js";
import ApiClient from "../../Config/index.config.js";
import ExhausteModel from "../Modals/ExhaustModals/ExhaustModals.js";
import SubscriptionModal from "../Modals/SubscriptionModal/SubscriptionModal.js";

const StartLearn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentFlashCardIndex, setcurrentFlashCardIndex] = useState(0);
  const CardData  = useSelector(state => state?.FlashCards?.CardData);
  
  const [loading, setLoading] = useState(false);
  const document_id  = useSelector(state => state?.FlashCards?.document_id);
  const botSubcriptionVisibility  = useSelector(state => state?.subscriptionVisibility?.isVisible);
  const [data, setData] = useState(CardData);
  const serializedData = localStorage.getItem('FlashCards');
  const data1 = JSON.parse(serializedData);
  const StatsElements = data1?.stats; 
  let length = StatsElements?.unvisited + StatsElements?.needs_review + StatsElements?.mastered;
  const [borderPercentage,setBorderPercentage] = useState(100/(length));
  const [enable,setenable] = useState(true);
  const lang = useSelector((state) => state?.lang?.language);
  const [explanationData, setExplanationData] = useState(null)
  const [explanationLoading, setExplanationLoading] = useState(false);
  const [LimitExhaustModal, setLimitExhaustModal] = useState(false);
  const [explanVisibility, setExplanVisibility] = useState(false);
  const [flipped, setFlipped] = useState(false);
  const [subscriptionVisibility, setSubscriptionVisibility] = useState(false)

  const exhaustModalData = 
    [   
        // Flash Cards Exhausted Data
        {LimitHeading: {ar: "المحاولات المجانية اليومية", en: "Daily free trails"},
        Limit: {ar: "10", en: "10"},
        Heading: {ar: "لقد تم استنفاد التفسير المجاني", en: "The free explanation have been exhausted"},
        SubHeading: {ar: "للاستفادة والاستمتاع بالشرح اللامتناهي يجب عليك الاشتراك", en: "To enjoy the endless explanation, you must subscribe to"},
        Mumayaz: {ar: "غير محدود", en: "unlimited"},
        Free: {ar: "10 م.ب / الملف", en: "10 attempts/day"},
        Advantage: {ar: "توضيح", en: "Explanation"}},
    ]

  useEffect(() => {
    setData(CardData);
  }, [CardData]);

  const [User_Res,setres] = useState({
    document_id:document_id,
    id:'',
    response:''
  })

  const [update,setupdate] = useState({
    masterCount : 0,
    needReviewCount : 0,
    unvisitedCount :0 
  })


  const handleAnswerClick = async (value,id) => {
    setLoading(true);
    setExplanVisibility(false)
    setFlipped(false);
    setenable(false);
    if(value === 'unvisited'){
      let UpdatedBorder = borderPercentage + 100/(data?.length);
      setupdate(prevState => ({
        ...prevState,
        unvisitedCount: prevState.unvisitedCount + 1
      }));
      if (currentFlashCardIndex + 1 < data?.length) {
        setcurrentFlashCardIndex(currentFlashCardIndex + 1);
        setBorderPercentage(UpdatedBorder);
        setenable(true);
        setLoading(false);
      }
    }
    else{
      let UpdatedBorder = borderPercentage + 100/(data?.length);
      const updatedRes = {...User_Res, id: id, response: value}; 
      await setres(updatedRes);
      let response = await dispatch(UserUpdateResponse(updatedRes));
      if(response?.data?.message === 'Success'){
        if (value === 'mastered') {
          setupdate(prevState => ({
            ...prevState,
            masterCount: prevState.masterCount + 1
          }));
        }
        if (value === 'needs_review') {
          setupdate(prevState => ({
            ...prevState,
            needReviewCount: prevState.needReviewCount + 1
          }));
        }
      }
      if (currentFlashCardIndex + 1 < data?.length) {
        setcurrentFlashCardIndex(currentFlashCardIndex + 1);
        setBorderPercentage(UpdatedBorder);
        setenable(true);
        setLoading(false);
      }
    }
  };

  useEffect(()=>{
    setLoading(false);
      if(data?.length === currentFlashCardIndex + 1 && update?.masterCount + update?.needReviewCount + update?.unvisitedCount === data?.length){
        navigate('/Flash/Result',{state:{Update:update}});
      }
  },[update])

  const handleFlip = () => {
      setFlipped(!flipped);
  };

  const getExplanation = async (currentFlashCardIndex) => {
    try{
      const response = await ApiClient.get(`/flash-card/${CardData[currentFlashCardIndex].pdf_id}/${CardData[currentFlashCardIndex].id}/explain`)
      setExplanationData(response.data.data.explanation);
    }catch(error){
      console.log(error)
      if(error?.response?.data?.error_code === 1043){
        setLimitExhaustModal(true);
        setExplanVisibility(false)
      }
    }
    setExplanationLoading(false)
  }
  
  const handleExplain = (currentFlashCardIndex) => {
    if(!explanVisibility){
      setExplanVisibility(true)
      setExplanationLoading(true);
      getExplanation(currentFlashCardIndex)
    }
    else{
      setExplanVisibility(false)
    }
  }

  return (
    <StartContainer >
      <ChatbotContainer>
        <MyChatbot/>
      </ChatbotContainer>
      <ChatbotContainerFixed>
        <MyChatbot fixed="true"/>
      </ChatbotContainerFixed>
      <TopBar/>
      {
        data?
        <StartMid>
        <StartLearnDiv>
            <StartHead>
              <TestData>
                <TestUpper>{FlashCardsLearning[lang].UpperText}</TestUpper>
                <TestLower>
                  {FlashCardsLearning[lang].lowerText}
                </TestLower>
              </TestData>
              <TestNumberDiv lang={lang}>
                <CircularProgress currentFlashCardIndex={currentFlashCardIndex} length={data?.length} percentage={borderPercentage}/>
              </TestNumberDiv>
            </StartHead>
            <FlipDiv flipped={flipped}>
              <QuestionContainer>
                  <HeaderButtonsContainer>
                    <HeaderButton onClick={handleFlip} style={{color: "#4D2C77"}}>
                      <img src={FlashCardSeeIcon} alt=""/>
                      {FlashCardsLearning[lang].Answer}
                    </HeaderButton>
                    <HeaderButton onClick={() => handleExplain(currentFlashCardIndex)} style={{color: "#FFAF44"}}>
                      {explanVisibility ? 
                        <img src={FlashCardExplainCloseIcon} alt=""/>
                        : <img src={FlashCardExplainIcon} alt=""/>
                      }
                      {FlashCardsLearning[lang].Explanation}
                    </HeaderButton>
                    <HeaderButton>
                      <img src={FlashCardReportFlag} alt=""/>
                      {FlashCardsLearning[lang].Report}
                    </HeaderButton>
                  </HeaderButtonsContainer>
                  {
                    loading?
                    <LoadingArea>
                        <Loader/>
                    </LoadingArea>
                  :
                  <StartLearnParaDiv explanVisibility={explanVisibility} onClick={handleFlip}>
                      <div style={{marginBottom: "1rem"}}>
                        {data?data[currentFlashCardIndex]?.title:''}
                      </div>
                      {explanVisibility && !flipped?
                        <ExplanationContainer>
                          { explanationLoading ? <LoadingAnimation></LoadingAnimation> : explanationData }
                      </ExplanationContainer>
                      : null}
                  </StartLearnParaDiv>
                  }
              </QuestionContainer>
              <AnswerContainer>
                  <HeaderButtonsContainer>
                    <HeaderButton onClick={handleFlip} style={{color: "#4D2C77"}}>
                      <img src={FlashCardSeeCloseIcon} alt=""/>
                      {FlashCardsLearning[lang].Answer}
                    </HeaderButton>
                    <HeaderButton onClick={() => handleExplain(currentFlashCardIndex)} style={{color: "#FFAF44"}}>
                      {explanVisibility ? 
                        <img src={FlashCardExplainCloseIcon} alt=""/>
                        : <img src={FlashCardExplainIcon} alt=""/>
                      }
                      {FlashCardsLearning[lang].Explanation}
                    </HeaderButton>
                    <HeaderButton>
                      <img src={FlashCardReportFlag} alt=""/>
                      {FlashCardsLearning[lang].Report}
                    </HeaderButton>
                  </HeaderButtonsContainer>
                  {
                    loading?
                    <LoadingArea>
                      <Loader/>
                    </LoadingArea>
                    :
                    <StartLearnParaDiv explanVisibility={explanVisibility} onClick={handleFlip}>
                      <div style={{marginBottom: "1rem"}}>
                        {data?data[currentFlashCardIndex]?.description:''}
                      </div>
                      {explanVisibility && flipped?
                        <ExplanationContainer>
                            { explanationLoading ? <LoadingAnimation></LoadingAnimation> : explanationData}
                        </ExplanationContainer>
                      : null}
                    </StartLearnParaDiv>
                  }
              </AnswerContainer>
            </FlipDiv>
          <ButtonDiv>
                <RightReport lang={lang} onClick={enable ? () => handleAnswerClick("unvisited",data[currentFlashCardIndex]?.id) : undefined}>
                  {FlashCardsLearning[lang].Skip}
                </RightReport>
                <ButtonCombine>
                    <RightNeed lang={lang} onClick={enable ? () => handleAnswerClick("needs_review",data[currentFlashCardIndex]?.id) : undefined}>
                      {FlashCardsLearning[lang].NeedReview}
                    </RightNeed>
                    <RightMaster lang={lang} onClick={enable ? () => handleAnswerClick("mastered",data[currentFlashCardIndex]?.id) : undefined}>
                      {FlashCardsLearning[lang].Completed}
                      {
                        lang === "en"?
                        <MdNavigateNext style={{fontSize:"25px"}}/>
                        :
                        <GrFormPrevious style={{fontSize:"25px"}}/>
                      }
                    </RightMaster>
              </ButtonCombine>
          </ButtonDiv>
        </StartLearnDiv>
      </StartMid>
      :
      null
      }
      <ButtonDivFixed>
                <RightReport lang={lang} onClick={enable ? () => handleAnswerClick("unvisited",data[currentFlashCardIndex]?.id) : undefined}>
                  {FlashCardsLearning[lang].Skip}
                </RightReport>
                <ButtonCombine>
                    <RightNeed lang={lang} onClick={enable ? () => handleAnswerClick("needs_review",data[currentFlashCardIndex]?.id) : undefined}>
                      {FlashCardsLearning[lang].NeedReview}
                    </RightNeed>
                    <RightMaster lang={lang} onClick={enable ? () => handleAnswerClick("mastered",data[currentFlashCardIndex]?.id) : undefined}>
                      {FlashCardsLearning[lang].Completed}
                      {
                        lang === "en"?
                        <MdNavigateNext style={{fontSize:"25px"}}/>
                        :
                        <GrFormPrevious style={{fontSize:"25px"}}/>
                      }
                    </RightMaster>
              </ButtonCombine>
      </ButtonDivFixed>

      {LimitExhaustModal ?
        <ExhausteModel setLimitExhaustModal={setLimitExhaustModal} data={exhaustModalData[0]}/>
        : null
      }
      {botSubcriptionVisibility ?
        <SubscriptionModal subscriptionVisibility={subscriptionVisibility} setSubscriptionVisibility={setSubscriptionVisibility}/>
        :null
      }
    </StartContainer>
  );
};

export default StartLearn;
