export const model = {
  ar: {
    heading: "اختر معلوماتك الأكاديمية",
    description:
      "حتى نقدر نساعدك في رحلتك التعليمية مع متميز",
    next: "التالي",
    ask: "اسالني لاحقا",
    return: "يعود",
    select:"اختر مدرستك \ جامعتك",
    search:"ابحث"
  },
  en: {
    heading: "Choose your academic information",
    description:
      "For an easy experience, please select your tuition information",
    next: "Next",
    ask: "Ask me Later",
    return: "Return",
    select:"Select College/Major",
    search:"Search"
  },
};

export const model3 = {
  ar: {
    select: "اختر التخصص",
    placeholder: "ابحث",
  },
  en: {
    select: "Select the Speclization",
    placeholder: "College/School Name",
  },
};

export const model1 = {
  ar: {
    academic: "المستوى الأكاديمي",
    school: "مدرسة",
    college: "كلية",
  },
  en: {
    academic: "Academic Level",
    school: "School",
    college: "College",
  },
};
export const model2 = {
  ar: {
    select: "اختر جامعتك أو مرحلتك",
    placeholder: "ابحث",
  },
  en: {
    select: "Select the University",
    placeholder: "College/School Name",
  },
};
