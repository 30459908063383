import React, { useEffect, useState } from 'react'
import { MainContainer, SubContainer, LoadingArea, CardOuterContainer, OptionsModal, StyledList, TempContainer } from './Library.styles';
import LibraryCard from './LibraryCard';
import Navigation from '../NavigationBar/Navigation'
import { useDispatch, useSelector } from 'react-redux';
import {emptyFileTransferRoute, removeRoutesArray, setFileTransferRouteUpdated, updateRoutesArray} from '../../Store/Slice/Navigation/NavigationSlice'
import { useLocation, useNavigate } from 'react-router-dom';
import ApiClient from '../../Config/index.config';
import { setLibraryData } from '../../Store/Slice/LibraryData/LibraryDataSlice'
import localStorage from "../../Store/index"
import Loader from '../Loader/Loader';
import EmptyFolder from './EmptyFolder';
import FolderCreationModal from '../Modals/FolderCreationModal/FolderCreationModal';
import FileTransferModal from '../Modals/FileTransferModal/FileTransferModal';
import EditIcon from "../../Assets/Images/EditIcon.svg"
import DonwloadIcon from "../../Assets/Images/DownloadIcon.svg"
import DeleteIconRed from "../../Assets/Images/DeletIconRed.svg"
import {LibraryCardLanguage} from "../../Components/data/LibraryCard.lang"
import DeleteModal from '../Modals/DeleteModal/DeleteModal';
import { useStore } from 'react-redux';
import FacebookShare from '../SocialShare/FaceBookShare';
import TwitterShare from '../SocialShare/TwitterShare';
import CopyIcon from '../../Assets/Images/CopyIcon.svg';
import WhatsappShare from '../SocialShare/WhatsappShare';
import NotificationModal from '../Modals/NotificationModal/NotificationModal';
import { setAmount, setPremiumDetails } from '../../Store/Slice/PremiumDetails/PremiumDetailsSlice';

const LibraryCardsSection = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const id = localStorage.getState().libraryData?.id
  const LibraryData = useSelector((store) => store?.libraryData?.data)
  const updation = useSelector((store) => store?.libraryData?.updation)
  const [LibraryContent, setLibraryContent] = useState(LibraryData);
  const [moreOptions, setMoreOptions] = useState(false)
  const NavigationData = useSelector((store) => store.navigationData?.routesArray);
  const location = useLocation();
  const [editModal, setEditModal] = useState(false)
  const [fileTransferModal, setFileTransferModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [activeFile, setActiveFile] = useState(null)
  const [currentCardData, setCurrentCardData] = useState(null)
  const lang = useSelector((store) => store?.lang?.language)
  const store = useStore();
  const [shareModal, setShareModal] = useState(false);
  const [activeShare, setActiveShare] = useState(null);
  const [copyModal, setCopyModal] = useState(false);
  const [privateFileNotification, setPrivateFileNotification] = useState(false);

  const handleClick = (data) => {
    if(!moreOptions && data?.type === 'document'){
      dispatch(updateRoutesArray({data: data?.name, id:data?.id}))
      navigate(`/flash-card/${data?.id}`)
      navigate(`/flash-card/${data?.id}`, { state: { s3_link: data?.s3_link }})
    }
    else{
      if(!moreOptions && data !== null){
        setIsLoading(true)
        getData(data?.id);
        navigate(`/library/?id=${data?.id}`)
      }
    }
  }

  function getLastPart(location) {
    const parts = location?.split('/');
    if(parts?.at(-1) === ''){
      parts?.pop();
    }
    // return parts?.at(0);
    return location.substring(location.lastIndexOf('/') + 1);
  }

  function getFirstPart(location){
    let parts = location.split('/');
    parts = parts?.at(0) + "://" + parts?.at(2);
    return parts;
  }


  const getData = async (id, navigationBack) => {
    window.scrollTo(0, 0, {behaviour : "smooth"})
    try{
      const response = await ApiClient.get(`/library/` + id)
      let data = null;
      if(response?.data?.data?.parent_folder_name){
        data = response?.data?.data?.parent_folder_name
      }
      else{
        data = LibraryCardLanguage[lang]?.libraryNaviagtion;
      }
      setLibraryContent(response.data.data.library)
      dispatch(setLibraryData({data: response?.data?.data?.library, id: id}))
      if((data !== null && !navigationBack) || (id !== null && !navigationBack)){
        navigationArray(data, id)
      }
      setIsLoading(false)
    }catch(error){
      console.log(error)
      if(error?.response?.data?.error_code === 1019){
        dispatch(setLibraryData({data: [], id: id}))
        navigationArray(error?.response?.data?.details?.parent_folder_name,id);
        setLibraryContent([]);
      }
      // handling navigation from navigation bar when click on document and already inside document
      if(error?.response?.data?.error_code === 1021){
          navigate(`/flash-card/${id}`)
      }
      setIsLoading(false)
    }
  };

  const navigationArray = (data, id) => {
    if(NavigationData?.find((x) => x?.id === id)){
      let x = NavigationData?.length-1;
      while(NavigationData[x]?.id !== id){
        dispatch(removeRoutesArray());
        x--;
      }
    }else{
        dispatch(updateRoutesArray({data: data, id:id}))
    }
  }


  useEffect(() => {
    dispatch(setFileTransferRouteUpdated())
    setIsLoading(true)
    const params = new URLSearchParams(window.location.search);
    const id = params.get('id');
    if(getLastPart(location?.pathname) === 'library' && id === null){
      getData("");
    }else{
      // Handeling routing from public library
      if(location?.state?.id){
        console.log(location)
        getData(location?.state?.id)
      }
      else{
        getData(id);
      }
    }

  }, [updation]);



// Handle browser's back navigation
  useEffect(() => {
    const handleBackForward = () => {
      setIsLoading(true)
      const unsubscribe = store?.subscribe(() => {
        let currentNavigationValue = store?.getState().navigationData?.routesArray;
        if(currentNavigationValue.length <= 0){
          navigate("/")
        }
        else{
          getData(currentNavigationValue[currentNavigationValue?.length - 1].id, true);
        }
        
        return () => {
          unsubscribe()
        }
      });
      dispatch(removeRoutesArray());
      unsubscribe()
    };
    
    window.addEventListener('popstate', handleBackForward);
    
    return () => {
      window.removeEventListener('popstate', handleBackForward);
    };

  },[updation])

  useEffect(() => {
    return () => {
      dispatch(emptyFileTransferRoute());
    };
  },[])

  const handleEdit = (e) => {
    e.stopPropagation();
    setEditModal(true);
    setCurrentCardData(activeFile)
    setActiveFile(null)
  }

  const handleTransfer = (e)=> {
    e.stopPropagation();
    setFileTransferModal(true);
    setCurrentCardData(activeFile)
    setActiveFile(null)
  }

  const handleDonwload = (e, data) => {
    e.stopPropagation();
    const link = document?.createElement("a");
    link.href = data?.s3_link;
    document.body.appendChild(link);
    link?.click();
    link?.parentNode?.removeChild(link);

  }

  const handleDelete = (e)=> {
    e.stopPropagation();
    setDeleteModal(true);
    setCurrentCardData(activeFile)
    setActiveFile(null)
  }

  const handleCopy = (e, data) => {
    e.stopPropagation();
    if(data?.type === 'document'){
      navigator?.clipboard?.writeText(getFirstPart(window.location.href) + "/flash-card/" + data?.id);
    }
    else if(data?.type === 'folder'){
      navigator?.clipboard?.writeText(getFirstPart(window.location.href) + "/library?id=" + data?.id);
    }
    setActiveShare(null)
    setCopyModal(true)
  }

  const getPremiumDetails = async () => {
    try{
      const response = await ApiClient.get("library/premium-details")
      dispatch(setPremiumDetails(response.data.data.feature_data))
      dispatch(setAmount(response.data.data.amount))
    }
    catch(error){
      console.log(error);
    }
  }

  useEffect(() => {
    getPremiumDetails();
    // const params = new URLSearchParams(window.location.search);


    // const id = params.get('id');
    // if(id !== null){
    //   getData("hi",id);
    // }
    // console.log(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
  },[])

  return (
    <MainContainer>
        {!isLoading ?
        <>
            <Navigation getData={getData} setIsLoading={setIsLoading}/>
            <TempContainer>
                <SubContainer>
                    {LibraryContent?.length !== 0 ?
                    LibraryContent?.map((data, index) => {
                      return (
                          <CardOuterContainer key={index} onClick={() => handleClick(data)}>
                              <LibraryCard activeFile={activeFile} setActiveFile={setActiveFile} data={data} moreOptions={moreOptions} setMoreOptions={setMoreOptions} activeShare={activeShare} setActiveShare={setActiveShare} shareModal={shareModal} setShareModal={setShareModal} privateFileNotification={privateFileNotification} setPrivateFileNotification={setPrivateFileNotification} />
                              {activeFile && activeFile?.id === data?.id ?
                              <OptionsModal lang={lang} id={data?.id}>
                                  <StyledList onClick={(e) => handleEdit(e)}> <img style={{width : "0.8rem", height: "auto"}} src={EditIcon} alt="edit icon"/>{LibraryCardLanguage[lang]?.edit}</StyledList>
                                  <StyledList onClick={(e) => handleTransfer(e)}> <img style={{width : "0.8rem", height: "auto"}} src={EditIcon} alt="edit icon"/>{LibraryCardLanguage[lang]?.transfer}</StyledList>
                                  {data.type === 'document' ?
                                  <>
                                  {/* <StyledList onClick={(e) => handleDonwload(e, data)}> <img style={{width : "0.8rem", height: "auto"}} src={DonwloadIcon} alt="donwload icon" />{LibraryCardLanguage[lang].download}</StyledList> */}
                                  <StyledList onClick={(e) => handleDelete(e)} style={{color: "#FF6868"}}> <img style={{width: "0.8rem", height: "auto"}} src={DeleteIconRed} alt="delete icon" />{LibraryCardLanguage[lang].delete}</StyledList>
                                  </>
                                  : null}
                              </OptionsModal >
                              : null}
                              {activeShare && activeShare?.id === data?.id && data?.is_public === true ? 
                                  <OptionsModal lang={lang} id={data?.id} insideShare="true" onClick={(e) => e.stopPropagation()}>
                                    {data.type==='folder' ?
                                    <>
                                      <WhatsappShare pdfName={data?.name} url={getFirstPart(window.location.href) + "/library?id=" + data?.id}  />
                                      {/* <FacebookShare pdfName={data.name} url={getFirstPart(window.location.href) + "/library?id=" + data?.id} /> */}
                                      <TwitterShare pdfName={data?.name} url={getFirstPart(window.location.href) + "/library?id=" + data?.id} />
                                      <img style={{cursor:'pointer'}} onClick={(e) => handleCopy(e, data)} src={CopyIcon} alt="copy icon" />
                                    </>
                                    :
                                    <>
                                      <WhatsappShare pdfName={data?.name} url={getFirstPart(window.location.href) + "/flash-card/" + data?.id}  />
                                      {/* <FacebookShare pdfName={data.name} url={getFirstPart(window.location.href) + "/flash-card/" + data?.id} /> */}
                                      <TwitterShare pdfName={data?.name} url={getFirstPart(window.location.href) + "/flash-card/" + data?.id} />
                                      <img style={{cursor:'pointer'}} onClick={(e) => handleCopy(e, data)} src={CopyIcon} alt="copy icon" />
                                    </>
                                    }
                                    </OptionsModal > 
                                : null
                              }
                          </CardOuterContainer>
                      )
                    })
                    : <EmptyFolder/>}
                </SubContainer>
            </TempContainer>
        </>
        :
        <LoadingArea> <Loader/> </LoadingArea>
        }
        {editModal ?
            <FolderCreationModal setCreateFolderModal={setEditModal} type="edit" Name={currentCardData.name} EditId={currentCardData.id} IsPublic={currentCardData.is_public}/>
        : null
        }
        {fileTransferModal ?
            <FileTransferModal fileTransferModal={fileTransferModal} setFileTransferModal={setFileTransferModal} type="transfer" Name={currentCardData.name} itemId={currentCardData.id}/>
        : null
        }
        {deleteModal ?
            <DeleteModal deleteModel={deleteModal} setDeleteModal={setDeleteModal} Name={currentCardData.name} itemId={currentCardData.id}/>
        : null
        }
        {copyModal ? 
            <NotificationModal data={LibraryCardLanguage[lang].copyNotification} closeNotification={setCopyModal}/>
        : null
        }
        {privateFileNotification ?
            <NotificationModal style={{PointerEvent: "none"}} data={LibraryCardLanguage[lang]?.privateFileNotification} closeNotification={setPrivateFileNotification} color={"#F55157"}/>
        : null
        }
    </MainContainer>
  )
}

export default LibraryCardsSection;