import React from "react";
import styled, { keyframes } from "styled-components";

const bounceAnimation = keyframes`
  to {
    opacity: 0.1;
    transform: translateY(-8px);
  }
`;

const BouncingDotsLoader = styled.div`
  display: flex;
  justify-content: center;

  > div {
    width: 8px;
    height: 8px;
    margin: 3px 6px;
    border-radius: 50%;
    background-color: #a3a1a1;
    opacity: 1;
    animation: ${bounceAnimation} 0.6s infinite alternate;
  }

  > div:nth-child(2) {
    animation-delay: 0.2s;
  }

  > div:nth-child(3) {
    animation-delay: 0.4s;
  }
`;

const BouncingDotsLoaderComponent = () => {
  return (
    <BouncingDotsLoader>
      <div></div>
      <div></div>
      <div></div>
    </BouncingDotsLoader>
  );
};

export default BouncingDotsLoaderComponent;
