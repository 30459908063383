import React, { useEffect, useState } from 'react'
import { FolderSelectionSection, FoldersSection, HeadingSection, Text, MainHeading, SubHeading, UploadSubContainer, FolderNameSection, CreateNewFolderSection, ButtonsSection, StyledSVG, StyledButton, ScrollableFolderNamesSection, EmptyFolder, SubHeadingSmall, UpperBorderLine, LoaderContainer } from '../../UploadFile/UploadFile.styles'
import FolderCreationModal from '../../Modals/FolderCreationModal/FolderCreationModal'
import SubscriptionModal from '../../Modals/SubscriptionModal/SubscriptionModal'
import ApiClient from '../../../Config/index.config'
import { setUpdation } from '../../../Store/Slice/LibraryData/LibraryDataSlice'
import { useDispatch, useSelector } from 'react-redux'
import { FileSavingLanguage } from '../../data/UploadFile.lang'
import { CreateFolderModal } from '../FolderCreationModal/FolderCreationModal.styles'
import FolderIcon from "../../../Assets/Images/FolderIcon.svg"
import { emptyFileTransferRoute, removeFileTransferRoute, updateFileTransferRoute } from '../../../Store/Slice/Navigation/NavigationSlice'
import FileTransferNavigation from '../../NavigationBar/FileTransferNavigation'
import Loader from '../../Loader/Loader'
import { NavigationName } from '../../Library/Library.styles'

const FileTransferModal = ({fileTransferModal,  setFileTransferModal, Name, itemId}) => {

    const [createFolderModal, setCreateFolderModal] = useState(false);
    const [subscriptionVisibility, setSubscriptionVisibility] = useState(false);
    const [currentFolder, setCurrentFolder] = useState("library");
    const [folders, setFolders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [parentUUID, setParentUUID] = useState("")
    const dispatch = useDispatch()
    const lang = useSelector((store) => store.lang.language)
    const NavigationData = useSelector((store) => store.navigationData.fileTransferRoute);


    const setData = async () => {
        try{
            const response = await ApiClient.put(`/library/` + itemId + '/move', {
                "parent_uuid": parentUUID
            })
        }catch(error){
            console.log(error)
        }
        dispatch(emptyFileTransferRoute())
        dispatch(setUpdation());
        setFileTransferModal(false)
    };

    const getFolders = async (data, id) => {
        setIsLoading(true);
        try{
          const response = await ApiClient.get(`/library/` + id)
          setFolders(response.data.data.library)
          if(data !== null || id !== null){
            navigationArray(data, id)
          }
          setIsLoading(false)
        }catch(error){
          console.log(error)
          navigationArray(data, id)
          if(error?.response?.data?.error_code === 1019){
            setFolders([]);
          }
          setIsLoading(false)
        }
    };
    
    const navigationArray = (data, id) => {
        if(NavigationData?.find((x) => x.id === id)){
            let x = NavigationData.length-1;
            while(NavigationData[x].id !== id){
                dispatch(removeFileTransferRoute());
                x--;
            }
        }else{
            dispatch(updateFileTransferRoute({data: data, id:id}))
        }
    }

    const handleClick = (data) => {
        setIsLoading(true)
        setParentUUID(data.id)
        getFolders(data.name, data.id);
    }

    const handleClose = () => {
        setFileTransferModal(false);
        dispatch(emptyFileTransferRoute())
    }

    useEffect(() => {
        setParentUUID("");
        getFolders("library", "");
    },[])


    return (
        <CreateFolderModal>
        {/* <UploadSubContainer> */}
            <FolderSelectionSection filetransfer="true">
                <FoldersSection>
                    <HeadingSection>
                        <Text>
                            <MainHeading>{FileSavingLanguage[lang].heading}</MainHeading>
                            <SubHeading>
                                {FileSavingLanguage[lang].subHeading}
                            </SubHeading>
                            <SubHeadingSmall>
                                {FileSavingLanguage[lang].subHeadingSmall}
                            </SubHeadingSmall>
                        </Text>
                        <svg style={{marginTop : "-5rem" ,cursor:"pointer"}} lang={lang} onClick={() => {handleClose()}} width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13 1.83398L1 13.834M1 1.83398L13 13.834" stroke="#667085" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </HeadingSection>

                    {!isLoading ?
                    <>
                        <FileTransferNavigation getFolders={getFolders} setIsLoading={setIsLoading}/>
                        {folders?.length !== 0 ?
                        <ScrollableFolderNamesSection filetransfer = "true">
                        {folders.map((data, index) => {
                            if(data.type === 'folder' && data.id !== itemId)
                            return (
                                <FolderNameSection onClick={() => handleClick(data)} key={index} flexdirection="row" bordercolor="#f4f4f4" color="#252525" fontWeight="600">
                                    <img style={{width:"2rem"}} src={FolderIcon} alt='folder Icon' />
                                    <NavigationName>{data.name}</NavigationName>
                                </FolderNameSection>
                            )
                            return null;
                        })}
                        </ScrollableFolderNamesSection> 
                        :
                        <EmptyFolder>
                            {lang === 'en' ? "No Folders Found" : "لم يتم العثور على مجلدات"}
                        </EmptyFolder>
                        }
                         
                    </>
                    :
                    <LoaderContainer >
                        <Loader/>
                    </LoaderContainer>
                    }

                    <CreateNewFolderSection filetransfer = "true" onClick={() => setCreateFolderModal(true)}>
                        <StyledSVG width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 13.834H3.16667C2.72464 13.834 2.30072 13.6584 1.98816 13.3458C1.67559 13.0333 1.5 12.6093 1.5 12.1673V3.00065C1.5 2.55862 1.67559 2.1347 1.98816 1.82214C2.30072 1.50958 2.72464 1.33398 3.16667 1.33398H6.5L9 3.83398H14.8333C15.2754 3.83398 15.6993 4.00958 16.0118 4.32214C16.3244 4.6347 16.5 5.05862 16.5 5.50065V8.41732M12.3333 13.834H17.3333M14.8333 11.334V16.334" stroke="#CA56A1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </StyledSVG>
                        <span>{FileSavingLanguage[lang].newFolderButton}</span>
                    </CreateNewFolderSection>

                </FoldersSection>

                <UpperBorderLine></UpperBorderLine>
                <ButtonsSection>
                    <StyledButton  onClick={() => setData()}>{FileSavingLanguage[lang].fileTransferButton}</StyledButton>
                </ButtonsSection>
            </FolderSelectionSection>

            {createFolderModal ? 
                <FolderCreationModal setCreateFolderModal={setCreateFolderModal} type="createFolderTransfer" IsPublic="false" folderId={parentUUID} getFolders={getFolders} currentFolder={currentFolder}/>
            : null}

            {subscriptionVisibility ? 
            <SubscriptionModal setSubscriptionVisibility={setSubscriptionVisibility} />
            : null}

        {/* </UploadSubContainer> */}
        </CreateFolderModal>
    )
}

export default FileTransferModal