import React, { useEffect, useRef } from 'react';
import { CardContainer, IconContainer, MoreIconImage, FolderDetails, FolderName, TagsContainer, Tags, ButtonsSection, FolderCount, CountNumber, FolderType, ShareIconImage } from './Library.styles';
import MoreIcon from '../../Assets/Images/MoreIcon.svg';
import SmallFolderIcon from '../../Assets/Images/SmallFolderIcon.svg';
import ShareIcon from '../../Assets/Images/ShareIcon.svg';
import GreenCircle from '../../Assets/Images/GreenCircle.svg';
import CreamCircle from '../../Assets/Images/CreamCircle.svg';
import GrayCircle from '../../Assets/Images/GrayCircle.svg';
import FolderIcon from '../../Assets/Images/FolderIcon.svg'
import FileIcon from '../../Assets/Images/FileIcon.svg'
import { useSelector } from 'react-redux';
import {LibraryCardLanguage} from "../../Components/data/LibraryCard.lang"

const LibraryCard = ({activeFile, setActiveFile, data, moreOptions, setMoreOptions, activeShare, setActiveShare, shareModal, setShareModal, privateFileNotification, setPrivateFileNotification}) => {

    const lang = useSelector((store) => store.lang.language)
    const activeRef = useRef()
    const shareRef = useRef()

    const handleMoreOptions = (e, data) =>{
        e.stopPropagation();
        setActiveShare(null)
        if(moreOptions){
            setMoreOptions(!moreOptions);
        }

        if(activeFile && activeFile.id === data.id){
            setActiveFile(null)
        }
        else{
            setActiveFile(data)
        }
    }

    const handleShare = (e, data) => {
        e.stopPropagation();
        if(data?.is_public){
    
            setActiveFile(null)
            if(shareModal){
                setShareModal(!moreOptions);
            }
            if(activeShare && activeShare.id === data.id){
                setActiveShare(null)
            }
            else{
                setActiveShare(data)
            }
        }
        else{
            setPrivateFileNotification(true)
        }
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (activeRef.current && !activeRef.current.contains(event.target)) {
                setActiveFile(null)
            }
            if (shareRef.current && !shareRef.current.contains(event.target)) {
                setActiveShare(null)
            }
        };
    
        document.addEventListener("click", handleClickOutside);
    
        return () => {
            document.removeEventListener("click", handleClickOutside)
        }
    }, [activeRef]);


    return (
        <CardContainer>
            <IconContainer>
                {data.type === 'folder' ? <img src={FolderIcon} alt='folder icon' /> : <img src={FileIcon} alt='file icon'/> }
                <MoreIconImage ref={activeRef} src={MoreIcon} alt='more icon' onClick={(e) => handleMoreOptions(e, data)}/>
            </IconContainer>
            <FolderDetails>
                <FolderName>{data.name}</FolderName>
                {data.type === 'document' && data?.stats?.response?
                <TagsContainer>
                    {Object.keys(data.stats.response).length === 0 ?
                    <>
                        <Tags> <img src={GreenCircle} alt='green circle icon' /> {LibraryCardLanguage[lang].completeTag} (0)</Tags>
                        <Tags> <img src={CreamCircle} alt='green circle icon' /> {LibraryCardLanguage[lang].needReviewTag} (0)</Tags>
                        <Tags> <img src={GrayCircle} alt='green circle icon' /> {LibraryCardLanguage[lang].remainingTag} ({data.stats.total})</Tags>
                    </>
                    :
                    <>
                        <Tags> <img src={GreenCircle} alt='green circle icon' /> {LibraryCardLanguage[lang].completeTag} ({data.stats.response.mastered})</Tags>
                        <Tags> <img src={CreamCircle} alt='green circle icon' /> {LibraryCardLanguage[lang].needReviewTag} ({data.stats.response.needs_review})</Tags>
                        <Tags> <img src={GrayCircle} alt='green circle icon' /> {LibraryCardLanguage[lang].remainingTag} ({data.stats.total - (data.stats.response.mastered + data.stats.response.needs_review)})</Tags>
                    </>
                    }
                </TagsContainer>
                : null}
            </FolderDetails>
            <ButtonsSection>
                <FolderType lang={lang} $IsPublic={data.is_public}>{data.is_public ? LibraryCardLanguage[lang].publicButton : LibraryCardLanguage[lang].privateButton}</FolderType>
                <FolderCount>
                    <div style={{width: "1rem", display: "flex", alignItems: "center"}}>
                        <ShareIconImage ref={shareRef} onClick={(e) => handleShare(e, data)} lang={lang} src={ShareIcon} alt='small folder icon'/>
                    </div>
                    <CountNumber lang={lang}>
                        <img src={SmallFolderIcon} alt='small folder icon'/>
                        <span>{data.view_count}</span>
                    </CountNumber>
                </FolderCount>
            </ButtonsSection>
            {}
        </CardContainer>

    )
}

export default LibraryCard
