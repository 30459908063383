import styled from "styled-components"
import {device, size} from "../../../Breakpoints"

export const DeleteModalOuter = styled.div`
    position: fixed;
    background-color: rgba(77, 44, 119, 0.55);
    top: 0;
    left: 0;
    height: calc(100vh);
    right: 0;
    overflow: hidden;
    scroll-behavior: none;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const DeleteModalContainer = styled.div`
    width: 40rem;
    height: 22rem;
    background-color: white;
    display: flex;
    justify-content: end;
    align-items: start;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 2rem 3rem 2rem 3rem;
    @media ${device.md} {
        width: 35rem;
        height: auto;
    }
    @media ${device.sm} {
        position: absolute;
        bottom: 0;
        width: 100%;
        border-radius: 24px 24px 0 0;
        padding : 1rem 1rem 1rem 1rem;
        height: 24rem;
    }
`

export const DeleteModalSubContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
`

export const UpperBorderLine = styled.div`
    width: 100%;
    position: absolute;
    border: 1px solid #EEEEEE;
    bottom: 0;
    left: 0;
    right: 0;
    @media (min-width: ${size.sm}) {
        display: none;
    }

    @media ${device.sm}{
        margin-bottom: 10rem;
    }
`

export const TextSection = styled.div`
    width: ${(props) => props.width ? props.width : null};
    color: #727272;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 16px;
    line-height: 25px;
    box-sizing: border-box;
    @media ${device.sm} {
        align-items: center;
    }
    @media (max-width: 240px){
        margin-top: -2rem;
    }
`

export const Heading = styled.div`
    font-weight: bold;
    width: 100%;
    font-size: 28px;
    color: #252525;
    line-height: 3.5rem;
    text-align: center;
    /* margin-top: ${props => props.margintop ? props.margintop : null}; */
    @media ${device.md}{
        font-size: 24px;
    }
    @media ${device.sm} {
        font-size: 20px;
        line-height: 24px;
    }
    @media (max-width: 240px){
        font-size: 18px;
    }
`

export const ButtonsSection = styled.div`
    width: 100%;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    font-size: 16px;
    font-weight: bold;
    box-sizing: border-box;

    @media ${device.sm}{
        height: auto;
        flex-direction: column;
    }
`

export const CancelButton = styled.div`
    height: 4rem;
    width: 11.25rem;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #4D2C77;
    box-sizing: border-box;
    color: #4D2C77;
    cursor: pointer;

    @media ${device.sm}{
        height: 56px;
        width: 80%;
    }
    @media ${device.xs}{
        height: 56px;
        width: 100%;
    }
`

export const DeleteButton = styled.div`
    height: 4rem;
    width: 11.25rem;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F55157;
    box-sizing: border-box;
    color: white;
    cursor: pointer;

    @media ${device.sm}{
        height: 56px;
        width: 80%
    }
    @media ${device.xs}{
        height: 56px;
        width: 100%;
    }
`

export const StyledButton = styled.div`
    background-color: #4D2C77;
    width: 100%;
    color: white;
    height: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    gap:  12px;
    pointer-events: ${props => props.dissablebutton === "true" ? "none" : null};
    @media ${device.sm} {
        margin-top: 1.5rem;
    }
`