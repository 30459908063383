import styled from "styled-components";
import "../../Assets/fonts/Almarai-Regular.ttf";
import AlmaraiFont from "../../Assets/fonts/Almarai-Regular.ttf";
export const ButtonStyled=styled.button`
width: 181px;
height: 64px;
padding: 19px;
border-radius: 4px;
display: flex;
gap: 8px;
font-family: ${AlmaraiFont};
font-size: 18px;
font-weight: 700;
line-height: 26px;
letter-spacing: 0em;
text-align: center;
align-items: center;
justify-content: space-evenly;
background: #4D2C77;
// z-index:10000;
color: #FFFFFF;
transition:  background 0.5s ease;
&:hover{
    background: #CA56A1;
  }
  .image-transition {
    transform: scaleX(-1);
    transition: transform 0.7s ease;
  }
  
  .on-over {
    transform: scaleX(-1) rotate(45deg);
  }
  
`;
export const ButtonText=styled.div``;
export const ButtonImage=styled.div``;
