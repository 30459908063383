import { createSlice } from "@reduxjs/toolkit";
import { Auth } from "../../../Services/api/auth";
import Taost from "../../../Components/Toast/Toast.js";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../../Components/Error/Errorcode.js";

const initialState = {
  isAuthenticated: false,
  token: undefined,
  error: null,
  loading: false,
  phone_number:""
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginStart(state, action) {
      state.loading = true;
      state.error = null;
    },
    loginSuccess(state, action) {
      state.isAuthenticated = true;
      state.token = action.payload.data.access_token;
      state.error = null;
      state.loading = false;
    },
    loginFail(state, action) {
      state.loading = false;
      state.token = null;
      state.isAuthenticated = false;
      state.error = action.payload;
    },
    RegisterStart(state, action) {
      state.loading = true;
      state.error = null;
    },
    RegisterSuccess(state, action) {
      state.isAuthenticated = false;
      state.token = action.payload.token;
      state.loading = false;
      state.error = null;
    },
    RegisterFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    RegisterEnd(state, action) {
      state.loading = false;
      state.error = null;
    },
    Logout(state, action) {
      state.isAuthenticated = false;
      state.token = undefined;
      state.error = null;
      state.loading = false;
    },
    verify(state, action) {
      state.phone_number = action.payload.phone_number;
    }
  },
});

export const {
  loginStart,
  loginSuccess,
  loginFail,
  RegisterEnd,
  RegisterFail,
  RegisterSuccess,
  RegisterStart,
  Logout,
  verify
} = authSlice.actions;

export const { reducer: authReducer } = authSlice;

const loginDispatcher = (data, callback,callback2,lang) => async (dispatch) => {
  try {
    dispatch(loginStart());
    const response = await Auth.login(data);
    console.log(response)
    if (response.data.data.access_token) {
      dispatch(loginSuccess(response.data));
      callback();
    }
    dispatch(RegisterEnd());
  } catch (error) {
    console.log(error);
    if(error?.response?.data?.error_code ===   1046){
      dispatch(verify({phone_number:data.phone_number}));
      callback2();
    }
    else{
    const message = getErrorMessage(error?.response?.data?.error_code, lang);
    toast(<Taost message={message} error />);
    dispatch(loginFail(message || "something went wrong"));
    }
  } finally {
    dispatch(RegisterEnd());
  }
};

const registerDispatcher = (data,ref,callback2,lang) => async (dispatch) => {
  try {
    dispatch(RegisterStart());
    const response = await Auth.register(data,ref);
    
    if (response.status === 201) {
      dispatch(verify({phone_number:data.phone_number}));
      dispatch(RegisterSuccess(response.data));
      callback2();
    }
  } catch (error) {
    const message = getErrorMessage(error?.response?.data?.error_code, lang);
    toast(<Taost message={message} error />);
    dispatch(RegisterFail(message || "something went wrong"));
  } finally {
    dispatch(RegisterEnd());
  }
};
const Logoutdispatcher = () => async (dispatch) => {
  dispatch(Logout());
};

export { loginDispatcher, registerDispatcher, Logoutdispatcher };
export default authSlice.reducer;
