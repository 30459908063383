import React, { useEffect, useState } from "react";
import {
  AuthWrapper,
  ButtonContainer,
  ButtonStyle,
  Error,
  FormContainer,
  HeadingContainer,
  Loader,
  LoaderCenter,
  LoginContainer,
  Phone2Input,
} from "./Auth.Style";
import { useNavigate } from "react-router-dom";
import ApiClient from "../../Config/index.config";
import Taost from "../Toast/Toast";
import { toast } from "react-toastify";
import { getErrorMessage } from "../Error/Errorcode";
import { verify } from "../../Store/Slice/auth/Auth.Slice";
import { useDispatch } from "react-redux";

const Forget_password = () => {
  const [value, setValue] = useState("sa");
  const [moberror, setMobError] = useState(false);
  const [isButtonDisable, setIsButtonDisable] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const data = {
      phone_number: `+${value}`,
    };
    try {
      dispatch(verify({ phone_number: data.phone_number }));
      const forget = await ApiClient.post(
        "/auth/forgot-password/send-otp",
        data
      );
      toast(<Taost message={"otp sent successfully"} error />);
      setLoading(false);
      navigate("/forgot_password_verify");
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.error_code === 1010) {
        const message = getErrorMessage(1010, "ar");
        toast(<Taost message={message} error />);
      } else {
        const message = getErrorMessage(
          error?.response?.data?.error_code,
          "ar"
        );
        toast(<Taost message={message || "حدث خطأ ما"} error />);
      }
      setLoading(false);
    }
  };

  const validatePhoneNumber = (value) => {
    const nationalNumber = value.slice(3);
    if (nationalNumber.startsWith("5") && nationalNumber.length === 9) {
      setValue(value);
      setMobError(false);
      return true;
    } else {
      if (nationalNumber.length === 0) {
        setMobError(false);
        return true;
      }
      if (nationalNumber.startsWith("5") && nationalNumber.length <= 9) {
        setMobError(true);
        return false;
      }
      setMobError(true);
      return false;
    }
  };

  useEffect(() => {
    isButtonDisabled();
  }, [value]);

  const isButtonDisabled = () => {
    if (value.length === 3 || moberror) {
      setIsButtonDisable(true);
    } else {
      setIsButtonDisable(false);
    }
  };

  return (
    <AuthWrapper>
      <LoginContainer style={{ height: "60vh" }}>
        <HeadingContainer>{"أدخل رقم هاتفك المسجل"} </HeadingContainer>
        <FormContainer>
          <Phone2Input
            country={value}
            disableDropdown={true}
            onChange={(data) => setValue(data)}
            placeholder="5XXXXXXXX"
            countryCodeEditable={false}
            isValid={(value, country) => validatePhoneNumber(value)}
          />
          {moberror && (
            <Error>
              {"رقم الهاتف غير صحيح. يجب أن يبدأ بـ 5 وأن يتكون من 9 أرقام."}
            </Error>
          )}
          <ButtonContainer>
            <ButtonStyle
              type="submit"
              disabled={isButtonDisable}
              onClick={(e) => handleSubmit(e)}
            >
              {loading ? (
                <LoaderCenter>
                  <Loader />
                </LoaderCenter>
              ) : (
                "إرسال كلمة مرور لمرة واحدة"
              )}
            </ButtonStyle>
          </ButtonContainer>
        </FormContainer>
      </LoginContainer>
    </AuthWrapper>
  );
};

export default Forget_password;
