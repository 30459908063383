export const FlashCardQuiz = {
    ar:{
        TestHeading:"اختبر مستواك التعليمي",
        TestHeading1:"كيف نساعدك ؟",
        TestHeading2:"سيقيم هذا الاختبار مهاراتك الحالية، ويساعدنا في تحديد المواضيع المناسبة لك.",
        NoofQuestion:"عدد الأسئلة :",
        Question:"الأسئلة",
        StartTest:" ابدأ الاختبار ",
        FirstQuiz:"السؤال الأول",
        CorrectAns:"اختر الاجابة الصحيحة",
        Report:"إبلاغ",
        NextQuestion:"السؤال التالي",
        Explanation: "الشرح",
        ExplanationLimitMessage: "لقد استنفدت حدود الشرح",
        PlaceHolderText: "إجابة",
        SubmitButton: "يُقدِّم",
        CorrectAnswer: "اجابة صحيحة",
    },
    en:{
        TestHeading:"Test to determine your educational level",
        TestHeading1:"Guidelines for evaluating user experience",
        TestHeading2:"This test will assess your current skills, helping us determine which subjects are right for you.",
        NoofQuestion:"No of Questions :",
        Question:"Questions",
        StartTest:"Start the test",
        FirstQuiz:"The first question",
        CorrectAns:"choose the correct answer",
        Report:"Report",
        NextQuestion:"Next question",
        Explanation: "Explain",
        ExplanationLimitMessage: "Explanation Limit Exhausted",
        PlaceHolderText: "Answer",
        SubmitButton: "Submit",
        CorrectAnswer: "Correct Answer",
    }
}