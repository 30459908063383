import { WhatsappShareButton } from "react-share";
import WhatsappIcon from "../../Assets/Images/WhatsappFlashCardIcon.svg";
import React from 'react';
import { useSelector } from "react-redux";

function WhatsappShare({ pdfName , url }) {
  const lang = useSelector((store) => store.lang.language)
  const titleText = {
    ar:{
      data: `الـ  AI لخص لي مادة ${pdfName} , ذاكر معايا قبل الاختبار\n\n`,
    },
    en:{
     data: `The AI ​​summarized for me the subject "${pdfName}". Study with me before the test\n\n`,
    },
  }
  
  return (
    <WhatsappShareButton url={url} hashtags={['Growing']} title={titleText[lang].data}>
      <img src={WhatsappIcon} alt="Whatsapp Icon" />
    </WhatsappShareButton>
  );
}

export default WhatsappShare;