import styled from "styled-components"
import { device } from "../../../Breakpoints"


export const SubscriptionModalContainer = styled.div`
    position: fixed;
    background-color: rgba(77, 44, 119, 0.55);
    top: 0;
    left: 0;
    height: 100vh;
    right: 0;
    z-index: 2000;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-height: 900px){
        height: 100vh;
        align-items: start;
        overflow-y: auto;
    }
    @media ${device.md}{
        padding: 0 1rem 0 1rem;
    }
`

export const SubscriptionModalSubContainer = styled.div`
    width: 860px;
    height: 760px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 24px;
    position: relative;
    overflow-y: auto;
    text-align: center;
    overflow-x: hidden;

    @media (max-height: 900px){
        margin: 2rem 0 2rem 0;
    }
    @media ${device.md}{
        width: 100%;
    }
    @media ${device.sm}{
        position: fixed;
        bottom: 0;
        border-radius: 24px 24px 0 0;
        margin: 0;
    }
    @media ${device.md}{
        padding: 16px;
    }
    @media ${device.sm}{
        height: 720px;
        padding: 20px 12px 20px 12px;
    }
`

export const SubscriptionContent = styled.div`
    width: 772px;
    height: 100%;
    background-image: linear-gradient(to bottom, #ebe8ef, white, white, white);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    box-sizing: border-box;
    padding-top: 24px;
    gap: 1.5rem;
    overflow-x: hidden;
    @media ${device.md}{
        width: 100%;
    }
    @media ${device.sm}{
        border-radius: 36px;
        gap: 1rem;
    }
    @media ${device.sm}{
        padding-top: 16px;
    }
    @media ${device.xs}{
        gap: 1.5rem;
    }
    
`

export const CrossContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    margin: 1rem 0 0 1rem;
    display: flex;

    & > svg{
        cursor:pointer;
    }

    @media ${device.md}{
        margin: 1.6rem 0 0 1.6rem;
    }
    @media ${device.sm}{
        margin: 12px 0 0 8px;
    }
`

export const StyledPlusIcon = styled.img`
    width: 5rem;
`

export const Price = styled.div`
    color: #CA56A1;
    font-size: 32px;
    font-weight: 800;
    /* margin-top: 2rem; */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    @media ${device.xs}{
        font-size: 20px;
    }
`

export const SubPrice = styled.div`
    display: flex;
    justify-content: center;
`

export const SmallText = styled.span`
    font-size: medium;
    font-weight: 600;
    /* margin-top: 0.8rem; */
    margin-left: ${(props) => props.lang === 'en' ? '4px' : null};
    margin-right: ${(props) => props.lang === 'ar' ? '4px' : null};
`

export const LightSmallTextlight = styled.span`
    font-size: 14px;
    color: #727272;
`

export const LightSmallTextDark = styled.span`
    font-size: 14px;
    color: #252525;
`

export const LightSmallTextMedium = styled.span`
    font-size: 14px;
    color: #4c4c4c;
`
export const TableWrapper = styled.div`
    width: 100%;
    overflow-x: scroll;
    margin-bottom: 12px;
    &::-webkit-scrollbar {
        width: 0px;
        height: 0;
    }
`

export const TableContainer = styled.table`
    /* margin: 2rem 0 2rem 0; */
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #EEEEEE;
    /* height: 100%; */
    overflow-y: scroll;
    border-collapse: collapse;
    @media (max-width: 450px){
        width: 500px;
    }
    @media ${device.sm} {
        font-size: 14px ;
    }
`

export const TableBody = styled.tbody`
    & > :first-child{
        background-color: #FAFAFA;
    }
`

export const TableRow = styled.tr`
    width: 100%;
    height: 58px;
    font-weight: bold;
    border-bottom: 1px solid #EEEEEE;
    text-align: start;
    & > :first-child{
        background-color: #FAFAFA;
    }
`

export const TableHeader = styled.th`
    font-weight: bold;
    width: 1/3;
    text-align: start;
    box-sizing: border-box;
    padding: ${props => props.lang === 'en' ? "0 0 0 1.2rem" : "0 1.2rem 0 0"};
`

export const FreePackage = styled.td`
    color: #727272;
    width: 1/3;
    padding: 0 0 0 1rem;
    padding: ${props => props.lang === 'en' ? "0 0 0 1.2rem" : "0 1.2rem 0 0"};
`

export const MumayazPackage = styled.td`
    color: #252525;
    gap: 8px;
    width: 1/3;
    text-align: end;
    padding: ${props => props.lang === 'en' ? "0 0 0 1.2rem" : "0 1.2rem 0 0"};
`

export const Wrapper = styled.span`
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    width: fit-content;
`

export const Advantages = styled.td`
    width: 1/3;
    padding: 0 0 0 1.2rem;
    color: #4c4c4c;
    padding: ${props => props.lang === 'en' ? "0 0 0 1.2rem" : "0 1.2rem 0 0"};
`

export const TextSection = styled.div`
    width: ${(props) => props.width ? props.width : null};
    color: #727272;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 16px;
    line-height: 25px;
    @media ${device.md}{
        width: 80%;
    }
    @media ${device.sm}{
        width: 100%;
    }
    @media ${device.xs}{
        line-height: 20px;
        font-size: 14px;
    }
`

export const Heading = styled.div`
    width: 100%;
    font-size: 2rem;
    color: #252525;
    font-weight: bold;
    line-height: 4rem;
    text-align: center;
    margin-top: ${props => props.margintop ? props.margintop : null};
    @media ${device.xs}{
        line-height: 2.5rem;
        font-size: 20px;
    }
`

export const StyledButton = styled.div`
    background-color: #4D2C77;
    width: 100%;
    color: white;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    box-sizing: border-box;
    pointer-events: ${props => props.dissablebutton === "true" ? "none" : null};
`


export const PaymentOptionContainer = styled.div`
    position: fixed;
    background-color: rgba(77, 44, 119, 0.55);
    top: 0;
    left: 0;
    height: 100vh;
    right: 0;
    z-index: 2000;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const PaymentOptionMainContainer = styled.div`
    width: 500px;
    height: 200px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 24px;
    position: relative;
    overflow-y: auto;
    text-align: center;
    overflow-x: hidden;
    gap: 2rem;

    @media (max-width: 600px) {
        position: absolute;
        bottom: 0;
        width: 100%;
        border-radius: 24px 24px 0 0;
        padding : 1rem 1rem 1rem 1rem;
        height: 15rem;
        gap: 1rem;
    }
`

export const PaymentOptionHeading = styled.div`
    font-size: 20px;
    color: #252525;
    font-weight: bold;
`

export const PaymentButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
`

export const PaymentOptionButton = styled.div`
    width: 8rem;
    height: 3rem;
    border: 2px solid #4D2C77;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #4D2C77;
    font-weight: 600;
    cursor: pointer;
    /* &:hover{
        background-color: #4D2C77;
        color: white;
    } */
    @media (max-width: 400px) {
        width: 6rem;
        height: 2rem;
        font-size: 14px;
    }
`
