import React, { useEffect, useState } from "react";
import {
  Head3,
  Loader,
  Option,
  ProfileButton,
  ProfileButton2,
  ProfileEdit,
  ProfileForm,
  Select,
} from "./Profilestyle";
import { Auth } from "../../Services/api";
import { getErrorMessage } from "../Error/Errorcode";
import Taost from "../Toast/Toast";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { educationData } from "../data/profile.lang";

const EducationForm = () => {
  const [degree, setDegree] = useState("");
  const [College, setcollege] = useState("");
  const [specialization, setspecialization] = useState("");
  const [allcollege, setallcollege] = useState([]);
  const [alldata, setalldata] = useState([]);
  const [allsecilization, setallsecilization] = useState([]);
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const navigate = useNavigate();
  const lang = useSelector((store) => store?.lang?.language);

  useEffect(() => {
    getProfileData();
  }, []);

  const getProfileData = async () => {
    try {
      const data = await Auth.getProfile();
      const { education_name,  university_name, college_name,college_id,education_level_id ,university_id} = data?.data?.data?.user;
      setDegree(education_level_id);
      setcollege(university_id);
      setspecialization(college_id);
      const edudata= await Auth.getEducationData();
      const data2=await Auth.getUniversity(education_level_id);
      const clgdata=await Auth.getCollege(university_id,education_level_id);
      // const 
      setallcollege(data2?.data?.data);
      setallsecilization(clgdata?.data?.data);
      setalldata(edudata?.data?.data);
    } catch (error) {
      const errorcode = error?.response?.data?.error_code;
      const message = getErrorMessage(errorcode, lang);
      toast(<Taost message={message} error />);
    }
  };
  //on academic level change
  const handleAcademicLevel = async (inputData) => {
    try {
      const education_id = {
        education_level_id: inputData,
      };
      const update = await Auth.updateProfile(education_id);
      const data2=await Auth.getUniversity(inputData);
      setcollege("");
      setDegree(inputData);
      setallcollege(data2?.data?.data);
      setallsecilization([]);
      setspecialization("");
      setButtonDisabled(true);
    } catch (error) {
      const errorcode = error?.response?.data?.error_code;
      const message = getErrorMessage(errorcode, lang);
      toast(<Taost message={message} error />);
    }
  };

  const handlecollegename = async (inputData) => {
    try {
      const datatoupdate = {
        university_id: inputData,
      };
      const update = await Auth.updateProfile(datatoupdate);
      const data2=await Auth.getCollege(inputData,degree);
      setallsecilization(data2?.data?.data);
      setcollege(inputData);
      setspecialization("");
      setButtonDisabled(true);
    } catch (error) {
      const errorcode = error?.response?.data?.error_code;
      const message = getErrorMessage(errorcode, lang);
      toast(<Taost message={message} error />);
    }
  };

  const handleSpecialization = async (inputData) => {
    try {
      setspecialization(inputData);
      setButtonDisabled(false);
    } catch (error) {
      const errorcode = error?.response?.data?.error_code;
      const message = getErrorMessage(errorcode, lang);
      toast(<Taost message={message} error />);
    }
  };

  const handleSave = async (event) => {
    try {
      event.preventDefault();
      const datatoupdate = {
        education_level_id: degree,
        university_id:College,
        college_id:specialization,
      };
      
      const data = await Auth.updateProfile(datatoupdate);
      toast(<Taost message="Profile updated" />);
      navigate("/");
    } catch (error) {
      const errorcode = error?.response?.data?.error_code;
      const message = getErrorMessage(errorcode);
      toast(<Taost message={message} error />);
    }
  };
  if (alldata.length === 0 || !allcollege || !allsecilization) {
    return (
      <ProfileEdit>
        <ProfileForm>
          <Loader />
        </ProfileForm>
      </ProfileEdit>
    );
  }
  return (
    <ProfileEdit>
      <ProfileForm>
        <Head3>{educationData[lang]?.educationalData}</Head3>
        <Select
          onChange={(e) => handleAcademicLevel(e.target.value)}
          value={degree}
        >
          {alldata &&
            alldata.map((data) => {
              return <Option value={data.tmyyoz_id}>{data.name}</Option>;
            })}
        </Select>
        <Select
          onChange={(e) => handlecollegename(e.target.value)}
          value={College}>
          <Option value="">{educationData[lang]?.college}</Option>
          {allcollege &&
            allcollege.map((data) => {
              return <Option value={data.tmyyoz_id}>{data.name}</Option>;
            })}
        </Select>
        <Select
          onChange={(e) => handleSpecialization(e.target.value)}
          value={specialization}
        >
          <Option value="">{educationData[lang]?.specialization}</Option>
          {allsecilization &&
            allsecilization.map((data) => {
              return <Option value={data.tmyyoz_id}>{data.name}</Option>;
            })}
        </Select>
        <ProfileButton2
          onClick={(e) => handleSave(e)}
          disabled={isButtonDisabled}
        >
          {educationData[lang]?.save}
        </ProfileButton2>
        <ProfileButton2 modified onClick={() => navigate("/")}>
          {educationData[lang]?.cancel}
        </ProfileButton2>
      </ProfileForm>
    </ProfileEdit>
  );
};

export default EducationForm;
