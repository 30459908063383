import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data:[],
    id: "",
    updation:false,
}

const LibraryDataSlice = createSlice({
    name:'libraryData',
    initialState,

    reducers:{
        setLibraryData:(state, action) => {
            state.data=null;
            state.id=null;
            state.data = action.payload.data;
            state.id = action.payload.id;
        },

        setUpdation:(state) => {
            state.updation = !state.updation;
        }
    }
})

export const {setLibraryData, setUpdation} = LibraryDataSlice.actions;
export default LibraryDataSlice.reducer;