import React from 'react'
import { FooterContainer, FooterColContainer, ColumnDataContainer, Heading, DataContainer, IconsContainer, CopyrightContainer, ArrowIconImage, FooterDescription, PaymentIconsContainer, BottomBorderLine } from './Footer.styles';
import LeftArrowIcon from '../../Assets/Images/LeftArrowIcon.svg'
import CompanyLogo from '../../Assets/Images/CompanyLogo.svg'
import { useSelector } from 'react-redux';
import {FooterLanguage} from "../../Components/data/Footer.lang"
import VisaIcon from "../../Assets/Images/VisaIcon.svg"
import MadaIcon from "../../Assets/Images/MadaIcon.svg"
import PaypalIcon from "../../Assets/Images/PaypalIcon.svg"
import MasterCardIcon from "../../Assets/Images/MasterIcon.svg"

const Footer = () => {

    const lang = useSelector((store) => store.lang.language)

    return (
    <FooterContainer>
        <FooterColContainer>

            <ColumnDataContainer lang={lang} noborder={`${true}`}  width='24rem'>
                <Heading>
                    <img src={CompanyLogo} alt='company logo' />
                </Heading>
                <FooterDescription lang={lang}>{"منصة تعليمية إلكترونية توفر وتطور دورات متخصصة وموارد تعليمية لمساعدة الطلاب على الاستعداد للاختبارات القياسية والتفوق في مناهجهم الأكاديمية بالتعاون مع معلمين وشارحين متخصصين.  "}</FooterDescription>
                <BottomBorderLine></BottomBorderLine>
            </ColumnDataContainer>

            <ColumnDataContainer lang={lang}>
                <Heading>
                    {lang === 'en' ? "About distinction" : "عن تميز" }
                </Heading>
                {FooterLanguage[lang].about.map((data, index) => {
                    return(
                        <DataContainer key={index} href={data.link} target='_blank'>
                            <ArrowIconImage lang={lang} src={LeftArrowIcon} alt='right arrow icon'/>
                            <span>{data?.name}</span>
                        </DataContainer>
                    )
                })}
                <BottomBorderLine></BottomBorderLine>
            </ColumnDataContainer>

            <ColumnDataContainer lang={lang} borderright='true'>
                <Heading>
                    {lang === 'en' ? "Important links" : "روابط مهمة" }
                </Heading>
                {FooterLanguage[lang].links.map((data, index) => {
                    return(
                        <DataContainer href={data?.link} target="_blank" key={index} >
                            <ArrowIconImage lang={lang}  src={LeftArrowIcon} alt='right arrow icon'/>
                            <span>{data?.name}</span>
                        </DataContainer>
                     
                    )
                })}
                <BottomBorderLine></BottomBorderLine>
            </ColumnDataContainer>

            <ColumnDataContainer lang={lang}>
                <Heading>
                {lang === 'en' ? "Connect with us" : "تواصل معنا" }
                </Heading>
                {FooterLanguage[lang].contact.map((data, index) => {
                    return(
                        <DataContainer key={index}>
                            <img src={data.icon} alt='call icon'/>
                            <span>{data.number || data.mail}</span>
                        </DataContainer>
                    )
                })}
                <IconsContainer>
                {FooterLanguage[lang].icons.map((data, index) => {
                    return(
                        <DataContainer key={index} href={data?.link} target='_blank'>
                            <img src={data.name} alt='icons' style={{width: '40px'}}/>
                        </DataContainer>
                    )
                })}
                </IconsContainer>

            </ColumnDataContainer>
            
        </FooterColContainer>
        <CopyrightContainer>
            <PaymentIconsContainer>
                <img src={MasterCardIcon} alt=""/>
                <img src={MadaIcon} alt=""/>
                <img src={VisaIcon} alt=""/>
            </PaymentIconsContainer>
             {"جميع الحقوق محفوظة لمنصة تميز ©2024"}
        </CopyrightContainer>
    </FooterContainer>
    )
}

export default Footer