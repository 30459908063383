import React, { useEffect } from 'react'
import { NavigationContainer, NavigationSubContainer, HomeImage, PathContainer, ArrowIconImage, NavigationName } from '../Library/Library.styles'
import HomeIcon from '../../Assets/Images/HomeIcon.svg'
import LeftArrowIcon from '../../Assets/Images/LeftArrowIcon.svg'
import {useLocation, useNavigate} from 'react-router-dom'
import localStorage from "../../Store/index"
import { removeRoutesArray, updateRoutesArray } from '../../Store/Slice/Navigation/NavigationSlice'
import { useDispatch, useSelector } from 'react-redux'
import { setLibraryData } from '../../Store/Slice/LibraryData/LibraryDataSlice'
import { clearData } from '../../Store/Slice/FlashCards/GetFlashCardsSlice'
import { LibraryCardLanguage } from '../data/LibraryCard.lang'

const Navigation = ({getData, setIsLoading, insideDocument}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const NavigationData = localStorage.getState().navigationData.routesArray;
    const lang = useSelector((store) => store.lang.language)

    function getLastPart(location) {
        const parts = location.split('/');
        dispatch(clearData([]));
        if(parts.at(-1) === ''){
            parts.pop();
        }
        return parts.at(-1);
    }
    
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const id = params.get('id');
        if(getLastPart(location.pathname) === 'library' && id === null){
            dispatch(removeRoutesArray())
            dispatch(updateRoutesArray({data: LibraryCardLanguage[lang]?.libraryNaviagtion, id: ""}))
        }
        // console.log(NavigationData.length)
        if(insideDocument && NavigationData.length === 1){
            dispatch(updateRoutesArray({data: "", id: ""}))
        }
    },[])
    
    const handleClick = (data) => {
        dispatch(clearData([]));
        // if (localStorage?.getItem('FlashCards') != null)
        //         localStorage?.removeItem('FlashCards');
        if(data === 'home'){
            navigate("/");
        }
        else if(data.name === LibraryCardLanguage[lang]?.publicLibraryNavigation){
            console.log(data.name)
            navigate('/public')
        }
        else if(!insideDocument){
            setIsLoading(true)
            getData(data.id)
            if(data.name === LibraryCardLanguage[lang]?.libraryNaviagtion){
                navigate('/library', {replace:true})
            }
            else{
                navigate(`/library/?id=${data.id}`, {replace:true});
            }
        }
        else{
            dispatch(setLibraryData({data: null, id: data.id}))
            if(data.name === LibraryCardLanguage[lang]?.libraryNaviagtion){
                navigate('/library', {replace:true}) // handeling when user click on libray on navigation
            }
            else if(data.type !== 'document'){
                navigate(`/library/?id=${data.id}`, {replace:true})
            }
        }

    }

    return (
        <NavigationContainer>
            <NavigationSubContainer lang={lang}>
                <HomeImage src={HomeIcon} alt='home icon' onClick={() => {handleClick('home')}}/>
                {NavigationData.map((data, index) => {
                    return (
                        <PathContainer lang={lang} key={index} $color={data.color} onClick={() => handleClick(data)}>
                            <ArrowIconImage lang={lang} src={LeftArrowIcon} alt='arrow icon'/>
                            <NavigationName>{data.name}</NavigationName>
                        </PathContainer>
                    )
                })}
            </NavigationSubContainer>
        </NavigationContainer>
    )
}

export default Navigation






