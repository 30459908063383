import { useSelector } from "react-redux";
import {Directions, EmptyFolderContainer } from "./Library.styles";

const EmptyFolder = () => {
    const lang = useSelector((store) => store.lang.language);
    return (
        <EmptyFolderContainer>
                {lang === 'en' ? "NO DOCUMENTS OR FOLERS FOUND" : "لم يتم العثور على أي مستندات أو مجلدات"}
            <Directions>
                {lang === 'en' ? "You can add file or document from above button" : "يمكنك إضافة ملف أو مستند من الزر أعلاه" }    
            </Directions>
        </EmptyFolderContainer>
    )
}

export default EmptyFolder;