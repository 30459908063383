import CallIcon from '../../Assets/Images/CallIcon.svg';
import MailIcon from '../../Assets/Images/MailIcon.svg';
import tiktokIcon from '../../Assets/Images/tiktok2.svg'
import TwitterIcon from '../../Assets/Images/TwitterIcon.svg'
import InstagramIcon from '../../Assets/Images/InstagramIconsvg.svg'
import LinkedinIcon from '../../Assets/Images/linkedin4.svg'

export const FooterLanguage = {

    ar : {
        "contact" : [
            {   
                "number" :  "+966561229596 ",
                "icon" : CallIcon
            },
            {   
                "mail" : "info@tmyyoz.com",
                "icon" : MailIcon
            }
        ],
    
        'icons' : [
            {name:LinkedinIcon,link:"https://sa.linkedin.com/company/tmyyoz"},
            {name:InstagramIcon,link:"https://www.instagram.com/tmyyozsa?igsh=ZzJtM3d1ZjhtcHNu"},
            {name:TwitterIcon,link:"https://twitter.com/tmyyozsa"},
            {name:tiktokIcon,link:"https://www.tiktok.com/@tmyyozsa?_t=8lrTYg9LB0y&_r=1"},
        ],
    
        "links" : [
           {name: "الشروط والأحكام",link:"https://www.tmyyoz.com/students/page/6"},
           {name: "سياسة الخصوصية",link:"https://www.tmyyoz.com/students/page/4"},
            {name:"الأسئلة الشائعة",link:""},
            {name: "سياسة الإسترجاع",link:"https://www.tmyyoz.com/students/page/3"},
        ],
    
        "about" : [
            {name: "من نحن",link:"https://www.tmyyoz.com/"},
            {name: "الملف الشخصي",link:""},
            {name:"مدونة تميز",link:"https://www.tmyyoz.com/students/blogs"},
            {name: "مركز المساعدة",link:"https://www.tmyyoz.com/students/help"},
        ],
    
        "description" :" نتيح لك فرصة اكتساب المعرفة بطريقة ملهمة وشيقة. انطلق في رحلة استكشاف وتعلم واستمتع بتجربة تعلم تتناغم مع احتياجاتك الفرديةمن دورات متنوعة وموارد متقدمة.",
    
        "copyright" :"2024© جميع الحقوق محفوظة لمنصة تميز ",
    },

    en : {
        "contact" : [
            {
                "number" : "0096 1234 567 765 ",
                "icon" : CallIcon
            },
            {
                "mail" : "info@namedweb.sa",
                "icon" : MailIcon
            }
        ],
    
        // 'icons' : [
        //     linkedinIcon,
        //     InstagramIcon,
        //     TwitterIcon,
        //     tiktokIcon,
        // ],
    
        "links" : [
            {name:"Terms and Conditions",link:"https://www.tmyyoz.com/students/page/6"},
            {name: "privacy policy", link:"https://www.tmyyoz.com/students/page/4"},
            {name: "common questions",link:""},
            {name: "Return policy",link:"https://www.tmyyoz.com/students/page/3"},
            // "Return policy",
        ],
    
        "about" : [
            {name: "who are we",link:"https://www.tmyyoz.com/"},
            {name: "Profile personly", link:""},
            {name: "Excellence blog",link:"https://www.tmyyoz.com/students/blogs"},
            {name: "Help Center",link:"https://www.tmyyoz.com/students/help"},
        ],
    
        "description" : "We give you the opportunity to gain knowledge in an inspiring and interesting way. Embark on a journey of discovery and learning and enjoy a learning experience tailored to your individual needs with diverse courses and advanced resources.",
    
        "copyright" : "All rights reserved to Tamayoz ©2023",
    },
}

