import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import {
  AuthWrapper,
  ButtonContainer,
  ButtonStyle,
  Error,
  FormContainer,
  HeadingContainer,
  InputStyle2,
  LoginContainer,
  ParaContainer,
  ParagraphContainer,
  PasswordInput,
  Passwordvisible,
  Phone2Input,
  SocialButton,
  ViewContain,
  ViewLine,
  ViewText,
  A,
  Span,
  Loader,
  LoaderCenter,
  InputStyle,
  ViewContain2,
} from "./Auth.Style";
import { BiSolidHide, BiSolidShow } from "react-icons/bi";
import { validateEmail, validatePassword } from "./Validate";
import Model from "./Model";
import { useDispatch, useSelector } from "react-redux";
import { registerDispatcher } from "../../Store/Slice/auth/Auth.Slice";
import { useLocation, useNavigate } from "react-router-dom";
import { authlang } from "../data/auth.lang";

const Register = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [value, setValue] = useState("sa");
  const [visible, setVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessage2, setErrorMessage2] = useState({});
  const [isDisable, setIsButtonDisable] = useState(true);
  const [moberror, setMobError] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.auth);
  const lang = useSelector((state) => state?.lang?.language);
  const isAuthanticated = useSelector((store) => store?.auth?.isAuthenticated);
  const clickId = useSelector((store) => store?.userData?.clickId);

  //navigate to home if user is already logged in
  useEffect(() => {
    if (isAuthanticated) {
      navigate("/");
    }
  }, [isAuthanticated]);

  // Scroll to top when location changes
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  //used for email validation
  useEffect(() => {
    if (!email || email === "") {
      setErrorMessage("");
      return;
    }
    const message = validateEmail(email, lang);
    if (message) {
      setErrorMessage(message);
    } else {
      setErrorMessage("");
    }
  }, [email, lang]);

  //used for password validation
  useEffect(() => {
    if (!password || password === "") {
      setErrorMessage2("");
      return;
    }
    const message = validatePassword(password, lang);
    if (message) {
      setErrorMessage2(message);
    } else {
      setErrorMessage2("");
    }
  }, [password, lang]);

  useEffect(() => {
    if (value.length >= 12) {
      if (value.startsWith("5") && value.length === 12) {
        setValue(value.slice(3));
      }
    }
  }, [value]);

  //form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    const params = new URLSearchParams(window.location.search);
    const ref = params.get("ref");
    if (value.length >= 12) {
      setValue(value.slice(3));
    }
    const data = {
      name: name,
      phone_number: `${value}`,
      password: password,
      email: email,
    };

    if (email.length > 0) {
      data.email = email;
    } else {
    }

    if (clickId !== null) {
      // console.log(clickId)
      data.click_id = clickId;
    }

    dispatch(registerDispatcher(data, ref, () => navigate("/verify"), lang));
  };

  // button disable

  useEffect(() => {
    isButtonDisabled();
  }, [name, password, value, email]);

  const isButtonDisabled = () => {
    if (
      name.length === 0 ||
      password.length <= 4 ||
      value.length === 3 ||
      email.length === 0 ||
      errorMessage ||
      moberror
    ) {
      setIsButtonDisable(true);
    } else {
      setIsButtonDisable(false);
    }
  };

  const validatePhoneNumber = (value) => {
    const nationalNumber = value.slice(3);
    if (nationalNumber.startsWith("5") && nationalNumber.length === 9) {
      setValue(nationalNumber);
      setMobError(false);
      return true;
    } else {
      if (nationalNumber.length === 0) {
        setMobError(false);
        return true;
      }
      if (nationalNumber.startsWith("5") && nationalNumber.length <= 9) {
        setMobError(true);
        return false;
      }
      setMobError(true);
      return false;
    }
  };

  return (
    <AuthWrapper>
      <LoginContainer>
        <HeadingContainer>{authlang[lang].registerHeading}</HeadingContainer>
        <FormContainer>
          <InputStyle
            regis
            type="text"
            placeholder={authlang[lang].fullname}
            onChange={handleNameChange}
            lang={lang}
          />
          <Phone2Input
            country={value}
            disableDropdown={true}
            onChange={(data) => setValue(data)}
            placeholder="5xxxxxxxx"
            countryCodeEditable={false}
            isValid={(value, country) => validatePhoneNumber(value)}
          />
          {moberror && (
            <Error>
              {"رقم الهاتف غير صحيح. يجب أن يبدأ بـ 5 وأن يتكون من 9 أرقام."}
            </Error>
          )}
          <InputStyle
            type="email"
            placeholder={authlang[lang].email}
            value={email}
            onChange={handleEmailChange}
            lang={lang}
            required
          />
          {errorMessage && <Error>{errorMessage}</Error>}
          <PasswordInput>
            <InputStyle2
              value={password}
              onChange={handlePasswordChange}
              type={visible ? "text" : "password"}
              placeholder={authlang[lang].password}
              pass
              lang={lang}
            />
            <Passwordvisible onClick={() => setVisible(!visible)}>
              {visible ? <BiSolidShow /> : <BiSolidHide />}
            </Passwordvisible>
          </PasswordInput>
          {errorMessage2.lengthError && (
            <Error>{errorMessage2.lengthError}</Error>
          )}
          {errorMessage2.uppercaseError && (
            <Error>{errorMessage2.uppercaseError}</Error>
          )}
          {errorMessage2.numberError && (
            <Error>{errorMessage2.numberError}</Error>
          )}
          {errorMessage2.specialCharError && (
            <Error>{errorMessage2.specialCharError}</Error>
          )}

          <ButtonContainer>
            <ButtonStyle
              type="submit"
              disabled={isDisable}
              onClick={handleSubmit}
            >
              {loading ? (
                <LoaderCenter>
                  <Loader />
                </LoaderCenter>
              ) : (
                authlang[lang].registerbutton
              )}
            </ButtonStyle>
          </ButtonContainer>
          <ViewContain>
            <ViewLine />
            <ViewText>
              {authlang[lang].registeraccountdesc}{" "}
              <A to={"/user/login"}>{authlang[lang].registeraccountdesc2}</A>
            </ViewText>
            <ViewLine />
          </ViewContain>

          <ParaContainer>
            <ParagraphContainer>
              {authlang[lang].term1}
              <A href=""> {authlang[lang].term2}</A> {authlang[lang].term3}
            </ParagraphContainer>
          </ParaContainer>
        </FormContainer>
      </LoginContainer>
    </AuthWrapper>
  );
};

export default Register;
