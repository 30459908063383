export const LibraryCardLanguage = {
    ar: {
        publicButton: "عام",
        privateButton: "خاص",
        completeTag: "مكتمل",
        needReviewTag: "يحتاج مراجعة",
        remainingTag: "متبقي",
        edit: "تعديل للملف",
        transfer: "نقل الملف",
        download: "حمل الملف",
        delete: "حذف الملف",
        facebook: "فيسبوك",
        twitter: "تويتر",
        copy: "ينسخ",
        copyNotification: "تم النسخ بنجاح",
        privateFileNotification: "غير الملف الى عام حتى تتمكن من مشاركته"    ,
        libraryNaviagtion:"مكتبتي",
        publicLibraryNavigation: "مكتبة عامة",
    },

    en: {
        publicButton: "Public",
        privateButton: "Private",
        completeTag: "comp.",
        needReviewTag: "need re.",
        remainingTag: "remain.",
        edit: "Edit File",
        transfer: "Transfer File",
        download: "Download File",
        delete: "Delete File",
        facebook: "Facebook",
        twitter: "Twitter",
        copy: "Copy",
        copyNotification: "Copied Successfully",
        privateFileNotification: "Document is private make it public to share",
        libraryNaviagtion:"Library",
        publicLibraryNavigation: "public library",
    },
}