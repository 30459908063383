import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./auth/Auth.Slice";
import NavigationSlice from "./Navigation/NavigationSlice";
import UserDetailsSlice from "./UserDetails/UserDetailsSlice";
import LibraryDataSlice from "./LibraryData/LibraryDataSlice";
import FolderCreationSlice from "./FolderCreation/FolderCreationSlice";
import langSlice from "./Language/langSlice";
import FlashCardsReducer  from "./FlashCards/GetFlashCardsSlice";
import QuizReducer from "./FlashCards/FlashCardsQuiz";
import SubscriptionModalSlice from "./SubscriptionModal/SubscriptionModal.slice";
import PublicReducer from "./PublicLibrary/Public.Slice"; 
import PremiumDetailsSlice from "./PremiumDetails/PremiumDetailsSlice";

const reducers = combineReducers({
  libraryData: LibraryDataSlice,
  auth: authReducer,
  FlashCards:FlashCardsReducer,
  Quiz:QuizReducer,
  navigationData : NavigationSlice,
  userData : UserDetailsSlice,
  folderCreationData : FolderCreationSlice,
  lang: langSlice,
  subscriptionVisibility:  SubscriptionModalSlice,
  public: PublicReducer,
  premiumDetails: PremiumDetailsSlice
});

export default reducers;
