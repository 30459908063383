import React, { useEffect, useState } from 'react'
import { Error, Interactivedatacontainer, Interactivedatawrapper, LibPublic, Librarydatacontent, Librarydataheader, OptionsModal } from '../PublicLibrary/public.style'
import LibraryBlogCard from '../PublicLibrary/LibraryBlogCard';
import { useSelector } from "react-redux";
import { home } from "../../Services/api";
import { publicLang } from '../data/public.lang';
import { getErrorMessage } from '../Error/Errorcode';
import { toast } from 'react-toastify';
import Taost from '../Toast/Toast';
import { SubHeadingText } from './blogs.styles';


const Recentdata = () => {
  const lang=useSelector((store)=>store?.lang?.language);
  const [LibraryContent, setLibraryContent] = useState([]);
  const [activeFile, setActiveFile] = useState(null);
  const [activeShare, setActiveShare] = useState(null);
  const [moreOptions, setMoreOptions] = useState(false);

  const fetchdata = async () => {
    try {
      const data = await home.getdata();
      setLibraryContent(data?.data?.data?.library);
    } catch (error) {
      setLibraryContent([]);
      const message = getErrorMessage(error?.response?.data?.error_code, lang);
      toast(<Taost message={message} error />);
    }
  };
  useEffect(() => {
    fetchdata();
  }, []);
  
  const handleousideclick = () => {
    setMoreOptions(false);
    setActiveShare(null);
    setActiveFile(null);
  }
  if(LibraryContent.length === 0){
    return <Error>{publicLang[lang]?.nodata}</Error>
  }
  
  return (
    <Interactivedatacontainer onClick={()=>handleousideclick()}>
        <Interactivedatawrapper>
            <Librarydataheader > {publicLang[lang].head4} </Librarydataheader>
            <SubHeadingText>{publicLang[lang].desc4}</SubHeadingText>
         <LibPublic showScrollbar={LibraryContent.length<=5} > 
            {LibraryContent === null && <Error>{publicLang[lang]?.nodata}</Error>} 
            {LibraryContent?.length !== 0 &&
              LibraryContent?.map((data, index) => {
                return (
                <div key={index}>
                  <LibraryBlogCard data={data} activeShare={activeShare} setActiveShare={setActiveShare} activeFile={activeFile} setActiveFile={setActiveFile} moreOptions={moreOptions} setMoreOptions={setMoreOptions} />
                </div>
               );
            })}
          </LibPublic>
        </Interactivedatawrapper>
    </Interactivedatacontainer>
  )
}




export default Recentdata