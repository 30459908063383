import React from 'react';
import { MdNavigateNext } from "react-icons/md";
import { GrFormPrevious } from "react-icons/gr";
import flag from "../../Assets/Images/flag.png";
import {
  Flag,
  FlagTopBar,
  InsideTopDiv,
  TestTop,
} from '../FlashCardQuiz/Quiz.styles';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {FlashCardsLearning} from '../data/FlashCardLearning.lang'
import { FlashCardQuiz } from '../data/FlashCardQuiz.lang';

const TopBar = ({containReport}) => {
  const navigate = useNavigate();
  const document_id = useSelector((state) => state?.FlashCards?.document_id);
  const lang = useSelector((state) => state?.lang?.language);

  const handleExit = () => {
    navigate(`/flash-card/${document_id}`)
  }
  return (
      <TestTop>
        <InsideTopDiv lang={lang} onClick={handleExit}>
          {
            lang === "en"?
            <GrFormPrevious
              style={{ fontSize: "22px", fontWeight: "500", color: "#727272" }}
            />:
            <MdNavigateNext
              style={{ fontSize: "22px", fontWeight: "500", color: "#727272" }}
            />
          }
            {FlashCardsLearning[lang].Exit}
        </InsideTopDiv>
        {containReport ?
        <FlagTopBar>
        <img style={{ marginRight: lang === "en" ? "12px" : "0", marginLeft: lang === "en" ? "0" : "12px" }} src={flag} alt="" />
            {FlashCardQuiz[lang].Report}
        </FlagTopBar>
        :
        null
        }
        </TestTop>
  )
}

export default TopBar
