import { useSelector } from "react-redux";

export const error_codes = {
  1001: "Invalid phone number is provided",
  1002: "Invalid email address is provided",
  1003: "Invalid password is provided",
  1004: "User with this email already exists",
  1005: "User with this phone number already exists",
  1006: "Invalid email, academic level, college or specialization provided",
  1009: "Invalid user credentials",
  1010: "User not found with given phone number",
  1011: "No user found with this phone number",
  1012: "Invalid request",
  1013: "Invalid token/token expired",
  1014: "Invalid password provided",
  1015: "User not found",
  1016: "Invalid data in request. Make sure data are consistent eith available options",
  1017: "Access token valid but user not found",
  1018: "Invalid Phone number or password",
  1019: "no documents or folders found in the library",
  1020: "Invalid document or folder id",
  1021: "Invalid parent folder id",
  1054: "Mobile number or email address is already in use. Try to log in"
};
export const error_codes_ar = {
  1001: "رقم الهاتف المقدم غير صالح",
  1002: "عنوان البريد الإلكتروني المقدم غير صالح",
  1003: "كلمة المرور المقدمة غير صالحة",
  1004: "المستخدم بهذا البريد الإلكتروني موجود بالفعل",
  1005: "المستخدم بهذا الرقم الهاتف موجود بالفعل",
  1006: "البريد الإلكتروني غير صالح، المستوى الأكاديمي، الكلية أو التخصص المقدم",
  1009: "بيانات اعتماد المستخدم غير صالحة",
  1010: "لم يتم العثور على المستخدم بالرقم الهاتف المقدم",
  1011: "لم يتم العثور على مستخدم بهذا الرقم الهاتف",
  1012: "طلب غير صالح",
  1013: "الرمز غير صالح / الرمز منتهي الصلاحية",
  1014: "كلمة المرور المقدمة غير صالحة",
  1015: "لم يتم العثور على المستخدم",
  1016: "بيانات غير صالحة في الطلب. تأكد من أن البيانات متسقة مع الخيارات المتاحة",
  1017: "رمز الوصول صالح ولكن لم يتم العثور على المستخدم",
  1018: "رقم الهاتف غير صالح أو كلمة المرور",
  1019: "لم يتم العثور على وثائق أو مجلدات في المكتبة",
  1020: "معرف المستند أو المجلد غير صالح",
  1021: "معرف المجلد الأصلي غير صالح",
  1054: "رقم الجوال أو البريد الالكتروني مستخدم من قبل"
};


export function getErrorMessage(code, lang) {
  if (lang === "en") {
    return error_codes[code] || "Unknown error";
  } else {
    return error_codes_ar[code] || "خطأ غير معروف";
  }
}
