export const result = {
  ar: {
    heading:"استمر ! تقدمك رهيب",
    subhead:
      "نأمل أن يكون هذا تجربة مفيدة لك. ولا تتردد في استكشاف المزيد من دوراتنا التعليمية.",
    success: "نجاح",
    remaining: "متبقية",
    review: "يحتاج مراجعة",
    paragraph: "تقييمك يساعدنا على تحسين التجربة وتطويرها. شكرًا لك",
    Submitbutton: "اعادة الاختبار",
    ending: "انتهاء",
  },
  en: {
    heading: "Congratulations! Your progress is awesome keep it up",
    subhead:
      "We hope this was a useful experience for you. Feel free to explore more of our educational courses.",
    success: "Success",
    remaining: "remaining",
    review: "needs review",
    paragraph:
      "Your rating helps us improve and develop the experience. Thank you",
    Submitbutton: "Exam REPETITON",
    ending: "ending",
  },
};
