import React, { useEffect, useState } from "react";
import PageDetails from "./PageDetails";
import UserDetails from "./UserDetails";
import {RegisterLink, LoginLink, HeaderContainer, AuthenticationLinksContainer, LanguageButton, LanguageText, LanguageImage, LanguageImageMobile, HeaderSubContanier, LanguageChangeArrow} from "./Header.styles";
import { useSelector } from "react-redux";
import LanguageChangeDropdown from "./DropdownLanguageChange";
import ArabicLanguageIcon from '../../Assets/Images/ArabicLanguageIcon.svg';
import ArabicLanguageIconMobile from '../../Assets/Images/ArabicLanguageIconMobile.svg';
import EnglishLanguageIcon from '../../Assets/Images/EnglishLanguageIcon.svg';
import EnglishLanguageIconMobile from '../../Assets/Images/EnglishLanguageIconMobile.svg';
import {HeaderLanguage} from "../data/Header.lang"
import { useLocation } from "react-router-dom";
import DropdownAddPDF from "./DropdownAdd";
import DropdownUserProfile from "./DropdownUserProfile"

function Header({setCreateFolderModal, setCreateFileModal}) {
  const isAuthenticated = useSelector((store) => store.auth.isAuthenticated)
  const lang = useSelector((store) => store.lang.language)
  const [languageChangeVisibility, setLanguageChangeVisibility] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState('English')
  const [addPDFVisibility, setAddPDFVisibility] = useState(false);
  const [userProfileVisibility, setUserProfileVisibility] = useState(false);

  const location = useLocation()

  useEffect(() => {
    if(lang === 'ar'){
      setCurrentLanguage("العربية")
    }
  },[])

  return (
    <HeaderContainer>
        <HeaderSubContanier>
            <PageDetails isAuthenticated={isAuthenticated}/>
            {isAuthenticated ? 
            <>
              <UserDetails setCreateFileModal={setCreateFileModal} setCreateFolderModal={setCreateFolderModal} languageChangeVisibility={languageChangeVisibility} setLanguageChangeVisibility={setLanguageChangeVisibility} currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage}/>
              {addPDFVisibility ? <DropdownAddPDF setCreateFolderModal={setCreateFolderModal} setCreateFileModal={setCreateFileModal}/> : null}
              {userProfileVisibility ? <DropdownUserProfile setUserProfileVisibility={setUserProfileVisibility}/> : null}
            </>
            :
              <AuthenticationLinksContainer>
                    {/* <LanguageButton onClick={() => setLanguageChangeVisibility(!languageChangeVisibility)}>
                        {currentLanguage === "English" ?
                        <>
                          <LanguageImage src={EnglishLanguageIcon} alt="english language icon"/>
                          <LanguageImageMobile src={EnglishLanguageIconMobile} alt="english language icon"/>
                        </>
                        :
                        <>
                          <LanguageImage src={ArabicLanguageIcon} alt="arabic language icon"/>
                          <LanguageImageMobile src={ArabicLanguageIconMobile} alt="arabic language icon"/>
                        </>
                        }
                        <LanguageText>{currentLanguage}</LanguageText>
                        <LanguageChangeArrow width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L7 7L13 1" stroke="#727272" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </LanguageChangeArrow>
                    {languageChangeVisibility ? <LanguageChangeDropdown setLanguageChangeVisibility={setLanguageChangeVisibility} currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage}/> : null}
                    </LanguageButton> */}
                    {location.pathname !== '/user/register' ? 
                    <RegisterLink to={{ pathname: "/user/register", state: { fromHome: true } }} hideOnMobile={location.pathname === '/'} >
                    {HeaderLanguage[lang].registerButton} </RegisterLink>
                    : null}
                    {location.pathname !== '/user/login' ? 
                    <LoginLink to="/user/login">{HeaderLanguage[lang].loginButton}</LoginLink>
                    : null}
                    
              </AuthenticationLinksContainer>
            }
          </HeaderSubContanier>
    </HeaderContainer>
  );
}

export default Header;
