import React, { useEffect, useState } from "react";
import { CardsWrapper, LibBlog } from "./blogs.styles";
import { useDispatch, useSelector } from "react-redux";
import { home } from "../../Services/api";
import { Error } from "../Auth/Auth.Style";
import { publicLang } from "../data/public.lang";
import LibraryBlogCard from "../PublicLibrary/LibraryBlogCard";


const LibraryBlog = ({activeFile,setActiveFile,activeShare,setActiveShare,moreOptions,setMoreOptions}) => {
  const [LibraryContent, setLibraryContent] = useState([]);
  const isAuth = useSelector((store) => store?.auth?.isAuthenticated);
  const lang = useSelector((store) => store?.lang?.language);
  
  const fetchdata = async () => {
    try {
      const data = await home.getrecent();
      setLibraryContent(data?.data?.data?.library?.slice(0,8));
    } catch (error) {
      setLibraryContent([]);
    }
  };
  useEffect(() => {
    fetchdata();
  }, [isAuth]);



  return (
    <LibBlog >
      {LibraryContent.length === 0 && <Error style={{fontSize:"2.3rem"}}>{publicLang[lang]?.nodata}</Error>}
      {LibraryContent.length !== 0 &&
        LibraryContent.map((data, index) => {
          return (
            <div key={index}>
              <LibraryBlogCard data={data} activeShare={activeShare} setActiveShare={setActiveShare} activeFile={activeFile} setActiveFile={setActiveFile} moreOptions={moreOptions} setMoreOptions={setMoreOptions}  />
            </div>
          );
        })}
    </LibBlog>
  );
};



export default LibraryBlog;
