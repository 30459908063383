import React from "react";
import { DropdownContainerProfile, SignoutSVG, StyledList } from "./Header.styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Logoutdispatcher } from "../../Store/Slice/auth/Auth.Slice";
import UserProfileIcon from "../../Assets/Images/UserProfileIcon.svg"
import SignoutIcon from "../../Assets/Images/SignoutIcon.svg"
import {HeaderLanguage} from "../data/Header.lang"

const DropdownUserProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const lang = useSelector((store) => store.lang.language)

  const logout = () => {
    dispatch(Logoutdispatcher());
    navigate("/");
  };

  return (
    <DropdownContainerProfile lang={lang}>
      <StyledList onClick={() => navigate("/user/profile")}>
        <img src={UserProfileIcon} alt='user profile icon'/>
        {HeaderLanguage[lang].profile}
      </StyledList>
      <StyledList color="#F55157" bordercolor="#F4F4F4" onClick={logout}>
        <SignoutSVG lang={lang} src={SignoutIcon} alt='user profile icon'/>
        {HeaderLanguage[lang].signout}
      </StyledList>
    </DropdownContainerProfile>
  );
};

export default DropdownUserProfile;
