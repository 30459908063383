import React, { useEffect, useState } from "react";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer.js";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import FolderCreationModal from "../Components/Modals/FolderCreationModal/FolderCreationModal";
import UploadFileModal from "../Components/Modals/UploadFileModal/UploadFileModal";
import { createGlobalStyle } from "styled-components";
import { useSelector } from "react-redux";
import Toast from "../Components/Toast/Toast.js";

const GlobalStyle = createGlobalStyle`
  .toastProgress {
    background: #4d2c77 !important;
  }
`;

const Layout = () => {
  const [createFolderModal, setCreateFolderModal] = useState(false);
  const [createFileModal, setCreateFileModal] = useState(false);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const navigate = useNavigate();

  const location = useLocation();
  useEffect(() => {
    if (!isAuthenticated) {
      if (
        !(
          location.pathname === "/user/login" ||
          location.pathname === "/user/register" ||
          location.pathname === "/"||
          location.pathname === "/verify"||
          location.pathname==="/forgot-password"||
          location.pathname==="/forgot_password_verify"||
          location.pathname==="/update_password"
        )
      ) {
        navigate("/user/login");
        toast(<Toast message="please Login to continue" error />);
      }
    }
  }, [location.pathname]);
  return (
    <div>
      <GlobalStyle />
      <ToastContainer
        position="top-center"
        progressClassName="toastProgress"
        newestOnTop={false}
        closeOnClick
        autoClose={7000}
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      {location.pathname === "/Flash/StartLearning/Cards" || location.pathname==="/Flash/StartQuiz" ||  location.pathname === "/Flash/StartLearning" || location.pathname==="/Flash/StarTest/Quiz"  ?"":<Header setCreateFolderModal={setCreateFolderModal} setCreateFileModal={setCreateFileModal} />}
      <Outlet />
      {location.pathname === "/Flash/StartLearning/Cards" ||
      location.pathname === "/Flash/Result" ||
      location.pathname === "/Flash/StartLearning" ||
      location.pathname === "/Flash/StartQuiz" ||
      location.pathname === "/Flash/StarTest/Quiz" ||
      location.pathname === "/Flash/TestResult" ? (
        ""
      ) : (
        <Footer />
      )}
      {createFolderModal ? (
        <FolderCreationModal
          setCreateFolderModal={setCreateFolderModal}
          type="create folder"
          IsPublic={false}
        />
      ) : null}

      {createFileModal ? (
        <UploadFileModal setCreateFileModal={setCreateFileModal} />
      ) : null}
    </div>
  );
};

export default Layout;
