import { useEffect, useState } from "react";
import ApiClient from "../../../Config/index.config";
import PaymentStatus from "./PaymentStatus.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BsExclamationCircleFill } from "react-icons/bs";
import { FaCircleCheck } from "react-icons/fa6";
import { MdCancel } from "react-icons/md";
import { IoChevronBack } from "react-icons/io5";

const PaymentProcessing = () => {

    const navigate = useNavigate();
    const lang = useSelector((store) => store?.lang?.language);
    const [showStatus, setShowStatus] = useState(null);
    const [borderColor, setBorderColor] = useState("#CA56A1")

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
    
        const id = params.get('id');
        if(id !== null){
          validateChackout(id);
        }else{
            navigate("/payment")
        }
    },[])

    const validateChackout = async (id) => {
        try{
            const response = await ApiClient.post("/payment/validate-checkout", {
                "checkout_id": id,
            });
            console.log(response?.data?.data?.status)
            setShowStatus(response?.data?.data?.status)
            if(response?.data?.data?.status === "SUCCESS"){
                setBorderColor("#5cb85c")
            }
            else if(response?.data?.data?.status === "FAILED" || response?.data?.data?.status === "CANCELLED"){
                setBorderColor("#ff3333")
            }
        }
        catch(error){
            console.log(error);
        }
    }

    const PaymentStatusText = {
        ar: {
            processing: "يرجى الانتظار بينما تتم معالجة الدفع",
            pendingHeading: "نحن نقوم بمعالجة الدفع الخاص بك",
            pendingSubHeading: "يمكنك التحقق بعد مرور بعض الوقت",
            failedHeading: "عملية الدفع فشلت",
            failedSubHeading: "حاول مرة أخرى في وقت لاحق",
            successHeading: "كانت عملية الدفع ناجحة",
            successSubHeading: "شكرا لتسديدك",
            goHome: "اذهب للمنزل",
        },
        en: {
            processing: "Please wait while payment is loading",
            pendingHeading: "We are processing your payment",
            pendingSubHeading: "You can check after some time",
            failedHeading: "Payment Failed",
            failedSubHeading: "Try again later",
            successHeading: "Your payment was successful",
            successSubHeading: "Thank you for your payment",
            goHome: "Go home",
        }
    }

    return(
        <div style={{width: "100%", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center"}}>
        {!showStatus ?
        <div className="payment-loader">
            <div className="pad">
                <div className="chip"></div>
                <div className="line line1"></div>
                <div className="line line2"></div>
            </div>
            <div className="loader-text">
                {PaymentStatusText[lang].processing}
            </div>
        </div>
        :null}
        
        {showStatus !== null ?
            <div className="container" style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent:"center"}}>
                    <div className="row justify-content-center">
                        <div className="col-md-5">
                            <div className="message-box _success" style={{borderBottom: `4px solid ${borderColor}`}}>
                                {showStatus === "SUCCESS"?
                                <>
                                    <FaCircleCheck style={{fontSize: "4rem", color: "#5cb85c"}}/>
                                    <h2 className="status-heading">{PaymentStatusText[lang].successHeading}</h2>
                                    <div className="status-subheading">{PaymentStatusText[lang].successSubHeading}</div>
                                </>
                                :null}
                                {showStatus === "PENDING" ?
                                <>
                                    <BsExclamationCircleFill style={{fontSize: "4rem", color: "#CA56A1"}}/>
                                    <h2 className="status-heading"> {PaymentStatusText[lang].pendingHeading}</h2>
                                    <div className="status-subheading">{PaymentStatusText[lang].pendingSubHeading}</div>
                                </>
                                :null}
                                {showStatus === "FAILED" || showStatus === "CANCELLED" ?
                                <>
                                    <MdCancel style={{fontSize: "4rem", color: "#ff3333"}}/>
                                    <h2 className="status-heading">{PaymentStatusText[lang].failedHeading}</h2>
                                    <div className="status-subheading">{PaymentStatusText[lang].failedSubHeading}</div>
                                </>
                                :null}       
                            </div> 
                        </div>
                    </div>
                    <div className="go-home" style={{backgroundColor: borderColor}} onClick={() => navigate("/")}>
                        {PaymentStatusText[lang].goHome}
                        <IoChevronBack />
                    </div>
            </div>
        : null}
        </div>
    )
}

export default PaymentProcessing;
