import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    Amount: null,
    Data: null,
}

const PremiumDetailsSlice = createSlice({
    name:'premiumDetails',
    initialState,

    reducers:{
        setPremiumDetails:(state, action) => {
            state.Data = action.payload;
        },
        setAmount:(state, action) => {
            state.Amount = action.payload;
        },

    }
})

export const {setPremiumDetails, setAmount} = PremiumDetailsSlice.actions;
export default PremiumDetailsSlice.reducer;