export const PaymentLanguage = {
    ar: {
        BillingHeading: "ملخص الطلب",
        PackageName: "باقة مميز",
        PackagePrice: " ر.س",
        BillAmount: " ر.س",
        BillText: "المبلغ الإجمالي",
        DiscountAmount: " ر.س",
        DiscountText: "الخصم",
        TotalAmount: " ر.س",
        TotalText: "القيمة الإجمالية",
        NavigationText: "سلة المشتريات",
    },
    en: {
        BillingHeading: "Order summary",
        PackageName: "Mumayaz Package",
        PackagePrice: " SAR",
        BillAmount: " SAR",
        BillText: "Total amount",
        DiscountAmount: " SAR",
        DiscountText: "Discount",
        TotalAmount: " SAR",
        TotalText: "Total value",
        NavigationText: "shopping basket",
    }
}