import { createSlice } from "@reduxjs/toolkit";
 
const initialState = {
    academic_level: "",
    college: "",
    specialization: "",
}

const Public = createSlice({
    name:'public',
    initialState,

    reducers:{
        setPublic:(state, action) => {
            state.academic_level = action.payload.academic_level;
            state.college = action.payload.college;
            state.specialization = action.payload.specialization;
        },
    }
})

export const {setPublic} = Public.actions;

export default Public.reducer;
