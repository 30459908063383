import styled from "styled-components";
import { media } from "../../Assets/Styles/utils";

export const ValueContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    `;
export const ValueWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
    `;  
export const ValueHead = styled.h1`
    font-size: 2.5rem;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
    margin-bottom: 0;
    font-weight: 600;
    ${media("small")}{
       font-size: 1.8rem;
    }
    `;
export const ValueDesc = styled.p`
    font-size: 1rem;
    font-weight: 400;
    color: #666;
    width: 60%;
    text-align: center;
    margin-bottom: 20px;
    ${media("small")}{
        font-size: 0.8rem;
        width:80%;
     }
    `;
export const Cardwrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 90%;
    margin: 0 auto;
    `;
export const Card = styled.div`
    width: 40%;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 20px;

    img{
        width: 100%;
        object-fit: cover;
    }
    
    h3{
        font-size: 1.3rem;
        color: #333;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 0;

        ${media("small")}{
            font-size: 1rem;
         }
    }
    p{
        font-size: 1rem;
        color: #666;
        text-align: center;
        margin-top: 10px;
        ${media("small")}{
            font-size: 0.8rem;
         }
    }
    ${media("small")}{
        width: 90%;
     }

    `;