import React, { useEffect, useState } from "react";
import {
  BlogBack,
  BlogContainer,
  BlogHeading,
  BlogWrapper,
  HeadingText,
  SubHeadingText,
} from "./blogs.styles";
import { Bloglang } from "../data/Blog.lang";
import blogback from "../../Assets/Images/Vectorblog.png";
import { useSelector } from "react-redux";
import LibraryBlog from "./LibraryBlog";

function Blogs() {
  const lang = useSelector((state) => state?.lang?.language);
  const [activeFile, setActiveFile] = useState(null);
  const [activeShare, setActiveShare] = useState(null);
  const [moreOptions, setMoreOptions] = useState(false);

  const handleclickshare = () => {
    setMoreOptions(false);
    setActiveShare(null);
    setActiveFile(null);
  }
  
  return (
    <>
      <BlogBack src={blogback}></BlogBack>
      <BlogContainer onClick={()=>handleclickshare()}>
        <BlogWrapper>
        <BlogHeading>
          <HeadingText>{Bloglang[lang]?.heading}</HeadingText>
          <SubHeadingText>{Bloglang[lang]?.description}</SubHeadingText>
        </BlogHeading>
        <LibraryBlog activeFile={activeFile} setActiveFile={setActiveFile} activeShare={activeShare} setActiveShare={setActiveShare} moreOptions={moreOptions} setMoreOptions={setMoreOptions}/>
        </BlogWrapper>
      </BlogContainer>
    </>
  );
}

export default Blogs;
