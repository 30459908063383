import styled, { keyframes } from "styled-components";
import backImage from "../../Assets/Images/back.png";
import { device, size } from "../../Breakpoints";
import { SwiperSlide } from "swiper/react";

export const FlashStartLearning = styled.div`
  height: 100vh;
  width: 100%;
`;

export const FlashStart = styled.div`
  height: calc(100vh - 96px);
  width: 100%;
  background-image: url(${backImage});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 8px 0 8px;
  @media ${device.sm}{
    padding: 2rem 1rem 2rem 1rem;
  }
  @media ${device.xs}{
    padding: 2rem 8px 2rem 8px;
  }
  @media (max-height: 700px){
    align-items: start;
    height: auto;
    padding: 2rem 8px 2rem 8px;
  }
`;
export const LoadingArea = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const FlashStartTop = styled.div`
  height: 110px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #eeeeee;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0 8px 0 8px;
  box-sizing: border-box;
  @media (max-width: 270px){
    height: auto;
    padding: 8px 8px 8px 8px;
  }
`;

export const GuidelinesDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 1rem 0 1rem;
  box-sizing: border-box;
  height: 100%;
  @media ${device.xs}{
    padding: 0 8px 0 8px;
    gap: 6px;
  }
`;

export const GuidelinesUpper = styled.div`
  height: 50%;
  width: 100%;
  font-size: 2rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  @media ${device.sm}{
    font-size: 18px;
  }
`;

export const GuidelinesLower1 = styled.div`
  font-weight: normal;
  font-size: 16px;
  color: #727272;
  @media ${device.sm}{
    font-size: 14px;
  }
`;

export const SpaceBetween = styled.div`
  height: 7%;
  width: 99.8%;
`;

export const SpaceBetweenDiv = styled.div`
  height: 5%;
  width: 99.8%;
`;

export const FlashStartInner = styled.div`
  height: 317px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: ${size.sm}){
    display: none;
  }
  @media ${device.sm} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
export const ProgressTap = styled.div`
  height: 7px;
  width: 100%;
`;

export const FlashStartInner1 = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media ${device.sm} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: none;
  }
`;

export const FlashInner1 = styled.div`
  height: 317px;
  width: 296px;
  padding: 2rem 24px 2rem 24px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  box-sizing: border-box;
  @media ${device.md}{
    width: 32%;
    font-size: 18px;
    padding: 2rem 1rem 2rem 1rem;
  }
  @media ${device.sm}{
    width: 296px;
    font-size: 20px;
    padding: 2rem 24px 2rem 24px;
  }
  @media ${device.xs}{
    width: 100%;
  }
`;

export const SwiperContainer = styled(SwiperSlide)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 1rem 0 1rem;
  box-sizing: border-box;
  @media ${device.sm}{
    background-color: red;
  }
`

export const StartImg = styled.img`
  width: 125px;
  height: 125px;
`;

export const StartText = styled.div`
  width: 100%;
  height: 5rem;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  @media ${device.md}{
    font-size: 18px;
  }
`;

export const FlashStartMid = styled.div`
  height: auto;
  width: 936px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  box-sizing: border-box;
  box-sizing: border-box;
  @media ${device.sm}{
    gap: 1rem;
    width: 100%;
  }
`;
export const ButtonMix = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
  align-items: center;
`;

export const StartJourneyButton = styled.div`
    height:64px;
    width:100%;
    background-color:#4d2c77;
    border-radius:4px;
    font-size: 18px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    cursor:pointer;
    text-align: center;
    @media ${device.md} {
        font-size: 16px;
    }
    @media ${device.xs}{
      height: 56px;
      font-size: 14px;
    }
`

export const OverButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: underline;
  margin-top: -8px;
`;

export const StartContainer = styled.div`
  height: 100vh;
  width: 100%;
`;

export const StartMid = styled.div`
  height: calc(100vh - 96px);
  width: 100%;
  background-image: url(${backImage});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-height: 700px){
    height: auto;
    padding: 2rem 0 2rem 0;
  }
  @media ${device.sm} {
    align-items: start;
    padding-top: 1rem;
    height: calc(100vh -(66px + 164px));
  }
`;

export const ChatbotContainer = styled.div`
  @media ${device.sm} {
    display: none;
  }
  z-index: 10;
`

export const ChatbotContainerFixed = styled.div`
  @media (min-width: ${size.sm}) {
    display: none;
  }
  z-index: 10;
`

export const StartLearnDiv = styled.div`
  height: 567px;
  width: 648px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-sizing: border-box;
  @media ${device.md} {
    width: 580px;
    padding: 0 1rem 0 1rem;
  }
  @media ${device.sm} {
    width: 100%;
  }
`;

export const StartLearnTop = styled.div`
  height: 38%;
  width: 100%;
  margin-bottom: 2px;
  display: flex;
`;

export const HeaderButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
`;

export const HeaderButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #989898;
  font-weight: bold;
  gap: 8px;
  cursor: pointer;
  @media ${device.sm} {
        font-size: 14px;
  }
`;

export const ButtonDiv = styled.div`
  height: 112px;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.1rem 0 1.1rem;
  box-sizing: border-box;
  @media ${device.sm} {
    display: none;
  }
`;

export const ButtonDivFixed = styled.div`
  height: 140px;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 1.1rem 0 1.1rem;
  box-sizing: border-box;
  gap: 1.6rem;
  @media (min-width: ${size.sm}) {
    display: none;
  }
`;

export const ButtonInnerDiv = styled.div`
  height: 64px;
  width: 90%;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StartNumber1 = styled.div``;

export const StartHead = styled.div`
  height: 128px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #eeeeee;
  display: flex;
  align-items: center;
  padding: 0 1.1rem 0 1.1rem;
  background-color: #ffffff;
  box-sizing: border-box;
  justify-content: space-between;
  @media ${device.xs} {
    justify-content: space-between;
    padding: 8px 1.1rem 8px 1.1rem;
    height: auto;
  }
  @media (max-width: 300px) {
      padding: 8px 8px 8px 8px;
  }
`;

export const StartLearnParaDiv = styled.div`
  width: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  text-align: center;
  height: 100%;
  font-size: 24px;
  font-weight: bold;
  position: relative;
  align-items: ${props => props.explanVisibility === true ? "start" : "center"};
  margin-top: ${props => props.explanVisibility === true ? "2rem" : null};
  @media ${device.sm} {
    margin-top: ${props => props.explanVisibility === true ? "1rem" : null};
    font-size: 20px;
  }
`;

export const ExplanationContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 65px;
  border: 1px solid #FFAF44;
  border-radius: 4px;
  background-color: #FFF7ED;
  padding: 12px;
  text-align: center;
  display: flex;
  align-items: start;
  justify-content: center;
  box-sizing: border-box;
  overflow: scroll;
  color: #F99611;
  font-size: 14px;
  font-weight: normal;
  margin-top: 1rem;
  z-index: 5;
  &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
  }
`

const spin = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
` 

export const LoadingAnimation = styled.div`
    border: 4px solid white;
    border-top: 4px solid #CA56A1 ;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: ${spin} 1s linear infinite;
`


export const LoaderDots = styled.div`
  width: 40px;
  aspect-ratio: 2;
  margin-top: 8px;
  --_g: no-repeat radial-gradient(circle closest-side,#CA56A1 90%,#0000);
  background: 
    var(--_g) 0%   50%,
    var(--_g) 50%  50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 50%;
  animation: l3 1s infinite linear;
  @keyframes l3 {
      20%{background-position:0%   0%, 50%  50%,100%  50%}
      40%{background-position:0% 100%, 50%   0%,100%  50%}
      60%{background-position:0%  50%, 50% 100%,100%   0%}
      80%{background-position:0%  50%, 50%  50%,100% 100%}
  }
`

export const FlipDiv = styled.div`
  height: 295px;
  width: 100%;
  background-color: #ffffff;
  transform-style: preserve-3d;
  transition: all 0.5s ease;
  transform: ${(props) => (props.flipped ? "rotateY(180deg)" : "rotateY(0)")};
  position: relative;
  padding: 1rem 1rem 1rem 1rem;
  box-sizing: border-box;
  top: 0;
  left: 0;
  @media (max-width: 300px) {
    padding: 1rem 8px 1rem 8px;

  }
  @media ${device.sm} {
      height: 220px;
  }
`;


export const QuestionContainer = styled.div`
  /* height: 100%;
  display: flex;
  flex-direction: column;
  backface-visibility: hidden;
  background-color: red; */
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  flex-direction: column;
  left: 0;
  display: flex;
  flex-direction: column;
  backface-visibility: hidden;
  padding: 1rem 1.1rem 1rem 1.1rem;
  box-sizing: border-box;
`;

export const AnswerContainer = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  flex-direction: column;
  left: 0;
  display: flex;
  flex-direction: column;
  backface-visibility: hidden;
  padding: 1rem 1.1rem 1rem 1.1rem;
  box-sizing: border-box;
  transform: rotateY(180deg);
`;

export const StartData = styled.div`
  height: 100%;
  width: 56%;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  margin-left: 20px;
`;

export const StartNumberDiv = styled.div`
  height: 100%;
  width: 40%;
  display: flex;
  justify-content: flex-end;
`;

export const StartNumber = styled.div`
  height: 60%;
  width: 38%;
  margin-top: 10px;
  border-radius: 100%;
  border: 2px solid #727272;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  font-weight: 700;
  margin-right: 8px;
`;

export const StartPara = styled.div`
  height: 50%;
  width: 86%;
  font-size: 20px;
  margin-top: 10px;
  font-weight: 600;
  margin-left: 22px;
`;

export const StartBottomDiv = styled.div`
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BottomStart = styled.div`
  height: 100%;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LeftBottom = styled.div`
  height: 100%;
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const RightBottom = styled.div`
  height: 100%;
  width: 70%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const RightBottomInside = styled.div`
  height: 70%;
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const LeftBottomInside = styled.div`
  height: 90%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LeftButton = styled.div`
  height: 90%;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: white;
  cursor: pointer;
`;
export const RightMaster = styled.div`
  height: 100%;
  width: 181px;
  display: flex;
  font-weight: bold;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  background-color: #e8ffdd;
  color: #039855;
  cursor: pointer;
  @media ${device.sm}{
    width: auto;
    padding: 0 1rem 0 1rem;
  }
`;
export const RightNeed = styled.div`
  height: 100%;
  width: 179px;
  display: flex;
  border-radius: 4px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  background-color: #fff7ed;
  color: #ffaf44;
  border: none;
  cursor: pointer;
  @media ${device.sm}{
    width: auto;
    padding: 0 1rem 0 1rem;
  }
`;

export const ButtonCombine = styled.div`
  display: flex;
  justify-content: space-between;
  height: 64px;
  gap: 1rem;
  width: auto;
  font-size: 18px;
  text-align: center;
  @media ${device.sm} {
    height: 56px;
    gap: 8px;
    font-size: 16px;
  }
  @media (max-width: 300px) {
    font-size: 14px;
  }
`;

export const RightReport = styled.div`
  display: flex;
  align-items: center;
  border: none;
  color: #989898;
  cursor: pointer;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  @media ${device.sm} {
        font-size: 16px;
  }
`;

