import React from 'react'
import {DeleteModalOuter, DeleteModalContainer, DeleteModalSubContainer, Heading, TextSection, UpperBorderLine, ButtonsSection, CancelButton, DeleteButton } from './DeleteModel.styles'
import localStorage from "../../../Store/index"
import ApiClient from '../../../Config/index.config'
import { setUpdation } from '../../../Store/Slice/LibraryData/LibraryDataSlice'
import { useDispatch, useSelector } from 'react-redux'
import { DeleteSubHeading, SubHeading } from '../../UploadFile/UploadFile.styles'
import { DeleteFileLanguage } from '../../data/DeleteFile.lang'
import DeleteWarningIcon from "../../../Assets/Images/DeleteWarningIcon.svg"

const DeleteModal = ({setDeleteModal, Name, itemId}) => {
    const lang = useSelector((store) => store.lang.language)
    const dispatch = useDispatch()

    const setDelete = async () => {
        try{
            const response = await ApiClient.delete(`/library/` + itemId)
            setDeleteModal(false)
        }catch(error){
            console.log(error)
            setDeleteModal(false)
        }
        dispatch(setUpdation())
    };

    return (
      <DeleteModalOuter>
          <DeleteModalContainer>
              <DeleteModalSubContainer>
                <img src={DeleteWarningIcon} alt="delte warning icon"/>

                <TextSection lang={lang} width={"100%"}>
                    <Heading lang={lang}>
                        {DeleteFileLanguage[lang].heading}
                    </Heading>
                    <DeleteSubHeading>
                        ({Name}) {" "}{DeleteFileLanguage[lang].subHeading}
                    </DeleteSubHeading>
                </TextSection>

                <ButtonsSection>
                    <DeleteButton onClick={() => setDelete()}>
                        {DeleteFileLanguage[lang].delete}
                    </DeleteButton>
                    <CancelButton onClick={() => setDeleteModal(false)}>
                        {DeleteFileLanguage[lang].cancle}
                    </CancelButton>
                </ButtonsSection>
                
                <UpperBorderLine></UpperBorderLine>

                {/* {type === 'edit' ?
                <StyledButton onClick={() => setDataEdit()}>{CreateFolderLanguage[lang].saveButton}</StyledButton>
                :
                <StyledButton onClick={() => setDataFolderCreation()}>{CreateFolderLanguage[lang].createFolderButton}</StyledButton>
                } */}
                  
              </DeleteModalSubContainer>
          </DeleteModalContainer>
      </DeleteModalOuter>
    )  
}

export default DeleteModal 