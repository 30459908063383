import styled, { keyframes } from "styled-components";

const spin = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
` 

const wobble = keyframes`
    20%{background-position: 0%   0%, 50%  50%, 100%  50%}
    40%{background-position: 0% 100%, 50%   0%, 100%  50%}
    60%{background-position: 0%  50%, 50% 100%, 100%   0%}
    80%{background-position: 0%  50%, 50%  50%, 100% 100%}
`

export const LoaderContainer = styled.div`
    border: 10px solid white;
    border-top: 10px solid #CA56A1 ;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: ${spin} 1s linear infinite;

    /* width: 60px;
    aspect-ratio: 2;
    --_g: no-repeat radial-gradient(circle closest-side,#CA56A1 90%,#0000);
    background: 
      var(--_g) 0%   50%,
      var(--_g) 50%  50%,
      var(--_g) 100% 50%;
    background-size: calc(100%/3) 50%;
    animation: ${wobble} 1s infinite linear; */
`
