import React from 'react'
import { NavigationContainer, NavigationSubContainer, HomeImage, PathContainer, ArrowIconImage, NavigationName } from '../Library/Library.styles'
import HomeIcon from '../../Assets/Images/HomeIcon.svg'
import LeftArrowIcon from '../../Assets/Images/LeftArrowIcon.svg'
import { useNavigate} from 'react-router-dom'
import { PaymentLanguage } from './Payment.lang'
import { useSelector } from 'react-redux'

const PaymentNavigation = () => {
    const navigate = useNavigate();
    const lang = useSelector((store) => store.lang.language)
    
    
    const handleClick = (data) => {
        if(data === 'home'){
            navigate("/");
        }
    }

    return (
        <NavigationContainer>
            <NavigationSubContainer lang={lang}>
                <HomeImage src={HomeIcon} alt='home icon' onClick={() => {handleClick('home')}}/>
                <PathContainer lang={lang}>
                    <ArrowIconImage lang={lang} src={LeftArrowIcon} alt='arrow icon'/>
                    <NavigationName>{PaymentLanguage[lang].NavigationText}</NavigationName>
                </PathContainer>
            </NavigationSubContainer>
        </NavigationContainer>
    )
}

export default PaymentNavigation






