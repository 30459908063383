export const DeleteFileLanguage = {
    ar:{
        heading:"هل أنت متاكد من حذف الملف؟",
        subHeading:"لن تتمكن من استرداده مرة أخرى",
        cancle:"تراجع",
        delete:"نعم أحذف ",
    },
    en:{
        heading:'Are you sure you want to delete the file?',
        subHeading:'You will not be able to regain it again',
        cancle:'to retreat',
        delete:'Yes delete'
    }
}