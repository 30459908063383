import React, { useState } from "react";
import {
  AuthWrapper,
  ButtonContainer,
  ButtonStyle,
  Error,
  FormContainer,
  HeadingContainer,
  InputStyle2,
  Loader,
  LoaderCenter,
  LoginContainer,
  PasswordInput,
  Passwordvisible,
} from "./Auth.Style";
import { BiSolidHide, BiSolidShow } from "react-icons/bi";
import { useEffect } from "react";
import { validatePassword } from "./Validate";
import { useSelector } from "react-redux";
import ApiClient from "../../Config/index.config";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Update_password = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage2, setErrorMessage2] = useState({});
  const [visible, setVisible] = useState(false);
  const lang = useSelector((state) => state?.lang?.language);
  const [visible2, setVisible2] = useState(false);
  const [isButtonDisable, setIsButtonDisable] = useState(true);
  const phone = useSelector((state) => state?.auth?.phone_number);
  const navigate = useNavigate();
  const [error, setError] = useState(false);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handlecnfPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  useEffect(() => {
    if (!password || password === "") {
      setErrorMessage2("");
      setIsButtonDisable(true);
      return;
    }

    const message = validatePassword(password, lang);
    if (message) {
      setErrorMessage2(message);
      setIsButtonDisable(true);
    } else {
      setErrorMessage2("");
      setIsButtonDisable(false);
    }
  }, [password, lang]);

  useEffect(() => {
    if (confirmPassword.length > 0) {
      if (password !== confirmPassword) {
        setError("كلمة المرور غير متطابقة");
        setIsButtonDisable(true);
      } else {
        setError("");
        setIsButtonDisable(false);
      }
    }
  }, [confirmPassword, password]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await ApiClient.post(
        "/auth/forgot-password/reset-password",
        {
          password: password,
          phone_number: phone,
          token: sessionStorage.getItem("pass_token"),
        }
      );
      toast.success("تم تحديث كلمة المرور بنجاح");
      navigate("/user/login");
    } catch (error) {
      toast.error("حدث خطأ ما");
    }
  };
  return (
    <AuthWrapper>
      <LoginContainer style={{ height: "60vh" }}>
        <HeadingContainer>{"أدخل كلمة المرور الجديدة"} </HeadingContainer>
        <FormContainer>
          <PasswordInput>
            <InputStyle2
              value={password}
              onChange={handlePasswordChange}
              type={visible ? "text" : "password"}
              placeholder={"أدخل كلمة المرور الجديدة"}
              pass
              lang={lang}
            />
            <Passwordvisible onClick={() => setVisible(!visible)}>
              {visible ? <BiSolidShow /> : <BiSolidHide />}
            </Passwordvisible>
          </PasswordInput>
          {errorMessage2.lengthError && (
            <Error>{errorMessage2.lengthError}</Error>
          )}
          {errorMessage2.uppercaseError && (
            <Error>{errorMessage2.uppercaseError}</Error>
          )}
          {errorMessage2.numberError && (
            <Error>{errorMessage2.numberError}</Error>
          )}
          {errorMessage2.specialCharError && (
            <Error>{errorMessage2.specialCharError}</Error>
          )}
          <PasswordInput>
            <InputStyle2
              value={confirmPassword}
              onChange={handlecnfPasswordChange}
              type={visible2 ? "text" : "password"}
              placeholder={"تأكيد كلمة المرور"}
              pass
              lang={lang}
            />
            <Passwordvisible onClick={() => setVisible2(!visible2)}>
              {visible2 ? <BiSolidShow /> : <BiSolidHide />}
            </Passwordvisible>
          </PasswordInput>
          {error && <Error>{error}</Error>}
          <ButtonContainer>
            <ButtonStyle
              type="submit"
              disabled={isButtonDisable}
              onClick={(e) => handleSubmit(e)}
            >
              {false ? (
                <LoaderCenter>
                  <Loader />
                </LoaderCenter>
              ) : (
                "تحديث كلمة المرور"
              )}
            </ButtonStyle>
          </ButtonContainer>
        </FormContainer>
      </LoginContainer>
    </AuthWrapper>
  );
};

export default Update_password;
