import FilesIcon from "../../Assets/Images/FilesIcon.svg";
import PagesIcon from "../../Assets/Images/PagesIcon.svg";
import FileUploadIcon from "../../Assets/Images/FileUploadIcon.svg";
import StarIocn from "../../Assets/Images/StarIcon.svg";
import ChatIcon from "../../Assets/Images/ChatIcon.svg";
import FlashCardIcon from "../../Assets/Images/FlashCardIcon.svg";
import QuizIcon from "../../Assets/Images/QuizIcon.svg";

export const SubscriptionLanguage = {
    ar : {
        heading : "باقة مميز",
        subHeading : "اشترك الآن في ميزة متميز , وذاكر بتميز مع أذكى تقنيات الذكاء الاصطناعي",
        price : "ر.س",
        month : "شهر",
        advantages: "الميزة",
        free: "الباقة المجانية "        ,
        mumayaz: "باقة المميز",
        freeFileLimit: "ملفات/يوم",
        MumayazFileLimit: "ملف/يوم",
        freePageNumbers: "صفحة/ملف",
        mumayazPageNumber: "صفحة/ملف",
        freeFileSize: "ميجابايت/ملف",
        mumayazFileSize: "ميجابايت/ملف",
        freeExplanationCount: "محاولات/يوم",
        mumayzExplanationCount: "غير محدود",    
        subscriptionButton : "اشترك الآن ",
        file: "عدد الملفات",
        page: "عدد الصفحات",
        size: "حجم الملف",
        explain: "الشرح",
        chats: "المساعد الذكي",
        flashCards: "بطاقات فلاش كرد",
        quiz: "اختبار تفاعلي",
        freeChats: "محادثة/يوم",
        premiumChats: "محادثة/يوم",
        freeFlashCards: "بطاقة / يوم",
        premiumFlashCards: "غير محدود",
        freeQuiz: "اختبارات / يوم" ,
        premiumQuiz: "غير محدود",
        fileIcon: FilesIcon,
        pagesIcon: PagesIcon,
        fileUploadIcon: FileUploadIcon,
        explainIcon: StarIocn,
        chatIcon: ChatIcon,
        flashCardIcon: FlashCardIcon,
        quizIcon: QuizIcon,
        PaymentOptionHeading: "اشترك وادفع بواسطة:",
        VisaMaster: "تأشيرة / ماجستير",
        Mada: "مدى",
    },

    en : {
        heading : "Mumayaz Package",
        subHeading : "You can upload files and enjoy a smart, interactive learning experience. Join today and take advantage of the unique features our service offers you.",
        price : "RS",
        month : "month",
        advantages: "Advantages",
        free: "Free Package",
        mumayaz: "Momayaz Package",
        freeFileLimit: "files/day",
        MumayazFileLimit: "file/day",
        freePageNumbers: "page/file",
        mumayazPageNumber: "page/file",
        freeFileSize: "mb/file",
        mumayazFileSize: "mb/file",
        freeExplanationCount: "attempts/day",
        mumayzExplanationCount: "unlimited",
        subscriptionButton : "Subscription",
        file: "Number of Files",
        page: "Number of Pages",
        size: "File Size",
        explain: "Explanation",
        chats: "Chats",
        flashCards: "Flash Cards",
        quiz: "Quiz",
        freeChats: "chats/day",
        premiumChats: "chats/day",
        freeFlashCards: "cards/day",
        premiumFlashCards: "unlimited",
        freeQuiz: "quiz/day",
        premiumQuiz: "unlimited",
        fileIcon: FilesIcon,
        pagesIcon: PagesIcon,
        fileUploadIcon: FileUploadIcon,
        explainIcon: StarIocn,
        chatIcon: ChatIcon,
        flashCardIcon: FlashCardIcon,
        quizIcon: QuizIcon,
        PaymentOptionHeading: "Subscribe and pay with:",
        VisaMaster: "Visa/Master",
        Mada: "Mada",
    },
    
}