import React from 'react';
import { TwitterShareButton } from 'react-share';
import twitterimage from '../../Assets/Images/TwitterFlashCardIcon.svg';
import { useSelector } from 'react-redux';

function TwitterShare({pdfName, url}) {
  const lang = useSelector((store) => store.lang.language)
  const titleText = {
    ar:{
      data: `الـ  AI لخص لي مادة ${pdfName} , ذاكر معايا قبل الاختبار\n\n`,
    },
    en:{
     data: `The AI ​​summarized for me the subject "${pdfName}". Study with me before the test\n\n`,
    },
  }
  return (
    <TwitterShareButton
      url={url}
      hashtags={['Growing']} // Hashtags should be provided as an array
      title={titleText[lang].data} // Make sure to replace <psdf_name> with the actual name
    >
      <img src={twitterimage} alt="Twitter Icon" />
    </TwitterShareButton>
  );
}

export default TwitterShare;
