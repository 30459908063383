import React from 'react'
import {CreateFolderSVG, DropdownContainer, StyledList} from './Header.styles'
import CreateFolderIcon from "../../Assets/Images/CreateFolderIcon.svg"
import AttachFileIcon from "../../Assets/Images/AttachFileIcon.svg"
import { useSelector } from 'react-redux'
import {HeaderLanguage} from "../data/Header.lang"

const DropdownAddPDF = ({setCreateFolderModal, setCreateFileModal}) => {

  const lang = useSelector((store) => store.lang.language);

  return (
    <DropdownContainer lang={lang}>
        <StyledList onClick={() => setCreateFolderModal(true)}><CreateFolderSVG lang={lang} src={CreateFolderIcon} alt="create folder con" />{HeaderLanguage[lang].folderCreation}</StyledList>
        <StyledList onClick={() => setCreateFileModal(true)}><img src={AttachFileIcon} alt="attach file Icon" />{HeaderLanguage[lang].addFile}</StyledList>
    </DropdownContainer>
  )
}

export default DropdownAddPDF