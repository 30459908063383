import styled from "styled-components";
import { media } from "../../Assets/Styles/utils";
import "../../Assets/fonts/Almarai-Regular.ttf";
import AlmaraiFont from "../../Assets/fonts/Almarai-Regular.ttf";

export const StoriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  justify-content: space-evenly;
  // gap: 80px;
  ${media("medium")} {
    font-size: column;
  }
`;
export const StoryTitles = styled.div`
  display: flex;
  flex-direction: column;

  gap: 4px;
  align-items: center;
  padding: 3px;
  ${media("medium")} {
    font-size: 30px;
  }
`;
export const StoryHeading = styled.span`
  font-family: ${AlmaraiFont};
  font-size: 32px;
  font-weight: 700;
  line-height: 51px;
  letter-spacing: 0.9px;
  text-align: center;
  color: #252525;
  
  ${media("medium")} {
    font-size: 1.1rem;
    line-height:1.5;
    .break {
      display: none;
     }
  }
`;
export const StorySubheading = styled.span`
  font-family: ${AlmaraiFont};
  font-size: 1.5rem;
  width: 70%;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 20px;
  ${media("medium")}{
   width:90%;
  }
`;
export const StoryCards1 = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
margin : auto 5%;
margin-top: 20px;
${media("medium")} {
  flex-direction: column;
  justify-content:center;
  align-items:center;
 
`;
export const StoryCards2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  ${media("medium")} {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 100px;
  }
`;
export const CardWrapper = styled.div`
  display: flex;
 
  align-items: center;
  border-radius: 8px;
  border: 1px solid #eeeeee;
  margin-top: 20px;
  width: 47%;
  height: 170px;
  img {
    width: 80px;
    margin: 20px;
     transition: transform 0.5s ease, margin-right 0.5s ease;
     transform-origin: 50%;

  }
  &:hover{
    background: #CA56A1;
    border: 1px solid #CA56A1;
    img {
      background:white;
      border-radius:50%;
      transform: rotateY(-180deg);
      scale:1.3;
    }
  }
  ${media("medium")} {
    min-width: 100%;
    height: auto;
  }
`;
// transition: transform 0.5s ease, margin-right 0.5s ease;
//   transform-origin: ${(props) =>
//     props.lang === "ar" ? "left center" : "right center"};

//   &.on-ov {
//     transform: rotateY(180deg);
//     margin-right: ${(props) => (props.lang === "ar" ? "auto" : "15px")};
//     margin-left: ${(props) => (props.lang !== "ar" ? "auto" : "15px")};
//   }
export const StoryCardText = styled.div`
  text-align: ${(props) => (props.lang === "ar" ? "right" : "left")};


  ${CardWrapper}:hover & * {
    color: white !important;
  } 
  ${media("medium")} {
    width: 90%;
    padding-bottom: 10px;
  }
  ${media("small")} {
    width: 90%;
  }
`;
export const StoryCardHeading = styled.span`
  font-family: ${AlmaraiFont};
  font-size: 24px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0px;
  text-align: left;
  color: #252525;
  ${media("medium")} {
    font-size: 20px;
  }
`;
export const StoryCardSub = styled.span`
  font-family: ${AlmaraiFont};
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: left;
  color: #727272;
  ${media("medium")} {
    font-size: 14px;
  }
`;

export const CardBack = styled.img`
  position: absolute;
  left: 0%;
  z-index: -1;
  ${media("medium")} {
    width: 50%;
  }
  ${media("small")} {
    width: 50%;
  }
`;
export const Card = styled.div`
  border: 1px solid #eeeeee;
  border-radius: 8px;
  display: flex;
  justify-content: space-evenly;
  img {
    width: 80px;
  }
`;
export const CardHead = styled.div`
  font-family: ${AlmaraiFont};
  font-size: 24px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0px;
  color: #252525;
`;
export const Carddesc = styled.div`
  font-family: ${AlmaraiFont};
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0px;
  color: #727272;
`;
