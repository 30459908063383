import React, { useState } from 'react'
import CompanyLogo from "../../Assets/Images/CompanyLogo.svg"
import { PageContentContainer, PublicLibrary, MyLibrary, Logo, MyLibrarySmall, PublicLibrarySmall } from './Header.styles'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {HeaderLanguage} from "../data/Header.lang"
import {emptyRoutesArray} from "../../Store/Slice/Navigation/NavigationSlice"
import { setLibraryData, setUpdation } from '../../Store/Slice/LibraryData/LibraryDataSlice'

const PageDetails = ({isAuthenticated}) => {
  const lang = useSelector((store) => store.lang.language)
  const [library, setLibrary] = useState("myLibrary");
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const handleMyLibrary = () => {
    setLibrary("myLibrary")
    navigate("/library");
    dispatch(emptyRoutesArray())
    dispatch(setLibraryData({data: "library", id: ""}))
    dispatch(setUpdation())
  }
  const handlepublicLibrary=()=>{
    setLibrary("publicLibrary")
    navigate("/public");
  }

  return (
    <PageContentContainer>
            <Logo src={CompanyLogo} alt='company logo' onClick={() => navigate("/")}/>
            {isAuthenticated ?
            <>
              <MyLibrary isselected={+(library === 'myLibrary')} onClick={() => handleMyLibrary()}>{HeaderLanguage[lang].myLibraryButton}</MyLibrary>
              {/* <MyLibrarySmall isselected={+(library === 'myLibrary')} onClick={() => handleMyLibrary()}>{HeaderLanguage[lang].myLibraryButtonSmall}</MyLibrarySmall> */}
              <PublicLibrary isselected={+(library === 'publicLibrary')} onClick={() => handlepublicLibrary()}>{HeaderLanguage[lang].publicLibraryButton}</PublicLibrary>
              {/* <PublicLibrarySmall isselected={+(library === 'publicLibrary')} onClick={() => handlepublicLibrary()}>{HeaderLanguage[lang].publicLibraryButtonSmall}</PublicLibrarySmall> */}
            </>
            : null}
    </PageContentContainer>
  )
}

export default PageDetails