import styled from "styled-components";
import { device } from "../../../Breakpoints";

export const NotificationPopUp = styled.div`
    position: fixed;
    top: 5rem;
    left: ${props => props.lang === "ar" ? "calc(50% - 195px)" : null};
    right: ${props => props.lang === "en" ? "calc(50% - 195px)" : null};
    width: 390px;
    height: 60px;
    display: flex;
    align-items: center;
    background-color: ${props => props.color || "#E8FFDD"};
    justify-content: ${props => props.color ? "center" : null};
    color: ${props => props.color ? "white" : "#039855"};
    font-size: 18px;
    font-weight: bold;
    gap: 12px;
    padding: 8px 24px 8px 24px;
    z-index: 10;
    box-sizing: border-box;

    @media ${device.sm}{
        font-size: 14px;
        width: 200px;
        height: 40px;
        padding: 8px 12px 8px 12px;
        gap: 8px;
        left: ${props => props.lang === "ar" ? "calc(50% - 100px)" : null};
        right: ${props => props.lang === "en" ? "calc(50% - 100px)" : null};
    }
`

export const Notification = styled.div`

`