import React from 'react'
import LibraryCardsSection from '../Components/Library/LibraryCardsSection'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

const Library = () => {

  const isAuthenticated = useSelector((store) => store.auth.isAuthenticated)
  if(!isAuthenticated){
    return <Navigate to="/user/login" replace />;
  }


  return (
      <LibraryCardsSection/>
  )

}

export default Library