import React,{useState,useEffect} from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import { ResultPage , 
         ResultMidPage,
         ResultInnerPage,
         ResultMid,
         ResultBottom,
         ResultTopDiv,
         ExamRepeat,
         EndButton,
         ValueDiv,
         StarDiv,
         Value1,
         Startdiv1,
         RatingText,
         DivInner,
         DivInnerValue,
         InnerText,
         ColorDiv,
         ResultHeading,
         ResultSubHeading,
         ResultBottomFixed,
         StarImage
} from '../FlashCardLearning/Result.styles';
import { FlashCardsLearning } from '../data/FlashCardLearning.lang';
import star_white from "../../Assets/Images/Star.png";
import star_yellow from "../../Assets/Images/Star1.png";
import { useDispatch, useSelector } from 'react-redux';
import { RatingApi } from '../../Store/Slice/FlashCards/GetFlashCardsSlice';
import ExhausteModel from '../Modals/ExhaustModals/ExhaustModals';
const QuizResult = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const resdata = location.state?.data;
  const document_id  = useSelector(state => state?.FlashCards?.document_id);
  const lang = useSelector((state) => state?.lang?.language);
  const [exhaustModal, setExhaustModal] = useState(false)
  const premiumFeaturePrize = useSelector((store) => store.premiumDetails.Data);
  const freeTests = premiumFeaturePrize?.free_user_number_of_questions
  const premiumTests = premiumFeaturePrize?.premium_user_number_of_questions
  const exhaustModalData = 
  [   
      {LimitHeading: {ar: "تجاوز حد الاختبار", en: "Test limit exceeds"},
      Limit: {ar: "10", en: "10"},
      Heading: {ar: "استنفذت البطاقات المجانية", en: "Access to unlimited test"},
      SubHeading: {ar: "اشترك الآن، وكمل رحلة تعليمك ", en: "To take full advantage of all tests indefinitely and enjoy all the benefits, please subscribe to the"},
      Mumayaz: {ar: "غير محدود", en: "unlimited"},
      Free: {ar: "10 سؤال/يوم", en: "10 question/day"},
      Advantage: {ar: "اختبار", en: "Quiz"}},
  ]

  const [Flashrating, setFlashRating] = useState({
    document_id:document_id,
    type:'quiz',
    rating:0
  });

  const handleStarClick = (starIndex) => {
    setFlashRating({
      ...Flashrating, // Spread the existing state
      rating: starIndex + 1 // Update the rating based on the clicked starIndex
    });
  };

  useEffect(()=>{
    if(Flashrating?.rating !== 0){
      dispatch(RatingApi(Flashrating));
    }
  },[Flashrating])

  
  const renderStars = () => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      const starImage = i < Flashrating?.rating ? star_yellow : star_white;
      stars.push(
        <StarImage
          key={i}
          src={starImage}
          alt="star"
          onClick={() => handleStarClick(i)}
        />
      );
    }
    return stars;
  };

  const handleBack = () =>{
    navigate(`/flash-card/${document_id}`)
  }

  const ReviewQuiz = () =>{
    navigate('/Flash/StartQuiz');
  }

  useEffect(() => {
    if(resdata.correct + resdata.wrong <= 10){
      setExhaustModal(true);
    } 
  },[])

  return (
    // <ResultContainer>
      <ResultPage>
      <ResultMidPage>
      <ResultInnerPage>
        <ResultTopDiv>
          <ResultHeading> {FlashCardsLearning[lang].CongratsTest}</ResultHeading>
          {/* <ResultSubHeading> {FlashCardsLearning[lang].CongratsBody}</ResultSubHeading> */}
        </ResultTopDiv>
      <ResultMid>
          <ValueDiv>
            <Value1>
              <DivInner>
                <DivInnerValue>
                  <ColorDiv  color='#039855'></ColorDiv>
                  {resdata?.correct}
                </DivInnerValue>
                <InnerText>
                  {FlashCardsLearning[lang].Correct}
                </InnerText>
              </DivInner>
            </Value1>
            <Value1>
            <DivInner>
            <DivInnerValue>
                  <ColorDiv color='#FFDFB4'></ColorDiv>
                  {resdata?.wrong}
                </DivInnerValue>
                <InnerText>
                  {FlashCardsLearning[lang].Wrong}
                </InnerText>
            </DivInner>
            </Value1>
            <Value1>
            <DivInner>
            <DivInnerValue>
                  <ColorDiv ></ColorDiv>
                  0
                </DivInnerValue>
                <InnerText>
                  {FlashCardsLearning[lang].RemainingCount}
                </InnerText>
              </DivInner>
            </Value1>
          </ValueDiv>
        <StarDiv>
          <Startdiv1>
          {renderStars()}
          </Startdiv1>
          <RatingText>
            {FlashCardsLearning[lang].Rating}
          </RatingText>
        </StarDiv>
      </ResultMid>
      <ResultBottom>
          <ExamRepeat onClick={ReviewQuiz}>
            {FlashCardsLearning[lang].Review}
          </ExamRepeat>
          <EndButton onClick={handleBack}>
              {FlashCardsLearning[lang].Ending}
          </EndButton>
      </ResultBottom>
      </ResultInnerPage>
      </ResultMidPage>

      <ResultBottomFixed>
          <ExamRepeat onClick={ReviewQuiz}>
            {FlashCardsLearning[lang].Review}
          </ExamRepeat>
          <EndButton onClick={handleBack}>
              {FlashCardsLearning[lang].Ending}
          </EndButton>
      </ResultBottomFixed>
      {exhaustModal ?
        <ExhausteModel setLimitExhaustModal={setExhaustModal} data={exhaustModalData[0]}/>
        : null
      }
      </ResultPage>
    // </ResultContainer>
  )
}

export default QuizResult
