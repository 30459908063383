import styled from "styled-components";
import {device} from "../../Breakpoints"

export const MainContainer = styled.div`
    background-color: #f9f4f8;
    min-height: 40rem;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    @media ${device.lg} {
        align-items: start;
    }
    @media ${device.sm} {
        background-color: #F4F4F4;
    }
`

export const TempContainer = styled.div`
    width: 70.5rem;
    box-sizing: border-box;
    @media ${device.lg} {
        width: 100%;
        padding: 0 1rem 0 1rem;
    }
`

export const SubContainer = styled.div`

    width: 70.5rem;
    height: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 2rem;
    margin: 3rem 0 3rem 0;
    box-sizing: border-box;
    @media ${device.lg} {
        width: 100%;
    }
    @media ${device.sm} {
        justify-content: center;
        row-gap: 1rem;
        margin: 2rem 0 2rem 0;
    }

`

export const CardOuterContainer = styled.div`

    width: 270px;
    height: 167px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    box-sizing: border-box;
    position: relative;
    @media (max-width: 1228px) {
    }
    @media ${device.sm} {
        width: 343px;
    }
    @media ${device.xs} {
        width: 280px;
    }
    @media (max-width: 275px){
        width: 100%;
    }
`

export const MoreIconImage = styled.img`
    width: 16px;
    color: black;
    cursor: pointer;
    padding: 5px 0 5px 0;
`

export const CardContainer = styled.div`

    background-color: white;
    /* height: 10.86rem; */
    width: 100%;
    border-radius: 8px;
    padding: 18px;
    box-sizing: border-box;
    display: flex ;
    align-items: end;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;
    &:hover {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
    overflow: hidden;

`

export const IconContainer = styled.div`
    display: flex;
    width: 100%;
    height: 4rem;
    justify-content: space-between;
    align-items: center;
`

export const FolderDetails = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    row-gap: 4px;
    overflow: hidden;
`

export const FolderName = styled.div`
    width: 100%;
    /* height: 1.2rem; */
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

export const TagsContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: 12px;
    color: #727272;
    gap: 10px;
`


export const Tags = styled.span`
    width: auto;
    height: 2rem;
    display: flex;
    gap: 2px;
    justify-content: start;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

export const StyledCircle = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: red;
`

export const ButtonsSection = styled.div`

    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

`

export const FolderCount = styled.data`
    display: flex;
    width: 4.2rem;
    justify-content: space-between;
    color: #727272;
    flex-direction: ${(props) => props.lang === 'ar' ? "row-reverse" : null};
    gap: 12px;
    align-items: center;
    box-sizing: border-box;
`

export const CountNumber = styled.span`
    width: auto;
    display: flex;
    justify-content: space-between;
    font-size: small;
    font-weight:500;
    gap: 4px;
    font-size: 12px;
    /* flex-direction: ${(props) => props.lang === 'ar' ? 'row-reverse' : null}; */

`

export const FolderType = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    width: 3.2rem;
    height: 1.5rem;
    background-color: ${(props) => props.$IsPublic === true ? "#E6F5EE" : "rgba(99, 180, 255, 0.2)"};
    border: 1px solid;
    border-color: ${(props) => props.$IsPublic === true ? "#75C7A1" : "#63B4FF"};
    font-size: 14px;
    padding: 0 5px 0 5px;
`

export const ShareIconImage = styled.img`
    margin-left: 0rem;
    /* transform : ${(props) => props.lang === 'en' ? 'rotateY(180deg)' : null} */
`

export const OptionsModal = styled.div`
    position: absolute;
    background-color: white;
    right: ${(props) => props.lang === 'en' ? '1rem' : null};
    left: ${(props) => props.lang === 'ar' ? '1rem' : null};
    top: ${(props) => props.insideShare === "true" ? "9.5rem": "3rem"};
    width: ${props => props.insideShare === "true" ? "12rem" : "8rem"};;
    height: ${props => props.insideShare === "true" ? "4rem" : "auto"};
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 4px;
    padding: 10px 0 10px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: ${props => props.insideShare === "true" ? "row" : "column"};
    justify-content: center;
    align-items: center;
    gap: 8px;
    z-index: 5;
`

export const StyledList = styled.div`
    width: 100%;
    color: #4C4C4C;
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: 12px;
    text-align: left;
    gap: 6px;
    padding : 0 8px 0 8px;
    &:hover{
        background-color: #f9f4f8;
    };
    height: 1.5rem;
    box-sizing: border-box;
    cursor:pointer;
`

export const NavigationContainer = styled.div`
    background-color: white;
    height: 4rem;
    margin-top: 3px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media ${device.sm} {
        margin-top: 0;
    }
`

export const NavigationSubContainer = styled.div`
    width: 70.5rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    @media ${device.lg} {
        width: 100%;
        margin: ${(props) => props.nomargin === "true" ? null : "0 1rem 0 1rem"};
    }
`

export const HomeImage = styled.img`
    cursor:pointer;
`

export const PathContainer = styled.div`
    height: 100%;
    max-width: 9rem;
    width: auto;
    margin-left: ${(props) => props.lang === 'en' ? "0.7rem" : null};
    margin-right: ${(props) => props.lang === 'ar' ? "0.7rem" : null};
    display: flex;
    justify-content: start;
    align-items: center;
    color: #727272;
    color: ${(props) => props.$color ? props.$color : null};
    cursor:pointer;
    pointer-events: ${(props) => props.disabled ? 'none' : null};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media (max-width: 300px){
        font-size: 14px;
    }
`

export const ArrowIconImage = styled.img`
    transform: ${(props) => props.lang === 'en' ? "rotate(180deg)" : null};
    margin-right: ${(props) => props.lang === 'en' ? "10px" : null};
    margin-left: ${(props) => props.lang === 'ar' ? "10px" : null};
`

// Loading Area

export const LoadingArea = styled.div`
    width: 100%;
    min-height: 40rem;
    display: flex;
    justify-content: center;
    align-items: center;
`

// Empty Folder Styles

export const EmptyFolderContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 800;
    color: #CA56A1;
    font-size: 2.5rem;
    gap: 2rem;
    margin-top: 12rem;
    text-align: center;
    @media ${device.md} {
        margin-top: 13.5rem;
        font-size: 1.5rem;
        gap: 1rem;
    }
    @media ${device.xs} {
        font-size: 1.3rem;
        gap: 0.7rem;
    }
`

export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const Directions = styled.div`
    font-weight: normal;
    font-size: 1rem;
    color: black;
    @media ${device.xs} {
        font-size: 0.9rem;
    }
`


export const NavigationName = styled.div`
    max-width: 10rem;
    height: auto;
    width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media ${device.sm} {
        width: 100%;
        font-size: 14px;
    }
`