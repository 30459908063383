import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    routesArray : [],
    fileTransferRoute : [],
    fileTransferRouteUpdated: false,
}

const NavigationSlice = createSlice({
    name:'navigationData',
    initialState,

    reducers:{
        updateRoutesArray:(state, action) => {
            if(action.payload.data !== ""){
                let lastElement = state.routesArray.length - 1;
                if(state.routesArray[lastElement] && action.payload.data !== state.routesArray[lastElement].name){
                    state.routesArray[lastElement]['color'] = '#CA56A1';
                    state.routesArray.push({name: action.payload.data, color: '#727272', id: action.payload.id});
                }
                if(!state.routesArray[lastElement]){
                    state.routesArray.push({name: action.payload.data, color: '#727272', id: action.payload.id});
                }
            }
        },

        removeRoutesArray:(state) => {
            state.routesArray.pop();
            let lastElement = state.routesArray.length - 1;
            if(state.routesArray[lastElement]){
                state.routesArray[lastElement]['color'] = '#727272';
            }
        },

        emptyRoutesArray: (state) => {
            state.routesArray = [];
        },

        initialFileTransferRoute: (state, action) => {
            if(!state.fileTransferRouteUpdated){
                state.fileTransferRouteUpdated = true;
                state.fileTransferRoute = state.routesArray
            }
        },

        updateFileTransferRoute:(state, action) => {
            let lastElement = state.fileTransferRoute.length - 1;
            if(state.fileTransferRoute[lastElement] && action.payload.data !== state.fileTransferRoute[lastElement].name){
                state.fileTransferRoute[lastElement]['color'] = '#CA56A1';
                state.fileTransferRoute.push({name: action.payload.data, color: '#727272', id: action.payload.id});
            }
            if(!state.fileTransferRoute[lastElement]){
                state.fileTransferRoute.push({name: action.payload.data, color: '#727272', id: action.payload.id});
            }
            
        },

        removeFileTransferRoute:(state) => {
            state.fileTransferRoute.pop();
            let lastElement = state.fileTransferRoute.length - 1;
            if(state.fileTransferRoute[lastElement]){
                state.fileTransferRoute[lastElement]['color'] = '#727272';
            }
        },

        emptyFileTransferRoute: (state) => {
            state.fileTransferRoute = [];
        },

        setFileTransferRouteUpdated : (state) => {
            state.fileTransferRouteUpdated = false;
        }
    }
})

export const {updateRoutesArray, removeRoutesArray, emptyRoutesArray, setFileTransferRouteUpdated, initialFileTransferRoute, updateFileTransferRoute, removeFileTransferRoute, emptyFileTransferRoute} = NavigationSlice.actions;
export default NavigationSlice.reducer;