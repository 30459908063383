import React from 'react'
import {
  ButtonBack,
  ButtonDelete,
  DeleteButtonDiv,
  DeleteImg,
  DeleteInnerPopUp,
  DeleteMid,
  DeletePop,
  ParaDelete,
  TextDelete,
} from "./DeletePopUp.styles.js";
import { MdErrorOutline } from "react-icons/md";
import {deleteLang} from '../../data/Delete.lang.js';
import { useSelector } from 'react-redux';

const DeletePopUp = ({handleBack, handleDelete}) => {
  const lang = useSelector((state) => state?.lang?.language);
  return (
       <DeletePop>
        <DeleteInnerPopUp>
          <DeleteImg>
          <MdErrorOutline style={{fontSize:"80px",color:"#EF0E01",marginTop:"10px"}}/>
          </DeleteImg>
          <DeleteMid>
            <TextDelete>
              {deleteLang[lang].TextDelete}
            </TextDelete>
            <ParaDelete>
                {deleteLang[lang].ParaDelete}
            </ParaDelete>
          </DeleteMid>
          <DeleteButtonDiv>
            <ButtonBack onClick={handleBack} lang={lang}> 
                {deleteLang[lang].Revert}
            </ButtonBack>
            <ButtonDelete onClick={handleDelete} lang={lang}>
                {deleteLang[lang].YesDelete}
            </ButtonDelete>
          </DeleteButtonDiv>
        </DeleteInnerPopUp>
      </DeletePop>
  )
}

export default DeletePopUp
