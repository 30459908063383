import styled from "styled-components";
import { device, size } from "../../Breakpoints";

export const FooterContainer = styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #4C4C4C;
    gap:2.5rem;
    @media ${device.lg} {
        padding: 1.5rem 1rem 1.5rem 1rem;
    }
    @media ${device.md} {
        align-items: start;
        gap: 0;
    }
`

export const FooterColContainer = styled.div`
    width: 70.5rem;
    display:flex;
    flex-direction: row;
    margin-top: 2.5rem;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    @media ${device.lg} {
        width: auto;
    }
    @media ${device.md} {
        flex-direction: column;
        width: 26rem;
        gap: 1.5rem;
    }
    @media ${device.sm} {
        width: auto;
        margin-top: 0.5rem;
    }
`

export const Heading = styled.div`
    margin-bottom:0.5rem;
    font-weight: bold;
    color: #252525;
    font-size: 18px;
`

export const ColumnDataContainer = styled.div`
    display:flex;
    flex-direction: column;
    border-left: ${(props) => props.lang === "en" && !props.noborder ? '1px solid #EEEEEE' : null};
    border-right: ${(props) => props.lang === 'ar' && !props.noborder ? '1px solid #EEEEEE' : null};
    justify-content: center;
    align-items: start;
    padding-right: ${props => props.lang === 'ar' && props.noborder ? null : "2rem"};
    padding-left: ${props => props.lang === 'en' && !props.noborder ? "2rem" : null};
    width: ${(props) => props.width ? props.width : '12rem'};
    text-align: left;
    gap: 0.5rem;
    position: relative;
    box-sizing: border-box;
    @media ${device.lg} {
        width: 100%;
        padding-right: 1.5rem;
    }
    @media ${device.md} {
        padding-right: 0;
        border-left: none;
        border-right: none;
        padding: 0 0 1.5rem 0;
        gap: 0.8rem;
    }
`

export const BottomBorderLine = styled.div`
    position: absolute;
    width: 10rem;
    border: 1px solid #EEEEEE;
    bottom: 0;
    @media (min-width: ${size.md}) {
        display : none;
    }

`

export const DataContainer = styled.a`
    display: flex;
    gap: 0.5rem;
    width: auto;
    color:#727272;
    text-decoration: none
`

export const IconsContainer = styled.div`
    display:flex;
    gap:0.5rem;
    cursor: pointer;
   ;
`

export const FooterDescription = styled.div`
    text-align: ${(props) => props.lang === 'ar' ? "right" : null};
`

export const CopyrightContainer = styled.div`
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #EEEEEE;
    width: 100%;
    height: 3rem;
    padding-top: 1.8rem;
    gap: 1.5rem;
    margin-bottom: 1rem;
    @media ${device.md} {
        height: auto;
        flex-direction: column;
        align-items: center;
        margin-top: 1rem;
        text-align: center;
}
`

export const PaymentIconsContainer = styled.div`
    display: flex;
    gap: 1rem;
    width: auto;
    @media (max-width: 300px){
        flex-direction: column;
    }
`

export const ArrowIconImage = styled.img`
    transform: ${(props) => props.lang === 'en' ? "rotateY(180deg)" : null}
`