import React from "react";
import Hero from "../Components/Hero/Hero";
import Stories from "../Components/AboutHeroSection/Stories";
import Blogs from "../Components/Blogs/Blogs";
// import About from "../Components/AboutUs/About";
// import ServicesPro from "../Components/ServicesProvided/ServicesPro";
import HomeSearch from "../Components/HomeSearch/HomeSearch";
import Value from "../Components/Value/Value";
import Recentdata from "../Components/Blogs/Recent";
import Work from "../Components/Working/work";

const HomeContainer = () => {
  const style = {
    width: "100%",
    overflowX: "hidden",
  };
  return (
    <div style={style}>
      <HomeSearch/>
      <Hero />
      <Blogs />
      <Recentdata/>
      <Work/>
      <Value/>
      <Stories />
      {/* <About /> */}
      {/* <ServicesPro /> */}
    </div>
  );
};

export default HomeContainer;
