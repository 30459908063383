import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
const CircularProgress = ({currentFlashCardIndex,length,percentage}) => {
  const radius = 50;
  const strokeWidth = 7;
  const normalizedRadius = radius - strokeWidth * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (percentage / 100) * circumference;
  // const location = useLocation();
  // const serializedData = localStorage.getItem('FlashCards');
  // const data = JSON.parse(serializedData);
  // console.log(data)
  // const StatsElements = data?.stats; 
  // length = StatsElements?.unvisited + StatsElements?.needs_review + StatsElements?.mastered;
  // console.log(StatsElements);
  // const questions = useSelector((state) => state?.Quiz?.QuizData?.questions);
  // const mcqObjects = questions?.filter((obj) => obj.type === "mcq");

  // if(location.pathname === '/Flash/StarTest/Quiz'){
  //   length = mcqObjects.length;
  // }
  return (
    <ProgressBarWrapper>
      <Svg height={radius * 2} width={radius * 2}>
        <BackgroundCircle
          stroke="#ddd"
          fill="transparent"
          strokeWidth={strokeWidth}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <ProgressCircle
          stroke="#CA56A1"
          fill="transparent"
          strokeWidth={strokeWidth}
          strokeDasharray={circumference + ' ' + circumference}
          style={{ strokeDashoffset }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
      <PercentageText x="50%" y="50%" textAnchor="middle" stroke="#000" fontSize="17px" dy=".3em">
        {currentFlashCardIndex+1}/{length}
      </PercentageText>
      </Svg>
    </ProgressBarWrapper>
  );
};

const ProgressBarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Svg = styled.svg`
  display: block;
`;

const BackgroundCircle = styled.circle``;

const ProgressCircle = styled.circle``;

const PercentageText = styled.text``;

export default CircularProgress;
