import styled from "styled-components";
import bgimg from "../../Assets/Images/bg_filter.png"
import { media } from "../../Assets/Styles/utils";
import AlmaraiFont from "../../Assets/fonts/Almarai-Regular.ttf";


export const Filterdatacontainer=styled.div`
 background-image: url(${bgimg});
 background-repeat: no-repeat;
 background-color:rgba(202, 86, 161, 0.05);
 width: 100%;
 background-size: cover;
 min-height: 90vh;
 
`

export const Filterdatawrapper=styled.div`
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    gap:0;
    padding-top: 5%;
    
`

export const Header=styled.h1`
  margin: 0;
  font-size: 2.6rem;
  font-weight:700;
  font-style:${AlmaraiFont};
  ${media("medium")}{
   font-size:1.6rem
  }
`

export const Description=styled.p`
font-size: 1.2rem;
font-weight:400;
font-style:${AlmaraiFont};
display: flex;
align-items: center;
text-align: center;
justify-content: center;
padding: 0 20%;
color:#727272;
${media("medium")}{
   font-size:1rem;
   padding :0;
}
`

export const Selectionfeature=styled.div`
display: flex;
flex-direction:row;
gap: 20px;
margin-top:3%;
align-items: center;
justify-content: center;
background-color:white;
padding:20px;
width:60%;
${media("medium")}{
    flex-direction:column;
    width:80%;  
}
`;

export const Dropdown=styled.div`
 
 height:40px
 gap:0;
 cursor:pointer;
 p{
    padding:0;
    margin:0;
    width:100%;
    font-size:0.8rem;
    color:#727272;
 }
 select{
    border:none;
    width:220px;
    cursor:pointer;
    background-color:white !important;
    option{
         color:black;
         background-color:white;
      }
 }
   select:focus{
      outline:none;
   }
 ${media("medium")}{
      width:100%;
      margin-bottom:20px;
      display:flex;
      flex-direction:column;
      justify-content:center;
      align-items:center;
      select{
         width:100%;
      }
   }
`;

export const DropdownButton=styled.button`
   font-family: "Almarai",sans-serif;
   font-weight:800;
   background-color: #ca56a1;
   color:white;
   width: 250px;
   height: 40px;
   border: none;
   border-radius: 5px;
   cursor:pointer;
   ${media("medium")}{
      width:100%;
   }
`;

{/* Library Data */}
export const Librarydatacontainer=styled.div`
 background-color:#f5f5f5;
 width: 100%;
 min-height: 60vh;
 display: flex;
 justify-content: center;

`
export const Librarydatawrapper=styled.div`
   display: flex;
   flex-direction:column;
   align-items: center;
   gap:0;
   width:80%;
   padding-top:3%;
   `

export const Librarydataheader=styled.h1`
   margin: 0;
   font-size: 1.5rem;
   font-weight:700;
   font-style:${AlmaraiFont};
   margin-bottom: 0;
   `

export const Librarydatacontent=styled.p`
   font-size: 1.2rem;
   text-align: center;
   align-items: center;
   justify-content: center;
   padding: 0 20%;
   color:#727272;
   ${media("medium")}{
      padding:0;
   }
   `

export const Librarydatacard=styled.div`
   display: flex;
   flex-direction:row;
   gap: 20px;
   align-items: center;
   justify-content: center;
   background-color:white;
   padding:20px;
   border:1px solid black;
   ${media("medium")}{
      
   }
   `;

{/* Interactivedata */}
export const Interactivedatacontainer=styled.div`
   background-color:rgba(202, 86, 161, 0.05);
   width: 100%;
   height: 50vh;
   display: flex;
   justify-content: center;
   overflow:hidden;
   ${media("medium")}{
      height:80vh;
   }
   ${media("small")}{
      height:auto;
   }
  
   `;

export const Interactivedatawrapper=styled.div`
   display: flex;
   width:80%;
   flex-direction:column;
   // justify-content: center;
   align-items: center;
   padding: 20px;
   gap:0;
   ${media("medium")}{
      width:90%;
   }
}
${media("small")}{
   width:100%;
}
   `;

   export const LibPublic=styled.div`
     display:flex;
     width:90%;
     gap:20px;
     padding-top:20px;
     padding-bottom:20px;
     overflow-x:scroll;
     white-space:nowrap;
     overflow-y: hidden;

     &::-webkit-scrollbar {
      height: ${(props) => (props.showScrollbar!==true ? '8px' : '0')};
   }
    &::-webkit-scrollbar-track {
      background: #727272; 
      border-radius:20px;     
    }
    &::-webkit-scrollbar-thumb {
      background-color: #ca56a1;  
      border-radius: 20px;        
    }
    
   `;

   {/* overview */}
   export const Overviewcontainer=styled.div`
   background-color:#f5f5f5;
   width: 100%;
   min-height: 95vh;
   display: flex;
   flex-direction:column;
   justify-content: start;
   align-items: center;
   background-image: url(${bgimg});
   background-repeat: no-repeat;
 background-color:rgba(202, 86, 161, 0.05);
 background-size: cover;
   `;

   export const OverviewError=styled.div`
    color:#ca56a1;
    font-size:1.2rem;
    margin-top:10%;
   `;

   export const Error=styled.div`
   color:#ca56a1;
   font-size:2rem;
   margin-top:10%;
   `;

   export const OptionsModal = styled.div`
   position:absolute;
   z-index:10;
    background-color: white;
    right: ${(props) => props.lang === 'en' ? '6rem' : null};
    left: ${(props) => props.lang === 'ar' ? '6rem' : null};
    top: ${(props) => props.insideShare === "true" ? "8rem": "3rem"};
    width: 10rem;
    height: 7rem;
    height: auto;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
    padding: 10px 0 10px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: ${props => props.insideShare === "true" ? "row" : "column"};
    justify-content: center;
    align-items: center;
    gap: ${props => props.insideShare === "true" ? null : "8px"};
    ${media("small")} {
      right: ${(props) => props.lang === 'en' ? '9rem' : null};
      left: ${(props) => props.lang === 'ar' ? '9rem' : null};
    }
` 
export const LibSec=styled.div`
position:relative
`;
