import React, { useEffect, useRef, useState } from "react";
import { MdNavigateNext } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import correct from "../../Assets/Images/Success.png";
import flag from "../../Assets/Images/flag.png";
import wrong from "../../Assets/Images/wrong.png";
import FlashCardExplainIcon from "../../Assets/Images/FlashCardExplainIcon.svg";
import FlashCardExplainCloseIcon from "../../Assets/Images/FlashCardExplainCloseIcon.svg";
import { GrFormPrevious } from "react-icons/gr";
import { IoCheckmarkOutline } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";


import { ChatbotContainer, ChatbotContainerFixed, HeaderButton, LoadingAnimation } from "../FlashCardLearning/StartLearn.styles";
import {
  BottomData,
  BottomDiv,
  ExplanationContainer,
  Flag,
  HorizontalLine,
  LeftBottomDiv,
  NextQues,
  NumberingContainer,
  OptionContainer,
  Ques,
  RightBottomDiv,
  StyledInput,
  SubmitFillUp,
  TestContainer,
  TestData,
  TestDiv,
  TestHead,
  TestLower,
  TestMiddle,
  TestMiddleDiv,
  TestNumberDiv,
  TestOne,
  TestOpt,
  TestUpper,
  UpperContainer,
  UpperInnerDiv,
} from "./Quiz.styles";

import TopBar from "../FlashCardLearning/TopBar";
import CircularProgress from "../FlashCardLearning/CircularProgress";
import { FlashCardQuiz } from "../data/FlashCardQuiz.lang";
import MyChatbot from "../ChatBot/Bot";
import ApiClient from "../../Config/index.config";
import ExhausteModel from "../Modals/ExhaustModals/ExhaustModals";
import SubscriptionModal from "../Modals/SubscriptionModal/SubscriptionModal";


const Quiz = () => {
  const navigate = useNavigate();
  const explainRef = useRef();
  const inputRef = useRef();

  const document_id = useSelector((state) => state?.FlashCards?.document_id);
  const botSubcriptionVisibility  = useSelector(state => state?.subscriptionVisibility?.isVisible);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isAnswered, setIsAnswered] = useState(false);
  const questions = useSelector((state) => state?.Quiz?.QuizData?.questions);
  const optionNumbersEnglish = ["a", "b", "c", "d"];
  const optionNumbersArabic = ["أ", "ب", "ج", "د"];
  const lang = useSelector((state) => state?.lang?.language);
  const [borderPercentage, setBorderPercentage] = useState(100 / questions?.length);
  const [quiz, setquiz] = useState({correct: 0, wrong: 0});
  const [explanationData, setExplanationData] = useState(null)
  const [explanationLoading, setExplanationLoading] = useState(false);
  const [LimitExhaustModal, setLimitExhaustModal] = useState(false);
  const [explanVisibility, setExplanVisibility] = useState(false);
  const [subscriptionVisibility, setSubscriptionVisibility] = useState(false)
  const [inputStyle, setInputStyle] = useState(null);
  const [fillUpAnswer, setFillUpAnswer] = useState(null)
  
  const exhaustModalData = 
    [   
        // Flash Cards Exhausted Data
        {LimitHeading: {ar: "المحاولات المجانية اليومية", en: "Daily free trails"},
        Limit: {ar: "10", en: "10"},
        Heading: {ar: "لقد تم استنفاد التفسير المجاني", en: "The free explanation have been exhausted"},
        SubHeading: {ar: "للاستفادة والاستمتاع بالشرح اللامتناهي يجب عليك الاشتراك", en: "To enjoy the endless explanation, you must subscribe to"},
        Mumayaz: {ar: "غير محدود", en: "unlimited"},
        Free: {ar: "10 م.ب / الملف", en: "10 attempts/day"},
        Advantage: {ar: "توضيح", en: "Explanation"}},
    ]

  const nextQuestion = () => {

    // Handeling the FillUps functionality
    if(inputRef.current){
      setInputStyle(null);
      setFillUpAnswer(null);
      inputRef.current.value = null;
      inputRef.current.disabled = false;
    }

    setExplanVisibility(false)
    let UpdatedBorder = borderPercentage + 100 / questions?.length;
    if (currentQuestionIndex + 2 > questions?.length) {
      navigate("/Flash/TestResult", { state: { data: quiz } });
    }
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    setBorderPercentage(UpdatedBorder);
    setIsAnswered(false);
    setSelectedOption(null);
  };

  const handleOptionClick = (index) => {
    if (!isAnswered) {
      setSelectedOption(index+1);
      setIsAnswered(true);
      if (index+1 === questions[currentQuestionIndex].correct_ans) {
        setquiz((prevState) => ({
          ...prevState,
          correct: prevState.correct + 1,
        }));
      } else {
        setquiz((prevState) => ({
          ...prevState,
          wrong: prevState.wrong + 1,
        }));
      }
    }
  };

  const getExplanation = async (currentQuestionIndex) => {
    try{
      const response = await ApiClient.get(`/quiz/${document_id}/${questions[currentQuestionIndex].id}/explain`)
      setExplanationData(response.data.data.explanation);
    }catch(error){
      console.log(error)
      setLimitExhaustModal(true)
      if(error?.response?.data?.error_code === 1043){
        setLimitExhaustModal(true);
        setExplanVisibility(false);
      }
    }
    setExplanationLoading(false)
  }
  
  const handleExplain = (currentQuestionIndex) => {
    if(!explanVisibility){
      setExplanVisibility(true)
      setExplanationLoading(true);
      getExplanation(currentQuestionIndex)
    }
    else{
      setExplanVisibility(false)
    }
  }


  const handleInput = (e) => {
    setFillUpAnswer(e.target.value)
    if(!e.target.value){
      setInputStyle(null)
    }
  }

  const handleInputAnswer = (e, answer) => {
    e.preventDefault();
    inputRef.current.disabled = true;
    setIsAnswered(true);
    if(fillUpAnswer === answer){
        setInputStyle({"backgroundColor": "rgba(3, 152, 85, 0.05)", "border": "1px solid #039855"})
        setquiz((prevState) => ({
          ...prevState,
          correct: prevState.correct + 1,
        }));
      }
      else{
        setInputStyle({"backgroundColor": "rgba(239, 14, 1, 0.05)", "border": "1px solid #F55157"})
        setquiz((prevState) => ({
          ...prevState,
          wrong: prevState.wrong + 1,
        }));
      }
  }

  useEffect(() => {
    window.scrollTo(0, explainRef.current?.offsetTop, {behaviour : "smooth"})
  },[explanVisibility])

  return (
    <TestContainer>
      <TopBar containReport="true" />
      {questions != null?
          <TestMiddleDiv>

              <ChatbotContainer>
                  <MyChatbot insideQuiz={true}/>
              </ChatbotContainer>
              <ChatbotContainerFixed>
                  <MyChatbot insideQuiz={true} fixed={true}/>
              </ChatbotContainerFixed>
              
              <TestMiddle>
                    <TestHead>
                        <TestData lang={lang}>
                            <TestUpper>{FlashCardQuiz[lang].TestHeading1}</TestUpper>
                            <TestLower>
                                {FlashCardQuiz[lang].TestHeading2}
                            </TestLower>
                        </TestData>

                        <TestNumberDiv lang={lang}>
                            <CircularProgress
                              currentFlashCardIndex={currentQuestionIndex}
                              length={questions?.length}
                              percentage={borderPercentage}
                            />
                        </TestNumberDiv>
                    </TestHead>

                    <TestDiv>
                        <UpperContainer>
                            <UpperInnerDiv>
                                <div>
                                    {FlashCardQuiz[lang].FirstQuiz}
                                    <div style={{ fontSize: "14px", color: "#727272", fontWeight: "normal"}}>
                                      {FlashCardQuiz[lang].CorrectAns}
                                    </div>
                                </div>
                            </UpperInnerDiv>
                            {isAnswered ?
                                <HeaderButton onClick={() => handleExplain(currentQuestionIndex)} style={{color: "#FFAF44"}}>
                                    {explanVisibility ? 
                                      <img src={FlashCardExplainCloseIcon} alt=""/>
                                      : <img src={FlashCardExplainIcon} alt=""/>
                                    }
                                    {FlashCardQuiz[lang].Explanation}
                                </HeaderButton>
                            : null
                            }
                        </UpperContainer>

                        <Ques lang={lang}>
                            {questions[currentQuestionIndex]?.title}
                        </Ques>
                        
                        <TestOpt lang={lang}>
                              {/* MCQ */}
                              {Object.keys(questions[currentQuestionIndex].options).map(
                                  (key, index) => (
                                    <TestOne lang={lang} key={index} onClick={() => handleOptionClick(index)}
                                        style={{
                                          backgroundColor:
                                            isAnswered &&
                                            index+1 === questions[currentQuestionIndex].correct_ans
                                              ? "rgba(3, 152, 85, 0.05)"
                                              : isAnswered && selectedOption === index+1
                                              ? "rgba(239, 14, 1, 0.05)"
                                              : null,
                                          border:
                                            isAnswered &&
                                            index+1 === questions[currentQuestionIndex].correct_ans
                                              ? "1px solid #039855"
                                              : isAnswered && selectedOption === index+1
                                              ? "1px solid #F55157"
                                              : null,
                                          cursor: isAnswered ? "not-allowed" : "pointer",
                                        }}>
                                        <NumberingContainer>
                                              {lang === "en" ? optionNumbersEnglish[index] + " - " : optionNumbersArabic[index] + " - "}
                                        </NumberingContainer>
                                        <OptionContainer>{questions[currentQuestionIndex].options[key]}</OptionContainer>
                                    </TestOne>)
                              )}
                              {/* True/False */}
                              {questions[currentQuestionIndex].type === "true_false" ?
                              <>
                                {[0, 1].map((index) => (
                                  <TestOne lang={lang} key={index} onClick={() => handleOptionClick(index)}
                                      style={{
                                        backgroundColor:
                                          isAnswered &&
                                          index+1 === questions[currentQuestionIndex].correct_ans
                                            ? "rgba(3, 152, 85, 0.05)"
                                            : isAnswered && selectedOption === index+1
                                            ? "rgba(239, 14, 1, 0.05)"
                                            : null,
                                        border:
                                          isAnswered &&
                                          index+1 === questions[currentQuestionIndex].correct_ans
                                            ? "1px solid #039855"
                                            : isAnswered && selectedOption === index+1
                                            ? "1px solid #F55157"
                                            : null,
                                        cursor: isAnswered ? "not-allowed" : "pointer",
                                      }}>
                                      <NumberingContainer>{index === 0 ? <IoCheckmarkOutline/> : <RxCross2/>}</NumberingContainer>
                                      {index === 0 ?
                                        lang === "en" ? "True" : "صح"
                                        :
                                        lang === "en" ? "False" : "خطأ"
                                      }
                                  </TestOne>
                                ))}
                              </>
                              : null
                              }
                              {/* Fill Ups */}
                              {questions[currentQuestionIndex].type === "fill_ups"?
                                <TestOne style={inputStyle}>
                                  <form 
                                  style={{display: "flex", width: "100%", alignItems:'center', height:"100%"}}
                                  onSubmit={(e) => handleInputAnswer(e, questions[currentQuestionIndex].answer)}>
                                    <StyledInput ref={inputRef} onChange={(e) => handleInput(e)} type="text" placeholder={FlashCardQuiz[lang].PlaceHolderText}/>
                                    {fillUpAnswer && !isAnswered ?
                                        <SubmitFillUp type="submit" onClick={(e) => handleInputAnswer(e, questions[currentQuestionIndex].answer)}>{FlashCardQuiz[lang].SubmitButton}</SubmitFillUp>
                                    : null
                                    }
                                  </form>
                                </TestOne>
                              : null
                            }
                            {isAnswered  && questions[currentQuestionIndex].type === "fill_ups"
                            && questions[currentQuestionIndex].answer !== fillUpAnswer
                            ?
                            <div style={{color: "#252525", fontWeight:"bold"}}>
                              {FlashCardQuiz[lang].CorrectAnswer} : <span style={{color: "#35CF75"}}>&nbsp;{questions[currentQuestionIndex].answer}</span>
                            </div> 
                            : null
                            }
                              
                        </TestOpt>
                        
                        {explanVisibility ?
                        <ExplanationContainer absolute ref={explainRef}>
                         { explanationLoading ? <LoadingAnimation></LoadingAnimation> : explanationData }
                        </ExplanationContainer>
                      : null}

                    </TestDiv>
              </TestMiddle>

          </TestMiddleDiv>
      : null}
      <BottomDiv>
          <BottomData>

              <LeftBottomDiv>
                  {isAnswered && selectedOption !== null && (
                    <>
                        <img style={{ height: "30px" }}
                          src={selectedOption === questions[currentQuestionIndex].correct_ans ? correct : wrong}
                          alt="logo" />
                        { lang === "en" ?
                          (selectedOption === questions[currentQuestionIndex].correct_ans ?
                            "Keep shining" :
                            "We will show you another attempt at the question during the test"
                          ) :
                          (selectedOption === questions[currentQuestionIndex].correct_ans ?
                            "كفو! يبدوا إنك دافور الشعبة " :
                            "ركز، بنعطيك فرصة ثانية "
                          )
                        }
                    </>
                  )}
              </LeftBottomDiv>

              {isAnswered ? <HorizontalLine></HorizontalLine> : null}

              <RightBottomDiv>
                  <Flag>
                      <img style={{ marginRight: lang === "en" ? "12px" : "0", marginLeft: lang === "en" ? "0" : "12px" }} src={flag} alt="" />
                      {FlashCardQuiz[lang].Report}
                  </Flag>

                  {isAnswered ?
                      <NextQues onClick={nextQuestion} isAnswered={isAnswered}>
                          {FlashCardQuiz[lang].NextQuestion}
                          {lang === 'en'? <MdNavigateNext /> : <GrFormPrevious />}
                      </NextQues>
                      :
                      <NextQues isAnswered={isAnswered}>
                          {FlashCardQuiz[lang].NextQuestion}
                          {lang === 'en'? <MdNavigateNext /> : <GrFormPrevious />}
                      </NextQues>
                   }
              </RightBottomDiv>
              
          </BottomData>
      </BottomDiv>
      {LimitExhaustModal ?
        <ExhausteModel setLimitExhaustModal={setLimitExhaustModal} data={exhaustModalData[0]}/>
        :null
      }
      {botSubcriptionVisibility ?
        <SubscriptionModal subscriptionVisibility={subscriptionVisibility} setSubscriptionVisibility={setSubscriptionVisibility}/>
        :null
      }
    </TestContainer>
  );
};

export default Quiz;
