import React from 'react'
import { ValueContainer, ValueDesc, ValueHead, ValueWrapper } from '../Value/Value.style'
import { Card, Cardwrapper, ResponsiveImage } from './work.style'
import { useSelector } from 'react-redux';
import { worklang } from '../data/Home.lang';
import step1 from "../../Assets/Images/step1.png";
import step2 from "../../Assets/Images/step2.png";
import step3 from "../../Assets/Images/step3.png";
import img from '../../Assets/Images/workrout.png';


const Work = () => {
    const lang=useSelector(state=>state?.lang?.language);
  return (
    <ValueContainer>
    <ValueWrapper>
        <ValueHead>{worklang[lang]?.heading}</ValueHead>
        <ValueDesc>{worklang[lang]?.description}</ValueDesc>
         <div style={{position:"relative"}}>
        <Cardwrapper>
            <Card>
                <img src={step1} alt="card1"/>
                <h3>{worklang[lang]?.step1head}</h3>
                <p>{worklang[lang]?.step1desc}</p>
            </Card>
            <Card>
                <img src={step2} alt="card2"/>
                <h3>{worklang[lang]?.step2head}</h3>
                <p>{worklang[lang]?.step2desc}</p>
            </Card>
            <Card>
                <img src={step3} alt="card3"/>
                <h3>{worklang[lang]?.step3head}</h3>
                <p>{worklang[lang]?.step3desc}</p>
            </Card>
        </Cardwrapper>
        <ResponsiveImage src={img} alt="route" />
        </div>
    </ValueWrapper>
</ValueContainer>
  )
}

export default Work