import styled from "styled-components";
import { media } from "../../Assets/Styles/utils";
import "../../Assets/fonts/Almarai-Regular.ttf";
import AlmaraiFont from "../../Assets/fonts/Almarai-Regular.ttf";
import bg from "../../Assets/Images/UploadPDFBackground.svg";

export const BlogBack = styled.img`
  position: absolute;
  // left: 86.58%;
  margin-top: -5.5%;
  overflow: hidden;
`;
export const BlogContainer = styled.div`
 display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #E4DAF1;

`;
export const BlogWrapper = styled.div`
min-height: 415px;
width: 80%;
padding: 10px;
padding-right: 0px;
background: #E4DAF1;
display: flex;
flex-direction: column;
align-items: center;
${media("medium")} {
  height: 100%;
  width: 100%;
}
`;
export const BlogHeading = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  padding: 3%;
  ${media("medium")} {
    font-size: 30px;
  }
`;
export const HeadingText = styled.span`
  font-family: ${AlmaraiFont};
  font-size: 32px;
  font-weight: 700;
  line-height: 51px;
  letter-spacing: 0.9px;
  text-align: center;
  color: black;
  ${media("medium")} {
    font-size: 30px;
  }
`;
export const SubHeadingText = styled.span`
  font-family: ${AlmaraiFont};
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: center;
  color: #727272;
`;
export const CardsContainer = styled.div`
  margin-top: -10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 30px;
  gap: 20px;
  ${media("medium")} {
    flex-direction: column;
  }
`;
export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: white;
  width: 25%;
  border-radius: 8px;
  border: 1px;
  padding: 10px;
  gap: 12px;
  justify-content: center;
  align-items: center;
  ${media("medium")} {
    width: 90%;
  }
`;
export const CardsImage = styled.div`
  padding: 15px;
  border-radius: 50%;
  transition: background-color 0.5s ease;
  &:hover {
    background: #ca56a1;
  }
`;
export const CardText = styled.div`
  text-align: left;
`;
export const CardTextBlt = styled.span`
  font-family: ${AlmaraiFont};
  font-size: 14px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #252525;
`;

export const CardTextBls = styled.span`
  font-family: ${AlmaraiFont};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #727272;
`;
export const CardsDoc = styled.div``;
export const LibBlog = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 30px;
  width:80%;
  ${media("small")} {
    flex-direction: column;
    padding: auto;
  }
`;
